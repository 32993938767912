import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
// i18next
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import { RootState } from 'store';
import IQuiz from 'models/Quiz';
import IBucket from 'models/Bucket';
import IQuestion from 'models/Question';
import IAnswer from 'models/Answer';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { getQuiz, updateQuiz } from 'store/quiz/quizzesAsync';
// Actions
import { updateForm, deleteForm, setHash } from 'store/form/formSlice';
import { setInitialField } from 'store/quiz/quizzesSlice';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectFormByQuizId } from 'store/form/formSelectors';
import { getNamespaceItems } from 'store/namespace/namespaceSelectors';
import { getUpdateCorrectAnswersLoading } from 'store/result/resultSelectors';
// Components
import { Button } from 'components/Buttons';
import QuizFormSidebar from 'components/QuizFormSidebar';
import { QuizToolbar } from 'components/QuizToolbar';
// Initial form
import { initialQuizForm } from 'store/form/formInitial';
// Utilties
import { encodeBase64 } from 'utilities/utilities';
// 
import TabContentQuiz from './TabContentQuiz';
import TabContentQuestions from './TabContentQuestions';
import TabContentPostQuizPages from './TabContentPostQuizPages';
import TabContentCorrectAnswers from './TabContentCorrectAnswers';
import UpdateCorrectAnswerLoader from './UpdateCorrectAnswerLoader';
import TabContentLiveLeaderboard from './TabContentLiveLeaderboard';

const QuizFormPage:React.FC = () => {
  const { quizId } = useParams<{ quizId:string }>();
  const { search } = useLocation<{ search:string }>();

  const params = new URLSearchParams(search);
  const activeTab = params.get('tab');

  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);
  const quizForm:any = useAppSelector((state:RootState) => selectFormByQuizId(state, { quizId }));
  const namespaces = useAppSelector(getNamespaceItems);
  const isLoading = useAppSelector(getUpdateCorrectAnswersLoading);

  const timeout = useRef<any>(null);
  const isMounted = useRef(false);

  const methods = useForm<any>({
    defaultValues: initialQuizForm
  });

  const handleClearTimeout = () => {
    if ( timeout.current ) clearTimeout(timeout.current);
  }

  const getNamespaceSlug = (namespaceId:string | undefined) => {
    if ( !namespaceId ) return null;
    const foundedNamespace = namespaces?.find((namespace:any) => namespace._id === namespaceId);
    if ( !foundedNamespace ) return null;
    return foundedNamespace.slug;
  }

  useEffect(() => {
    dispatch(getQuiz(quizId));
    return () => {
      isMounted.current = false;

      handleClearTimeout();
      dispatch(setInitialField('quiz'));
      dispatch(setHash(null));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( quiz ){
      isMounted.current = true;

      const quizData:any = {...initialQuizForm, ...quiz };

      const nextQuizData = {...quizData, nonce: uuidv4(), clientNameSlug: getNamespaceSlug(quizData.namespace._id) };
      
      if ( !quizForm ){
        dispatch(updateForm({ quizId, quizForm: nextQuizData }));
        dispatch(setHash(encodeBase64(nextQuizData)));
      } else {
        dispatch(setHash(encodeBase64(nextQuizData)));
      }

      if (quiz.registrationPage?.showPriorQuiz !== undefined) nextQuizData['registrationPage'] = { ...quiz.registrationPage, showPriorQuiz: `${quiz.registrationPage?.showPriorQuiz}` }

      const quizFormData = {
        ...nextQuizData,
        registrationPage: quiz.registrationPage ? quiz.registrationPage : null,
        quizGroup: quiz.quizGroup ? quiz.quizGroup._id : '',
        advertVideoEndQuiz: quiz.advertVideo && quiz.advertVideo[0] ? quiz.advertVideo[0].endQuiz : false,
        advertVideoMidQuiz: quiz.advertVideo && quiz.advertVideo[0] ? quiz.advertVideo[0].midQuiz : false,
        advertVideoStartQuiz: quiz.advertVideo && quiz.advertVideo[0] ? quiz.advertVideo[0].startQuiz : false,
        advertVideoUrl: quiz.advertVideo && quiz.advertVideo[0] ? quiz.advertVideo[0].videoUrl : '',
        startsDate: nextQuizData.startsDate ? new Date(Number(nextQuizData.startsDate)) : '',
        endsDate: nextQuizData.endsDate ? new Date(Number(nextQuizData.endsDate)) : '',
        spotPrizes: quiz.spotPrizes ? quiz.spotPrizes.join('') : '',
        namespaceId: nextQuizData.namespaceId || quiz.namespace._id,
        prizes: quiz.prizes ? quiz.prizes.map((prize:string) => ({ prize })) : [],
        integrations: quiz.integrations ? quiz.integrations.map(integration => ({ integrationId: integration._id, value: true })) : [],
      };
      methods.reset(quizFormData);
    }
    // eslint-disable-next-line
  }, [quiz]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      if ( type === 'change' ){
        handleClearTimeout();
        timeout.current = setTimeout(() => {
          dispatch(setHash(null));
          const { advertVideoEndQuiz, advertVideoMidQuiz, advertVideoStartQuiz, advertVideoUrl, ...otherValues } = value;
          const quizForm:IQuiz = {
            ...otherValues,
            advertVideo: [
              {
                endQuiz: value.advertVideoEndQuiz,
                midQuiz: value.advertVideoMidQuiz,
                startQuiz: value.advertVideoStartQuiz,
                videoUrl: value.advertVideoUrl
              }
            ],
            spotPrizes: value.spotPrizes ? [value.spotPrizes] : [],
            prizes: value.prizes ? value.prizes.map((prize:any) => prize.prize) : [],
            startsDate: value.startsDate ? moment(value.startsDate).format('x') : '',
            endsDate: value.endsDate ? moment(value.endsDate).format('x') : '',
            namespace: value.namespaceId,
            nonce: uuidv4(),
            clientNameSlug: getNamespaceSlug(value.namespace)
          };
          dispatch(updateForm({ quizId, quizForm }));
          dispatch(setHash(encodeBase64(quizForm)));
        }, 1000);
      }
    });
    return () => {
      if ( timeout.current ) clearTimeout(timeout.current);
      subscription.unsubscribe();
    }
    // eslint-disable-next-line
  }, [methods.watch]);

  const onSubmit = methods.handleSubmit((data:any) => {
    let questions = data.questions;

    if ( data.type === QuizTypes.Personality ){
      const bucketIds:string[] = data.buckets.map((bucket:IBucket) => bucket.ref);
      questions = data.questions.map((question:IQuestion) => ({
        ...question,
        answers: question.answers.map((answer:IAnswer) => ({
          ...answer,
          bucketScores: answer.bucketScores
            ? answer.bucketScores.filter((bucketScore:any) => bucketIds.includes(bucketScore.ref))
            : []
        }))
      }));
    };

    const { namespaceId, translations, ...otherData } = data;

    const nextTranslations:any = {};

    Object.keys(translations).forEach(key => {
      if (data.additionalLanguages?.includes(key)) nextTranslations[key] = translations[key];
    })

    const newData:any = {
      ...otherData,
      translations: nextTranslations,
      registrationPage: otherData.registrationPage ? {
        ...otherData.registrationPage,
        showPriorQuiz: typeof otherData.registrationPage.showPriorQuiz === 'string' ? otherData.registrationPage.showPriorQuiz === 'true' : otherData.registrationPage.showPriorQuiz
      } : null,
      advertVideo: [
        {
          endQuiz: data.advertVideoEndQuiz,
          midQuiz: data.advertVideoMidQuiz,
          startQuiz: data.advertVideoStartQuiz,
          videoUrl: data.advertVideoUrl
        }
      ],
      questions,
      proceedClickBonusPoints: data.proceedClickBonusPoints ? Number(data.proceedClickBonusPoints) : 0,
      namespace: namespaceId,
      integrations: data.integrations.reduce((acc:string[], cur:any) => {
        if (cur.value) acc.push(cur.integrationId);
        return acc;
      }, []),
    };
    if ( data.startsDate ) newData['startsDate'] = moment(data.startsDate).format('x');
    if ( data.endsDate ) newData['endsDate'] = moment(data.endsDate).format('x');
    if ( [QuizTypes.Scoring, QuizTypes.Prediction].includes(data.type) ){
      newData['spotPrizes'] = data.spotPrizes ? [data.spotPrizes] : [];
      newData['prizes'] = data.prizes ? data.prizes.map((prize:any) => prize.prize) : [];
    }
    const filteredData:any = {};
    Object.keys(newData).forEach((key) => {
      const unnecessaryKeys = ['account', 'updatedBy', 'createdAt', 'updatedAt', '__v', '_id'];
      if (unnecessaryKeys.includes(key)) return;
      // if (newData[key] === null) return;
      // if (typeof newData[key] === 'string' && !newData[key]) return;
      // if (Array.isArray(newData[key]) && !newData[key].length) return;

      filteredData[key] = newData[key];
    })
    updateQuizAsync({ quizId, data: filteredData });
  });

  const updateQuizAsync = async (nextData:any)  => {
    try {
      await dispatch(updateQuiz(nextData)).unwrap();
      dispatch(deleteForm(quizId));
    } catch(err){}
  }

  const handleCancel = () => {
    dispatch(deleteForm(quizId));
    dispatch(getQuiz(quizId));
  };

  const isQuizContentActive = !activeTab || activeTab === 'quiz';
  const isQuestionsContentActive = activeTab === 'questions';
  const isPostQuizPagesContentActive = activeTab === 'postQuizPages';
  const isCorrectAnswersContentActive = activeTab === 'correctAnswers';
  const isLiveLeaderboardContentActive = activeTab === 'liveLeaderboard';

  if ( !quiz ) return null;
  if ( !isMounted.current ) return null;
  return (
    <FormProvider {...methods}>
      <form
        className="
          flex flex-col container w-full h-full max-w-screen-desktop
          overflow-x-hidden
          overflow-y-auto tablet:overflow-y-hidden
        "
        onSubmit={onSubmit}
        noValidate
      >
        <div className="">
          <div className="
            flex
            flex-col tablet:flex-row
            items-start: tablet:items-center
            py-4
          ">
            <QuizToolbar />
            <div>
              <Button
                className="mr-4"
                variant="outlined"
                onClick={handleCancel}
              >{t('ownerPage.quizForm.clearNotUpdatedChanges')}</Button>
              <Button
                type="submit"
                variant="contained"
                loading={isLoading}
              >{t('ownerPage.quizForm.save')}</Button>
            </div>
          </div>
        </div>
        {isLoading ? <UpdateCorrectAnswerLoader /> : (
          <div className="
            overflow-y-visible tablet:overflow-y-auto
            block tablet:flex
            flex-col tablet:flex-row
            mb-4
            flex-grow bg-content rounded
          ">
            <QuizFormSidebar />
            {isQuizContentActive ? <TabContentQuiz /> : null}
            {isLiveLeaderboardContentActive ? <TabContentLiveLeaderboard /> : null}
            {isQuestionsContentActive ? <TabContentQuestions /> : null}
            {isPostQuizPagesContentActive ? <TabContentPostQuizPages /> : null}
            {isCorrectAnswersContentActive ? <TabContentCorrectAnswers /> : null}
          </div>
        )}
      </form>
    </FormProvider>
  )
}

export default QuizFormPage;
