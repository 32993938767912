import { RootState } from "store";

export const getNotifications = (state:RootState) => state.app.notifications;
export const getPublicDeviceSnackbarOpen = (state:RootState) => state.app.publicDeviceSnackbarOpen;
export const getQuizNameSlug = (state:RootState) => state.app.quizNameSlug;
export const getNamespaceSlug = (state:RootState) => state.app.namespaceSlug;

export const selectQuizFormSidebar = (state:RootState) => state.app.quizFormSidebar;
export const selectQuizFormPreview = (state:RootState) => state.app.quizFormPreview;
export const selectQuizFormMenuItem = (state:RootState) => state.app.quizFormMenuItem;
