import { FC, Fragment, useEffect } from 'react';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Actions
import { getCurrentUser } from 'store/currentUser/currentUserAsync';
// Selectors
import { selectIsAuthenticated } from 'store/auth/authSelectors';
// Routing
import AppRouting from './App.routing';
// Components
import { AppLoader } from 'components/AppLoader';
import { Notifications } from 'components/Notifications';
import Header from 'components/Header';
import PublicDeviceSnackbar from 'components/PublicDeviceSnackbar';

const excludedURLs:Array<string> = ['/leaderboard', '/post-quiz', '/cumulative-leaderboard', '/privacy-policy', '/rules'];

const App:FC = () => {
  const excludeAppContent:boolean = excludedURLs.some((url:string) => window.location.pathname.includes(url));
  // Dispatch
  const dispatch = useDispatch();
  // Sttae
  const isAuthenticated:null | boolean = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if ( !excludeAppContent ) dispatch(getCurrentUser(false));
    // eslint-disable-next-line
  }, []);

  if ( !excludeAppContent && isAuthenticated === null ) return <AppLoader />;
  return (
    <Fragment>
      {!excludeAppContent ? <Header /> : null}
      <PublicDeviceSnackbar />
      <AppRouting />
      <Notifications />
    </Fragment>
  );
}

export default App;
