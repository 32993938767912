import React from 'react';

export enum Direction {
  Ascending = 'ascending',
  Descending = 'descending'
}

export interface IConfig {
  key: string;
  direction: Direction
}

export const useSortableItems = (items:Array<any> | null, config:IConfig | null = null) => {
  const [ sortConfig, setSortConfig ] = React.useState<IConfig | null>(config);

  const sortedItems = React.useMemo(() => {
    if ( !items ) return [];
    let sortableItems = [...items];
    if ( sortConfig !== null ){
      sortableItems.sort((a, b) => {
        const aItem = Array.isArray(a[sortConfig.key]) ? a[sortConfig.key][0] : a[sortConfig.key];
        const bItem = Array.isArray(b[sortConfig.key]) ? b[sortConfig.key][0] : b[sortConfig.key];
        if ( aItem < bItem ) return sortConfig.direction === Direction.Ascending ? -1 : 1;
        if ( aItem > bItem ) return sortConfig.direction === Direction.Ascending ? 1 : -1;
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key:string, direction?:Direction) => {
    if ( !direction ){
      direction = Direction.Ascending;
      if ( sortConfig && sortConfig.key === key && sortConfig.direction === Direction.Ascending ){
        direction = Direction.Descending;
      }
      setSortConfig({ key, direction });
    } else {
      setSortConfig({ key, direction });
    }
  }

  const getClassNamesFor = (key:string) => {
    if ( !sortConfig ) return;
    return sortConfig.key === key ? `sort-${sortConfig.direction}` : undefined;
  };

  return {
    items: sortedItems,
    sortConfig,
    requestSort,
    getClassNamesFor,
  };
}