import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
// Utilities
import HttpClient from 'utilities/HttpClient';

const _url:string = '/aiChat';
const _http:HttpClient = new HttpClient();

const AiChatAsync = {
  generateQuestions: createAsyncThunk(
    'aiChat/Generate questions',
    async (data:{message: string}, { rejectWithValue }) => {
      const messages = [
        { role: 'system', content: 'It is necessary to create questions on the topic specified by the user and the specified amount, add answers to them. Mark the correct answer. Also bring back the questions topic. Convert everything to JSON in this model: { topic: String, questions: [{ title: String, answers: [{ title: String, correct: Boolean  }, ...] }, ...] }' },
        { role: 'user', content: data.message },
      ]
      const response:Response = await _http.post(_url, {messages});
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  generateQuestion: createAsyncThunk(
    'aiChat/Generate question',
    async (questionIndex:number, { rejectWithValue, getState }) => {
      const { aiChat } = getState() as RootState;
      const messages = [
        { role: 'user', content: `Create one unique question on the topic: ${aiChat.content?.topic}, add answers and mark the correct answer. Convert everything to JSON in this model: { title: String, answers: [{ title: String, correct: Boolean  }, ...]}` },
      ]
      const response:Response = await _http.post(_url, {messages});
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
}

export default AiChatAsync;
