import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuestion from 'models/Question';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import Question from './Question';
// i18next
import { useTranslation } from 'react-i18next';

const TabContentCorrectAnswers:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const { watch } = useFormContext();
  const watchedQuestions: IQuestion[] = watch('questions') || [];
  const watchedEndsDate = watch('endsDate');

  if (quiz && [QuizTypes.Scoring, QuizTypes.Personality].includes(quiz.type)) return null;
  if (+watchedEndsDate >= new Date().getTime()) return (
    <div className="p-6 w-full">
      <h2 className="text-white text-xl leading-6 font-bold text-center">
        {t('ownerPage.quizForm.correctAnswersDisabledHint')}
      </h2>
    </div>
  )
  return (
    <div className="w-full overflow-y-auto overflow-x-hidden">
      <h3 className="text-white text-md font-semibold px-6 pt-4 pb-3">
        {t('ownerPage.quizForm.correctAnswersHint')}
      </h3>
      <span className="divider"></span>
      {watchedQuestions.map((question, index) => (
        <Question key={question.ref} question={question} index={index} />
      ))}
    </div>
  )
}

export default TabContentCorrectAnswers;
