import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Async
import { getUsers, deleteUser, inviteStaff, inviteOwner, inviteAdministrator, changePassword, recoverPassword } from './userAsync';

interface IState {
  users: IUser[] | null;
  filter: {
    search: string;
    group: UserRoles | string;
  }
  loading: boolean;
};

const initialState:IState = {
  users: null,
  filter: {
    search: '',
    group: ''
  },
  loading: false
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:keyof IState['filter'], value:string }>) => {
      state.filter[action.payload.field] = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    // Get users
    builder.addCase(getUsers.pending, (state) => {
      state.users = null
    })
    builder.addCase(getUsers.fulfilled, (state, action:PayloadAction<any[]>) => {
      state.users = action.payload;
    })
    // Create password
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true
    })
    // Recover password
    builder.addCase(recoverPassword.pending, (state) => {
      state.loading = true
    })
    // Delete user
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteUser.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.users ){
        state.users = state.users.filter((user:IUser) => user._id !== action.payload)
      }
    })
    // Invite staff
    builder.addCase(inviteStaff.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inviteStaff.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users.push(action.payload);
      }
    })
    // Invite owner
    builder.addCase(inviteOwner.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inviteOwner.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users.push(action.payload);
      }
    })
    // Invite administrator
    builder.addCase(inviteAdministrator.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inviteAdministrator.fulfilled, (state, action:PayloadAction<IUser>) => {
      if ( state.users ){
        state.users.push(action.payload);
      }
    })

    builder.addMatcher(
      (action) => action.type.includes('/fulfilled') || action.type.includes('/rejected'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const { setFilter } = slice.actions;

export default slice.reducer;
