import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IBucket from 'models/Bucket';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// 
import PostQuizPage from './PostQuizPage';

const TabContentPostQuizPages = () => {
  const quiz = useAppSelector(selectQuiz);

  const { control, getValues } = useFormContext();

  const { fields, replace } = useFieldArray<any>({
    control,
    name: 'postQuizPages'
  });

  const getBuckets = getValues('buckets');

  useEffect(() => {
    if ( getBuckets ){
      const getPostQuizPages = getValues('postQuizPages');
      const postQuizPages = getBuckets.map((bucket:IBucket, index:number) => {
        const postQuizPage = getPostQuizPages[index];
        return {
          condition: {
            type: 'equals',
            source: 'winningBucket',
            value: bucket.ref
          },
          callToAction: {
            text: postQuizPage?.callToAction.text || 'Based on your answers, we think the best for you is',
            image: postQuizPage?.callToAction.image || '',
            url: postQuizPage?.callToAction.url || '',
            buttonTitle: postQuizPage?.callToAction.buttonTitle || ''
          }
        }
      });
      replace(postQuizPages);
    }
    // eslint-disable-next-line
  }, [getBuckets]);

  if ( quiz && [QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type) ) return null;
  return (
    <div className="w-full overflow-y-auto overflow-x-hidden">
      {fields.map((_, index:number) => (
        <PostQuizPage
          key={`post-quiz-page-item-${index}`}
          index={index}
        />
      ))}
    </div>
  )
}

export default TabContentPostQuizPages;
