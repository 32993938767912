import { createSelector } from '@reduxjs/toolkit';
import { RootState } from "store";
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';

export const selectUsers = (state:RootState) => state.user.users;
export const selectFilter = (state:RootState) => state.user.filter;
export const selectLoading = (state:RootState) => state.user.loading;

export const selectFilteredUsers = createSelector(
  [
    selectUsers,
    selectFilter
  ],
  (users:any[] | null, filter:{ search:string, group:UserRoles | string }) => {
    if ( !users ) return null;
    const { search, group } = filter;
    const searchToLower = search.toLowerCase();
    if ( !searchToLower && !group ) return users;
    return users.filter((user:IUser) => {
      let hasSearch = user.name.toLowerCase().includes(searchToLower);
      if ( !hasSearch ) hasSearch = user.surname ? user.surname.toLowerCase().includes(searchToLower) : false;
      if ( !hasSearch ) hasSearch = user.email ? user.email.toLowerCase().includes(searchToLower) : false;
      if ( !hasSearch ) hasSearch = user.account?.organization ? user.account.organization.toLowerCase().includes(searchToLower) : false;
      const hasGroup = group ? user.groups.includes(group as UserRoles) : true;
      return hasSearch && hasGroup;
    });
  }
);

export const selectUsersOptions = createSelector(
  [selectUsers], (users:IUser[] | null) => {
    if ( !users || users.length === 0 ) return [];
    console.log('Selector users', users);
    return users.map((item:any) => ({ value: item._id, label: item.email }));
  }
);
