import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector, useAppDispatch } from 'hooks/useStore';
// Async
import { getQuizzes } from 'store/quiz/quizzesAsync';
// Selectors
import { selectFilteredQuizzes } from 'store/quiz/quizzesSelectors';
// Components
import { Toolbar } from 'components/Toolbar';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// 
import QuizzesFilter from './QuizzesFilter';
import QuizzesSort from './QuizzesSort';
import QuizzesList from './QuizzesList';
import QuizzesCreate from './QuizzesCreate';

const initialConfig = { key: 'createdAt', direction: Direction.Descending }

const QuizzesPage:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quizzes:IQuiz[] | null = useAppSelector(selectFilteredQuizzes);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    quizzes, initialConfig
  );

  useEffect(() => {
    dispatch(getQuizzes({}));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <Toolbar className="items-center" title={t('ownerPage.quizzesPage.manageQuizzes')}>
        <div
          className="
            flex items-center
            order-2 lg:order-none
            w-full lg:w-auto
            mt-4 lg:mt-0
          "
        >
          <div className="flex-grow mr-4">
            <QuizzesFilter />
          </div>
          <QuizzesSort
            sortConfig={sortConfig || initialConfig}
            onRequestSort={requestSort}
          />
        </div>
        <QuizzesCreate />
      </Toolbar>
      <QuizzesList
        sortedQuizzes={items}
        onRequestSort={requestSort}
        getClassNamesFor={getClassNamesFor}
      />
    </div>
  )
}

export default QuizzesPage;
