import React from 'react';
import { useForm, Controller } from 'react-hook-form';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { signIn } from 'store/auth/authAsync';
// Selectors
import { selectLoading } from 'store/auth/authSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input } from 'components/Controls';
import { Button } from 'components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';
import { useTranslation } from 'react-i18next';
interface IFormData {
  email: string;
  password: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
};

const SignInDialog:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors } } = useForm<IFormData>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      await dispatch(signIn(data)).unwrap();
      onClose();
    } catch(error){}
  });

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle title={t('common.signIn')} onClose={onClose} />
        <DialogContent>
          <Controller
            control={control} name="email" rules={{ required: isRequired, pattern: isEmail }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('common.email')} id="email" type="email" name="email" value={value}
                required={true}
                onChange={onChange}
                error={Boolean(errors.email)}
                errorText={errors.email ? errors.email.message : ''}
              />
            )}
          />
          <Controller
            control={control} name="password" rules={{ required: isRequired }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('common.password')} id="password" type="password" name="password" value={value}
                required={true}
                onChange={onChange}
                error={Boolean(errors.password)}
                errorText={errors.password ? errors.password.message : ''}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.cancel')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            type="submit"
            loading={loading}
          >{t('common.signIn')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default SignInDialog;
