import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { resultActions } from 'store/result/resultSlice';
import { getQuizAnalytics } from 'store/result/resultSelectors';
import Events from 'types/Events';
import Interactions from 'types/Interactions';
// Components
import { Loader } from 'components/Utilities';
// i18next
import { useTranslation } from 'react-i18next';

const Analytics:FC = () => {
  const { t } = useTranslation('common');
  const { quizId } = useParams<{ quizId:string }>();
  
  const dispatch = useAppDispatch();
  
  const analytics = useAppSelector(getQuizAnalytics);

  useEffect(() => {
    dispatch(ResultAsync.fetchQuizAnalytics(quizId))
    return () => {
      dispatch(resultActions.setInitialField('quizAnalytics'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !analytics ) return <Loader />;

  const events = analytics.events;
  const interactions = analytics.interactions;
  const plays = analytics.plays;
  const conversions = analytics.conversions;
  const rate = plays && conversions >= plays
    ? 100
    : Number(plays ? conversions*(100/plays) : 0).toFixed(2)
  ;

  const eventsLabels:Record<Events, string> = {
    [Events.QuizStart]: t('ownerPage.quizReportPage.playersStartedQuiz')
  }

  const interactionsLabels:Record<Interactions, string> = {
    [Interactions.AdClick]: t('ownerPage.quizReportPage.adClicks'),
    [Interactions.ProceedClick]: t('ownerPage.quizReportPage.ctaClicks'), 
  }

  const getInteractionCountByValue = (value:Interactions):number =>
    interactions.find(interaction => interaction.value === value)?.count || 0;

  return (
    <div className="mb-8">
      <h3 className="text-white font-bold">{t('common.analytics')}</h3>
      <div className="flex flex-col font-medium">
        <div className="flex flex-wrap">
          {events?.map((event) => (
            <div key={event.value} className="flex flex-col text-white text-opacity-80 pr-4">
              <span className="text-sm font-light">{eventsLabels[event.value]}</span>
              <span className="font-bold">{event.count}</span>
            </div>
          ))}
          <div className="flex flex-col text-white text-opacity-80 pr-4">
            <span className="text-sm font-light">{t('ownerPage.quizReportPage.playersCompletedQuiz')}</span>
            <span className="font-bold">{plays}</span>
          </div>
          <div className="flex flex-col text-white text-opacity-80 pr-4">
            <span className="text-sm font-light">{t('ownerPage.quizReportPage.playersRegistered')}</span>
            <span className="font-bold">{conversions}</span>
          </div>
          <div className="flex flex-col text-white text-opacity-80 pr-4">
            <span className="text-sm font-light">{t('ownerPage.quizReportPage.registrationRate')}</span>
            <span className="font-bold">{rate}%</span>
          </div>
          {Object.values(Interactions).map((value) => (
            <div key={value} className="flex flex-col text-white text-opacity-80 pr-4">
              <span className="text-sm font-light">{interactionsLabels[value]}</span>
              <span className="font-bold">{getInteractionCountByValue(value)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Analytics;
