import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Async
import { signIn, signOut } from './authAsync';

interface IState {
  isAuthenticated: null | boolean;
  loading: boolean;
};

const initialState:IState = {
  isAuthenticated: null,
  loading: false
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action:PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    }
  },
  extraReducers: (builder) => {
    // Sign in
    builder.addCase(signIn.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signIn.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(signIn.rejected, (state) => {
      state.loading = false
    })
    // Sign out
    builder.addCase(signOut.fulfilled, (state) => {
      state.isAuthenticated = false;
    })
  }
});

export const { setIsAuthenticated } = slice.actions;

export default slice.reducer;
