import LogoImage from 'assets/images/Logo.png';

import React from 'react';

type Props = {};

export const AppLoader:React.FC<Props> = () => {
  return (
    <div
      className="
        fixed top-0 left-0 w-full h-full
        flex items-center justify-center
      "
      style={{
        background: 'linear-gradient(90deg, #0c141a 0%, #1d262d 100%)'
      }}
    >
      <div className="text-center">
        <div className="mb-4">
          <img src={LogoImage} alt="Quizwizard - Admin panel" />
        </div>
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
