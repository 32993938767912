import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectQuizFormMenuItem } from 'store/app/appSelectors';
import IQuiz from 'models/Quiz';
import IQuestion from 'models/Question';
// Components
import MenuItem from './MenuItem';

const CorrectAnswersMenu:FC = () => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const { watch } = useFormContext();
  const watchedQuestions: IQuestion[] = watch('questions') || [];
  const watchedEndsDate = watch('endsDate');
  
  const quiz:IQuiz | null = useAppSelector(selectQuiz);
  const quizFormMenuItem = useAppSelector(selectQuizFormMenuItem);

  useEffect(() => {
    dispatch(appActions.setQuizFormMenuItem('correct-answer-0'));
    // eslint-disable-next-line
  }, []);

  if (!quiz || +watchedEndsDate >= new Date().getTime()) return null;
  return (
    <div className="flex flex-col flex-grow my-2">
      {watchedQuestions.map((question, index) => (
        <MenuItem
          key={`correct-answer-${index}`}
          menuItem={{
            icon: 'help',
            label: `${t('common.question')} ${index + 1}`,
            value: `correct-answer-${index}`
          }}
          active={quizFormMenuItem === `correct-answer-${index}`}
        />
      ))}
    </div>
  )
}

export default CorrectAnswersMenu;
