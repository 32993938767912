import { FC, Fragment } from 'react';
// i18next
// import { useTranslation } from 'react-i18next';
// Models
import IPlayer from 'models/Player';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectPlayers } from 'store/player/playerSelectors';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// Components
import { Toolbar } from 'components/Toolbar';
// 
import PlayersSort from './PlayersSort';
import PlayersFilter from './PlayersFilter';
import PlayersList from './PlayersList';

const initialConfig = { key: 'name', direction: Direction.Ascending }

const PlayersPage:FC = () => {
  // const { t } = useTranslation('common');
  // State
  const players:IPlayer[] | null = useAppSelector(selectPlayers);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    players, initialConfig
  );

  return (
    <Fragment>
      <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
        <Toolbar title="Participants">
          <div
            className="
              flex items-center
              order-2 lg:order-none
              w-full lg:w-80
              mt-4 lg:mt-0
              gap-4
            "
          >
            <div className="flex-grow">
              <PlayersFilter />
            </div>
            <PlayersSort
              sortConfig={sortConfig || initialConfig}
              onRequestSort={requestSort}
            />
          </div>
        </Toolbar>
        <PlayersList
          sortedPlayers={items}
          onRequestSort={requestSort}
          getClassNamesFor={getClassNamesFor}
        />
      </div>
    </Fragment>
  )
}

export default PlayersPage;
