import { FC, useState, useEffect, ChangeEvent } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { getPlayers } from 'store/player/playerAsync';
// Actions
import { setFilter, setInitialField } from 'store/player/playerSlice';
// Selectors
import { selectFilter } from 'store/player/playerSelectors';
// Components
import { Input } from 'components/Controls';

let timeout:any = null;

const PlayersFilter:FC = () => {
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const filter = useAppSelector(selectFilter);

  const [ search, setSearch ] = useState(filter.search);

  useEffect(() => {
    return () => {
      if ( timeout ) clearTimeout(timeout);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( !search ){
      dispatch(setInitialField('players'));
      dispatch(setInitialField('filter'));
      return;
    }

    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(getPlayers({ email: search }));
      dispatch(setFilter({ field: 'search', value: search }));
    }, 1000);
    // eslint-disable-next-line
  }, [search])

  const handleChange = (event:ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSearch(event.target.value);
  }

  return (
    <Input
      className="mb-0"
      id="search" name="search" value={search} placeholder="Search by email"
      onChange={handleChange}
    />
  );
}

export default PlayersFilter;
