import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Models
import IAnswer from 'models/Answer';
import IOption from 'models/Option';
// Components
import { Select } from 'components/Controls';

type Props = {
  questionIndex: number;
}

const QuestionAnswer:FC<Props> = ({ questionIndex }) => {
  const name = `questions[${questionIndex}].answers`;

  const { watch, setValue } = useFormContext();
  const answers:IAnswer[] = watch(name);

  const AnswersAsOptions = answers.reduce((acc:IOption[], cur: IAnswer) => {
    return [...acc, { value: cur.ref, label: cur.title }]
  }, [{ value: '', label: 'Choose correct answer' }]);
  
  const correctAnswer = answers.find(answer => answer.correct)?.ref || '';

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setValue(name, answers.map(answer => {
      const correct = e.target.value !== answer.ref ? false : !answer.correct;
      return { ...answer, correct };
    }));
  }

  return (
    <Select
      options={AnswersAsOptions}
      id={`question-${questionIndex}-answers`}
      name={`question-${questionIndex}-answers`}
      value={correctAnswer}
      onChange={handleChange}
    />
  )
}

export default QuestionAnswer;
