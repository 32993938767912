import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useToggle from 'hooks/useToggle';
// Components
import { Input, Textarea } from './Controls';
import { IconButton } from './Buttons';
import { Tooltip } from './Tooltip';
// Dialogs
import MultiLanguageTextFieldsDialog from 'dialogs/MultiLanguageTextFields.dialog';
// Icons
import { FaLanguage } from "react-icons/fa6";
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  label?: string;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  className?: string;
  rules?: any;

  multiline?: boolean;
  rows?: number;
  customKey?: string;
}

const MultiLanguageTextField:FC<Props> = ({
  label, name, helperText = '', disabled = false, required = false, error = false,
  errorText = '', className = '', rules = {}, multiline = false, rows = 5, customKey = ''
}) => {
  const { t } = useTranslation('common');

  const { control, watch } = useFormContext();

  const watchedAdditionalLanguages = watch('additionalLanguages');

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      <div className="flex-grow flex gap-4">
        <Controller
          control={control} name={name}
          rules={rules}
          render={({ field }) => multiline ? (
            <Textarea
              {...field}
              className={`flex-1 ${className}`}
              disabled={disabled}
              required={required}
              label={label} id={name} name={name}
              rows={rows}
              helperText={helperText}
              error={error}
              errorText={errorText}
            />
          ) : (
            <Input
              {...field}
              className={`flex-1 ${className}`}
              disabled={disabled}
              required={required}
              label={label} id={name} name={name}
              helperText={helperText}
              error={error}
              errorText={errorText}
            />
          )}
        />
        {!!watchedAdditionalLanguages.length && (
          <Tooltip title={t('common.translations')}>
            <IconButton onClick={toggle} className={`${label ? 'mt-6' : ''}`} disabled={disabled}>
              <FaLanguage fontSize="24" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {open ? (
        <MultiLanguageTextFieldsDialog name={name} label={label} open={open} onClose={toggle} multiline={multiline} rows={rows} customKey={customKey} />
      ) : null}
    </Fragment>
  )
}

export default MultiLanguageTextField;