import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import AiChatAsync from 'store/aiChat/aiChatAsync';
import { aiChatActions } from 'store/aiChat/aiChatSlice';
import { selectContent } from 'store/aiChat/aiChatSelectors';
// MUI
import { Tooltip } from '@material-ui/core';
// Icons
import { FaTrash, FaGoogle } from 'react-icons/fa';
import { TfiReload } from "react-icons/tfi";
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input, Textarea } from 'components/Controls';
import { Button, IconButton } from 'components/Buttons';

type Props = {
  open: boolean;
  onClose: () => void;
  handleAddQuestions: (questions: any[]) => void;
}

const GenerateQuestionsDialog:React.FC<Props> = ({ open, onClose, handleAddQuestions }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const content = useAppSelector(selectContent);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [amountQuestions, setAmountQuestions] = useState(5);

  const onChange = (e:ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  }

  const onSubmit = () => {
    if (!content) {
      setIsLoading(true);
      dispatch(AiChatAsync.generateQuestions({ message: `${message}. Questions amount - ${amountQuestions}` })).unwrap()
        .finally(() => setIsLoading(false));
    } else {
      handleAddQuestions(content.questions);
      onClose();
    }
  }

  const generateQuestion = (index: number) => {
    setIsLoading(true);
    dispatch(AiChatAsync.generateQuestion(index)).unwrap()
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    return () => {
      dispatch(aiChatActions.setInitialField('content'));
    }
    // eslint-disable-next-line
  }, []);

  const isDisabled = !content && (!message || !amountQuestions);

  return (
    <Dialog size="lg" open={open} onClose={onClose}>
      <div className="flex flex-grow flex-col overflow-y-auto">
        <DialogTitle
          title={!content ? t('dialogs.generateQuestions.aiQuestionsGenerator') : `${content.topic} ${t('dialogs.generateQuestions.questions')}`}
          onClose={onClose}
        >
          <span className="block text-gray-400 text-sm font-light leading-5">{t('dialogs.generateQuestions.aiQuestionsGeneratorHint')}</span>
        </DialogTitle>
        <DialogContent>
          {!content ? (
            <Fragment>
              <Textarea
                id="generate-questions-message"
                name="generate-questions-message"
                label={t('dialogs.generateQuestions.topic')}
                onChange={onChange}
                value={message}
                helperText={t('dialogs.generateQuestions.topicHint')}
                disabled={isLoading}
                required
              />
              <Input
                value={amountQuestions || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value ? Number(e.target.value) : e.target.value;
                  const min = Number(e.target.min);
                  const max = Number(e.target.max);
                  
                  const nextValue = typeof value === 'number'
                    ? value < min ? min : value > max ? max : value
                    : '';

                  setAmountQuestions(Number(nextValue));
                }}
                label={t('dialogs.generateQuestions.amountOfQuestions')} id="amountQuestions" name="amountQuestions"
                type="number"
                min={1}
                max={20}
                disabled={isLoading}
              />
            </Fragment>
          ) : (
            <Fragment>
              {content.questions.map((question, i) => (
                <div key={question.title} className="flex items-center justify-between gap-4">
                  <div className="text-white mb-4">
                    <h4 className="font-bold">{`${i+1}. ${question.title}`}</h4>
                    {question.answers.map((answer, i) => (
                      <p key={answer.title} className="ml-2">
                        {`${i+1}) ${answer.title} `}
                        {answer.correct && <span className="font-bold">{`(${t('dialogs.generateQuestions.correct')})`}</span>}
                      </p>
                    ))}
                  </div>
                  <div className="flex items-center gap-1">
                    <Tooltip title="Regenerate question">
                      <div>
                        <IconButton onClick={() => generateQuestion(i)} disabled={isLoading}>
                          <TfiReload />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={t('dialogs.generateQuestions.searchInGoogle')}>
                      <div>
                        <IconButton onClick={() => window.open(`https://www.google.com/search?q=${encodeURIComponent(question.title)}`)}>
                          <FaGoogle />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={t('dialogs.generateQuestions.removeQuestion')}>
                      <div>
                        <IconButton onClick={() => dispatch(aiChatActions.removeQuestion(i))} disabled={isLoading}>
                          <FaTrash />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ))}
              <div className="pt-2">
                <Button
                  variant="contained"
                  onClick={() => generateQuestion(content.questions.length)}
                  loading={isLoading}
                >
                  {t('common.addQuestion')}
                </Button>
              </div>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.close')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            loading={isLoading}
            onClick={onSubmit}
            disabled={isDisabled}
          >{content ? t('common.apply') : t('dialogs.generateQuestions.generate')}</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default GenerateQuestionsDialog;
