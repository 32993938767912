import { FC, Fragment } from 'react';
// 
import QuizPreview from './QuizPreview';
import SectionMain from './SectionMain';
import SectionPrizes from './SectionPrizes';
import SectionBuckets from './SectionBuckets';
import SectionTheme from './SectionTheme';
import SectionImages from './SectionImages';
import SectionPromo from './SectionPromo';
import SectionCallToAction from './SectionCallToAction';
import SectionOther from './SectionOther';
import SectionConsents from './SectionConsents';

const TabContentQuiz:FC = () => {
  return (
    <Fragment>
      <div className="w-full overflow-y-hidden tablet:overflow-y-auto overflow-x-hidden" react-action="scroll">
        <SectionMain />
        <SectionPrizes />
        <SectionBuckets />
        <SectionTheme />
        <SectionImages />
        <SectionPromo />
        <SectionCallToAction />
        <SectionConsents />
        <SectionOther />
      </div>
      <QuizPreview preview="quiz" />
    </Fragment>
  )
}

export default TabContentQuiz;
