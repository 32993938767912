import React from 'react';
import JoditEditor from 'jodit-react';

type Props = {
  value: string;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  onChange: (value:string) => void;
}

export const TextEditor:React.FC<Props> = ({
  // Props
  value, error = false, errorText = '', helperText = '', onChange
}) => {
  const textEditorRef = React.useRef<any>(null);

  return (
    <React.Fragment>
      <div className="inner-html">
        <JoditEditor
          ref={textEditorRef}
          value={value}
          onChange={onChange}
        />
      </div>
      {error && errorText ? <span className="block text-red-500 text-sm py-1 px-4">{errorText}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm py-1 px-4">{helperText}</span> : null}
    </React.Fragment>
  )
}
