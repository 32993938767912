import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// translation files
import common_en from 'translations/en/translation.json';
import common_es from 'translations/es/translation.json';
import common_se from 'translations/se/translation.json';
import common_pt from 'translations/pt/translation.json';
import common_ar from 'translations/ar/translation.json';
import common_hi from 'translations/hi/translation.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whitelist: ["en", "es", "se", "pt", "ar", "hi"],
    debug: false,
    detection: {
      order: ["localStorage", "cookie"],
      chaches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { common: common_en },
      es: { common: common_es },
      se: { common: common_se },
      pt: { common: common_pt },
      ar: { common: common_ar },
      hi: { common: common_hi },
    },
  });

export default i18n;
