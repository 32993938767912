import { createAsyncThunk } from '@reduxjs/toolkit';
// Utilities
import HttpClient from 'utilities/HttpClient';
// Actions
import { appActions } from 'store/app/appSlice';
import { IConsentData } from 'models/Consent';
import { ConsentsMessages } from './consentsMessages';

const _url:string = '/consent/documents';
const _http:HttpClient = new HttpClient();

const ConsentsAsync = {
  getConsents: createAsyncThunk(
    'consents/Get consents',
    async (params:any, { rejectWithValue }) => {
      const response:Response = await _http.get(_url, params);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  getConsent: createAsyncThunk(
    'consents/Get consent',
    async (consentId:string, { rejectWithValue }) => {
      const response:Response = await _http.get(`${_url}/${consentId}`);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  createConsent: createAsyncThunk(
    'consents/Create consent',
    async (data:IConsentData, { rejectWithValue, dispatch }) => {
      const response:Response = await _http.post(_url, data);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: ConsentsMessages.create }));
      return response.json();
    }
  ),
  updateConsent: createAsyncThunk(
    'consents/Update consent',
    async (data:{ consentId: string, consentData: IConsentData }, { rejectWithValue, dispatch }) => {
      const { consentId, consentData } = data;
      const response:Response = await _http.put(`${_url}/${consentId}`, consentData);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: ConsentsMessages.update }));
      return response.json();
    }
  ),
  deleteConsent: createAsyncThunk(
    'consents/Delete consent',
    async (consentId: string, { rejectWithValue, dispatch }) => {
      const response:Response = await _http.delete(`${_url}/${consentId}`);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: ConsentsMessages.delete }));
    }
  ),
  publishConsent: createAsyncThunk(
    'consents/Publish consent',
    async (consentId:string, { rejectWithValue, dispatch }) => {
      const response:Response = await _http.post(`${_url}/${consentId}/actions/publish`);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: ConsentsMessages.publish }));
    }
  ),
  archiveConsent: createAsyncThunk(
    'consents/Archive consent',
    async (consentId:string, { rejectWithValue, dispatch }) => {
      const response:Response = await _http.post(`${_url}/${consentId}/actions/archive`);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: ConsentsMessages.archive }));
    }
  )
}

export default ConsentsAsync;
