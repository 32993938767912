import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
// Icons
import { MdDragIndicator } from 'react-icons/md';
// Types
import QuizTypes from 'types/QuizTypes';
import QuestionTypes from 'types/QuestionTypes';
// Models
import IAnswer from 'models/Answer';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selector
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Checkbox, Input, Select } from 'components/Controls';
import ImageUpload from 'components/ImageUpload';
import VideoUpload from 'components/VideoUpload';
// Utilities
import { isRequired } from 'utilities/validation';
import { Button, IconButton } from 'components/Buttons';
// Service
import QuestionService from 'services/question.service';
// 
import Answer from './Answer';
import MultiLanguageTextField from 'components/MultiLanguageTextField';
// framer-motion
import { Reorder } from 'framer-motion/dist/framer-motion';

type Props = {
  index: number;
  initialAnswer: IAnswer;
}

const Question:FC<Props> = ({
  // Props
  index, initialAnswer
}) => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const { control, formState: { errors }, watch, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: `questions[${index}].answers`
  });

  const getQuestionError = useCallback((index:number, field:string) => {
    if ( !errors || !errors.questions ) return '';

    const question = (errors as any).questions[index];

    return question && question[field] ? question[field].message : '';
    // eslint-disable-next-line
  }, [errors]);

  const handleAdd = () => {
    append({...initialAnswer, ref: uuidv4() });
  }

  const handleDelete = (index:number) => {
    remove(index);
  }

  const watchedQuestionType = watch(`questions[${index}].type`);
  const watchedQuestionRef = watch(`questions[${index}].ref`);
  const watchedQuestionTimeout = watch('questionTimeout');
  const defaultQuestionTimeout = quiz?.type === QuizTypes.Prediction ? 30 : 10;

  useEffect(() => {
    if (watchedQuestionType === QuestionTypes.ShortText) {
      setValue(`questions[${index}].answers`, []);
    }
    // eslint-disable-next-line
  }, [watchedQuestionType]);


  const [items, setItems] = useState(fields);

  const reorder = () => {
    setValue(`questions[${index}].answers`, items);
  }

  useEffect(() => {
    setItems(fields);
  }, [fields]);

  return (
    <div>
      <div className="p-6" react-scroll={`question-${index}`}>
        <h2 className="text-white text-2xl leading-6 font-bold">{`${t('common.question')} ${index + 1}`}</h2>
      </div>
      <div className="flex flex-wrap -mx-6 -y-4 px-6 pb-3">
        <div className="w-full landscape:w-1/2 tablet:w-full laptop:w-7/12 px-6">
          <MultiLanguageTextField
            rules={{ required: isRequired }}
            name={`questions.${index}.title`}
            label={t('common.question')}
            required={true}
            error={Boolean(getQuestionError(index, 'title'))}
            errorText={getQuestionError(index, 'title')}
            customKey={`questions.${watchedQuestionRef}.title`}
            className="mb-4"
          />
          {quiz && [QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type) && (
            <Controller
              control={control} name={`questions.${index}.score`}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="number" 
                  label={t('common.score')} id={`questions.${index}.score`} name={`questions.${index}.score`}
                  value={value}
                  onChange={onChange}
                  helperText={watchedQuestionTimeout
                    ? t('ownerPage.quizQuestionsPage.questionScoreHintWithTimeout', { score: (watchedQuestionTimeout || defaultQuestionTimeout) * 100 })
                    : t('ownerPage.quizQuestionsPage.questionScoreHint', { score: quiz.type === QuizTypes.Prediction ? '3,000' : '1,000' })
                  }
                />
              )}
            />
          )}
          {quiz && [QuizTypes.Personality, QuizTypes.Prediction].includes(quiz.type) ? (
            <Controller
              control={control} name={`questions.${index}.type`}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('common.type')} id={`questions.${index}.type`}
                  options={QuestionService.questionTypeOptions(quiz.type === QuizTypes.Personality)}
                />
              )}
            />
          ) : null}
          {quiz && quiz.type === QuizTypes.Prediction && watchedQuestionType === QuestionTypes.SingleChoice && (
            <Controller
              control={control} name={`questions.${index}.renderAsDropdown`}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  className="mb-0"
                  label={t('ownerPage.quizQuestionsPage.renderAsDropdown')}
                  id={`questions.${index}.renderAsDropdown`}
                  name={`questions.${index}.renderAsDropdown`}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          )}
          {quiz && quiz.type === QuizTypes.Prediction && watchedQuestionType === QuestionTypes.ShortText && (
            <Controller
              control={control} name={`questions.${index}.format`}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Format" id={`questions.${index}.fomat`}
                  options={[{value: '', label: 'Free text'}, ...QuestionService.questionFormatOptions()]}
                />
              )}
            />
          )}
          {watchedQuestionType !== QuestionTypes.ShortText && (
            <Fragment>
              <span className="divider"></span>
              <h2 className="text-white text-xl font-bold leading-6 my-4">{t('common.answers')}</h2>
              {quiz?.type === QuizTypes.Scoring ? (
                <span className="block text-gray-400 text-sm font-light leading-5 -mt-2 mb-4">{t('ownerPage.quizQuestionsPage.answersScoringHint')}</span>
              ) : null}
              <Reorder.Group axis="y" values={items} as="div" onReorder={setItems} className="flex flex-col gap-2 mb-4">
                {items.map((field, idx:number) => (
                  <Reorder.Item
                    key={`${index}-answer-item-${field.id}`} as="div"
                    className="flex items-center gap-4"
                    value={field} onDragEnd={reorder}
                  >
                    <IconButton onClick={() => {}}>
                      <MdDragIndicator />
                    </IconButton>
                    <Answer
                      questionIndex={index}
                      questionRef={watchedQuestionRef}
                      index={idx}
                      onDelete={fields.length > 2 ? handleDelete : undefined}
                    />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
              <div className="text-right">
                <Button
                  variant="contained"
                  onClick={handleAdd}
                >{t('common.addAnswer')}</Button>
              </div>
            </Fragment>
          )}
        </div>
        <div
          className="
            w-full landscape:w-1/2 tablet:w-full laptop:w-5/12
            landscape:border-l tablet:border-l-0 laptop:border-l
            px-6
          "
          style={{
            borderColor: 'rgba(255,255,255,0.2)'
          }}
        >
          <span className="block text-gray-400 text-sm py-1">{t('ownerPage.quizQuestionsPage.mediaHint')}</span>

          <Controller
            control={control}
            name={`questions.${index}.image`}
            render={({ field }) => (
              <ImageUpload
                {...field}
                width={420}
                height={236}
                label="common.image"
                helperText="ownerPage.quizQuestionsPage.imageHint"
              />
            )}
          />
          <Controller
            control={control} name={`questions.${index}.video`}
            render={({ field }) => (
              <VideoUpload
                {...field}
                label="common.video"
                helperText="ownerPage.quizQuestionsPage.videoHint"
              />
            )}
          />
        </div>
      </div>
      <span className="divider"></span>
    </div>
  )
}

export default Question;
