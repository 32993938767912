import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// 
import { updateCurrentUser } from './currentUserAsync';
import { IUser } from 'models/User';

interface IState {
  me: IUser | null;
  loading: boolean;
}

const initialState:IState = {
  me: null,
  loading: false
};

const slice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, action:PayloadAction<any>) => {
      state.me = action.payload
    }
  },
  extraReducers: (builder) => {
    // Update current user
    builder.addCase(updateCurrentUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateCurrentUser.fulfilled, (state, action:PayloadAction<any>) => {
      state.me = {...state.me, ...action.payload};
      state.loading = false
    })
    builder.addCase(updateCurrentUser.rejected, (state) => {
      state.loading = false
    })
  }
});

export const { setCurrentUser } = slice.actions;

export default slice.reducer;
