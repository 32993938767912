import { FC, useState, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { resultActions } from 'store/result/resultSlice';
import { getParams, getTotalItems } from 'store/result/resultSelectors';
// Lab
import { Pagination, PaginationItem } from '@material-ui/lab';

const ReportPagination:FC = () => {
  const dispatch = useAppDispatch();
  
  const totalItems = useAppSelector(getTotalItems);
  const params = useAppSelector(getParams);

  const [ currentPage, setCurrentPage ] = useState<number>(1);

  useEffect(() => {
    return () => {
      dispatch(resultActions.setInitialField('totalItems'));
      dispatch(resultActions.setInitialField('params'));
    }
    // eslint-disable-next-line
  }, []);

  const pageCount = Math.ceil(totalItems/params.limit);

  const handleChange = (_:any, value: number) => {
    dispatch(ResultAsync.fetchResultList({
      ...params,
      offset: (value - 1) * params.limit
    }));
    setCurrentPage(value);
  }

  if ( pageCount <= 1 ) return null;
  return (
    <div className="flex justify-center py-4">
      <Pagination
        count={pageCount}
        defaultPage={currentPage}
        color="primary"
        onChange={handleChange}
        renderItem={(params) => (
          <PaginationItem {...params} style={{ color: '#fefefe' }} />
        )}
      />
    </div>
  )
}

export default ReportPagination;
