import { FC, useEffect, useState } from 'react';

type Props = {
  label: string;
  value: number;
}

let interval:any = null;

const RadialBar:FC<Props> = ({ label, value }) => {
  const [percentage, setPercentage] = useState(0);

  const radius = 85;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  useEffect(() => {
    interval = setInterval(() => {
      setPercentage(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      })
    }, 10);

    return () => {
      clearInterval(interval);
    }
  }, [])

  return (
    <div className="relative">
      <svg
        width={300}
        height={300}
        viewBox={`0 0 ${200} ${200}`}
      >
        <defs>
          <linearGradient id="radial-gradient">
            <stop offset="10%" stopColor="#ff9000" />
            <stop offset="60%" stopColor="#ffb95e" />
          </linearGradient>
        </defs>
        <circle
          cx={100}
          cy={100}
          strokeWidth="12px"
          r={radius}
          style={{ fill: '#293450', stroke: '#ddd' }}
        />
        <circle
          cx={100}
          cy={100}
          strokeWidth="12px"
          r={radius}
          style={{
            fill: 'none',
            strokeLinejoin: 'round',
            strokeLinecap: 'round',
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          transform={`rotate(-90 100 100)`}
          stroke="url(#radial-gradient)"
        />
      </svg>
      <div className="absolute top-1/2 left-1/2 text-center" style={{ transform: 'translate(-50%,-50%)' }}>
        <p className="text-sm font-semibold" style={{ color: '#ff9000' }}>{label}</p>
        <p className="text-4xl text-white mt-2">{value.toLocaleString()}</p>
      </div>
    </div>
  )
}

export default RadialBar