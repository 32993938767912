import { FC, Fragment, useState, useEffect } from 'react';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import ConsentsAsync from 'store/consents/consentsAsync';
import { consentsActions } from 'store/consents/consentsSlice';
import { selectConsents } from 'store/consents/consentsSelectors';
// Components
import ConsentsFormDialog from 'dialogs/ConsentsForm.dialog';
import ConsentsFilter from './ConsentsFilter';
import ConsentsSort from './ConsentsSort';
import ContentsList from './ConsentsList';
import { Button } from 'components/Buttons';
import { Toolbar } from 'components/Toolbar';
// i18next
import { useTranslation } from 'react-i18next';

const initialConfig = { key: 'slug', direction: Direction.Ascending }

const ConsentsPage:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const consents = useAppSelector(selectConsents);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    consents, initialConfig
  );

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  const toggleDialogOpen = () => setDialogOpen(!dialogOpen);

  useEffect(() => {
    dispatch(ConsentsAsync.getConsents({}));

    return () => {
      dispatch(consentsActions.setInitialField('consents'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
        <Toolbar title={t('adminPage.manageConsents')}>
          <div
            className="
              flex items-center
              order-2 lg:order-none
              w-full lg:w-auto
              mt-4 lg:mt-0
            "
          >
            <div className="flex-grow mr-4">
              <ConsentsFilter />
            </div>
            <ConsentsSort 
              sortConfig={sortConfig || initialConfig}
              onRequestSort={requestSort}
            />
          </div>
          <Button
            variant="contained"
            type="button"
            onClick={toggleDialogOpen}
          >{t('adminPage.createConsent')}</Button>
        </Toolbar>
        <ContentsList
          sortedConsents={items}
          onRequestSort={requestSort}
          getClassNamesFor={getClassNamesFor}
        />
      </div>
      {dialogOpen ? (
        <ConsentsFormDialog
          open={dialogOpen}
          onClose={toggleDialogOpen}
        />
      ) : null}
    </Fragment>
  )
}

export default ConsentsPage;
