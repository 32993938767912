import { FC, useMemo } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Tooltip } from 'components/Tooltip';
// Icons
import { MdWarningAmber } from "react-icons/md";
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  tab: any;
  onClick: () => void;
  active: boolean;
  disabled?: boolean;
}

const TabItem:FC<Props> = ({
  // Props
  tab, active, onClick, disabled = false,
}) => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const classes = useMemo(() => {
    return active
      ? 'bg-primary-light text-primary'
      : 'bg-content-dark text-white'
    ;
  }, [active]);

  return (
    <button
      className={`
        ${classes}
        py-2 px-4 rounded font-bold capitalize bg
        flex gap-2 items-center justify-center
        ${disabled ? 'opacity-50 cursor-default' : ''}
      `}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {tab.value === 'questions' && !quiz?.questions?.length && (
        <Tooltip title={t('ownerPage.quizzesPage.addQuestionsToBeAblePlayQuiz')}>
          <MdWarningAmber color="orange" />
        </Tooltip>
      )}
      {tab.label}
    </button>
  )
}

export default TabItem;
