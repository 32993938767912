import i18n from "i18n";
i18n.setDefaultNamespace('common');

export const quizzesMessages = {
  create: i18n.t('notifications.quizzes.create'),
  update: i18n.t('notifications.quizzes.update'),
  delete: i18n.t('notifications.quizzes.delete'),
  clone: i18n.t('notifications.quizzes.clone'),
  start: i18n.t('notifications.quizzes.start'),
  end: i18n.t('notifications.quizzes.end'),
  assignPrizeWinner: i18n.t('notifications.quizzes.assignPrizeWinner')
}
