import { FC, useState, Fragment } from 'react';
import { push } from 'connected-react-router';
import config from 'config';
// Redux
import { useAppDispatch } from 'hooks/useStore';
import { GroupsAsync } from 'store/group/groupAsync';
import { IGroup } from 'models/Group';
// Dialogs
import GroupFormDialog from 'dialogs/GroupForm.dialog';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
import GroupQuizzesDialog from 'dialogs/GroupQuizzesDialog';
// Components
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// Icons
import { FaEdit, FaTrash, FaListAlt, FaPoll, FaRegUser, FaChartBar } from 'react-icons/fa';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  group: IGroup;
}

const GroupsListItem:FC<Props> = ({ group }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [ isLoading, setIsLoading ] = useState(false);
  const [ dialogFormOpen, setDialogFormOpen ] = useState<boolean>(false);
  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState<boolean>(false);
  const [ dialogGroupQuizzesOpen, setDialogGroupQuizzesOpen ] = useState<boolean>(false);

  const toggleFormDialog = () => setDialogFormOpen(!dialogFormOpen);
  const toggleConfirmationDialog = () => setDialogConfirmationOpen(!dialogConfirmationOpen);
  const toggleGroupQuizzesDialog = () => setDialogGroupQuizzesOpen(!dialogGroupQuizzesOpen);

  const handleGoTo = (url:string) => dispatch(push(url));

  const handleLiveLeaderBoard = () => {
    window.open(`${config.quizURL}/quiz-group/${group.slug}/live-leaderboard`);
  };

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(GroupsAsync.deleteGroup(group._id)).unwrap()
      .finally(() => setIsLoading(false));
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">{group.name}</td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {(group as any).createdByName || '-'}
        </td>
        <td className="text-right actions">
          <Tooltip title={t('ownerPage.groupsPage.editGroup')}>
            <IconButton
              onClick={toggleFormDialog}
            ><FaEdit /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={t('ownerPage.groupsPage.deleteGroup')}>
            <IconButton
              onClick={toggleConfirmationDialog}
            ><FaTrash /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={t('ownerPage.groupsPage.quizzes')}>
            <IconButton
              onClick={toggleGroupQuizzesDialog}
            ><FaListAlt /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={group.publicLeaderboard.enabled ? t('ownerPage.quizzesPage.liveLeaderboard') : ''}>
            <IconButton
              disabled={!group.publicLeaderboard.enabled}
              onClick={handleLiveLeaderBoard}
            ><FaChartBar /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={t('ownerPage.groupsPage.report')}>
            <IconButton
              onClick={() => handleGoTo(`/owner/groups/${group._id}/report`)}
            ><FaPoll /></IconButton>
          </Tooltip>
        </td>
      </tr>
      {dialogFormOpen ? (
        <GroupFormDialog
          groupId={group._id}
          open={dialogFormOpen}
          onClose={toggleFormDialog}
        />
      ) : null}
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('ownerPage.groupsPage.deleteGroup')}: ${group.name}`}
          content={t('ownerPage.groupsPage.confirmDeleteGroup')}
          open={dialogConfirmationOpen}
          loading={isLoading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDelete}
        />
      ) : null}
      {dialogGroupQuizzesOpen ? (
        <GroupQuizzesDialog
          groupId={group._id}
          open={dialogGroupQuizzesOpen}
          onClose={toggleGroupQuizzesDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default GroupsListItem;
