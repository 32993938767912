import { createAsyncThunk } from '@reduxjs/toolkit';
import { appActions } from 'store/app/appSlice';
import { errorMessage } from 'utilities/error-message';
// Utilities
import HttpClient from 'utilities/HttpClient';
import { integrationsMessages } from './integrationsMessages';

const _url:string = '/integrations';
const _http:HttpClient = new HttpClient();

export const getIntegrations = createAsyncThunk(
  'integrations/Get integrations',
  async (_, { rejectWithValue }) => {
    const response:Response = await _http.get(_url);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

export const getIntegration = createAsyncThunk(
  'integrations/Get integration',
  async (integrationId:string, { rejectWithValue }) => {
    const response:Response = await _http.get(`${_url}/${integrationId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

export const createIntegration = createAsyncThunk(
  'integrations/Create integration',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(_url, data);
    if ( !response.ok ){
      const body = await response.json();
      let message = body.message || `${errorMessage('creating')} API error: ${response.status}`;
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: integrationsMessages.create }));
    return response.json();
  }
);

export const updateIntegration = createAsyncThunk(
  'integrations/Update integration',
  async ({ integrationId, data }:{ integrationId:string, data:any }, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.put(`${_url}/${integrationId}`, data);
    if ( !response.ok ){
      const body = await response.json();
      let message = body.message || `${errorMessage('updating')} API error: ${response.status}`;
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: integrationsMessages.update }));
    return response.json();
  }
);

export const deleteIntegration = createAsyncThunk(
  'integrations/Delete integration',
  async (integrationId:string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const response:Response = await _http.delete(`${_url}/${integrationId}`);
    if ( !response.ok ){
      const body = await response.json();
      const message = body.message || `${errorMessage('deleting')} API error: ${body.status}`
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: integrationsMessages.delete }));
    return fulfillWithValue(integrationId);
  }
);
