import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
// Reducers
import AppReducer from './app/appSlice';
import AuthReducer from './auth/authSlice';
import CurrentUserReducer from './currentUser/currentUserSlice';
import MediaReducer from './media/mediaSlice';
import FormReducer from './form/formSlice';
import GroupsReducer from './group/groupSlice';
import ResultReducer from './result/resultSlice';
import UserReducer from './user/userSlice';
import NamespaceReducer from './namespace/namespaceSlice';
import GeofenceReducer from './geofence/geofenceSlice';
import QuizzesReducer from './quiz/quizzesSlice';
import PlayerReducer from './player/playerSlice';
import IntegrationsReducer from './integrations/integrationsSlice';
import ConsentsReducer from './consents/consentsSlice';
import AccountsReducer from './accounts/accountsSlice';
import AiChatReducer from './aiChat/aiChatSlice';

const rootReducer = (history:History) => combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  currentUser: CurrentUserReducer,
  media: MediaReducer,
  form: FormReducer,
  group: GroupsReducer,
  result: ResultReducer,
  user: UserReducer,
  namespace: NamespaceReducer,
  geofence: GeofenceReducer,
  quizzes: QuizzesReducer,
  player: PlayerReducer,
  integrations: IntegrationsReducer,
  consents: ConsentsReducer,
  accounts: AccountsReducer,
  aiChat: AiChatReducer,
  router: connectRouter(history)
});

export default rootReducer;
