import { FC } from 'react';
// Models
import IQuestion from 'models/Question';
// Types
import QuestionTypes from 'types/QuestionTypes';
// Components
import QuestionMultiAnswer from './MultiAnswer';
import QuestionTextAnswer from './TextAnswer';
import QuestionAnswer from './Answer';
import QuestionGameScoreAnswer from './GameScoreAnswer';
import QuestionFormats from 'types/QuestionFormats';

type Props = {
  question: IQuestion;
  index: number;
}

const Question:FC<Props> = ({ question, index }) => {
  return (
    <div>
      <div className="p-6" react-scroll={`correct-answer-${index}`}>
        <h2 className="text-white text-2xl leading-6 font-bold">{`${index + 1}. ${question.title}`}</h2>
      </div>
      <div className="w-full px-6 pb-3">
        {question.type === QuestionTypes.SingleChoice && (
          <QuestionAnswer questionIndex={index} />
        )}
        {question.type === QuestionTypes.MultiChoice && (
          question.answers.map((answer, i) => (
            <QuestionMultiAnswer
              key={answer.ref}
              answer={answer}
              index={i}
              questionIndex={index}
            />
          ))
        )}
        {question.type === QuestionTypes.ShortText && (
          question.format === QuestionFormats.Score
            ? <QuestionGameScoreAnswer questionIndex={index} />
            : <QuestionTextAnswer questionIndex={index} />
        )}
      </div>
      <span className="divider"></span>
    </div>
  )
}

export default Question;
