import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'hooks/useStore';
// Async
import { getQuiz } from 'store/quiz/quizzesAsync';
// Components
import { QuizToolbar } from 'components/QuizToolbar';
// 
import ReportAssignSpotPrizeWinner from './ReportAssignSpotPrizeWinner';
import ReportFilter from './ReportFilter';
import ReportList from './ReportList';
import ReportPagination from './ReportPagination';


const QuizReportPage:FC = () => {
  const { quizId } = useParams<{ quizId:string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getQuiz(quizId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <QuizToolbar />
      <ReportAssignSpotPrizeWinner />
      <ReportFilter />
      <ReportList />
      <ReportPagination />
    </div>
  )
}

export default QuizReportPage;
