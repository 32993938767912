import ImageUploadPattern from 'assets/images/ImageUploadPattern.png';

import { memo, ChangeEvent, forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { v4 as uuidv4 } from 'uuid';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useStore';
// Async
import { uploadImage } from 'store/media/mediaAsync';
// Components
import { Button } from 'components/Buttons';
import { Loader } from 'components/Utilities';
const pica = require('pica')();

type Props = {
  className?: string;
  label?: string;
  value: string;
  onChange: (imageUrl:string) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  width: number;
  height: number;
}

const ImageUpload = forwardRef<any, Props>(({
  // Props
  className = '',
  label = '', value, onChange, required = false, disabled = false,
  error = false, errorText = '', helperText = '',
  width, height, ...otherProps
}, ref) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const editorRef = useRef<any>(null);

  const [ uuid, setUUID ] = useState<string>('');
  const [ fileDataUrl, setFileDataUrl ] = useState<string | null>(null);
  const [ scale, setScale ] = useState(1);
  const [ isGif, setIsGif ] = useState(checkIsGif(value));
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    setUUID(uuidv4());
    return () => {
      setUUID('');
      setFileDataUrl(null);
      setIsGif(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if ( !files || !files[0] ) return;
    const file = files[0];
    const fileReader:FileReader = new FileReader();
    fileReader.onload = (event:ProgressEvent<FileReader>) => {
      if ( !event.target ) return;
      setIsGif(checkIsGif(file.type));
      setFileDataUrl(event.target.result as string);
    }
    fileReader.readAsDataURL(file);
  }

  const handleChangeScale = (event:ChangeEvent<HTMLInputElement>) => {
    setScale(Number(event.target.value));
  }

  const handleClickClear = () => {
    if ( !inputRef.current ) return;
    inputRef.current.value = '';
    setFileDataUrl(null);
    onChange('');
  }

  const handleClickApply = async () => {
    if ( !fileDataUrl ) return;

    setLoading(true);

    if ( isGif ){
      uploadGifImage(fileDataUrl);
    } else {
      if ( !editorRef.current ){
        setLoading(false);
        throw Error('Avatar editor error');
      }

      const img = editorRef.current.getImage()

      const offScreenCanvas = document.createElement("canvas");

      offScreenCanvas.width = width * 2;
      offScreenCanvas.height = height * 2;

      const picaCanvas = await pica.resize(img, offScreenCanvas);
      picaCanvas.toBlob(
        (blob:Blob) => URL.createObjectURL(blob),
        "image/jpeg",
        0.9
      )

      picaCanvas.toBlob((blob:Blob) => {
        uploadOtherImage(blob);
      });
    }
  }

  const uploadGifImage = async (fileBase64:string) => {
    try {
      const response = await fetch(fileBase64);
      const blob = await response.blob();
      const imageUrl = await dispatch(uploadImage(blob)).unwrap();
      onChange(imageUrl.toString());
      if ( inputRef.current ) inputRef.current.value = '';
    } catch(error){} finally {
      setLoading(false);
    }
  }

  const uploadOtherImage = async (blob:Blob) => {
    try {
      const imageUrl = await dispatch(uploadImage(blob)).unwrap();
      onChange(imageUrl.toString());
      if ( inputRef.current ) inputRef.current.value = '';
    } catch(error){} finally {
      setLoading(false);
    }
  }

  const paddingTop = height/width * 100;
  const imageUrl = fileDataUrl || value;
  const isClearButtonDisabled = !Boolean(imageUrl);
  const isApplyButtonDisabled = !Boolean(inputRef.current && inputRef.current.value); // !Boolean(fileDataUrl);
  const isScaleDisabled = isClearButtonDisabled;

  return (
    <div className="mb-4">
      <input ref={ref} type="hidden" name={(otherProps as any).name} />

      <div className={className}>
        {label ? (
          <span className={`block text-white text-sm font-semibold mb-1 ${disabled ? 'opacity-50' : ''}`}>
            {t(label)}{required ? <Fragment>&nbsp;*</Fragment> : null}
          </span>
        ) : null}
        <div
          className="relative rounded overflow-hidden w-full mb-4"
          style={{
            backgroundImage: `url(${ImageUploadPattern})`,
            boxShadow: 'inset 0 0 16px rgba(0,0,0,0.75)',
            paddingTop: `${paddingTop}%`
          }}
        >
          {loading ? (
            <div
              className="
                absolute top-0 left-0 w-full h-full
                flex justify-center items-center
                z-10
              "
              style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
            ><Loader /></div>
          ) : null}
          {isGif ? (
            <img
              className="absolute top-0 left-0 w-full h-full object-contain"
              src={imageUrl}
              alt=""
            />
          ) : (
            <AvatarEditor
              ref={editorRef}
              className="absolute top-0 left-0 rounded"
              style={{
                backgroundImage: `url(${ImageUploadPattern})`,
                width: '100%',
                height: '100%',
                opacity: disabled ? '0.75' : '1'
              }}
              image={imageUrl}
              width={width}
              height={height}
              scale={scale}
              border={0}
              rotate={0}
            />
          )}
        </div>
      </div>
      {!isGif ? (
        <div className="mb-2">
          <input
            className="w-full" id={`image-scale-${uuid}`} name={`image-scale-${uuid}`}
            type="range" value={scale}
            min="0.1" max="2" step="0.1"
            disabled={isScaleDisabled}
            onChange={handleChangeScale}
          />
        </div>
      ) : null}
      {fileDataUrl ? (
        <span className="block text-white text-sm font-light leading-5 mb-4">{t('components.imageUpload.clickApplyToAddImage')}</span>
      ): null}
      <div className="flex gap-2">
        <label className="btn btn-outlined">
          <input
            ref={inputRef}
            className="hidden"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {t('common.browse')}
        </label>
        <Button
          variant="outlined"
          disabled={disabled ? true : isClearButtonDisabled}
          onClick={handleClickClear}
        >{t('common.clear')}</Button>
        <Button
          variant="contained"
          disabled={disabled ? true : isApplyButtonDisabled}
          onClick={handleClickApply}
        >{t('common.apply')}</Button>
      </div>
      {error && errorText ? <span className="block text-red-500 text-sm py-1 px-4">{t(errorText)}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{t(helperText)}</span> : null}
    </div>
  )
});

export default memo(ImageUpload, (prev, next) => {
  return prev.value === next.value && prev.error === next.error && prev.disabled === next.disabled
});

const checkIsGif = (value:string):boolean => {
  if ( !value ) return false;
  return value.includes('gif');
}
