import config from 'config';

import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Types
import QuizTypes from 'types/QuizTypes';
// Components
import ImageUpload from 'components/ImageUpload';
import { Toggle } from 'components/Controls';
import Copy from 'components/Copy';
import MultiLanguageTextField from 'components/MultiLanguageTextField';
import { ColorPicker } from 'components/ColorPicker';
// i18next
import { useTranslation } from 'react-i18next';

const PublicLeaderboard:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const quizType = watch('type');
  const publicLeaderboardEnabled = watch('publicLeaderboard.enabled');

  if (!quiz || quizType === QuizTypes.Personality) return null;

  const url = `${config.quizURL}/quiz/${quiz.quizNameSlug}/${quiz?.type === QuizTypes.Poll ? 'live-results' : 'live-leaderboard'}`

  return (
    <div className="mt-8">
      <div className="flex items-center mb-4">
        <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.showScreen')}</span>
        <Controller
          control={control} name="publicLeaderboard.enabled"
          render={({ field: { onChange, value } }) => (
            <Toggle
              id="publicLeaderboardEnabled"
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <span className="block text-gray-400 text-sm">
        {quiz.type === QuizTypes.Poll ? t('ownerPage.quizForm.liveResultsHint') : t('ownerPage.quizForm.publicLeaderboardHint')}
      </span>
      <span className="block text-gray-400 text-sm">{t('ownerPage.quizForm.publicLeaderboardHint2')}</span>
      
      {publicLeaderboardEnabled && (
        <Fragment>
          <div className="pt-2 flex items-center gap-2">
            <a
              rel="noreferrer" target="_blank"
              className="text-sm text-primary hover:underline"
              href={url}
            >{url}</a>
            <Copy text={url} />
          </div>
          <div className="pt-4">
            <MultiLanguageTextField
              name="publicLeaderboard.headerText"
              label={t('ownerPage.quizForm.publicLeaderboardHeaderText')}
              helperText={quiz.type === QuizTypes.Poll ? t('ownerPage.quizForm.liveResultsHeaderTextHint') : t('ownerPage.quizForm.publicLeaderboardHeaderTextHint')}
              className="mb-4"
            />
          </div>
          <div>
            <Controller
              control={control} name="publicLeaderboard.wallpaperImage"
              render={({ field }) => (
                <ImageUpload
                  {...field}
                  width={1920}
                  height={1080}
                  label="ownerPage.quizForm.wallpaperImage"
                  helperText={t('ownerPage.quizForm.publicLeaderboardWallpaperImageHint')}
                />
              )}
            />
          </div>
          <Controller
            control={control} name="publicLeaderboard.textColor"
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                label={t('ownerPage.quizForm.textColor')}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Fragment>
      )}
    </div>
  )
}

export default PublicLeaderboard;
