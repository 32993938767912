import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GeofenceAsync } from './geofenceAsync';
import { IGeofence } from 'models/Geofence';

interface IState {
  items: IGeofence['countries'] | null;
};

const initialState:IState = {
  items: null,
};

const slice = createSlice({
  name: 'geofence',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GeofenceAsync.fetchGeofenceList.pending, (state:IState) => {
        state.items = null;
      })
      .addCase(GeofenceAsync.fetchGeofenceList.fulfilled, (state:IState, action:PayloadAction<IGeofence>) => {
        state.items = action.payload.countries;
      })
      .addCase(GeofenceAsync.updateGeofence.fulfilled, (state:IState, action:PayloadAction<IGeofence>) => {
        state.items = action.payload.countries;
      })
  }
});

export const geofenceActions = slice.actions;

export default slice.reducer;
