import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Components
import { Input } from 'components/Controls';
// Utilities
import { isRequired } from 'utilities/validation';

type Props = {
  questionIndex: number;
  answerIndex: number;
  index: number;
  bucketRef: string;
  bucketTitle: string;
}

const AnswerBucketScore:FC<Props> = ({
  // Props
  questionIndex, answerIndex, index, bucketRef, bucketTitle
}) => {
  const name = `questions[${questionIndex}].answers[${answerIndex}].bucketScores[${index}]`;

  const { register, control, formState:{ errors } } = useFormContext();

  const getBucketError = useCallback(() => {
    if ( !errors || !errors.questions ) return '';

    const question = (errors as any).questions[questionIndex.toString()];

    if ( !question || !question.answers ) return '';

    const answer = question.answers[answerIndex];

    if ( !answer || !answer.bucketScores ) return '';

    const bucket = answer.bucketScores[index];

    return bucket && bucket.score ? bucket.score.message : '';
    // eslint-disable-next-line
  }, [])();

  return (
    <div>
      <input type="hidden" {...register(`${name}.ref`)} defaultValue={bucketRef} />
      <Controller
        control={control} name={`${name}.score`} rules={{ required: isRequired }} defaultValue="0"
        render={({ field }) => (
          <Input
            {...field}
            className="mb-0"
            label={bucketTitle} id={`${name}.score`}
            required={true}
            error={Boolean(getBucketError)}
            errorText={getBucketError}
          />
        )}
      />
    </div>
  )
}

export default AnswerBucketScore;
