import { FC, useEffect } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { resultActions } from 'store/result/resultSlice';
import { getAnalytics } from 'store/result/resultSelectors';
// Components
import { Loader } from 'components/Utilities';
import RadialBar from './RadialBar';
// i18next
import { useTranslation } from 'react-i18next';

const Analytics:FC = () => {
  const { t } = useTranslation('common');
  
  const dispatch = useAppDispatch();
  
  const analytics = useAppSelector(getAnalytics);

  useEffect(() => {
    dispatch(ResultAsync.fetchAnalytics());
    return () => {
      dispatch(resultActions.setInitialField('analytics'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !analytics ) return <Loader />;

  const { quizzes, plays, conversions } = analytics;

  return (
    <div className="pt-8">
      <div className="flex flex-col font-medium">
        <div className="flex flex-wrap justify-between items-center">
          <RadialBar label={t('common.quizzes')} value={quizzes} />
          <RadialBar label={t('ownerPage.analyticsPage.playersCompletedQuizzes')} value={plays} />
          <RadialBar label={t('ownerPage.analyticsPage.playersRegistered')} value={conversions} />
        </div>
      </div>
    </div>
  )
}

export default Analytics;
