import { ChangeEvent, FC } from 'react';
// Components
import Sidebar from 'components/Sidebar';
import { Select } from 'components/Controls';
// Hooks
import { IConfig, Direction } from 'hooks/useSortableItems';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortConfig: IConfig;
  onRequestSort: (key:string, direction:Direction) => void;
}

const PlayersSort:FC<Props> = ({
  // Props
  sortConfig, onRequestSort
}) => {
  const { t } = useTranslation('common');
  const handleChange = (event:ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const key = name === 'key' ? value : sortConfig.key;
    const direction = name === 'key' ? sortConfig.key : value;
    onRequestSort(key, direction as Direction);
  }

  const directionOptions = (Object.keys(Direction) as Array<keyof typeof Direction>).map((direction) => ({
    value: Direction[direction], label: direction
  }));

  return (
    <Sidebar>
      <Select
        label={t('common.sortBy')} id="sortBy" name="key" value={sortConfig.key}
        onChange={handleChange}
        options={[
          { value: 'name', label: t('common.firstName') },
          { value: 'surname', label: t('common.lastName') },
          { value: 'email', label: t('common.email') }
        ]}
      />
      <Select
        label={t('common.direction')} id="direction" name="direction" value={sortConfig.direction}
        onChange={handleChange}
        options={directionOptions}
      />
    </Sidebar>
  )
}

export default PlayersSort;
