import { FC, useEffect } from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
// Types
import UserRoles from 'types/UserRoles';
// Store
import { useDispatch } from 'react-redux';
// Async
import { getUsers } from 'store/user/userAsync';
import { NamespaceAsync } from 'store/namespace/namespaceAsync';
// Pages
import UsersPage from './UsersPage';
import QuizzesPage from 'pages/OwnerPage/QuizzesPage';
import QuizFormPage from 'pages/OwnerPage/QuizFormPage';
// Components
import PrivateRoute from 'components/PrivateRoute';
import ConsentsPage from './ConsentsPage';

const AdminPage:FC = () => {
  const { url } = useRouteMatch<{ url:string }>();
  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers({ groups: [UserRoles.Admin, UserRoles.Owner] }));
    dispatch(NamespaceAsync.fetchNamespacesList());
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <PrivateRoute roles={[UserRoles.Admin]} path={`${url}/users`} component={UsersPage} />
      <PrivateRoute roles={[UserRoles.Admin]} path={`${url}/consents`} component={ConsentsPage} />
      <PrivateRoute roles={[UserRoles.Admin]} exact path={`${url}/quizzes`} component={QuizzesPage} />
      <PrivateRoute roles={[UserRoles.Admin]} exact path={`${url}/quizzes/:quizId`} component={QuizFormPage} />
      <Redirect from={url} to="/admin/users" />
    </Switch>
  )
}

export default AdminPage;
