import { FC, useMemo } from "react";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { resultActions } from "store/result/resultSlice";
import { selectQuiz } from "store/quiz/quizzesSelectors";
import { getSelectedTab } from "store/result/resultSelectors";
import QuizTypes from "types/QuizTypes";
import AnswersTabsTypes from "types/AnswersTabs";
// MUI
import { Tab, Tabs } from "@material-ui/core";
// i18next
import { useTranslation } from "react-i18next";
import i18n from "i18n";


const AnswersTabs:FC = () => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const quiz:any = useAppSelector(selectQuiz);
  const selectedTab = useAppSelector(getSelectedTab);

  const tabs = useMemo(() => [
    { value: AnswersTabsTypes.Answers, label: t('common.main') },
    { value: AnswersTabsTypes.PostQuizAnswers, label: t('ownerPage.questionAnswersBreakdownPage.postQuiz') },
    { value: AnswersTabsTypes.PreQuizAnswers, label: t('ownerPage.questionAnswersBreakdownPage.preQuiz') }
    // eslint-disable-next-line
  ], [i18n.language])

  const onChangeTab = (_:any, nextTab:AnswersTabsTypes) => dispatch(resultActions.setSelectedTab(nextTab));

  if(![QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type)) return null;
  return (
    <Tabs
      value={selectedTab}
      indicatorColor="primary"
      textColor="inherit"
      onChange={onChangeTab}
      aria-label="Answers tabs"
    >
      {tabs.map(tab => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  );
}
 
export default AnswersTabs;