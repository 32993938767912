import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Types
// import QuizTypes from 'types/QuizTypes';
// Models
// import IQuiz from 'models/Quiz';
// Redux
// import { useAppSelector } from 'hooks/useStore';
// Selectors
// import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Toggle } from 'components/Controls';
import MultiLanguageTextField from 'components/MultiLanguageTextField';
import ShowBrandLogoOnWaiver from './ShowBrandLogoOnWaiver';

const Waiver:FC = () => {
  const { t } = useTranslation('common');

  // State
  // const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const watchedShowWaiverBeforeQuiz = watch('showWaiverBeforeQuiz');

  const isFieldsDisabled = !watchedShowWaiverBeforeQuiz;

  // if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <Fragment>
      <div className="flex items-center mt-2 mb-4">
        <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.showWaiverBeforeQuiz')}</span>
        <Controller
          control={control} name="showWaiverBeforeQuiz"
          render={({ field: { onChange, value } }) => (
            <Toggle
              id="showWaiverBeforeQuiz"
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <MultiLanguageTextField
        name="waiverText"
        helperText={t('ownerPage.quizForm.waiverTextHint')}
        disabled={isFieldsDisabled}
        multiline
        className="mb-4"
      />
      <ShowBrandLogoOnWaiver />
      <span className="divider my-4" />
    </Fragment>
  )
}

export default Waiver;
