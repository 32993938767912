import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
// Middlewares
import { routerMiddleware } from 'connected-react-router';
import notificationMiddleware from 'middlewares/notificationMiddleware';

import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const rootConfigureStore = () => {
  const store = configureStore({
    reducer: rootReducer(history),
    middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(routerMiddleware(history))
      .concat(notificationMiddleware)
  });
  return store;
}

const rootStore = rootConfigureStore();

export default rootStore;

export type RootState = ReturnType<typeof rootStore.getState>;
export type AppDispatch = typeof rootStore.dispatch

