import { FC, Fragment, useState } from 'react';
// Icons
import { FaTrash, FaRegUser, FaRegBuilding } from 'react-icons/fa';
// Models
import IPlayer from 'models/Player';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Actions
import { deletePlayer } from 'store/player/playerAsync';
// Selectors
import { selectLoading } from 'store/player/playerSelectors';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  player: IPlayer;
}

const PlayersListItem:FC<Props> = ({
  // Props
  player
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState(false);

  const toggleConfirmationDialog = () => setDialogConfirmationOpen(!dialogConfirmationOpen);

  const handleDeletePlayer = async () => {
    try {
      await dispatch(deletePlayer(player._id)).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">
          {player.name}
          <span className="lg:hidden">&nbsp;{player.surname}</span>
        </td>
        <td className="text-left hidden lg:table-cell">
          {player.surname}
        </td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {player.email}
        </td>
        <td className="text-left text">
          <FaRegBuilding className="icon" size="16px" />
          {player.quizzes.map((quiz:any) => (
            <b key={quiz._id} className="block">{quiz.quizName}</b>
          ))}
        </td>
        <td className="text-right actions">
          <Tooltip className="ml-1" title={t('adminPage.deleteUser')}>
            <IconButton
              onClick={toggleConfirmationDialog}
            ><FaTrash /></IconButton>
          </Tooltip>
        </td>
      </tr>
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`Delete participant: ${player.name}`}
          content="Are you sure you want to delete participant ? It will remove all the participant results"
          open={dialogConfirmationOpen}
          loading={loading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDeletePlayer}
        />
      ) : null}
    </Fragment>
  )
}

export default PlayersListItem;
