import React from 'react';
// Components
import { Portal } from 'components/Portal';

type Props = {
  open: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full';
  onClose: () => void;
  children: React.ReactNode;
}

const Dialog:React.FC<Props> = ({
  // Props
  open, size = 'md', onClose, children
}) => {
  const backdropRef = React.useRef<HTMLDivElement | null>(null);

  const handleBackdropClick = (event:MouseEvent) => {
    if ( event.target === backdropRef.current ) onClose();
  };

  React.useEffect(() => {
    const { current } = backdropRef;
    if ( current ) current.addEventListener('click', handleBackdropClick)
    return () => {
      if ( current ) current.removeEventListener('click', handleBackdropClick)
    }
    // eslint-disable-next-line
  }, [open]);

  if ( !open ) return null;
  return (
    <Portal id="root-dialog">
      <div
        ref={backdropRef}
        className="
          fixed top-0 left-0 w-full h-full
          flex justify-center items-center
        "
        style={{
          backgroundColor: 'rgba(0,0,0,0.75)'
        }}
      >
        <div
          className={`
            relative w-full h-full
            flex flex-col justify-center items-center
            ${size !== 'full' ? 'p-4' : ''}
          `}
        >
          <div
            className={`
              w-full
              flex flex-col overflow-y-auto
              bg-content
              ${size === 'sm' ? 'rounded max-w-screen-sm' : ''}
              ${size === 'md' ? 'rounded max-w-screen-md' : ''}
              ${size === 'lg' ? 'rounded max-w-screen-lg' : ''}
              ${size === 'xl' ? 'rounded max-w-screen-xl' : ''}
              ${size === 'full' ? 'h-full' : ''}
            `}
          >
            <React.Fragment>{children}</React.Fragment>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default Dialog;
