import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Checkbox } from 'components/Controls';

const PublicDevicePlayable:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control } = useFormContext();

  if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <Controller
      control={control} name="publicDevicePlayableOnly"
      render={({ field: { onChange, value } }) => (
        <Checkbox
          label={t('ownerPage.quizForm.publicDevicePlayableOnly')} id="publicDevicePlayableOnly" name="publicDevicePlayableOnly" checked={value}
          onChange={onChange}
          helperText={t('ownerPage.quizForm.publicDevicePlayableOnlyHint')}
        />
      )}
    />
  )
}

export default PublicDevicePlayable;
