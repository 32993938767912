import { FC, Fragment, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// Models
import IBucket from 'models/Bucket';
// Types
import QuizTypes from 'types/QuizTypes';
// Store
import { useAppSelector, useAppDispatch } from 'hooks/useStore';
// Actions
import { createQuiz } from 'store/quiz/quizzesAsync';
// Selectors
import { selectLoading } from 'store/quiz/quizzesSelectors';
import { getNamespaceItemsForSelect } from 'store/namespace/namespaceSelectors';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Dialog
import NamespaceFormDialog from 'dialogs/NamespaceForm.dialog';
// Components
import ImageUpload from 'components/ImageUpload';
import { Input, Select, Toggle } from 'components/Controls';
import { Button } from 'components/Buttons';
import DatePicker from 'components/DatePicker';
import QuizPersonalityFields from './QuizPersonalityFields';
// Utilities
import { isRequired } from 'utilities/validation';
import { textFromCamelToNormalCase } from 'utilities/utilities';

type Props = {
  open: boolean;
  onClose: () => void;
};

interface IFormData {
  type: QuizTypes;
  namespace: string;
  quizName: string;

  // Quiz:Scroring
  startsDate: string;
  endsDate: string;
  publicLeaderboard: {
    enabled: boolean;
  };
  // Quiz:Personality
  buckets: IBucket[];

  logoImage: string;
  mastheadImage: string;
}

const QuizFormDialog:FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const namespaceOptions = useAppSelector(getNamespaceItemsForSelect);
  const loading:boolean = useAppSelector(selectLoading);
  const currentUser = useAppSelector(selectCurrentUser);

  const [ dialogNamespaceFormOpen, setDialogNamespaceFormOpen ] = useState(false);

  const toggleNamespaceFormDialog = () => setDialogNamespaceFormOpen(!dialogNamespaceFormOpen);

  const methods = useForm<IFormData>({
    defaultValues: {
      type: currentUser?.account?.quizTypes?.[0] || QuizTypes.Scoring,
      namespace: '',
      quizName: '',
      startsDate: '',
      endsDate: '',
      publicLeaderboard: {
        enabled: false,
      },
      // Personality
      buckets: [],

      logoImage: '',
      mastheadImage: ''
    }
  });

  const { control, handleSubmit, formState:{ errors }, watch } = methods;

  const registrationCaption:Record<QuizTypes, string> = {
    [QuizTypes.Scoring]: t('ownerPage.quizForm.registrationCaptionScoring'),
    [QuizTypes.Personality]: t('ownerPage.quizForm.registrationCaptionPersonality'),
    [QuizTypes.Prediction]: t('ownerPage.quizForm.registrationCaptionPrediction'),
    [QuizTypes.Poll]: t('ownerPage.quizForm.registrationCaptionPoll'),
  }

  const onSubmit = handleSubmit((data:IFormData) => {
    const { startsDate, endsDate, buckets, ...otherData } = data;
    const nextData:any = {
      ...otherData,
      advertVideo: [
        {
          endQuiz: false,
          midQuiz: false,
          startQuiz: false,
          videoUrl: ''
        }
      ],
      landingPage: {
        callToAction: {
          text: '',
          image: '',
          url: '',
          buttonTitle: otherData.type === QuizTypes.Poll ? t('ownerPage.quizForm.voteNow') : t('ownerPage.quizForm.playNow')
        }
      },
      questions: [],
      registrationPage: {
        anonymous: false,
        showPriorQuiz: 'false',
        caption: registrationCaption[otherData.type]
      }
    }
    nextData['questionTimeout'] = otherData.type === QuizTypes.Prediction ? 30 : 10;
    if ( startsDate ) nextData['startsDate'] = moment(startsDate).format('x');
    if ( endsDate ) nextData['endsDate'] = moment(endsDate).format('x');
    if ( otherData.type === QuizTypes.Personality ){
      if ( !buckets.length ) return;
      nextData['buckets'] = buckets;
    } else {
      nextData['postQuizPages'] = [{ questions: [] }];
    }
    createQuizAsync(nextData);
  });

  const createQuizAsync = async (data:IFormData) => {
    try {
      await dispatch(createQuiz(data)).unwrap();
      onClose();
    } catch(err){}
  }

  const typeOptions = currentUser?.account?.quizTypes?.map((quizType) => ({
    value: quizType,
    label: textFromCamelToNormalCase(quizType)
  })) || [];

  const watchedType = watch('type');

  return (
    <Fragment>
      <Dialog size="lg" open={open} onClose={onClose}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} noValidate>
            <DialogTitle title={t('ownerPage.quizForm.createQuiz')} onClose={onClose} />
            <DialogContent>
              <div className="flex flex-wrap laptop:flex-nowrap gap-6">
                <div className="w-full tablet:w-full laptop:w-1/2">
                  <div className="mb-4">
                    <h2 className="flex-grow text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.minimumRequirements')}</h2>
                  </div>
                  <Controller
                    control={control} name="type" rules={{ required: isRequired }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={t('ownerPage.quizForm.type')} id="type"
                        options={typeOptions}
                        required={true}
                        error={Boolean(errors.type)}
                        errorText={errors.type ? errors.type.message : ''}
                        helperText={t('ownerPage.quizForm.typeHint')}
                      />
                    )}
                  />

                  {watchedType === QuizTypes.Personality ? (
                    <span className="block text-gray-400 text-sm font-light leading-5 mb-4">
                      {t('ownerPage.quizForm.typeDescription')}
                    </span>
                  ) : null}

                  <Controller
                    control={control} name="quizName" rules={{ required: isRequired }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label={t('ownerPage.quizForm.quizName')} id="quizName"
                        required={true}
                        error={Boolean(errors.quizName)}
                        errorText={errors.quizName ? errors.quizName.message : ''}
                      />
                    )}
                  />
                  <Controller
                    control={control} name="namespace" rules={{ required: isRequired }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label={t('ownerPage.quizForm.category')} id="namespace" name="namespace"
                        required={true}
                        options={[
                          { value: '', label: t('ownerPage.quizForm.chooseCategory') },
                          ...namespaceOptions
                        ]}
                        error={Boolean(errors.namespace)}
                        errorText={errors.namespace ? errors.namespace.message : ''}
                        helperText={t('ownerPage.quizForm.categoryHint')}
                      />
                    )}
                  />
                  <div className="-mt-3 mb-4">
                    <button
                      className="uppercase text-primary font-bold hover:underline"
                      type="button"
                      onClick={toggleNamespaceFormDialog}
                    >{t('ownerPage.quizForm.createCategory')}</button>
                  </div>
                  <div className="flex flex-wrap -mx-2">
                    <div className="w-full sm:w-1/2 px-2">
                      <div className="mb-4">
                        <Controller
                          control={control} name="startsDate"
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label={t('ownerPage.quizForm.startDate')}
                              id="startsDate"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 px-2">
                      <div className="mb-4">
                        <Controller
                          control={control} name="endsDate"
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              label={t('ownerPage.quizForm.endDate')}
                              id="endsDate"
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  {watchedType === QuizTypes.Prediction && (
                    <span className="block text-gray-400 text-sm font-light leading-5">
                      {t('ownerPage.quizForm.datesPredictionsHint')}
                    </span>
                  )}
                  {watchedType === QuizTypes.Personality ? (
                    <QuizPersonalityFields />
                  ) : null}
                  {[QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(watchedType) && (
                    <div className="pt-4">
                      <div className="flex items-center mb-4">
                        <span className="block text-white text-base font-semibold mr-3">
                          {watchedType === QuizTypes.Poll ? t('ownerPage.quizForm.showLiveResultsScreen') : t('ownerPage.quizForm.publicLeaderboard')}
                        </span>
                        <Controller
                          control={control} name="publicLeaderboard.enabled"
                          render={({ field: { onChange, value } }) => (
                            <Toggle
                              id="publicLeaderboardEnabled"
                              checked={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                      <span className="block text-gray-400 text-sm">
                        {watchedType === QuizTypes.Poll ? t('ownerPage.quizForm.liveResultsHint') : t('ownerPage.quizForm.publicLeaderboardHint')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="w-full tablet:w-full laptop:w-1/2">
                  <div className="mb-4">
                    <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.images')}</h2>
                  </div>
                  <Controller
                    control={control} name="logoImage" rules={{ required: isRequired }}
                    render={({ field }) => (
                      <ImageUpload
                        {...field}
                        className="w-1/4"
                        width={140}
                        height={60}
                        required={true}
                        label="ownerPage.quizForm.logoImage"
                        error={Boolean(errors.logoImage)}
                        errorText={errors.logoImage ? errors.logoImage.message : ''}
                        helperText="ownerPage.quizForm.logoImageHint"
                      />
                    )}
                  />
                  <Controller
                    control={control} name="mastheadImage" rules={{ required: isRequired }}
                    render={({ field }) => (
                      <ImageUpload
                        {...field}
                        className="w-1/2"
                        width={420}
                        height={168}
                        required={true}
                        label="ownerPage.quizForm.mastheadImage"
                        error={Boolean(errors.mastheadImage)}
                        errorText={errors.mastheadImage ? errors.mastheadImage.message : ''}
                        helperText="ownerPage.quizForm.mastheadImageHint"
                      />
                    )}
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onClose}
              >{t('common.cancel')}</Button>
              <Button
                className="ml-2"
                variant="contained"
                type="submit"
                loading={loading}
              >{t('common.create')}</Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      {dialogNamespaceFormOpen ? (
        <NamespaceFormDialog
          open={dialogNamespaceFormOpen}
          onClose={toggleNamespaceFormDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default QuizFormDialog;
