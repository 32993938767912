import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { Button } from 'components/Buttons';
// 
import QuizFormDialog from './QuizFormDialog';

const QuizzesCreate:FC = () => {
  const { t } = useTranslation('common');

  const [ dialogOpen, setDialogOpen ] = useState(false);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  return (
    <Fragment>
      <Button
        onClick={toggleDialog}
        variant="contained"
      >{t('ownerPage.quizzesPage.createQuiz')}</Button>
      {dialogOpen ? (
        <QuizFormDialog
          open={dialogOpen}
          onClose={toggleDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default QuizzesCreate;
