import React from 'react';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
// 
import QuizzesList from './QuizzesList';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  groupId: string;
  open: boolean;
  onClose: () => void;
}

const GroupQuizzesDialog:React.FC<Props> = ({
  // props
  groupId, open, onClose
}) => {
  const { t } = useTranslation('common');

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={t('dialogs.groupQuizzes.groupQuizzes')} onClose={onClose} />
      <DialogContent>
        <QuizzesList groupId={groupId} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default GroupQuizzesDialog;
