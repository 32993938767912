import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
// Redux
import { useAppDispatch, useAppSelector } from "hooks/useStore";
import { ResultAsync } from "store/result/resultAsync";
import { getQuiz } from "store/quiz/quizzesAsync";
import { resultActions } from "store/result/resultSlice";
import { setInitialField } from "store/quiz/quizzesSlice";
import { selectQuiz } from "store/quiz/quizzesSelectors";
import { getQuestionsResults, getSelectedTab } from "store/result/resultSelectors";
import AnswersTabsTypes from "types/AnswersTabs";
// Components
import { Loader } from "components/Utilities";
import { QuizToolbar } from "components/QuizToolbar";
import QuestionsList from "./QuestionsList";
import PostQuizQuestionsList from "./PostQuizQuestionsList";
import PreQuizQuestionsList from "./PreQuizQuestionsList";
import AnswersTabs from "./AnswersTabs";
// i18next
import { useTranslation } from "react-i18next";

const QuestionAnswersBreakdownPage:FC = () => {
  const { t } = useTranslation('common');

  const { quizId } = useParams<{ quizId:string }>();
  const dispatch = useAppDispatch();

  const quiz:any = useAppSelector(selectQuiz);
  const questionsResults = useAppSelector(getQuestionsResults);
  const selectedTab = useAppSelector(getSelectedTab);


  useEffect(() => {
    dispatch(getQuiz(quizId));
    dispatch(ResultAsync.fetchCountAnswersByQuestions(quizId));

    return () => {
      dispatch(setInitialField('quiz'));
      dispatch(resultActions.setInitialField('questionsResults'));
      dispatch(resultActions.setInitialField('selectedTab'));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <QuizToolbar />
      {(!questionsResults || !quiz) ? (
        <Loader />
      ) : (
        <div className="mb-8">
          <div className="flex justify-between items-center flex-wrap gap-4 text-white mb-4">
            <h3 className="font-bold">{t('common.answersAnalytics')}</h3>
            <AnswersTabs />
          </div>
          {selectedTab === AnswersTabsTypes.Answers && <QuestionsList />}
          {selectedTab === AnswersTabsTypes.PostQuizAnswers && <PostQuizQuestionsList />}
          {selectedTab === AnswersTabsTypes.PreQuizAnswers && <PreQuizQuestionsList />}
        </div>
      )}
    </div>
  );
}
 
export default QuestionAnswersBreakdownPage;