import { FC, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectQuizFormMenuItem } from 'store/app/appSelectors';
import IQuiz from 'models/Quiz';
import QuizTypes from 'types/QuizTypes';
// Components
import MenuItem from './MenuItem';

interface IMenuItem {
  value: string;
  label: string;
  icon: string;
  quizType: QuizTypes[];
  hidden?: boolean;
}

const QuizMenu:FC = () => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const quizFormMenuItem = useAppSelector(selectQuizFormMenuItem);
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const [ menuItemErrors, setMenuItemErrors ] = useState<string[]>([]);

  const { watch, formState:{ errors } } = useFormContext();

  const registrationPageWatcher = watch('registrationPage');

  const menuItems:IMenuItem[] = useMemo(() => {
    if ( !quiz ) return [];

    return [
      { value: 'main', label: t('common.main'), icon: 'list', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll] },
      { value: 'prizes', label: t('common.prizes'), icon: 'cup', quizType: [QuizTypes.Scoring, QuizTypes.Prediction] },
      { value: 'buckets', label: t('common.buckets'), icon: 'delete', quizType: [QuizTypes.Personality] },
      { value: 'theme', label: t('common.theme'), icon: 'theme', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll] },
      { value: 'images', label: t('common.images'), icon: 'images', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll] },
      { value: 'promoVideos', label: t('common.promoVideos'), icon: 'video', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll] },
      { value: 'callToAction', label: t('common.callToAction'), icon: 'call_to_action', quizType: [QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll] },
      { value: 'consents', label: t('common.consents'), icon: 'clipboard', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll], hidden: !registrationPageWatcher || registrationPageWatcher.anonymous },
      { value: 'other', label: t('common.other'), icon: 'notes', quizType: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Prediction, QuizTypes.Poll] }
    ]
    // eslint-disable-next-line
  }, [t, registrationPageWatcher]);

  useEffect(() => {
    dispatch(appActions.setQuizFormMenuItem('main'));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( Object.keys(errors) ){
      const nextErrors = [];
      if ( errors.namespace || errors.owner || errors.quizName || errors.startsDate || errors.endsDate || errors.registrationCaption ){
        nextErrors.push('main');
      }
      if ( errors.buckets ){
        nextErrors.push('buckets');
      }
      if ( errors.logoImage || errors.mastheadImage ){
        nextErrors.push('images');
      }
      setMenuItemErrors(nextErrors);
    }
    // eslint-disable-next-line
  }, [Object.keys(errors).length]);

  return (
    <div className="flex flex-col flex-grow my-2">
      {menuItems.map(menuItem => {
        if (menuItem.hidden) return null;
        if (quiz && !menuItem.quizType.includes(quiz.type)) return null;
        return (
          <MenuItem
            key={`menu-item-${menuItem.value}`}
            menuItem={menuItem}
            active={quizFormMenuItem === menuItem.value}
            error={menuItemErrors.includes(menuItem.value)}
          />
        )
      })}
    </div>
  )
}

export default QuizMenu;
