import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IIntegration from 'models/Integration';
// Async
import { getIntegrations, createIntegration, updateIntegration, deleteIntegration } from './integrationsAsync';

interface IState {
  integrations: IIntegration[];
};

const initialState:IState = {
  integrations: [],
};

const slice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getIntegrations.fulfilled, (state, action:PayloadAction<IIntegration[]>) => {
      state.integrations = action.payload;
    });
    builder.addCase(createIntegration.fulfilled, (state, action:PayloadAction<IIntegration>) => {
      state.integrations = [...state.integrations, action.payload]
    });
    builder.addCase(updateIntegration.fulfilled, (state, action:PayloadAction<IIntegration>) => {
      state.integrations = state.integrations.map((integration:IIntegration) => {
        return integration._id === action.payload._id ? action.payload : integration;
      })
    });
    builder.addCase(deleteIntegration.fulfilled, (state, action:PayloadAction<any>) => {
      state.integrations = state.integrations.filter((integration:IIntegration) => integration._id !== action.payload);
    });
  }
});

export const { setInitialField } = slice.actions;

export default slice.reducer;
