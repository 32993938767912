import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Components
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import Icon from 'components/Icon';
// i18next
import { useTranslation } from 'react-i18next';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

type Props = {
  name: string;
  onRemove: () => void;
  idx: number;

  questionRef: string;
};

const Answer:FC<Props> = ({
  // Props
  name, onRemove, idx, questionRef
}) => {
  const { t } = useTranslation('common');

  const { watch } = useFormContext();

  const watchedAnswerRef = watch(`${name}.ref`);
  const answerKey = `${name.split('.').slice(0, 2).join('.')}[${questionRef}].answers.${watchedAnswerRef}.title`;

  return (
    <div className="flex gap-4">
      <MultiLanguageTextField
        name={`${name}.title`}
        customKey={answerKey}
        className="mb-4"
        label={`${t('common.answer')} ${idx + 1}`}
      />
      <div>
        <span className="block mb-1 h-5"></span>
        <Tooltip title={t('common.removeAnswer')}>
          <IconButton
            onClick={onRemove}
          ><Icon icon="delete" /></IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default Answer;
