import React from 'react';

type Props = {
  children: React.ReactNode;
}

const DialogActions:React.FC<Props> = ({ children }) => {
  return (
    <div className="
      flex justify-end items-center
      p-4 border-t border-gray-500 text-right
    ">
      {children}
    </div>
  )
}

export default DialogActions;
