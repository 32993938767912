import { FC, Fragment, memo, forwardRef, useRef, useState, useEffect } from 'react';
// import { v4 as uuidv4 } from 'uuid';
// Store
import { useAppDispatch } from 'hooks/useStore';
// Async
import { uploadVideo } from 'store/media/mediaAsync';
// Components
import { Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  value: string;
  onChange: (imageUrl:string) => void;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
};

const VideoUpload:FC<Props> = forwardRef<any, Props>(({
  // Props
  label = '', value, onChange, required = false, disabled = false,
  error = false, errorText = '', helperText = '', ...otherProps
}, ref) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);

  // const [ uuid, setUUID ] = useState<string>('');
  const [ fileDataUrl, setFileDataUrl ] = useState<string | null>(null);
  const [ file, setFile ] = useState<File | null>(null);
  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    // setUUID(uuidv4());
    return () => {
      // setUUID('');
      setFileDataUrl(null);
      setFile(null);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if ( !files || !files[0] ) return;
    const file = files[0];
    setFile(file);
    const fileReader:FileReader = new FileReader();
    fileReader.onload = (event:ProgressEvent<FileReader>) => {
      if ( !event.target ) return;
      setFileDataUrl(event.target.result as string);
    }
    fileReader.readAsArrayBuffer(file);
  }

  const handleClickClear = () => {
    if ( !inputRef.current ) return;
    inputRef.current.value = '';
    setFile(null);
    setFileDataUrl(null);
    onChange('');
  }

  const handleClickApply = async () => {
    if ( !fileDataUrl ) return;

    setLoading(true);

    try {
      const blob = new Blob([fileDataUrl],{"type": file?.type});
      const videoUrl = await dispatch(uploadVideo(blob)).unwrap();
      onChange(videoUrl.toString());
      if ( inputRef.current ) inputRef.current.value = '';
      setFile(null);
      setFileDataUrl(null);
    } catch(error){} finally {
      setLoading(false);
    }
  };

  const videoUrl = fileDataUrl || value;
  const isClearButtonDisabled = !Boolean(videoUrl);
  const isApplyButtonDisabled = !Boolean(inputRef.current && inputRef.current.value); // !Boolean(fileDataUrl);

  return (
    <div className="mb-4">
      <input ref={ref} type="hidden" name={(otherProps as any).name} />

      {label ? (
        <span className={`block text-white text-sm font-semibold mb-1 ${disabled ? 'opacity-50' : ''}`}>
          {t(label)}{required ? <Fragment>&nbsp;*</Fragment> : null}
        </span>
      ) : null}
      <div className="mb-2">
        {loading ? (
          <h1 className="text-white font-semibold">{`${t('components.videoUpload.loading')} ...`}</h1>
        ) : (
          value ? (
            <div className="aspect-w-16 aspect-h-9">
              <video className="w-full h-full object-center object-cover" controls>
                <source src={buildMediaUrl(value)} type="video/mp4" />
              </video>
            </div>
          ) : (
            <span className="text-white text-xs font-light">{file && file.name ? file.name : t('components.videoUpload.chooseVideo')}</span>
          )
        )}
        {isApplyButtonDisabled ? null : <h1 className="text-white font-semibold">{t('components.videoUpload.clickApplyToAddVideo')}</h1>}
      </div>
      <div className="mb-1">
        <label className="btn btn-outlined mr-2">
          <input
            ref={inputRef}
            className="hidden"
            type="file"
            autoComplete="off"
            accept="video/mp4,video/quicktime"
            onChange={handleChange}
          />
          {t('common.browse')}
        </label>
        <Button
          className="mr-2"
          variant="outlined"
          disabled={isClearButtonDisabled}
          onClick={handleClickClear}
        >{t('common.clear')}</Button>
        <Button
          variant="contained"
          disabled={isApplyButtonDisabled}
          onClick={handleClickApply}
        >{t('common.apply')}</Button>
      </div>
      {error && errorText ? <span className="block text-red-500 text-sm py-1 px-4">{t(errorText)}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{t(helperText)}</span> : null}
    </div>
  )
});

export default memo(VideoUpload, (prev, next) => {
  return prev.value === next.value && prev.error === next.error
});

const buildMediaUrl = (url:string) => {
  return url.replace('https://quizwizards-web-app.s3.amazonaws.com/', 'https://app.quizwizards.com/media/');
}
