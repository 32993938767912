import { FC } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectConsents } from 'store/consents/consentsSelectors';
import IConsent from 'models/Consent';
// Components
import { Loader, Message } from 'components/Utilities';
import ConsentsListItem from './ConsentsListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedConsents: IConsent[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const ConsentsList:FC<Props> = ({ sortedConsents, onRequestSort, getClassNamesFor }) => {
  const { t } = useTranslation('common');
  
  const consents = useAppSelector(selectConsents);

  if ( !consents ) return <Loader />;
  if ( consents.length === 0 ) return <Message text={t('adminPage.noConsents')} />;
  return (
    <table>
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('name')}`}
            onClick={() => onRequestSort('name')}
          >{t('common.name')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('status')}`}
            onClick={() => onRequestSort('status')}
          >{t('adminPage.status')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('language')}`}
            onClick={() => onRequestSort('language')}
          >{t('adminPage.language')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdAt')}`}
            onClick={() => onRequestSort('createdAt')}
          >{t('common.dateCreated')}</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedConsents.map(consent => (
          <ConsentsListItem
            key={`content-item-${consent._id}`}
            consent={consent}
          />
        ))}
      </tbody>
    </table>
  )
}

export default ConsentsList;
