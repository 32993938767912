import { createSelector } from "@reduxjs/toolkit";
import IConsent from "models/Consent";
import { RootState } from "store";

export const selectConsents = (state:RootState) => state.consents.consents;
export const selectConsent = (state:RootState) => state.consents.consent;
export const selectFilter = (state:RootState) => state.consents.filter;

export const getFilteredContentItems = createSelector(
  selectConsents, selectFilter,
  (items, filter) => {
    if ( !items ) return null;
    const { search } = filter;
    const searchToLower = search.toLowerCase();
    return items.filter(item => {
      return item.name.toLowerCase().indexOf(searchToLower) > -1;
    });
  }
);

export const selectConsentsGroups = (state:RootState, { lng }:{ lng:string }) => {
  const { consents } = state.consents;
  if ( !consents ) return null;
  return consents.filter(consent => consent.language === lng).reduce((acc:Record<string, IConsent[]>, cur) => {
    const key = cur.platform ? 'platform' : 'brand';
    return {...acc, [key]: [...(acc[key] || []), cur]};
  }, {});
}
