import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
// Models
import IBucket from 'models/Bucket';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selector
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// 
import Question from './Question';
import QuestionsSettings from './QuestionsSettings';

const TabContentQuestions:React.FC = () => {
  const quiz = useAppSelector(selectQuiz);

  const { watch } = useFormContext();

  const initialAnswer = useMemo(() => {
    const answer:any = { title: '', correct: false, };
    if ( quiz?.buckets ){
      answer['bucketScores'] = quiz.buckets.map((bucket:IBucket) => ({
        ref: bucket.ref,
        score: 0
      }));
    }
    return answer;
    // eslint-disable-next-line
  }, [quiz]);

  const watchedQuestions = watch('questions') || [];

  return (
    <div className="w-full overflow-y-auto overflow-x-hidden">
      {watchedQuestions.map((question:any, index:number) => (
        <Question
          key={`question-item-${question.ref}`}
          index={index}
          initialAnswer={initialAnswer}
        />
      ))}
      <QuestionsSettings />
    </div>
  )
}

export default TabContentQuestions;
