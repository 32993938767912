import { FC, Fragment } from 'react';
// Hooks
import useToggle from 'hooks/useToggle';
// Models
import IConsent from 'models/Consent';
// Components
import { Checkbox } from 'components/Controls';
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import ConsentsDetailsDialog from 'dialogs/ConsentsDetails.dialog';
import MultiLanguageConsentsDialog from './MultiLanguageConsents.dialog';
// Icons
import { FaEye } from "react-icons/fa";
import { FaLanguage } from "react-icons/fa6";
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  consent: IConsent;
  checked: boolean;
  handleSelect: (consentId: string) => void;
}

const ConsentItem:FC<Props> = ({ consent, checked, handleSelect }) => {
  const { t } = useTranslation('common');

  const { open, toggle } = useToggle();
  const { open:openDetails, toggle:toggleDetails } = useToggle();

  return (
    <Fragment>
      <div className="flex items-center gap-2 mb-2">
        <Checkbox
          label={consent.name} id={consent.name} name={consent.name}
          checked={checked}
          onChange={() => handleSelect(consent._id)}
          disabled={consent.required}
          className="mb-0"
        />
        <Tooltip title={t('common.details')}>
          <IconButton
            size="sm"
            onClick={toggleDetails}
          >
            <FaEye />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('common.translations')}>
          <IconButton
            size="sm"
            onClick={toggle}
          >
            <FaLanguage />
          </IconButton>
        </Tooltip>
      </div>
      
      {openDetails ? (
        <ConsentsDetailsDialog open={openDetails} onClose={toggleDetails} consentId={consent._id} />
      ) : null}
      
      {open ? (
        <MultiLanguageConsentsDialog name={consent.name} consentId={consent._id} open={open} onClose={toggle} />
      ) : null}
    </Fragment>
  )
}

export default ConsentItem;
