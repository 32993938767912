import { FC, Fragment, useState } from 'react';
import moment from 'moment';
// Icons
import { FaTrash, FaRedo, FaRegUser, FaRegClock } from 'react-icons/fa';
// Models
import { IUser } from 'models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Actions
import { resendInvite, deleteUser } from 'store/user/userAsync';
// Selectors
import { selectLoading } from 'store/user/userSelectors';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  user: IUser;
}

const UsersListItem:FC<Props> = ({
  // Props
  user
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const [ disabled, setDisabled ] = useState(false);
  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState(false);

  const toggleConfirmationDialog = () => setDialogConfirmationOpen(!dialogConfirmationOpen);

  const handleResendUserInvitation = async () => {
    setDisabled(true);
    try {
      await dispatch(resendInvite(user._id)).unwrap();
      setDisabled(false);
    } catch(error){}
  }

  const handleDeleteUser = async () => {
    try {
      await dispatch(deleteUser(user._id)).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">
          {user.name}
          <span className="lg:hidden">&nbsp;{user.surname}</span>
        </td>
        <td className="text-left hidden lg:table-cell">
          {user.surname}
          {user.passwordRecoveryToken ? (
            <div className="text-xs text-blue-400">{t('ownerPage.usersPage.invitationPending')}</div>
          ) : null}
        </td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {user.email}
        </td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(user.createdAt).format('MMM Do YYYY')}
        </td>
        <td className="text-right actions">
          {user.passwordRecoveryToken ? (
            <Tooltip title={t('ownerPage.usersPage.resendInvitation')} className="ml-2">
              <IconButton
                disabled={disabled}
                onClick={handleResendUserInvitation}
              ><FaRedo /></IconButton>
            </Tooltip>
          ) : null}
          <Tooltip className="ml-1" title={t('ownerPage.usersPage.deleteUser')}>
            <IconButton
              onClick={toggleConfirmationDialog}
            ><FaTrash /></IconButton>
          </Tooltip>
        </td>
      </tr>
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('ownerPage.usersPage.deleteUser')}: ${user.name}`}
          content={t('ownerPage.usersPage.confirmDeleteUser')}
          open={dialogConfirmationOpen}
          loading={loading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDeleteUser}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersListItem;
