import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
// Actions
import { appActions } from 'store/app/appSlice';
// Models
import IQuestion from 'models/Question';
import IAnswer from 'models/Answer';
// Types
import QuizTypes from 'types/QuizTypes';
// Utilities
import HttpClient from 'utilities/HttpClient';
import { isEqual } from 'lodash';
// ToDO: Should be moved to some JSON file with all errors
import { errorMessage } from 'utilities/error-message';
import { ResultAsync } from 'store/result/resultAsync';
import { quizzesMessages } from './quizzesMessages';

const _url:string = '/quiz'
const _http:HttpClient = new HttpClient();

export const getQuizzes = createAsyncThunk(
  'quizzes/Get quizzes',
  async (params:any = {}, { rejectWithValue }) => {
    const searchParams = new URLSearchParams();
    Object.keys(params).forEach((key:string) => {
      if ( params[key] !== '' ) searchParams.append(key, params[key]);
    });
    const response:Response = await _http.get(`${_url}?${searchParams}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

export const getQuiz = createAsyncThunk(
  'quizzes/Get quiz',
  async (quizId:string, { rejectWithValue }) => {
    const response:Response = await _http.get(`${_url}/${quizId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

export const createQuiz = createAsyncThunk(
  'quizzes/Create quiz',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(_url, data);
    if ( !response.ok ){
      const body = await response.json();
      let message = body.message || `${errorMessage('creating')} API error: ${response.status}`;
      if ( response.status === 403 ){
        message = data.owner
          ? 'Owner doesn\'t own the group name, please try again.'
          : 'Owner is required, please try again.'
        ;
      }
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: quizzesMessages.create }));
    return response.json();
  }
);

export const updateQuiz = createAsyncThunk(
  'quizzes/Update quiz',
  async ({ quizId, data }:{ quizId:string, data:any }, { rejectWithValue, dispatch, getState }) => {
    const response:Response = await _http.put(`${_url}/${quizId}`, data);

    if ( !response.ok ){
      const body = await response.json();
      let message = body.message || `${errorMessage('updating')} API error: ${response.status}`;
      if ( response.status === 403 ){
        message = data.owner
          ? 'Owner doesn\'t own the group name, please try again.'
          : 'Owner is required, please try again.'
        ;
      }
      return rejectWithValue({ message });
    }

    const state = getState() as RootState;
    const { quiz } = state.quizzes;

    if (quiz?.type === QuizTypes.Prediction) {
      const oldAnswers = quiz?.questions.reduce((acc:IAnswer[], cur:IQuestion) => (
        [...acc, ...cur.answers.filter(answer => answer.correct)]
      ), [])
      const nextAnswers = data.questions.reduce((acc:IAnswer[], cur:IQuestion) => (
        [...acc, ...cur.answers.filter(answer => answer.correct)]
      ), [])
  
      if (!isEqual(oldAnswers, nextAnswers)) {
        dispatch(ResultAsync.updateCorrectAnswersAndScores({ quizId: quiz._id }));
      }
    }
    
    dispatch(appActions.createNotification({ message: quizzesMessages.update }));
    return response.json();
  }
);

export const deleteQuiz = createAsyncThunk(
  'quizzes/Delete quiz',
  async (quizId:string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const response:Response = await _http.delete(`${_url}/${quizId}`);
    if ( !response.ok ){
      const body = await response.json();
      const message = body.message || `${errorMessage('deleting')} API error: ${body.status}`
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: quizzesMessages.delete }));
    return fulfillWithValue(quizId);
  }
);

export const cloneQuiz = createAsyncThunk(
  'quizzes/Clone quiz',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/clone`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: quizzesMessages.clone }));
    return response.json();
  }
);

export const startQuiz = createAsyncThunk(
  'quizzes/Start quiz',
  async (quizId:string, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/${quizId}/actions/start`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: quizzesMessages.start }));
    return response.json();
  }
);

export const endQuiz = createAsyncThunk(
  'quizzes/End quiz',
  async (quizId:string, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/${quizId}/actions/end`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: quizzesMessages.end }));
    return response.json();
  }
);

export const generateRichMedia = createAsyncThunk(
  'quizzes/Generate rich media',
  async (quizId:string, { rejectWithValue, fulfillWithValue }) => {
    const response:Response = await _http.get(`${_url}/generate-rich-media/${quizId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);

    const blob:Blob = await response.blob();

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = `rich-media_${quizId}.zip`;
    link.click();
    window.URL.revokeObjectURL(url);

    return fulfillWithValue('Rich media was generated');
  }
);

export const assignSpotPrizeWinner = createAsyncThunk(
  'quizzes/Assign spot prize winner',
  async (quizId:string, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/${quizId}/actions/assignSpotPrizeWinners`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: quizzesMessages.assignPrizeWinner }));
    return response.json();
  }
);
