import { FC } from 'react';
// Models
import IPlayer from 'models/Player';
// Store
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectFilter, selectPlayers } from 'store/player/playerSelectors';
// Componenr
import { Loader, Message } from 'components/Utilities';
// 
import PlayersListItem from './PlayersListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedPlayers: any[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const PlayersList:FC<Props> = ({
  // Props
  sortedPlayers, onRequestSort, getClassNamesFor
}) => {
  const { t } = useTranslation('common');
  // State
  const players:IPlayer[] | null = useAppSelector(selectPlayers);
  const filter = useAppSelector(selectFilter)

  if ( !players ) return <Loader />;
  if ( !filter.search ) return <Message text="Start search by typing participant email" />;
  if ( !players.length ) return <Message text="No participant(s)" />;
  return (
    <table>
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('name')}`}
            onClick={() => onRequestSort('name')}
          >{t('common.firstName')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('surname')}`}
            onClick={() => onRequestSort('surname')}
          >{t('common.lastName')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('email')}`}
            onClick={() => onRequestSort('email')}
          >{t('common.email')}</th>
          <th className="text-left">Quizzes</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedPlayers.map((player:any) => (
          <PlayersListItem
            key={`player-item-${player._id}`}
            player={player}
          />
        ))}
      </tbody>
    </table>
  )
}

export default PlayersList;
