import { createAsyncThunk } from '@reduxjs/toolkit';
import { appActions } from 'store/app/appSlice';
// Utilities
import HttpClient from 'utilities/HttpClient';
//
import { geofenceMessages } from './geofenceMessages';

const _url:string = '/geofence';
const _http:HttpClient = new HttpClient();

export const GeofenceAsync = {
  fetchGeofenceList: createAsyncThunk(
    'geofence/Fetch geofence list',
    async (data:{ id: string }, thunkApi) => {
      const response:Response = await _http.post(_url, data);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  updateGeofence: createAsyncThunk(
    'geofence/Update geofence',
    async (data:any, thunkApi) => {
      const { quizId, geofenceData } = data;
      const response:Response = await _http.put(`${_url}/${quizId}`, geofenceData);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: geofenceMessages.update }))
      return response.json();
    }
  )
}
