import React from 'react';
// Icons
import { FaTimes } from 'react-icons/fa';
// Components
import { IconButton } from 'components/Buttons';

type Props = {
  title: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const DialogTitle:React.FC<Props> = ({
  title, onClose, children
}) => {
  return (
    <div className="p-4 border-b border-gray-500">
      <div className="flex items-center justify-between">
        <h3 className="text-2xl text-white font-semibold pr-4">
          {title}
        </h3>
        {onClose ? (
          <IconButton
            onClick={onClose}
          ><FaTimes /></IconButton>
        ) : null}
      </div>
      {children}
    </div>
  )
}

export default DialogTitle;
