import React from 'react';
// Components
import { Portal } from 'components/Portal';

type Props = {
  className?: string;
  title?: string;
  children: React.ReactNode;
};

export const Tooltip:React.FC<Props> = ({
  // Props
  className = '', title = '', children
}) => {
  const [ elem, setElem ] = React.useState<HTMLSpanElement | null>(null);

  const open = Boolean(elem);

  const handleMouseEnter = (event:React.MouseEvent<HTMLSpanElement>) => {
    setElem(event.currentTarget);
  };
  const handleMouseLeave = () => {
    setElem(null);
  };

  const style = React.useMemo(() => {
    if ( elem ){
      const { top, left, width, height } = elem.getBoundingClientRect();
      return { top: `${top + height/2}px`, left: `${left + width/2}px` };
    }
    return {}
  }, [elem]);

  if ( !title ) return <React.Fragment>{children}</React.Fragment>;
  return (
    <span
      className={`${className} relative inline-block`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {open ? (
        <Portal id="root-tooltip">
          <span
            className="
              fixed transform -translate-x-1/2 translate-y-full
              rounded
              bg-gray-900 text-white text-xs text-center normal-case
              py-1 px-2 -mb-2
              z-50
              whitespace-nowrap
            "
            style={style}
          >{title}</span>
        </Portal>
      ) : null}
    </span>
  )
}
