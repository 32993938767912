import { createAsyncThunk } from '@reduxjs/toolkit';
// Redux
import { appActions } from 'store/app/appSlice';
import { IGroupData } from 'models/Group';
// Utilities
import HttpClient from 'utilities/HttpClient';
//
import { groupMessages } from './groupMessages';

const _url:string = '/quizGroup';
const _http:HttpClient = new HttpClient();

export const GroupsAsync = {
  fetchGroupsList: createAsyncThunk(
    'group/Fetch groups list',
    async (_, thunkApi) => {
      const response:Response = await _http.get(_url);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  fetchGroupById: createAsyncThunk(
    'group/Fetch group by id',
    async (groupId:string, thunkApi) => {
      const response:Response = await _http.get(`${_url}/${groupId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  createGroup: createAsyncThunk(
    'group/Create group',
    async (data:IGroupData, thunkApi) => {
      const response:Response = await _http.post(_url, data);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: groupMessages.create }))
      return response.json();
    }
  ),
  updateGroup: createAsyncThunk(
    'group/Update group',
    async (data:{groupId:string, groupData:IGroupData}, thunkApi) => {
      const { groupId, groupData } = data;
      const response:Response = await _http.put(`${_url}/${groupId}`, groupData);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: groupMessages.update }))
      return response.json();
    }
  ),
  deleteGroup: createAsyncThunk(
    'group/Delete group',
    async (groupId:string, thunkApi) => {
      const response:Response = await _http.delete(`${_url}/${groupId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: groupMessages.delete }))
    }
  )
}
