import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Components
import { Checkbox } from 'components/Controls';
import VideoUpload from 'components/VideoUpload';

const SectionPromo:FC = () => {
  const { t } = useTranslation('common');

  const { control } = useFormContext();

  return (
    <div react-scroll="promoVideos">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.promoVideos')}</h2>
      </div>
      <div className="p-6">
        <Controller
          control={control} name="promoVideo"
          render={({ field }) => (
            <VideoUpload
              {...field}
              label="ownerPage.quizForm.promoVideo"
              helperText="ownerPage.quizForm.promoVideoHint"
            />
          )}
        />
        <span className="block border-b border-gray-400 my-4"></span>
        <Controller
          control={control} name="brandVideo"
          render={({ field }) => (
            <VideoUpload
              {...field}
              label="ownerPage.quizForm.brandVideo"
              helperText="ownerPage.quizForm.brandVideoHint"
            />
          )}
        />
        <span className="block text-gray-400 text-sm font-light leading-5 mt-1">{t('ownerPage.quizForm.promoVideosHint1')}</span>
        <span className="block text-gray-400 text-sm font-light leading-5">{t('ownerPage.quizForm.promoVideosHint2')}</span>
        <span className="divider my-4"></span>
        <div className="mb-4">
          <div className="mb-4">
            <Controller
              control={control} name="advertVideoUrl"
              render={({ field }) => (
                <VideoUpload
                  {...field}
                  label="ownerPage.quizForm.midrollBrandVideo"
                  helperText="ownerPage.quizForm.midrollBrandVideoHint"
                />
              )}
            />
          </div>
          <span className="block text-white text-base font-semibold mb-2">{t('ownerPage.quizForm.chooseLocationVideo')}</span>
          <Controller
            control={control} name="advertVideoStartQuiz"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('ownerPage.quizForm.chooseLocationVideoHint1')} id="advertVideoStartQuiz" name="advertVideoStartQuiz" checked={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control} name="advertVideoMidQuiz"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('ownerPage.quizForm.chooseLocationVideoHint2')} id="advertVideoMidQuiz" name="advertVideoMidQuiz" checked={value}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control} name="advertVideoEndQuiz"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('ownerPage.quizForm.chooseLocationVideoHint3')} id="advertVideoEndQuiz" name="advertVideoEndQuiz" checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default SectionPromo;
