import { FC, Fragment, useState } from 'react';
import moment from 'moment';
// Icons
import { FaTrash, FaRedo, FaRegUser, FaUsers, FaRegClock, FaSignInAlt } from 'react-icons/fa';
import { IoSettings } from "react-icons/io5";
// Models
import { IUser } from 'models/User';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Actions
import { singInAs } from 'store/auth/authAsync';
import { resendInvite, deleteUser } from 'store/user/userAsync';
// Selectors
import { selectLoading } from 'store/user/userSelectors';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import UserRoles from 'types/UserRoles';
// i18next
import { useTranslation } from 'react-i18next';
import useToggle from 'hooks/useToggle';
import AccountsFormDialog from 'dialogs/AccountsForm.dialog';

type Props = {
  user: IUser;
}

const UsersListItem:FC<Props> = ({
  // Props
  user
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const [ disabled, setDisabled ] = useState(false);

  const { open:dialogConfirmationOpen, toggle:toggleConfirmationDialog } = useToggle();
  const { open:accountsFormDialogOpen, toggle:toggleAccountsFormDialog } = useToggle();

  const handleResendUserInvitation = async () => {
    setDisabled(true);
    try {
      await dispatch(resendInvite(user._id)).unwrap();
      setDisabled(false);
    } catch(error){}
  }

  const handleDeleteUser = async () => {
    try {
      await dispatch(deleteUser(user._id)).unwrap();
      toggleConfirmationDialog();
    } catch(error){}
  }

  const handleSignInAs = () => {
    dispatch(singInAs(user._id));
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">
          {user.name}
          <span className="lg:hidden">&nbsp;{user.surname}</span>
        </td>
        <td className="text-left hidden lg:table-cell">
          {user.surname}
          {user.passwordRecoveryToken ? (
            <div className="text-xs text-blue-400">{t('adminPage.invitationPending')}</div>
          ) : null}
        </td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {user.email}
        </td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {user.groups.join(', ')}
        </td>
        <td className="text-left text">
          <FaUsers className="icon" size="16px" />
          {user.account?.organization || '-'}
        </td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(user.createdAt).format('MMM Do YYYY')}
        </td>
        <td className="text-right actions">
          {user.passwordRecoveryToken ? (
            <Tooltip title={t('adminPage.resendInvitation')} className="ml-2">
              <IconButton
                disabled={disabled}
                onClick={handleResendUserInvitation}
              ><FaRedo /></IconButton>
            </Tooltip>
          ) : null}
          {user.groups.includes(UserRoles.Owner) && (
            <Tooltip className="ml-1" title={t('adminPage.signInAs')}>
              <IconButton
                onClick={handleSignInAs}
              ><FaSignInAlt /></IconButton>
            </Tooltip>
          )}
          {user.account ? (
            <Tooltip title={t('adminPage.accountSettings')}>
              <IconButton
                onClick={toggleAccountsFormDialog}
              ><IoSettings /></IconButton>
            </Tooltip>
          ) : null}
          <Tooltip className="ml-1" title={t('adminPage.deleteUser')}>
            <IconButton
              onClick={toggleConfirmationDialog}
            ><FaTrash /></IconButton>
          </Tooltip>
        </td>
      </tr>
      {user.account && accountsFormDialogOpen ? (
        <AccountsFormDialog accountId={user.account._id} open={accountsFormDialogOpen} onClose={toggleAccountsFormDialog} />
      ): null}
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('adminPage.deleteUser')}: ${user.name}`}
          content={user.groups.includes(UserRoles.Owner) ? t('adminPage.confirmDeleteOwner', { userName: user.name }) : t('adminPage.confirmDeleteUser')}
          open={dialogConfirmationOpen}
          loading={loading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDeleteUser}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersListItem;
