import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
// i18next
import { useTranslation } from 'react-i18next';
// Hooks
import useToggle from 'hooks/useToggle';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectQuizFormMenuItem } from 'store/app/appSelectors';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import IQuiz from 'models/Quiz';
import IAnswer from 'models/Answer';
import IQuestion from 'models/Question';
import QuizTypes from 'types/QuizTypes';
import QuestionTypes from 'types/QuestionTypes';
import AccountFeatures from 'types/AccountFeatures';
// Componenrs
import { Button } from 'components/Buttons';
import Icon from 'components/Icon';
import MenuItem from './MenuItem';
import GenerateQuestionsDialog from 'dialogs/GenerateQuestions.dialog';
// Icons
import { RiAiGenerate } from "react-icons/ri";
// framer-motion
import { Reorder } from 'framer-motion/dist/framer-motion';

const QuestionsMenu:FC = () => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();

  const quizFormMenuItem = useAppSelector(selectQuizFormMenuItem);
  const quiz:IQuiz | null = useAppSelector(selectQuiz);
  const currentUser = useAppSelector(selectCurrentUser);

  const initialScore = quiz?.type === QuizTypes.Prediction ? 3000 : 1000;

  const { control, formState:{ errors }, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions'
  });

  const [items, setItems] = useState(fields);

  const reorder = () => {
    setValue('questions', items);
  }

  useEffect(() => {
    setItems(fields);
  }, [fields]);

  const hasQuestionError = useCallback((index:number) => {
    if ( !errors || !errors.questions ) return false;
    return (errors as any).questions[index] ? true : false;
    // eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    dispatch(appActions.setQuizFormMenuItem('question-0'));
    // eslint-disable-next-line
  }, []);

  const handleAdd = (title = '', answers = []) => {
    const question:any = {
      ref: uuidv4(),
      title,
      description: '',
      type: QuestionTypes.SingleChoice,
      image: '',
      video: '',
      answers
    }
    if (quiz && [QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type)) question['score'] = initialScore;
    append(question);
  }

  const handleCopy = (index:number) => () => {
    const { title, type, answers, score } = fields[index] as any;

    const question:any = {
      ref: uuidv4(),
      title,
      description: '',
      type,
      image: '',
      video: '',
      answers: answers.map((answer:IAnswer) => ({ ...answer, ref: uuidv4() })),
    }
    if (quiz && [QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type)) question['score'] = score || initialScore;
    
    append(question);
    
    dispatch(appActions.setQuizFormMenuItem(`question-${fields.length}`));
  }

  const handleDelete = (index:number) => () => {
    remove(index);
  }

  const getQuestionIndex = (questionId: string) => {
    return fields.findIndex(field => field.id === questionId) + 1;
  }

  const handleAddQuestions = (questions:IQuestion[]) => {
    questions.forEach((question) => {
      const answers:any = question.answers.map(answer => ({ ...answer, ref: uuidv4() }));
      handleAdd(question.title, answers);
    });
  }

  const { open, toggle } = useToggle();

  return (
    <Fragment>
      {open ? (
        <GenerateQuestionsDialog open={open} onClose={toggle} handleAddQuestions={handleAddQuestions} />
      ) : null}

      <div className="p-4">
        <Button
          className="flex justify-center items-center w-full mb-2"
          onClick={() => handleAdd()}
          variant="contained"
        >
          <Icon className="mr-2" icon="add" />
          {t('common.addQuestion')}
        </Button>
        {currentUser?.account?.features?.includes(AccountFeatures.AIModule) ? (
          <Button
            className="flex justify-center items-center w-full"
            onClick={toggle}
            variant="contained"
          >
            <RiAiGenerate className="mr-2" fontSize={20} />
            {t('ownerPage.quizQuestionsPage.aiGeneratedQuestions')}
          </Button>
        ) : null}
      </div>
      <div className="divider my-0" />
      <Reorder.Group axis="y" values={items} as="div" onReorder={setItems} className="flex flex-col flex-grow my-2 overflow-y-scroll">
        {quiz && [QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(quiz.type) && (
          <MenuItem
            menuItem={{
              icon: 'settings',
              label: t('ownerPage.quizQuestionsPage.settings'),
              value: 'settings'
            }}
            active={quizFormMenuItem === 'settings'}
          />
        )}
        {items.map((field:any, index:number) => (
          <Reorder.Item key={`question-item-${field.id}`} value={field} as="div" onDragEnd={reorder}>
            <MenuItem
              menuItem={{
                label: `${t('common.question')} ${getQuestionIndex(field.id)}`,
                value: `question-${index}`
              }}
              onDelete={handleDelete(index)}
              onCopy={handleCopy(index)}
              active={quizFormMenuItem === `question-${index}`}
              error={hasQuestionError(index)}
              dragIcon={true}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </Fragment>
  )
}

export default QuestionsMenu;
