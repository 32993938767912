import { createSlice } from '@reduxjs/toolkit';

interface IState {
  loading: boolean
};

const initialState:IState = {
  loading: false
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {

  },
  extraReducers: () => {

  }
});

// export const {  } = slice.actions;

export default slice.reducer;
