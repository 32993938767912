import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectConsents } from 'store/consents/consentsSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
import { Select } from 'components/Controls';
//
import { countries } from 'components/LanguageSelect';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  consentId: string;
  open: boolean;
  onClose: () => void;
}

const MultiLanguageConsentsDialog:React.FC<Props> = ({ name, consentId, open, onClose }) => {
  const { t } = useTranslation('common');

  const consents = useAppSelector(selectConsents) || [];

  const { watch, setValue } = useFormContext();

  const watchedAdditionalLanguages = watch('additionalLanguages');
  const watchedTranslations = watch('translations') || {};

  const key = `consents.${consentId}.consent`;

  const onChange = (e:ChangeEvent<HTMLSelectElement>, lng:string) => {
    setValue('translations', {
      ...watchedTranslations,
      [lng]: {
        ...(watchedTranslations[lng] || {}),
        [key]: e.target.value
      }
    })
  }

  const getOptions = (lang: string) => {
    const filteredConsents = consents.filter(consent => consent.language === lang).map(c => ({ value: c._id, label: c.name }));

    return [{ label: 'None', value: '' }, ...filteredConsents];
  }

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={name} onClose={onClose} />
      <DialogContent>
        {watchedAdditionalLanguages.map((lang:string) => (
          <Select
            key={`${name}-${lang}`}
            options={getOptions(lang)}
            value={watchedTranslations[lang]?.[key] || ''}
            onChange={(e) => onChange(e, lang)}
            label={countries.find(country => country.value === lang)?.label}
            id={`${name}-${lang}`} name={`${name}-${lang}`}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
        >{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MultiLanguageConsentsDialog;
