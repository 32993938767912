import React from 'react';

type Props = {
  children: React.ReactNode;
}

const DialogContent:React.FC<Props> = ({ children }) => {
  return (
    <div className="flex-grow relative p-4 overflow-y-auto">{children}</div>
  )
}

export default DialogContent;
