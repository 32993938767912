import { FC, useEffect } from 'react';
import { Switch, Redirect, useRouteMatch } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { GroupsAsync } from 'store/group/groupAsync';
import { getUsers } from 'store/user/userAsync';
import { getIntegrations } from 'store/integrations/integrationsAsync';
import { NamespaceAsync } from 'store/namespace/namespaceAsync';
import { getNamespaceItems } from 'store/namespace/namespaceSelectors';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import { IUser } from 'models/User';
import UserRoles from 'types/UserRoles';
// Pages
import PlayersPage from './PlayersPage';
import QuizzesPage from './QuizzesPage';
import QuizFormPage from './QuizFormPage';
import QuizReportPage from './QuizReportPage';
import GroupsPage from './GroupsPage';
import GroupReportPage from './GroupReportPage';
import UsersPage from './UsersPage';
import NamespacesPage from './NamespacesPage';
import QuizAnalyticsPage from './QuizAnalyticsPage';
import ConsentsPage from '../AdminPage/ConsentsPage';
import AnalyticsPage from './AnalyticsPage';
import QuestionAnswersBreakdownPage from './QuestionAnswersBreakdownPage';
// Components
import PrivateRoute from 'components/PrivateRoute';

const OwnerPage:FC = () => {
  const { url } = useRouteMatch<{ url:string }>();
  const dispatch = useAppDispatch();
  
  const currentUser:IUser | null = useAppSelector(selectCurrentUser);
  const namespaces = useAppSelector(getNamespaceItems);

  useEffect(() => {
    if ( currentUser && currentUser.groups.includes(UserRoles.Owner) ){
      dispatch(getUsers({ groups: [UserRoles.Staff] }));
    }
    dispatch(GroupsAsync.fetchGroupsList());
    dispatch(NamespaceAsync.fetchNamespacesList());
    dispatch(getIntegrations());

    // eslint-disable-next-line
  }, []);

  if ( !namespaces ) return null;
  return (
    <Switch>
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} exact path={`${url}/players`} component={PlayersPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} exact path={`${url}/quizzes`} component={QuizzesPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} exact path={`${url}/quizzes/:quizId`} component={QuizFormPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/quizzes/:quizId/report`} component={QuizReportPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/quizzes/:quizId/analytics`} component={QuizAnalyticsPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} exact path={`${url}/groups`} component={GroupsPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/groups/:groupId/report`} component={GroupReportPage} />
      <PrivateRoute roles={[UserRoles.Owner]} path={`${url}/users`} component={UsersPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/categories`} component={NamespacesPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/consents`} component={ConsentsPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/analytics`} component={AnalyticsPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path={`${url}/quizzes/:quizId/answers-analytics`} component={QuestionAnswersBreakdownPage} />
      <Redirect from={url} to={`/${currentUser?.groups[0]}/quizzes`} />
    </Switch>
  )
}

export default OwnerPage;
