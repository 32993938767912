import { FC, Fragment } from 'react';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks/useStore';
// Types
import UserRoles from 'types/UserRoles';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// Components
import { IconButton } from 'components/Buttons';
// Icons
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
// i18next
import { useTranslation } from 'react-i18next';

export const QuizToolbar:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const quiz = useSelector(selectQuiz);

  const isAdmin = currentUser?.groups.includes(UserRoles.Admin);
  const role = isAdmin ? 'admin' : 'owner';

  const handleBackToQuizzesList = () => dispatch(push(`/${role}/quizzes`));

  return (
    <h1 className="flex items-center flex-grow text-xl font-bold text-white leading-6 mb-4">
      <span
        className="
          hidden tablet:inline-block
          cursor-pointer
        "
        onClick={handleBackToQuizzesList}
      >{t('ownerPage.quizForm.manageQuizzes')}</span>
      <IconButton
        className="flex justify-center items-center tablet:hidden mr-2"
        onClick={handleBackToQuizzesList}
      ><FaChevronLeft /></IconButton>
      <Fragment>
        <FaChevronRight className="hidden tablet:inline-block mx-2" />
        <span className="opacity-60">{quiz?.quizName}</span>
      </Fragment>
    </h1>
  )
};
