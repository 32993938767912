import React, { useEffect, useState } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useStore';
import AccountsAsync from 'store/accounts/accountsAsync';
import { accountsActions } from 'store/accounts/accountsSlice';
import AccountFeatures from 'types/AccountFeatures';
import QuizTypes from 'types/QuizTypes';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Checkbox, Toggle } from 'components/Controls';
import { Button } from 'components/Buttons';
import { Loader } from 'components/Utilities';
// Utilities
import { textFromCamelToNormalCase } from 'utilities/utilities';

type Props = {
  accountId: string;
  open: boolean;
  onClose: () => void;
}

const AccountsFormDialog:React.FC<Props> = ({ accountId, open, onClose }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [features, setFeatures] = useState<AccountFeatures[]>([]);
  const [quizTypes, setQuizTypes] = useState<QuizTypes[]>([]);

  const onChangeFeature = (feature: AccountFeatures) => {
    if (features.includes(feature)) {
      setFeatures(prev => prev.filter(f => f !== feature));
    } else {
      setFeatures(prev => [...prev, feature]);
    }
  }

  const onChangeQuizTypes = (quizType: QuizTypes) => {
    if (quizTypes.includes(quizType)) {
      setQuizTypes(prev => prev.filter(type => type !== quizType));
    } else {
      setQuizTypes(prev => [...prev, quizType]);
    }
  }

  const onSubmit = () => {
    setIsUpdating(true);

    dispatch(AccountsAsync.updateAccount({ accountId: accountId, accountData: { features, quizTypes } })).unwrap()
      .then(() => onClose())
      .finally(() => setIsUpdating(false));
  };

  useEffect(() => {
    setIsLoading(true);

    dispatch(AccountsAsync.getAccount(accountId)).unwrap()
      .then((data) => {
        setFeatures(data.features || []);
        setQuizTypes(data.quizTypes || []);
      })
      .then(() => setIsLoading(false));

    return () => {
      dispatch(accountsActions.setInitialField('account'));
    }
    // eslint-disable-next-line
  }, []);

  const featuresLabels:Record<AccountFeatures, string> = {
    [AccountFeatures.WhiteLabeling]: t('dialogs.accountsForm.whiteLabeling'),
    [AccountFeatures.AIModule]: t('dialogs.accountsForm.aiModule')
  }

  return (
    <Dialog size="lg" open={open} onClose={onClose}>
      {isLoading ? <Loader /> : (
        <form className="flex flex-grow flex-col overflow-y-auto" noValidate>
          <DialogTitle title={t('dialogs.accountsForm.updateAccount')} onClose={onClose} />
          <DialogContent>
            <div className="mb-4 w-max">
              {Object.values(AccountFeatures).map((feature) => (
                <div className="flex items-center justify-between mb-2" key={feature}>
                  <span className="block text-white text-base font-semibold mr-3">{featuresLabels[feature]}</span>
                  <Toggle
                    id={`account-feature-${feature}`}
                    checked={features.includes(feature)}
                    onChange={() => onChangeFeature(feature)}
                  />
                </div>
              ))}
            </div>
            <div className="mb-4">
              <p className="text-white text-base font-semibold mb-2">{t('dialogs.accountsForm.quizTypes')}</p>
              {Object.values(QuizTypes).map((quizType) => (
                <Checkbox
                  key={`quiz-type-${quizType}`} id={`quiz-type-${quizType}`} name={`quiz-type-${quizType}`}
                  className="ml-2 mb-2"
                  checked={quizTypes.includes(quizType)}
                  label={textFromCamelToNormalCase(quizType)}
                  onChange={() => onChangeQuizTypes(quizType)}
                />
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
            >{t('common.cancel')}</Button>
            <Button
              className="ml-2"
              variant="contained"
              loading={isUpdating}
              onClick={onSubmit}
            >{t('common.save')}</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

export default AccountsFormDialog;
