import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IPlayer from 'models/Player';
// Async
import { getPlayers, deletePlayer } from './playerAsync';

interface IState {
  players: IPlayer[] | null;
  filter: {
    search: string;
  };
  loading: boolean
};

const initialState:IState = {
  players: [],
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:keyof IState['filter'], value:string }>) => {
      state.filter[action.payload.field] = action.payload.value;
    },
    setInitialField: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPlayers.pending, (state) => {
      state.players = null;
    });
    builder.addCase(getPlayers.fulfilled, (state, action:PayloadAction<IPlayer[]>) => {
      state.players = action.payload;
    });

    // Delete player
    builder.addCase(deletePlayer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePlayer.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.players ){
        state.players = state.players.filter((player:IPlayer) => player._id !== action.payload);
      }
    });
  }
});

export const { setFilter, setInitialField } = slice.actions;

export default slice.reducer;
