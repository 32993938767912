import React from 'react';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
// Models
import IQuiz from 'models/Quiz';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { getQuizzes } from 'store/quiz/quizzesAsync';
// Actions
import { setInitialField } from 'store/quiz/quizzesSlice';
// Selectors
import { selectQuizzes } from 'store/quiz/quizzesSelectors';
// Components
import { Loader, Message } from 'components/Utilities';
// 
import ReportListItem from './QuizzesListItem';

type Props = {
  groupId: string;
}

const QuizzesList:React.FC<Props> = ({
  // Props
  groupId
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quizzes:IQuiz[] | null = useAppSelector(selectQuizzes);

  const handleGoTo = (url:string) => dispatch(push(url));

  React.useEffect(() => {
    dispatch(getQuizzes({ quizGroupId: groupId }));
    return () => {
      dispatch(setInitialField('quizzes'));
    }
    // eslint-disable-next-line
  }, []);

  if ( !quizzes ) return <Loader />;
  if ( !quizzes.length ) return <Message text={t('dialogs.groupQuizzes.noQuizzes')} />;
  return (
    <div
      className="
        flex flex-col
        border border-gray-500 rounded
      "
    >
      {quizzes.map((item:any) => (
        <ReportListItem
          key={`quiz-item-${item._id}`}
          item={item}
          onGoTo={handleGoTo}
        />
      ))}
    </div>
  )
}

export default QuizzesList;
