import { FC, useMemo } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { getQuizAnalytics } from 'store/result/resultSelectors';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// i18next
import { useTranslation } from 'react-i18next';
//
import ReactApexChart from "react-apexcharts";
import IAnalytics from 'models/QuizAnalytics';

const SocialsBars:FC = () => {
  const { t } = useTranslation('common');

  const analytics = useAppSelector(getQuizAnalytics);
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const shareClicks = useMemo(() => {
    if (!analytics) return {};
    return {
      'Facebook': socialShareClicks(analytics, 'facebook'),
      'Twitter': socialShareClicks(analytics, 'twitter'),
      'Reddit': socialShareClicks(analytics, 'reddit'),
      'LinkedIn': socialShareClicks(analytics, 'linkedIn'),
      'Messenger': socialShareClicks(analytics, 'messenger'),
      'WhatsApp': socialShareClicks(analytics, 'whatsApp')
    }
  }, [analytics])

  const socialShares = analytics?.socialShares || [];
  const totalClicks = socialShares.reduce((acc, cur) => acc + cur.shares, 0);

  const series = [{
    name: 'Share clicks',
    data: Object.values(shareClicks)
  }];

  const options:any = {
    chart: {
      type: 'bar',
      height: 200
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: true
    },
    xaxis: {
      categories: Object.keys(shareClicks),
      labels: {
        show: false
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#eee'
        }
      }
    }
  }

  if ( !analytics ) return null;
  if ( !quiz || quiz.type === QuizTypes.Personality ) return null;
  return (
    <div className="mb-8">
      <h3 className="text-white font-bold">{t('ownerPage.quizReportPage.socialsShareClicks')}</h3>
      <p className="text-white text-sm font-light text-opacity-80">{`${t('ownerPage.quizReportPage.total')}: ${totalClicks}`}</p>
      <ReactApexChart options={options} series={series} type="bar" height={200} />
    </div>
  )
}

export default SocialsBars;

const socialShareClicks = (analytics:IAnalytics, type:string) => {
  if ( !analytics || !analytics.socialShares ) return 0;
  const findedType = analytics.socialShares.find((share) => share.type === type);
  if ( !findedType ) return 0;
  return findedType.shares;
}
