import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupsAsync } from './groupAsync';
import { IGroup } from 'models/Group';

interface IState {
  items: IGroup[] | null;
  item: IGroup | null;
  filter: {
    search: string;
  },
};

const initialState:IState = {
  items: null,
  item: null,
  filter: {
    search: ''
  },
};

const slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setFilter: (state:IState, action:PayloadAction<Record<string, string>>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GroupsAsync.fetchGroupsList.pending, (state:IState) => {
        state.items = null;
      })
      .addCase(GroupsAsync.fetchGroupsList.fulfilled, (state:IState, action:PayloadAction<IGroup[]>) => {
        state.items = action.payload;
      })
      .addCase(GroupsAsync.fetchGroupById.pending, (state:IState) => {
        state.item = null;
      })
      .addCase(GroupsAsync.fetchGroupById.fulfilled, (state:IState, action:PayloadAction<IGroup>) => {
        state.item = action.payload;
      })
      .addCase(GroupsAsync.createGroup.fulfilled, (state:IState, action:PayloadAction<IGroup>) => {
        if ( state.items ){
          state.items.push(action.payload);
        }
      })
      .addCase(GroupsAsync.updateGroup.fulfilled, (state:IState, action:PayloadAction<IGroup>) => {
        if ( state.items ){
          state.items = state.items.map(item => item._id === action.payload._id ? action.payload : item)
        }
      })
      .addCase(GroupsAsync.deleteGroup.fulfilled, (state:IState, action) => {
        const { arg:conentId } = action.meta;
        if ( state.items ){
          state.items = state.items.filter((item) => item._id !== conentId)
        }
      })
  }
});

export const groupsActions = slice.actions;

export default slice.reducer;
