import { createSelector } from '@reduxjs/toolkit';
// 
import { RootState } from "store";

export const selectEntities = (state:RootState) => state.form.entities;
export const selectHash = (state:RootState) => state.form.hash;

export const selectFormByQuizId = createSelector(
  [
    selectEntities,
    (_, props:{ quizId:string }) => props
  ],
  (entities:{ [key:string]:any }, { quizId }) => {
    if ( !entities[quizId] ) return undefined;
    return JSON.parse(entities[quizId]);
  }
);
