import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';

type Props = {
  roles: string[];
  exact?: boolean;
  path: string;
  component: any;
}

const PrivateRoute:React.FC<Props> = ({
  // Props
  roles, component:Component, ...rest
}) => {
  // State
  const currentUser:any = useSelector(selectCurrentUser);
  return (
    <Route {...rest} render={(props:RouteProps) => (
      currentUser && currentUser.groups.some((group:string) => roles.includes(group))
      ? <Component {...props} />
      : <Redirect to="/" />
    )} />
  );
}

export default PrivateRoute;
