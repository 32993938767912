import { FC } from 'react';
// Components
import { Toolbar } from 'components/Toolbar';
// 
import ReportFilter from './ReportFilter';
import ReportList from './ReportList';
// i18next
import { useTranslation } from 'react-i18next';

const GroupReportPage:FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <Toolbar title={t('ownerPage.groupReportPage.report')} goBackTo="/owner/groups" />
      <ReportFilter />
      <ReportList />
    </div>
  )
}

export default GroupReportPage;
