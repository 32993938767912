import { FC, ReactChild, ReactNode } from 'react';
import { push } from 'connected-react-router';
// Font
import { FaAngleLeft } from 'react-icons/fa';
// Redux
import { useDispatch } from 'react-redux';
// Components
import { IconButton } from './Buttons';


type Props = {
  className?: string;
  title: ReactChild;
  goBackTo?: string;
  children?: ReactNode;
}

export const Toolbar:FC<Props> = ({
  // Props
  className = '', title, goBackTo = '', children
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if ( goBackTo ) dispatch(push(goBackTo));
  };

  return (
    <div
      className={`
        ${className}
        flex flex-wrap justify-between items-center
        mb-4
      `}
    >
      {goBackTo ? (
        <IconButton
          className="mr-2 -ml-2"
          onClick={handleClick}
        ><FaAngleLeft /></IconButton>
      ) : null}
      {typeof title === 'string' ? (
        <h1
          className="flex items-center flex-grow text-xl font-bold text-white leading-6"
          title={title}
        >{title}</h1>
      ) : title}
      {children || null}
    </div>
  )
};
