import { FC, useEffect, useState } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { GeofenceAsync } from 'store/geofence/geofenceAsync';
import { geofenceActions } from 'store/geofence/geofenceSlice';
import { getGeofenceItems } from 'store/geofence/geofenceSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  quizId: string;
  open: boolean;
  onClose: () => void;
};

const QuizGeofenceFormDialog:FC<Props> = ({ quizId, open, onClose }) => {
  const { t } = useTranslation('common');

  const dispatch = useAppDispatch();
  
  const geofenceItems = useAppSelector(getGeofenceItems);

  const [ geofenceOptions, setGeofenceOptions ] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ( quizId ) dispatch(GeofenceAsync.fetchGeofenceList({ id: quizId }));
    return () => {
      if ( quizId ) dispatch(geofenceActions.setInitialField('items'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( geofenceItems ) setGeofenceOptions(geofenceItems);
    // eslint-disable-next-line
  }, [geofenceItems]);

  const handleSave = () => {
    setIsLoading(true);
    dispatch(GeofenceAsync.updateGeofence({quizId, geofenceData: { countries: geofenceOptions }})).unwrap()
      .then(() => onClose())
      .finally(() => setIsLoading(false));
  }

  const handleSelectAll = () => setGeofenceOptions((prevState:any) => prevState.map((option:any) => ({...option, allowed: true})));
  const handleDeselectAll = () => setGeofenceOptions((prevState:any) => prevState.map((option:any) => ({...option, allowed: false})));

  const toggleGeofence = (value:string) => {
    setGeofenceOptions((prevState:any) => prevState.map((option:any) => option.value === value ? {...option, allowed: !option.allowed} : option))
  };

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={t('dialogs.quizGeofenceForm.manageGeofence')} onClose={onClose} />
      <DialogContent>
        <div className="mb-4 text-right">
          <Button
            variant="contained"
            onClick={handleSelectAll}
          >{t('dialogs.quizGeofenceForm.selectAll')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            onClick={handleDeselectAll}
          >{t('dialogs.quizGeofenceForm.deselectAll')}</Button>
        </div>
        <span className="block text-white text-sm font-semibold mb-2">{t('dialogs.quizGeofenceForm.selectAllowedCountries')}</span>
        <div
          className="
            flex flex-col
            rounded border border-gray-400
            p-2
            overflow-y-auto
          "
          style={{
            height: '640px'
          }}
        >
          {geofenceOptions.map((option:any, index:number) => (
            <button
              key={`geofence-item-${option.value}-${index}`}
              className={`
                rounded
                text-white text-center font-semibold
                p-2 mb-1 
                ${option.allowed ? 'bg-primary hover:bg-secondary' : 'hover:bg-gray-500'}
              `}
              type="button"
              onClick={() => toggleGeofence(option.value)}
            >{option.name}</button>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >{t('common.cancel')}</Button>
        <Button
          className="ml-2"
          variant="contained"
          onClick={handleSave}
          loading={isLoading}
        >{t('common.save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuizGeofenceFormDialog;
