import { FC, Fragment, useState } from 'react';
// Dialog
import SignInDialog from 'dialogs/SignIn.dialog';
import RecoverPasswordDialog from 'dialogs/RecoverPassword.dialog';
// Components
import { Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

const PublicContent:FC = () => {
  const { t } = useTranslation('common');

  const [ dialogSignInOpen, setDialogSignInOpen ] = useState(false);
  const [ dialogRecoverPasswordOpen, setDialogRecoverPasswordOpen ] = useState(false);

  const toggleSignInDialog = () => setDialogSignInOpen(!dialogSignInOpen);
  const toggleRecoverPasswordDialog = () => setDialogRecoverPasswordOpen(!dialogRecoverPasswordOpen);

  return (
    <Fragment>
      <h1
        className="text-white text-4xl font-semibold mb-8"
        style={{
          textShadow: '6px 0 8px rgba(0,0,0,0.38)'
        }}
      >{t('homePage.welcomeGuest')}</h1>
      <h3
        className="text-white text-2xl mb-8"
        style={{
          textShadow: '6px 0 8px rgba(0,0,0,0.38)'
        }}
      >{t('homePage.pleaseLogin')}</h3>
      <div className="mb-2">
        <Button
          className="w-9/12"
          variant="contained"
          size="xl"
          onClick={toggleSignInDialog}
        >{t('common.signIn')}</Button>
      </div>
      <div>
        <Button
          className="w-9/12"
          variant="outlined"
          size="xl"
          onClick={toggleRecoverPasswordDialog}
        >{t('homePage.forgotPassword')}</Button>
      </div>
      {/* Dialogs */}
      {dialogSignInOpen ? (
        <SignInDialog
          open={dialogSignInOpen}
          onClose={toggleSignInDialog}
        />
      ) : null}
      {dialogRecoverPasswordOpen ? (
        <RecoverPasswordDialog
          open={dialogRecoverPasswordOpen}
          onClose={toggleRecoverPasswordDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default PublicContent;
