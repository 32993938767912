import React from 'react';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  content: string;
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationDialog:React.FC<Props> = ({
  // Props
  title, content, open, loading, onClose, onConfirm
}) => {
  const { t } = useTranslation('common');

  const handleConfirm = () => {
    onConfirm();
    onClose();
  }

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={title} onClose={onClose} />
      <DialogContent>
        <p className="text-white">{content}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >{t('common.cancel')}</Button>
        <Button
          className="ml-2"
          variant="contained"
          loading={loading}
          onClick={handleConfirm}
        >{t('common.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog;
