import { FC } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import { IUser } from 'models/User';
// i18next
import { useTranslation } from 'react-i18next';

const UserText:FC = () => {
  const { t } = useTranslation('common');

  const currentUser:IUser | null = useAppSelector(selectCurrentUser);

  const impersonatorName = currentUser && currentUser.impersonator
    ? currentUser.impersonator.name
    : null
  ;

  if ( !currentUser ) return null;
  return (
    <span className="hidden sm:flex text-white mr-4">
      {impersonatorName ? `${impersonatorName} ${t('components.header.as')} ${currentUser.name}` : currentUser.name}
      {currentUser.account?.organization ? ` (${currentUser.account.organization})` : null}
    </span>
  )
}

export default UserText;
