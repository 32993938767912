import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import ImageUpload from 'components/ImageUpload';
import { ColorPicker } from 'components/ColorPicker';

const Countdown:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control } = useFormContext();

  if ( quiz && [QuizTypes.Personality, QuizTypes.Poll].includes(quiz.type) ) return null;
  return (
    <Fragment>
      <Controller
        control={control} name="countDownLogoImage"
        render={({ field }) => (
          <ImageUpload
            {...field}
            className="w-1/4"
            width={140}
            height={60}
            label="ownerPage.quizForm.countDownLogoImage"
            helperText="ownerPage.quizForm.countDownLogoImageHint"
          />
        )}
      />
      <div className="flex flex-wrap mb-4">
        <div className="w-full sm:w-3/5 mr-4">
          <Controller
            control={control} name="countDownImage"
            render={({ field }) => (
              <ImageUpload
                {...field}
                width={420}
                height={236}
                label="ownerPage.quizForm.countDownImage"
                helperText="ownerPage.quizForm.countDownImageHint"
              />
            )}
          />
        </div>
        <div className="w-full sm:w-2/5">
          <Controller
            control={control} name="countDownTextColor"
            render={({ field: { value, onChange } }) => (
              <ColorPicker
                label={t('ownerPage.quizForm.countDownTextColor')}
                value={value}
                onChange={onChange}
                helperText={t('ownerPage.quizForm.countDownTextColorHint')}
              />
            )}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default Countdown;
