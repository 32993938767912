import { FC, Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
// Hooks
import useMenu from 'hooks/useMenu';
// Icons
import { FaBars } from 'react-icons/fa';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Actions
import { signOut, singOutAs } from 'store/auth/authAsync';
import { namespaceActions } from 'store/namespace/namespaceSlice';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// Dialog
import ProfileFormDialog from 'dialogs/ProfileForm.dialog';
// Components
import { IconButton, Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

const UserMenu:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentUser:IUser | null = useAppSelector(selectCurrentUser);

  const adminLinks:{to:string, label:string}[] = [
    { to: '/admin/users', label: t('components.header.manageUsers') },
    { to: '/admin/consents', label: t('components.header.manageConsents') },
    { to: '/admin/quizzes', label: t('components.header.manageQuizzes') },
  ];
  
  const ownerLinks:{to:string, label:string, role?: UserRoles}[] = [
    { to: '/owner/players', label: t('components.header.participants') },
    { to: '/owner/quizzes', label: t('components.header.manageQuizzes') },
    { to: '/owner/groups', label: t('components.header.manageQuizGroups') },
    { to: '/owner/users', label: t('components.header.manageUsers'), role: UserRoles.Owner },
    { to: '/owner/categories', label: t('components.header.manageCategories') },
    { to: '/owner/consents', label: t('components.header.manageConsents') },
    { to: '/owner/analytics', label: t('common.analytics') },
  ];

  const { Menu, MenuItem, openMenu, closeMenu } = useMenu();

  const isImpersonator = currentUser && currentUser.impersonator;
  const impersonatorName = isImpersonator ? currentUser.impersonator.name : null;

  const [ dialogProfileFormOpen, setDialogProfileFormOpen ] = useState<boolean>(false);

  const toggleProfileFormDialog = () => setDialogProfileFormOpen(!dialogProfileFormOpen);

  const handleSignOut = () => {
    if ( impersonatorName ){
      dispatch(singOutAs());
      dispatch(namespaceActions.setInitialField('items'));
    } else {
      dispatch(signOut());
    }
    closeMenu()
  }

  if ( !currentUser ) return null;
  return (
    <Fragment>
      <div className="relative">
        <IconButton
          onClick={openMenu}
        ><FaBars /></IconButton>
        <Menu>
          {currentUser.groups.includes(UserRoles.Admin) ? (
            adminLinks.map((link, index) => (
              <MenuItem
                key={`admin-menu-item-${index}`}
                component={Link}
                to={link.to}
              >{link.label}</MenuItem>
            ))
          ) : null}
          {currentUser.groups.some((group:UserRoles) => [UserRoles.Owner, UserRoles.Staff].includes(group)) ? (
            ownerLinks.map((link, index) => {
              if ( link.role && !currentUser.groups.includes(link.role) ) return null;
              return (
                <MenuItem
                  key={`owner-menu-item-${index}`}
                  component={Link}
                  to={link.to}
                >{link.label}</MenuItem>
              )
            })
          ) : null}
          {!isImpersonator ? (
            <MenuItem
              onClick={toggleProfileFormDialog}
            >{t('components.header.myProfile')}</MenuItem>
          ) : null}
          <div className="pt-2 px-2">
            <Button
              className="w-full"
              variant="contained"
              onClick={handleSignOut}
            >{impersonatorName ? t('components.header.switchBack', { impersonatorName }) : t('components.header.signOut')}</Button>
          </div>
        </Menu>
      </div>
      {dialogProfileFormOpen ? (
        <ProfileFormDialog
          open={dialogProfileFormOpen}
          onClose={toggleProfileFormDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default UserMenu;
