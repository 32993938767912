import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultAsync } from './resultAsync';
// Models
import IResult from 'models/Result';
import IQuizAnalytics from 'models/QuizAnalytics';
import IAnalytics from 'models/Analytics';
import AnswersTabs from 'types/AnswersTabs';

interface IAnswersResults {
  questionRef: string;
  answers: Record<string, number>;
}

interface IState {
  analytics: IAnalytics | null;
  quizAnalytics: IQuizAnalytics | null;
  items: IResult[] | null;
  totalItems: number;
  params: {
    limit: number;
    offset: number;
    key: string;
    direction: 'asc' | 'desc';
  },
  updateCorrectAnswersLoading: boolean;
  questionsResults: {
    answers: IAnswersResults[];
    postQuizAnswers: IAnswersResults[];
    preQuizAnswers: IAnswersResults[];
  } | null;
  selectedTab: AnswersTabs;
};

const initialState:IState = {
  analytics: null,
  quizAnalytics: null,
  items: null,
  totalItems: 0,
  params: {
    limit: 50,
    offset: 0,
    key: 'createdAt',
    direction: 'asc'
  },
  updateCorrectAnswersLoading: false,
  questionsResults: null,
  selectedTab: AnswersTabs.Answers
};

const slice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    setUpdateCorrectAnswersLoading: (state:IState, action:PayloadAction<boolean>) => {
      state.updateCorrectAnswersLoading = action.payload;
    },
    setSelectedTab: (state:IState, action: PayloadAction<AnswersTabs>) => {
      state.selectedTab = action.payload;
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ResultAsync.fetchResultList.pending, (state:IState, action) => {
        const { arg: params } = action.meta
        state.items = null;
        state.totalItems = 0;
        state.params = params;
      })
      .addCase(ResultAsync.fetchResultList.fulfilled, (state:IState, action:PayloadAction<{ data: IResult[], count: number }>) => {
        state.items = action.payload.data;
        state.totalItems = action.payload.count;
      })
      .addCase(ResultAsync.fetchCountAnswersByQuestions.fulfilled, (state:IState, action:PayloadAction<any>) => {
        state.questionsResults = action.payload;
      })
      .addCase(ResultAsync.fetchAnalytics.pending, (state:IState) => {
        state.analytics = null;
      })
      .addCase(ResultAsync.fetchAnalytics.fulfilled, (state:IState, action:PayloadAction<IAnalytics>) => {
        state.analytics = action.payload;
      })
      .addCase(ResultAsync.fetchQuizAnalytics.pending, (state:IState) => {
        state.quizAnalytics = null;
      })
      .addCase(ResultAsync.fetchQuizAnalytics.fulfilled, (state:IState, action:PayloadAction<IQuizAnalytics>) => {
        state.quizAnalytics = action.payload;
      })
      .addCase(ResultAsync.updateResult.fulfilled, (state:IState, action:PayloadAction<IResult>) => {
        if ( state.items ){
          state.items = state.items.map(item => item.id === action.payload.id ? action.payload : item)
        }
      })
      .addCase(ResultAsync.deleteResult.fulfilled, (state:IState, action) => {
        const { arg:resultId } = action.meta;
        if ( state.items ){
          state.items = state.items.filter((item) => item.id !== resultId)
          state.totalItems = state.totalItems - 1;
        }
      })
      .addCase(ResultAsync.deleteAllResults.fulfilled, (state:IState) => {
        state.items = [];
        state.totalItems = 0;
      })
      .addCase(ResultAsync.updateCorrectAnswersAndScores.pending, (state:IState) => {
        state.updateCorrectAnswersLoading = true;
      })
  }
});

export const resultActions = slice.actions;

export default slice.reducer;
