import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Types
import QuizTypes from 'types/QuizTypes';
// Components
import { Input, Toggle } from 'components/Controls';
import MultiLanguageTextField from 'components/MultiLanguageTextField';
import QRCode from 'components/QRCode';
// i18next
import { useTranslation } from 'react-i18next';

const PublicLeaderboardQrCode:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const quizType = watch('type');
  const {
    enabled:publicLeaderboardEnabled,
    quizQRCodeEnabled:publicLeaderboardQuizQRCodeEnabled,
    quizQRCodeLink:publicLeaderboardQuizQRCodeLink
  } = watch('publicLeaderboard');

  if (!quiz || quizType === QuizTypes.Personality) return null;
  return (
    <div className="mt-8">
      {publicLeaderboardEnabled && (
        <Fragment>
          <div className="flex items-center mb-4">
            <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.showQuizQRCode')}</span>
            <Controller
              control={control} name="publicLeaderboard.quizQRCodeEnabled"
              render={({ field: { onChange, value } }) => (
                <Toggle
                  id="publicLeaderboardQuizQRCodeEnabled"
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <span className="block text-gray-400 text-sm">
            {quiz.type === QuizTypes.Poll ? t('ownerPage.quizForm.showPollQuizQRCodeHint') : t('ownerPage.quizForm.showQuizQRCodeHint')}
          </span>
          {publicLeaderboardQuizQRCodeEnabled && (
            <Fragment>
              <QRCode link={publicLeaderboardQuizQRCodeLink} quizName={quiz.quizName} quizNameSlug={quiz.quizNameSlug} />
              <div className="pt-4">
                <MultiLanguageTextField
                  name="publicLeaderboard.quizQRCodeTitle"
                  label={t('ownerPage.quizForm.quizQRCodeTitle')}
                  helperText={t('ownerPage.quizForm.quizQRCodeTitleHint')}
                  className="mb-4"
                />
                <Controller
                  control={control} name="publicLeaderboard.quizQRCodeLink"
                  render={({ field }) => (
                    <Input
                      {...field}
                      label={t('ownerPage.quizForm.quizQRCodeLink')} id="publicLeaderboard.quizQRCodeLink"
                      helperText={t('ownerPage.quizForm.quizQRCodeLinkHint')}
                    />
                  )}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default PublicLeaderboardQrCode;
