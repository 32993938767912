import { FC, useState, useEffect, ChangeEvent } from 'react';
// Models
import { IUser } from 'models/User';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Actions
import { setFilter } from 'store/user/userSlice';
// Selectors
import { selectUsers, selectFilter } from 'store/user/userSelectors';
// Components
import { Input } from 'components/Controls';
// i18next
import { useTranslation } from 'react-i18next';

let timeout:any = null;

const UsersFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useDispatch();
  // State
  const users:IUser[] | null = useSelector(selectUsers);
  const filter:any = useSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState<any>(filter);

  useEffect(() => {
    return () => {
      if ( timeout ) clearTimeout(timeout);
      dispatch(setFilter({ field: 'search', value: '' }));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({ ...prevState, [name]: value }));
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setFilter({ field: 'search', value }));
    }, 1000);
  }

  if ( !users || users.length === 0 ) return null;
  return (
    <Input
      className="mb-0"
      id="search" name="search" value={stateFilter.search} placeholder={t('common.search')}
      onChange={handleChange}
    />
  );
}

export default UsersFilter;
