import i18next from "i18next";
import i18n from "../i18n";

export let isRequired:string = i18n.getResource(i18n.language, 'common', 'validations.isRequired');

export const isEmail = {
  // eslint-disable-next-line
  value: /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
  message: i18n.getResource(i18n.language, 'common', 'validations.isEmail'),
};

export const isPassword = (value:string | undefined) => {
  if ( !value ) return true;
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || `${i18n.getResource(i18n.language, 'common', 'validations.isPassword')}`;
}

export const isMatch = (value:string | undefined, matchField:string | undefined, message = i18n.getResource(i18n.language, 'common', 'validations.isMatch')) => {
  if ( !value || !matchField ) return true;
  return value === matchField || message;
}

export const isMinValue = (value:string | undefined, min:number) => {
  if ( !value ) return true;
  return value.length >= min || i18n.getResource(i18n.language, 'common', i18n.t('validations.isMinValue', {min}));
};

export const isLowerCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[a-z]/.test(value) || i18n.getResource(i18n.language, 'common', 'validations.isLowerCase');
};

export const isUpperCase = (value:string | undefined) => {
  if ( !value ) return true;
  return /[A-Z]/.test(value) || i18n.getResource(i18n.language, 'common', 'validations.isUpperCase');
};

export const isSpecial = (value:string | undefined) => {
  if ( !value ) return true;
  // eslint-disable-next-line
  return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || i18n.getResource(i18n.language, 'common', 'validations.isSpecial');
};

function updateTranslations () {
  isRequired = i18n.getResource(i18n.language, 'common', 'validations.isRequired');
  isEmail.message = i18n.getResource(i18n.language, 'common', 'validations.isEmail');
}

if (i18n.isInitialized) {
  updateTranslations();
}

i18next.on('languageChanged', function(lng) {
  updateTranslations();
});

i18next.on('loaded', function(lng) {
  updateTranslations();
});
