import { appActions } from 'store/app/appSlice';
import Statuses from 'types/Statuses';

const notificationMiddleware = ({ dispatch }:any) => (next:any) => (action:any) => {
  const { type, payload } = action;

  if ( type.includes('/rejected') ){
    if ( payload && (typeof payload.showNotification === 'undefined' || payload.showNotification)){
      dispatch(appActions.createNotification({ message: payload.message || 'An error', status: Statuses.Error }));
    }
  }
  return next(action);
}

export default notificationMiddleware;