import config from '../../../config';

import { FC, Fragment, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { appActions } from 'store/app/appSlice';
import { getParams } from 'store/result/resultSelectors';
import Statuses from 'types/Statuses';
// Components
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
import { Button, IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import QuizReportSort from './QuizReportSort';
// Icons
import { FaTrash, FaUser, FaUserSlash, FaUsers } from 'react-icons/fa';
// i18next
import { useTranslation } from 'react-i18next';

const ReportFilter:FC = () => {
  const { t } = useTranslation('common');
  const { quizId } = useParams<{ quizId:string }>();
  
  const dispatch = useAppDispatch();
  
  const params = useAppSelector(getParams);

  const [ startDate, setStartDate ] = useState(null);
  const [ endDate, setEndDate ] = useState(null);
  const [ filtered, setFiltered ] = useState<boolean>(false);
  const [ loading, setLoading ] = useState<boolean>(false);

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  const toggleDialogOpen = () => setDialogOpen(!dialogOpen);

  const handleFilter = () => {
    if ( startDate && endDate ){
      setFiltered(true);
      dispatch(ResultAsync.fetchResultList({
        ...params,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).add(1, 'day').format('YYYY-MM-DD')
      }));
    }
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFiltered(false);
    dispatch(ResultAsync.fetchResultList({
      ...params,
      startDate: '',
      endDate: ''
    }));
  };

  const handleDeleteAllResults = () => {
    setLoading(true);
    dispatch(ResultAsync.deleteAllResults(quizId)).unwrap()
      .finally(() => setLoading(false));
  }

  const handleDownloadUsers = (registered?:boolean) => {
    const queryParams = new URLSearchParams();
    if ( typeof registered !== 'undefined' ){
      queryParams.append('registered', registered.toString());
    }
    if ( startDate && endDate ){
      queryParams.append('startDate', moment(startDate).format('YYYY-MM-DD'));
      queryParams.append('endDate', moment(endDate).format('YYYY-MM-DD'));
    }
    let url:string = `${config.apiURL}/analytics/${quizId}/export?${queryParams}`;
    setLoading(true);
    fetch(url, {credentials: 'include'})
      .then(response => {
        if (!response.ok) throw new Error(`${response.status} ${response.statusText}`);
        return response.blob();
      })
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `autosave${!config.production ? '-testing-' : '-'}analytics-${new Date().getTime()}.${getFileExtension(blob.type)}`;
        a.click();
        a.remove();
      })
      .catch((error) => {
        dispatch(appActions.createNotification({ message: `${error}`, status: Statuses.Error }))
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Fragment>
      <div className="flex flex-col lg:flex-row justify-between items-center">
        <div className="w-full lg:w-auto flex items-end mb-4">
          <div className="flex-grow lg:flex-grow-0 mr-2">
            <label className="block text-white text-sm font-semibold mb-1">{t('common.startDate')}</label>
            <DatePicker
              className="control"
              selected={startDate}
              onChange={(newDate:any) => setStartDate(newDate)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="flex-grow lg:flex-grow-0 mr-2">
            <label className="block text-white text-sm font-semibold mb-1">{t('common.endDate')}</label>
            <DatePicker
              className="control"
              selected={endDate}
              onChange={(newDate:any) => setEndDate(newDate)}
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="flex items-end">
            <Button
              variant="contained"
              disabled={!(startDate && endDate)}
              onClick={handleFilter}
            >{t('common.filter')}</Button>
            {filtered ? (
              <Button
                className="ml-2"
                variant="outlined"
                onClick={handleReset}
              >{t('common.reset')}</Button>
            ) : null}
            <Tooltip className="ml-2" title={t('ownerPage.quizReportPage.deleteAllResults')}>
              <IconButton
                onClick={toggleDialogOpen}
              ><FaTrash /></IconButton>
            </Tooltip>
            <QuizReportSort />
          </div>
        </div>
        <div className="relative items-center mb-4 lg:mb-0">
          {loading ? (
            <div className="absolute left-0 top-0 right-0 bottom-0 -my-2 -mr-2 -ml-4 rounded z-10" style={{ backgroundColor: 'rgba(69,68,68,0.85)' }}>
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <svg
                  className="inline-block animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
            </div>
          ) : null}
          <span className="text-white">{t('ownerPage.quizReportPage.downloadUsersCSV')}</span>
          <Fragment>
            <Tooltip className="ml-2" title={t('ownerPage.quizReportPage.registered')}>
              <IconButton
                onClick={() => handleDownloadUsers(true)}
              ><FaUser /></IconButton>
            </Tooltip>
            <Tooltip className="ml-2" title={t('ownerPage.quizReportPage.unRegistered')}>
              <IconButton
                onClick={() => handleDownloadUsers(false)}
              ><FaUserSlash /></IconButton>
            </Tooltip>
          </Fragment>
          <Tooltip className="ml-2" title={t('ownerPage.quizReportPage.all')}>
            <IconButton
              onClick={() => handleDownloadUsers()}
            ><FaUsers /></IconButton>
          </Tooltip>
        </div>
      </div>
      {dialogOpen ? (
        <ConfirmationDialog
          title={t('ownerPage.quizReportPage.deleteAllResults')}
          content={t('ownerPage.quizReportPage.confirmDeleteAllResults')}
          open={dialogOpen}
          loading={loading}
          onClose={toggleDialogOpen}
          onConfirm={handleDeleteAllResults}
        />
      ) : null}
    </Fragment>
  )
}

const getFileExtension = (type:string) => {
  switch (type){
    case 'text/csv':
      return 'csv';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'xls'
    default:
      return 'csv';
  }
}

export default ReportFilter;
