// const STORAGE_KEY:string = 'quizwizard_admin';
import moment from 'moment'

export default class DateTimeService {
  public static generateMonthsOptions() {
    return Array.from({ length: 12 }, (_:any, index:number) => index).map((_:any, index:number) => ({
      value: index,
      label: moment().month(index).format('MMMM')
    }));
  }

  public static generateYearsOptions() {
    const currentYear = moment().format('YYYY');
    let minYear = Number(currentYear);
    const maxYear = Number(currentYear) + 10;
    const result = [minYear];
    while ( minYear < maxYear ){
      minYear++;
      result.push(minYear);
    }
    return result.map((year:number) => ({ value: year, label: year.toString() }));
  }

  public static getYear(date:Date):number {
    return Number(moment(date).format('YYYY'));
  }

  public static getMonth(date:Date):number {
    return moment(date).month();
  }
}
