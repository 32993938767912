import React, { ReactNode, useState } from 'react';
// MUI
import { makeStyles, MenuItem, TextField, Tooltip } from '@material-ui/core';
// i18next
import { useTranslation } from 'react-i18next';

export const countries = [
  { label: 'English', value: 'en', imgUrl: '/images/flags/us.png' },
  { label: 'Español (Spanish)', value: 'es', imgUrl: '/images/flags/es.png' },
  { label: 'Svenska (Swedish)', value: 'se', imgUrl: '/images/flags/se.png' },
  { label: 'Português (Portuguese)', value: 'pt', imgUrl: '/images/flags/pt.png' },
  { label: 'العربية (Arabic)', value: 'ar', imgUrl: '/images/flags/ar.png' },
  { label: 'हिन्दी (Hindi)', value: 'hi', imgUrl: '/images/flags/hi.png' },
]

const LanguageSelect:React.FC = () => {
  const classes = useStyles();
  const { i18n } = useTranslation('common');

  const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'en');

  const handleLanguage = (event:any) => {
    const { value:lang } = event.target;
    setLang(lang);
    i18n.changeLanguage(lang);
  }

  return (
      <TextField
        variant="outlined"
        style={{ border: 'none' }}
        className={classes.input}
        size="small"
        select
        onChange={handleLanguage}
        value={lang}
        SelectProps={{
          IconComponent: "span",
          renderValue: (value: any):ReactNode => {
            return (
              <Tooltip title="Choose your language">
                <img src={countries.find(country => country.value === value)?.imgUrl} className={classes.icon} alt={lang} />
              </Tooltip>
            )
          }
        }}
      >
        {countries.map(country => (
          <MenuItem key={country.value} value={country.value}>
            <img src={country.imgUrl} className={classes.icon} alt={country.label} />&nbsp;&nbsp;{country.label}
          </MenuItem>
        ))}
      </TextField>
  );
}

export default LanguageSelect;

const useStyles = makeStyles({
  input: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  icon: {
    width: '32px',
    height: '32px',
    objectFit: 'cover',
  }
});
