import { FC, useState, useEffect, ChangeEvent } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { getFilter, getNamespaceItems } from 'store/namespace/namespaceSelectors';
// Components
import { Input } from 'components/Controls';
import { useTranslation } from 'react-i18next';
import { namespaceActions } from 'store/namespace/namespaceSlice';

let timeout:any = null;

const NamespacesFilter:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const namespaces = useAppSelector(getNamespaceItems);
  const filter = useAppSelector(getFilter);

  const [ stateFilter, setStateFilter ] = useState<any>(filter);

  useEffect(() => {
    return () => {
      if ( timeout ) clearTimeout(timeout);
      dispatch(namespaceActions.setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({ ...prevState, [name]: value }));
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(namespaceActions.setFilter({ [name]: value }));
    }, 1000);
  }

  if ( !namespaces || namespaces.length === 0 ) return null;
  return (
    <Input
      className="mb-0"
      id="search" name="search" value={stateFilter.search} placeholder={t('common.search')}
      onChange={handleChange}
    />
  );
}

export default NamespacesFilter;
