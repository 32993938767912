import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const clearPlayerCookies = (quizNameSlug:string) => {
  const cookieNames = [
    // `${quizNameSlug}_quiz_status`,
    // `${quizNameSlug}_quiz_leaderboard_url`,
    `${quizNameSlug}_quiz_nonce`,
    'qwid'
  ];
  for ( let name of cookieNames ) {
    cookies.remove(name, {
      path: '/',
    });
    if (window.parent) {
      window.parent.postMessage(JSON.stringify({
        'action': 'removeCookie',
        'name': name,
      }), '*');
    }
  }
}
