import { FC, Fragment, useState } from 'react';
import moment from 'moment';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch } from 'hooks/useStore';
import ConsentsAsync from 'store/consents/consentsAsync';
import IConsent from 'models/Consent';
import ConsentsStatuses from 'types/ConsentsStatuses';
// Dialogs
import ConsentsFormDialog from 'dialogs/ConsentsForm.dialog';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// Icons
import { FaEdit, FaTrash, FaRegClock, FaArchive } from 'react-icons/fa';
import { MdPublish } from "react-icons/md";
// Utilities
import { textFromCamelToNormalCase } from 'utilities/utilities';
import { countries } from 'components/LanguageSelect';

type Props = {
  consent: IConsent;
}

const ConsentsListItem:FC<Props> = ({ consent }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const [isLoading, setIsLoading] = useState(false);

  const [ dialogContentFormOpen, setDialogContentFormOpen ] = useState<boolean>(false);
  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState<boolean>(false);

  const toggleContentFormDialog = () => setDialogContentFormOpen(!dialogContentFormOpen);
  const toggleConfirmationDialog = () => setDialogConfirmationOpen(!dialogConfirmationOpen);

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(ConsentsAsync.deleteConsent(consent._id)).unwrap()
      .finally(() => setIsLoading(false));
  }

  const publishConsent = () => {
    dispatch(ConsentsAsync.publishConsent(consent._id)).unwrap()
  }

  const archiveConsent = () => {
    dispatch(ConsentsAsync.archiveConsent(consent._id)).unwrap()
  }

  const getLanguageLabel = () => {
    return countries.find(language => language.value === consent.language)?.label || '';
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">{consent.name}</td>
        <td className="text-left text">{textFromCamelToNormalCase(consent.status)}</td>
        <td className="text-left text">{getLanguageLabel()}</td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(consent.createdAt).format('MMM Do YYYY')}
        </td>
        <td className="text-right actions">
          {consent.status === ConsentsStatuses.draft && (
            <Tooltip title={t('adminPage.publish')}>
              <IconButton
                onClick={publishConsent}
              ><MdPublish /></IconButton>
            </Tooltip>
          )}
          {consent.status === ConsentsStatuses.published && (
            <Tooltip title={t('adminPage.archive')}>
              <IconButton
                onClick={archiveConsent}
              ><FaArchive /></IconButton>
            </Tooltip>
          )}
          <Tooltip title={t('adminPage.editConsent')}>
            <IconButton
              onClick={toggleContentFormDialog}
            ><FaEdit /></IconButton>
          </Tooltip>
          {consent.status === ConsentsStatuses.draft && (
            <Tooltip className="ml-1" title={t('adminPage.deleteConsent')}>
              <IconButton
                onClick={toggleConfirmationDialog}
              ><FaTrash /></IconButton>
            </Tooltip>
          )}
        </td>
      </tr>
      {dialogContentFormOpen ? (
        <ConsentsFormDialog
          consentId={consent._id}
          open={dialogContentFormOpen}
          onClose={toggleContentFormDialog}
        />
      ) : null}
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('adminPage.deleteConsent')}: ${consent.name}`}
          content={t('adminPage.confirmDeleteConsent')}
          open={dialogConfirmationOpen}
          loading={isLoading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDelete}
        />
      ) : null}
    </Fragment>
  )
}

export default ConsentsListItem;
