import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Components
import { Input } from 'components/Controls';
import ImageUpload from 'components/ImageUpload';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

type Props = {
  index: number;
}

const PostQuizPage:FC<Props> = ({
  // Props
  index
}) => {
  const { control, getValues } = useFormContext();

  const getBuckets = getValues('buckets');

  const bucketName = (index:number) => {
    if ( !getBuckets || !getBuckets.length ) return '';
    // ToDO
    if ( !getBuckets[index] ) return '';

    return getBuckets[index].title;
  }

  return (
    <Fragment>
      <div className="p-6" react-scroll={`post-quiz-page-${index}`}>
        <h2 className="text-white text-2xl leading-6 font-bold">{bucketName(index)}</h2>
      </div>
      <div className="flex flex-wrap -mx-2 px-6 pb-3">
        <div className="w-full laptop:w-1/2 px-2">
          <Controller
            control={control} name={`postQuizPages[${index}].callToAction.image`}
            render={({ field }) => (
              <ImageUpload
                {...field}
                width={420}
                height={350}
                label="Image"
                helperText="ownerPage.quizForm.proceedImageHint"
              />
            )}
          />
        </div>
        <div className="w-full laptop:w-1/2 px-2">
          <Controller
            control={control} name={`postQuizPages[${index}].callToAction.url`}
            render={({ field }) => (
              <Input
                {...field}
                label="URL" id={`postQuizPages[${index}].callToAction.url`}
              />
            )}
          />
          <MultiLanguageTextField
            name={`postQuizPages[${index}].callToAction.text`}
            multiline
            rows={4}
            label="Text"
            className="mb-2"
          />
          <MultiLanguageTextField
            name={`postQuizPages[${index}].callToAction.buttonTitle`}
            label="Button title"
          />
        </div>
      </div>
      <span className="divider"></span>
    </Fragment>
  )
}

export default PostQuizPage;
