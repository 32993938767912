import React, { Fragment } from 'react';
import moment from 'moment';
// Layouts
import { Dialog, DialogTitle, DialogContent } from 'layouts/Dialog';
// Utilities
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import QuizTypes from 'types/QuizTypes';

type Props = {
  open: boolean;
  onClose: () => void;
  report: any;
};

const ReportDetailsDialog:React.FC<Props> = ({
  // Props
  open, onClose, report
}) => {
  const { t } = useTranslation('common');

  // State
  const quiz = useAppSelector(selectQuiz);

  const postQuizAnswers = report.postQuizAnswers && report.postQuizAnswers.length
    ? report.postQuizAnswers.reduce((acc:any, cur:any) => {
        const answer = cur.text || cur.title;
        if ( acc[cur.question.ref] ){
          acc[cur.question.ref]['answers'] = [...acc[cur.question.ref]['answers'], answer];
        } else {
          acc[cur.question.ref] = {
            title: cur.question.title,
            answers: [answer]
          };
        }
        return acc;
      }, {})
    : null
  ;

  const preQuizAnswers = report.preQuizAnswers && report.preQuizAnswers.length
    ? report.preQuizAnswers.reduce((acc:any, cur:any) => {
        const answer = cur.text || cur.title;
        if ( acc[cur.question.ref] ){
          acc[cur.question.ref]['answers'] = [...acc[cur.question.ref]['answers'], answer];
        } else {
          acc[cur.question.ref] = {
            title: cur.question.title,
            answers: [answer]
          };
        }
        return acc;
      }, {})
    : null
  ;

  const label = quiz 
    ? quiz.type === QuizTypes.Personality
      ? t('ownerPage.quizReportPage.winningBucket')
      : t('common.score')
    : null
  ;


  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title="Result details" onClose={onClose} />
      <DialogContent>
        <div className="flex flex-col flex-grow text-white">
          <h3 className="text-xl font-bold mb-2">
            {report.user.name}&nbsp;{report.user.surname}
            {report.user.username ? ` ( ${report.user.username} )` : null}
          </h3>
          <span>{t('common.email')}&nbsp;<b>{report.user.email || '-'}</b></span>
          <span>{t('common.phone')}&nbsp;<b>{report.user.phoneNumber || '-'}</b></span>
          <span>{label}&nbsp;<b>{report.bucket ? report.bucket.title : report.score}</b></span>
          <span>{t('ownerPage.quizReportPage.createdAt')}&nbsp;<b>{moment(report.createdAt).format('llll')}</b></span>

          {preQuizAnswers ? (
            <Fragment>
              <span className="divider my-4" />
              <h5 className="text-md font-bold mb-2">{t('ownerPage.quizReportPage.preQuizAnswers')}</h5>
              {Object.keys(preQuizAnswers).map((key) => {
                const answer = preQuizAnswers[key];
                return (
                  <Fragment key={key}>
                    <b>{answer.title}</b>
                    <span>{answer.answers.join(', ')}</span>
                  </Fragment>
                )
              })}
            </Fragment>
          ) : null}

          {postQuizAnswers ? (
            <Fragment>
              <span className="divider my-4" />
              <h5 className="text-md font-bold mb-2">{t('ownerPage.quizReportPage.postQuizAnswers')}</h5>
              {Object.keys(postQuizAnswers).map((key) => {
                const answer = postQuizAnswers[key];
                return (
                  <Fragment key={key}>
                    <b>{answer.title}</b>
                    <span>{answer.answers.join(', ')}</span>
                  </Fragment>
                )
              })}
            </Fragment>
          ) : null}

        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ReportDetailsDialog;
