import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Components
import MultiLanguageTextField from 'components/MultiLanguageTextField';
// i18next
import { useTranslation } from 'react-i18next';

const RegistrationCaption:FC = () => {
  const { t } = useTranslation('common');

  const { formState:{ errors } } = useFormContext();

  return (
    <MultiLanguageTextField
      name="registrationPage.caption"
      label={t('ownerPage.quizForm.registrationCaption')}
      error={Boolean(errors.registrationCaption)}
      errorText={errors.registrationCaption ? (errors as any).registrationCaption.message : ''}
      helperText={t('ownerPage.quizForm.registrationCaptionHint')}
      className="mb-4"
    />
  )
}

export default RegistrationCaption;
