import { createAsyncThunk } from '@reduxjs/toolkit';
import { appActions } from 'store/app/appSlice';
// Utilities
import HttpClient from 'utilities/HttpClient';
//
import { namespaceMessages } from './namespaceMessages';

const _url:string = '/namespace';
const _http:HttpClient = new HttpClient();

export const NamespaceAsync = {
  fetchNamespacesList: createAsyncThunk(
    'namespace/Fetch namespaces list',
    async (_, thunkApi) => {
      const response:Response = await _http.get(_url);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  fetchNamespaceById: createAsyncThunk(
    'namespace/Fetch namespace by id',
    async (namespaceId:string, thunkApi) => {
      const response:Response = await _http.get(`${_url}/${namespaceId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  createNamespace: createAsyncThunk(
    'namespace/Create namespace',
    async (data:any, thunkApi) => {
      const response:Response = await _http.post(_url, data);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: namespaceMessages.create }))
      return response.json();
    }
  ),
  updateNamespace: createAsyncThunk(
    'namespace/Update namespace',
    async (data:any, thunkApi) => {
      const { namespaceId, namespaceData } = data;
      const response:Response = await _http.put(`${_url}/${namespaceId}`, namespaceData);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: namespaceMessages.update }))
      return response.json();
    }
  ),
  deleteNamespace: createAsyncThunk(
    'namespace/Delete namespace',
    async (contentId:string, thunkApi) => {
      const response:Response = await _http.delete(`${_url}/${contentId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: namespaceMessages.delete }))
    }
  )
}
