import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import UserRoles from 'types/UserRoles';
// Store
import { useAppSelector, useAppDispatch } from 'hooks/useStore';
// Actions
import { updateCurrentUser } from 'store/currentUser/currentUserAsync';
// Selectors
import { selectCurrentUser, selectLoading } from 'store/currentUser/currentUserSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input } from 'components/Controls';
import { Button } from 'components/Buttons';
// Utilities
import { isRequired, isPassword, isMatch } from 'utilities/validation';

interface IFormData {
  organization?: string;
  name: string;
  surname: string;
  password?: string;
  rePassword?: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
};

const ProfileFormDialog:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentUser = useAppSelector(selectCurrentUser);
  const loading:boolean = useAppSelector(selectLoading);

  const { control, handleSubmit, formState: { errors }, watch } = useForm<IFormData>({
    defaultValues: {
      organization: currentUser?.account?.organization || '',
      name: currentUser?.name,
      surname: currentUser?.surname,
      password: '',
      rePassword: '',
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { name, surname, organization, password } = data;
    try {
      const nextData:IFormData = { name, surname };
      if ( organization ) nextData['organization'] = organization;
      if ( password ) nextData['password'] = password;
      await dispatch(updateCurrentUser(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  const passwordWatched = watch('password');
  const rePasswordWatched = watch('rePassword');

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle title={t('dialogs.profileForm.myProfile')} onClose={onClose} />
        <DialogContent>
          <Controller
            control={control} name="name" rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('common.name')} id="name" name="name"
                required={true}
                error={Boolean(errors.name)}
                errorText={errors.name ? errors.name.message : ''}
              />
            )}
          />
          <Controller
            control={control} name="surname" rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('common.surname')} id="surname" name="surname"
                required={true}
                error={Boolean(errors.surname)}
                errorText={errors.surname ? errors.surname.message : ''}
              />
            )}
          />
          {currentUser?.groups.includes(UserRoles.Owner) ? (
            <Controller
              control={control} name="organization" rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('common.organization')} id="organization" name="organization"
                  required={true}
                  error={Boolean(errors.organization)}
                  errorText={errors.organization ? errors.organization.message : ''}
                />
              )}
            />
          ) : null}
          <Controller
            control={control} name="password" rules={{
              required: rePasswordWatched ? isRequired : false,
              validate: {
                isPassword
              }
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('common.password')} id="password" type="password" name="password"
                required={Boolean(rePasswordWatched)}
                error={Boolean(errors.password)}
                errorText={errors.password ? errors.password.message : ''}
              />
            )}
          />
          <Controller
            control={control} name="rePassword" rules={{
              required: passwordWatched ? isRequired : false,
              validate: {
                isPasswordMatch: (value:string | undefined) => isMatch(value, passwordWatched, t('dialogs.profileForm.passwordDoesNotMatch'))
              }
            }}
            render={({ field }) => (
              <Input
                {...field}
                label={t('dialogs.profileForm.rePassword')} id="rePassword" type="password" name="rePassword"
                required={Boolean(passwordWatched)}
                error={Boolean(errors.rePassword)}
                errorText={errors.rePassword ? errors.rePassword.message : ''}
              />
            )}
          />
          <div>
            <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
              * {t('dialogs.profileForm.passwordHint1')}
            </span>
            <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
              * {t('dialogs.profileForm.passwordHint2')}
            </span>
            <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
              * {t('dialogs.profileForm.passwordHint3')}
            </span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.cancel')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            type="submit"
            loading={loading}
          >{t('common.update')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ProfileFormDialog;
