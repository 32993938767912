import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Components
import { Checkbox } from 'components/Controls';
// i18next
import { useTranslation } from 'react-i18next';

const ShowSponsorImageOnRegisterPage:FC = () => {
  const { t } = useTranslation('common');

  const { control, watch } = useFormContext();
  const sponsorImage = watch('sponsorImage');

  if (!sponsorImage) return null;
  return (
    <Controller
      control={control} name="showSponsorImageOnRegisterPage"
      render={({ field: { onChange, value } }) => (
        <Checkbox
          label={t('ownerPage.quizForm.showSponsorImageOnRegisterPage')}
          id="showSponsorImageOnRegisterPage"
          name="showSponsorImageOnRegisterPage"
          checked={value}
          onChange={onChange}
          helperText={t('ownerPage.quizForm.showSponsorImageOnRegisterPageHint')}
        />
      )}
    />
  )
}

export default ShowSponsorImageOnRegisterPage;
