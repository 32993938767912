import config from '../config';
// Types
import RequestMethods from 'types/RequestMethods';

// const excludedURLs:Array<string> = [
//   '/auth', '/auth/check', '/auth/sign-in', '/auth/sign-up', '/auth/sign-out', '/auth/recover',
//   '/content',
//   '/leaderboard'
// ];

export default class HttpClient {
  public async get(
    path:string,
    params:any = {},
    args:RequestInit = {
      method: RequestMethods.Get
    },
  ):Promise<Response> {
    if ( Object.keys(params).length ){
      const queryParams = new URLSearchParams();
      Object.keys(params).forEach((key:string) => {
        if ( params[key] ) queryParams.append(key, params[key]);
      });
      path = `${path}?${queryParams}`;
    }
    return await this._http(path, args);
  }

  public async post(
    path:string,
    body?:any,
    args:RequestInit = {
      method: RequestMethods.Post,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<Response> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http(path, args);
  }

  public async put(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Put,
      body: body instanceof FormData ? body : JSON.stringify(body)
    }
  ):Promise<Response> {
    if ( !(body instanceof FormData) ){
      args['headers'] = { 'Content-Type': 'application/json' };
    }
    return await this._http(path, args);
  }

  public async patch(
    path:string,
    body:any,
    args:RequestInit = {
      method: RequestMethods.Patch,
      body: JSON.stringify(body)
    }
  ):Promise<Response> {
    args['headers'] = { 'Content-Type': 'application/json' };
    return await this._http(path, args);
  }

  public async delete(
    path:string,
    args:RequestInit = { method: RequestMethods.Delete }
  ):Promise<Response> {
    return await this._http(path, args);
  }

  private async _http(path:string, args:RequestInit = {}):Promise<Response> {
    const url:string = `${config.apiURL}${path}`;
    const request:RequestInfo = new Request(url, {...args, credentials: 'include'});
    return await fetch(request);
    // const response = await fetch(request);
    // if ( !response.ok ){
    //   const body:any = await response.json();
    //   throw { message: body.message || '', status: response.status };
    // }
    // return response.json();
  }
}