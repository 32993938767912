import { FC, Fragment, useState } from 'react';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { getFilteredGroupItems } from 'store/group/groupSelectors';
// Components
import GroupFormDialog from 'dialogs/GroupForm.dialog';
import { Button } from 'components/Buttons';
import { Toolbar } from 'components/Toolbar';
import GroupsFilter from './GroupsFilter';
import GroupsSort from './GroupsSort';
import GroupsList from './GroupsList';
// i18next
import { useTranslation } from 'react-i18next';

const initialConfig = { key: 'name', direction: Direction.Descending }

const GroupsPage:FC = () => {
  const { t } = useTranslation('common');
  
  const groups = useAppSelector(getFilteredGroupItems);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    groups, initialConfig
  );

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  const toggleDialogOpen = () => setDialogOpen(!dialogOpen);

  return (
    <Fragment>
      <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
        <Toolbar title={t('ownerPage.groupsPage.manageQuizGroups')}>
          <div
            className="
              flex items-center
              order-2 lg:order-none
              w-full lg:w-auto
              mt-4 lg:mt-0
            "
          >
            <div className="flex-grow mr-4">
              <GroupsFilter />
            </div>
            <GroupsSort 
              sortConfig={sortConfig || initialConfig}
              onRequestSort={requestSort}
            />
          </div>
          <Button
            variant="contained"
            type="button"
            onClick={toggleDialogOpen}
          >{t('ownerPage.groupsPage.createQuizGroup')}</Button>
        </Toolbar>
        <GroupsList
          sortedGroups={items}
          onRequestSort={requestSort}
          getClassNamesFor={getClassNamesFor}
        />
      </div>
      {dialogOpen ? (
        <GroupFormDialog
          open={dialogOpen}
          onClose={toggleDialogOpen}
        />
      ) : null}
    </Fragment>
  )
}

export default GroupsPage;
