import { createAsyncThunk } from '@reduxjs/toolkit';
// Utilities
import HttpClient from 'utilities/HttpClient';
// Actions
import { appActions } from 'store/app/appSlice';
// ToDO: Should be moved to some JSON file with all errors
import { errorMessage } from 'utilities/error-message';
import { userMessages } from './userMessages';

const _url:string = '/user';
const _http:HttpClient = new HttpClient();

// Fetch users
export const getUsers = createAsyncThunk(
  'user/Get users',
  async (params:any = {}, { rejectWithValue }) => {
    const response:Response = await _http.get(_url, params);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

// Delete users
export const deleteUser = createAsyncThunk(
  'user/Delete user',
  async (userId:string, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const response:Response = await _http.delete(`${_url}/${userId}`);
    if ( !response.ok ){
      const body = await response.json();
      const message = body.message || `${errorMessage('deleting')} API error: ${body.status}`
      return rejectWithValue({ message });
    }
    dispatch(appActions.createNotification({ message: userMessages.delete }));
    return fulfillWithValue(userId);
  }
);

// Invite staff user
export const inviteStaff = createAsyncThunk(
  'user/Invite staff',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/invite`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.invite }));
    return response.json();
  }
);

// Invite staff
export const inviteOwner = createAsyncThunk(
  'user/Invite owner',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/inviteOwner`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.invite }));
    return response.json();
  }
);

// Invite administrator
export const inviteAdministrator = createAsyncThunk(
  'user/Invite admin',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/inviteAdministrator`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.invite }));
    return response.json();
  }
);

// Resend invite
export const resendInvite = createAsyncThunk(
  'user/Resend invite',
  async (userId:string, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.get(`${_url}/invite/${userId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.resendInvite }));
    return response.json();
  }
);

// Change password
export const changePassword = createAsyncThunk(
  'user/Change password',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.put(`${_url}/recover`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.changePassword }));
  }
);

// Recover password
export const recoverPassword = createAsyncThunk(
  'user/Recover password',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/recover`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: userMessages.recoverPassword }));
  }
);
