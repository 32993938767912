import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
// Models
import IAnswer from 'models/Answer';
// Components
import { Checkbox } from 'components/Controls';

type Props = {
  answer: IAnswer;
  index: number;
  questionIndex: number;
}

const QuestionMultiAnswer:FC<Props> = ({ answer, index, questionIndex }) => {
  const name = `questions[${questionIndex}].answers`;

  const { watch, setValue } = useFormContext();

  const watchedCorrect = watch(`${name}.${index}.correct`);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(`${name}.${index}.correct`, e.target.checked);
  }

  return (
    <Checkbox
      className="mb-0"
      id={`answer-${index}`}
      name={`answer-${index}`}
      label={answer.title}
      checked={watchedCorrect}
      onChange={handleChange}
    />
  )
}

export default QuestionMultiAnswer;
