export const sortByName = (a:any, b:any) => {
  // Ignore case
  const aName:string = a.name.toLowerCase();
  const bName:string = b.name.toLowerCase();
  if ( aName < bName ) return -1;
  if ( aName > bName ) return 1;
  return 0;
}

export const sortByEmail = (a:any, b:any) => {
  // Ignore case
  const aEmail:string = a.email.toLowerCase();
  const bEmail:string = b.email.toLowerCase();
  if ( aEmail < bEmail ) return -1;
  if ( aEmail > bEmail ) return 1;
  return 0;
}