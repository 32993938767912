import { FC } from 'react';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectFilteredQuizzes } from 'store/quiz/quizzesSelectors';
// Componenr
import { Loader, Message } from 'components/Utilities';
// 
import QuizzesListItem from './QuizzesListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedQuizzes: IQuiz[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const QuizzesList:FC<Props> = ({
  // Porps
  sortedQuizzes, onRequestSort, getClassNamesFor
}) => {
  const { t } = useTranslation('common');
  // State
  const quizzes:IQuiz[] | null = useAppSelector(selectFilteredQuizzes);

  if ( !quizzes ) return <Loader />;
  if ( !quizzes.length ) return <Message text={t('ownerPage.quizzesPage.noQuizzes')} />;
  return (
    <table className="responsive-table">
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('quizName')}`}
            onClick={() => onRequestSort('quizName')}
          >{t('common.name')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('type')}`}
            onClick={() => onRequestSort('type')}
          >{t('common.type')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('namespace')}`}
            onClick={() => onRequestSort('namespace')}
          >{t('common.category')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdByName')}`}
            onClick={() => onRequestSort('createdByName')}
          >{t('common.createdBy')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdAt')}`}
            onClick={() => onRequestSort('createdAt')}
          >{t('common.dateCreated')}</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedQuizzes.map((quiz:IQuiz) => (
          <QuizzesListItem
            key={`group-item-${quiz._id}`}
            quiz={quiz}
          />
        ))}
      </tbody>
    </table>
  )
}

export default QuizzesList;
