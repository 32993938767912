import { createSelector } from 'reselect';
import dayjs from 'dayjs';
// Models
import { RootState } from 'store';
import IQuiz from 'models/Quiz';

export const selectQuizzes = (state:RootState) => state.quizzes.quizzes;
export const selectQuiz = (state:RootState) => state.quizzes.quiz;
export const selectFilter = (state:RootState) => state.quizzes.filter;
export const selectLoading = (state:RootState) => state.quizzes.loading;

export const selectFilteredQuizzes = createSelector(
  [
    selectQuizzes, selectFilter
  ],
  (quizItems:Array<IQuiz> | null, filter:{ search:string }) => {
    if ( !quizItems ) return null;
    const { search } = filter;
    const searchToLower = search.toLowerCase();
    const quizzes = quizItems.map((quiz) => ({
      ...quiz,
      createdByName: quiz.createdBy ? `${quiz.createdBy.name} ${quiz.createdBy.surname}` : ''
    }));
    if ( !searchToLower ) return quizzes;
    return quizzes.filter((quizItem:IQuiz) => {
      const hasName = quizItem.quizName.toLowerCase().includes(searchToLower);
      const hasNamespace = quizItem.namespace.slug.toLowerCase().includes(searchToLower)
      return hasName || hasNamespace;
    });
  }
);

export const selectIsStartNowVisible = createSelector(
  [
    selectQuizzes,
    (_, props:{ quizId:string }) => props
  ],
  (quizzes:IQuiz[] | null, { quizId }) => {
    if ( !quizzes ) return false;
    const quiz = quizzes.find((quiz:IQuiz) => quiz._id === quizId);
    if ( !quiz ) return false;
    if ( !quiz.startsDate && !quiz.endsDate ) return true;
    const now = dayjs();
    const startDate = dayjs(Number(quiz.startsDate));
    return now.isBefore(startDate);
  }
);

export const selectIsEndNowVisible = createSelector(
  [
    selectQuizzes,
    (_, props:{ quizId:string }) => props
  ],
  (quizzes:IQuiz[] | null, { quizId }) => {
    if ( !quizzes ) return false;
    const quiz = quizzes.find((quiz:IQuiz) => quiz._id === quizId);
    if ( !quiz || !quiz.startsDate ) return false;
    const now = dayjs();
    const startDate = dayjs(Number(quiz.startsDate));
    const endDate = quiz.endsDate ? dayjs(Number(quiz.endsDate)) : null;

    return now.isAfter(startDate) && ( !endDate || now.isBefore(endDate) );
  }
);
