import { FC, useCallback } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Icons
import { FaTrash } from 'react-icons/fa';
// Components
import { Button, IconButton } from 'components/Buttons';
import { Input } from 'components/Controls';
import { Tooltip } from 'components/Tooltip';
// Utilities
import { isRequired } from 'utilities/validation';

const QuizPersonalityFields:FC = () => {
  const { t } = useTranslation('common');
  const { control, formState:{ errors, isSubmitted } } = useFormContext();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'buckets'
  });

  const getBucketError = useCallback((index:number) => {
    if ( !errors.buckets ) return '';
    const error = errors.buckets[index];
    return error && error.title ? error.title.message : '';
  }, [errors]);

  const handleAdd = () => {
    append({ ref: uuidv4(), title: '' });
  }

  const handleDelete = (index:number) => () => {
    remove(index);
  }

  return (
    <div>
      <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.buckets')}</h2>
      <div className="mb-4 text-right">
        <Button
          variant="outlined"
          onClick={handleAdd}
        >{t('ownerPage.quizForm.addBucket')}</Button>
      </div>
      {isSubmitted && !fields.length
        ? <span className="block text-red-500 text-sm py-1">{t('ownerPage.quizForm.bucketRequired')}</span>
        : null
      }
      {fields.map((_, index:number) => (
        <div
          key={`bucket-item-${index}`}
          className="flex items-center gap-2"
        >
          <Controller
            control={control} name={`buckets.${index}.title`} rules={{ required: isRequired }}
            render={({ field }) => (
              <Input
                {...field}
                className="flex-grow mb-4"
                label={t('common.title')} id={`buckets.${index}.title`}
                required
                error={Boolean(getBucketError(index))}
                errorText={getBucketError(index)}
              />
            )}
          />
          <Tooltip title={t('ownerPage.quizForm.removeBucket')}>
            <IconButton
              onClick={handleDelete(index)}
            ><FaTrash /></IconButton>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

export default QuizPersonalityFields;
