import { ChangeEvent, FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Input, Toggle } from 'components/Controls';
import ImageUpload from 'components/ImageUpload';

const SocialShare:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const watchedSocialShareEnabled = watch('socialShareEnabled');
  
  const isFieldsDisabled = !watchedSocialShareEnabled;

  if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <Fragment>
      <div className="flex items-center mb-8">
        <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.socialShareEnabled')}</span>
        <Controller
          control={control} name="socialShareEnabled"
          render={({ field: { onChange, value } }) => (
            <Toggle
              id="socialShareEnabled"
              checked={value}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <span className="block text-gray-400 text-sm">{t('ownerPage.quizForm.socialShareEnabledHint')}</span>
      </div>
      <Controller
        control={control} name="hashtags"
        render={({ field }) => (
          <Input
            {...field}
            label={t('ownerPage.quizForm.hashtags')} id="hashtags" name="hashtags"
            helperText={t('ownerPage.quizForm.hashtagsHint')}
            disabled={isFieldsDisabled}
          />
        )}
      />
      <Controller
        control={control} name="socialShareURL"
        render={({ field }) => (
          <Input
            {...field}
            label={t('ownerPage.quizForm.socialShareUrl')} id="socialShareURL" name="socialShareURL"
            helperText={t('ownerPage.quizForm.socialShareUrlHint')}
            disabled={isFieldsDisabled}
          />
        )}
      />
      <div className="mb-4">
        <Controller
          control={control} name="socialShareImage"
          render={({ field }) => (
            <ImageUpload
              {...field}
              className="w-2/5"
              width={300}
              height={200}
              label="ownerPage.quizForm.socialShareImage"
              helperText="ownerPage.quizForm.socialShareImageHint"
              disabled={isFieldsDisabled}
            />
          )}
        />
      </div>
      {quiz?.type !== QuizTypes.Poll && (
        <div className="mb-4">
          <Controller
            control={control} name="socialShareBonusValue"
            render={({ field:{ onChange, value } }) => (
              <Input
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value ? Number(e.target.value) : e.target.value;
                  const min = Number(e.target.min);
                  const max = Number(e.target.max);
                  
                  const nextValue = typeof value === 'number'
                    ? value < min ? min : value > max ? max : value
                    : value;

                  onChange(nextValue);
                }}
                label={t('ownerPage.quizForm.socialShareBonusValue')} id="socialShareBonusValue" name="socialShareBonusValue"
                type="number"
                min={0}
                max={100000}
              />
            )}
          />
        </div>
      )}
      <span className="divider my-4" />
    </Fragment>
  )
}

export default SocialShare;
