import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Components
import ImageUpload from 'components/ImageUpload';
import Countdown from './Countdown';
import ShowSponsorImageOnRegisterPage from './ShowSponsorImageOnRegisterPage';
// Utilities
import { isRequired } from 'utilities/validation';

const SectionImages:FC = () => {
  const { t } = useTranslation('common');

  const { control, formState: { errors } } = useFormContext();

  return (
    <div react-scroll="images">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.images')}</h2>
        <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{t('ownerPage.quizForm.imagesHint')}</span>
      </div>
      <div className="p-6">
        <Controller
          control={control} name="logoImage" rules={{ required: isRequired }}
          render={({ field }) => (
            <ImageUpload
              {...field}
              className="w-1/4"
              width={140}
              height={60}
              required={true}
              label="ownerPage.quizForm.logoImage"
              error={Boolean(errors.logoImage)}
              errorText={errors.logoImage ? errors.logoImage.message : ''}
              helperText="ownerPage.quizForm.logoImageHint"
            />
          )}
        />
        <Controller
          control={control} name="sponsorImage"
          render={({ field }) => (
            <ImageUpload
              {...field}
              className="w-1/4"
              width={140}
              height={60}
              label="ownerPage.quizForm.sponsorImage"
              helperText="ownerPage.quizForm.sponsorImageHint"
            />
          )}
        />
        <ShowSponsorImageOnRegisterPage />
        <Controller
          control={control} name="mastheadImage" rules={{ required: isRequired }}
          render={({ field }) => (
            <ImageUpload
              {...field}
              className="w-1/2"
              width={420}
              height={168}
              required={true}
              label="ownerPage.quizForm.mastheadImage"
              error={Boolean(errors.mastheadImage)}
              errorText={errors.mastheadImage ? errors.mastheadImage.message : ''}
              helperText="ownerPage.quizForm.mastheadImageHint"
            />
          )}
        />
        <Controller
          control={control} name="wallpaperImage"
          render={({ field }) => (
            <ImageUpload
              {...field}
              width={1920}
              height={1080}
              label="ownerPage.quizForm.wallpaperImage"
              helperText={t('ownerPage.quizForm.wallpaperImageHint')}
            />
          )}
        />
        <Countdown />
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 px-2">
            <Controller
              control={control} name="interstitialImage"
              render={({ field }) => (
                <ImageUpload
                  {...field}
                  width={268}
                  height={268}
                  label="ownerPage.quizForm.interstitialImage"
                  helperText="ownerPage.quizForm.interstitialImageHint"
                />
              )}
            />
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <Controller
              control={control} name="interstitialImage2"
              render={({ field }) => (
                <ImageUpload
                  {...field}
                  width={268}
                  height={268}
                  label="ownerPage.quizForm.interstitialImage2"
                  helperText="ownerPage.quizForm.interstitialImage2Hint"
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionImages;
