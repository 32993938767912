import { FC } from "react";
// Redux
import { useAppSelector } from "hooks/useStore";
import { getQuestionResults } from "store/result/resultSelectors";
import IQuestion from "models/Question";
// Components
import AnswerItem from "./AnswerItem";

type Props = {
  question: IQuestion;
  questionIndex: number;
}

const QuestionItem:FC<Props> = ({ question, questionIndex }) => {
  const questionResults = useAppSelector((state) => getQuestionResults(state, question.ref));

  const allAnswers:number[] = [];

  Object.keys(questionResults?.answers || {}).forEach((key) => {
    if (question.answers.find(answer => answer.ref === key)) {
      allAnswers.push(questionResults?.answers[key] || 0)
    }
  })

  const filling = 100 / Math.max(...allAnswers);
  const sum = allAnswers.reduce((a,b) => a+b, 0);

  return (
    <div className="p-4 mb-4 border border-gray-400">
      <h3 className="text-white text-sm font-bold mb-2">{`${questionIndex + 1}. ${question.title}`}</h3>
      {question.answers.map((answer, answerIndex) => {
        const value = questionResults?.answers[answer.ref] || 0;
        const width = value * filling;
        const percent = Number((value / sum * 100).toFixed(2)) || 0;
        const label = `${answerIndex + 1}) ${answer.title}`;
        const isCorrect = answer.correct || false;

        return <AnswerItem key={answer.ref} value={value} width={width} percent={percent} label={label} isCorrect={isCorrect} />
      })}
    </div>
  );
}
 
export default QuestionItem;