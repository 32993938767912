import { FC } from 'react';
// i18next
import { useTranslation } from 'react-i18next';

const UpdateCorrectAnswerLoader:FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="w-full flex-grow flex flex-col items-center justify-center gap-4">
      <div className="text-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <p className="text-white text-lg font-medium">{t('ownerPage.quizForm.updatingCorrectAnswer')}</p>
    </div>
  )
}

export default UpdateCorrectAnswerLoader;
