import { FC } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { getFilteredNamespaceItems } from 'store/namespace/namespaceSelectors';
// Components
import { Loader, Message } from 'components/Utilities';
import NamespacesListItem from './NamespacesListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedNamespaces: any[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const NamespacesList:FC<Props> = ({ sortedNamespaces, onRequestSort, getClassNamesFor }) => {
  const { t } = useTranslation('common');
  
  const namespaces = useAppSelector(getFilteredNamespaceItems);

  if ( !namespaces ) return <Loader />;
  if ( namespaces.length === 0 ) return <Message text={t('ownerPage.namespacesPage.noCategories')} />;
  return (
    <table>
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('name')}`}
            onClick={() => onRequestSort('name')}
          >{t('common.name')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdByName')}`}
            onClick={() => onRequestSort('createdByName')}
          >{t('common.createdBy')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdAt')}`}
            onClick={() => onRequestSort('createdAt')}
          >{t('common.dateCreated')}</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedNamespaces.map((namespace:any) => (
          <NamespacesListItem
            key={`namespace-item-${namespace._id}`}
            namespace={namespace}
          />
        ))}
      </tbody>
    </table>
  )
}

export default NamespacesList;
