import config from '../../../config';

import { FC, Fragment, useState } from 'react';
import { push } from 'connected-react-router';
import moment from 'moment';
// Hooks
import useMenu from 'hooks/useMenu';
// Models
import { RootState } from 'store';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { deleteQuiz, cloneQuiz, generateRichMedia, startQuiz, endQuiz } from 'store/quiz/quizzesAsync';
// Selectors
import { selectLoading, selectIsStartNowVisible, selectIsEndNowVisible } from 'store/quiz/quizzesSelectors';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// Icons
import {
  FaEllipsisV, FaEdit, FaQuestion, FaEye, FaCode,
  FaRegBuilding, FaRegUser, FaRegClock
} from 'react-icons/fa';
import { MdWarningAmber } from "react-icons/md";
// Models
import IQuiz from 'models/Quiz';
// Types
import QuizTypes from 'types/QuizTypes';
import UserRoles from 'types/UserRoles';
// Dialogs
import ConfirmationDialog from 'dialogs/Confirmation.dialog'
import QuizEmbedInstructionDialog from 'dialogs/QuizEmbedInstruction.dialog';
import QuizGeofenceFormDialog from 'dialogs/QuizGeofenceForm.dialog';
import QRCodeGeneratorDialog from 'dialogs/QRCodeGenerator.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  quiz: IQuiz;
}

const QuizzesListItem:FC<Props> = ({
  // Props
  quiz
}) => {
  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentUser = useAppSelector(selectCurrentUser);
  const loading:boolean = useAppSelector(selectLoading);
  const isStartNowVisible:boolean = useAppSelector((state:RootState) => selectIsStartNowVisible(state, { quizId: quiz._id }));
  const isEndNowVisible:boolean = useAppSelector((state:RootState) => selectIsEndNowVisible(state, { quizId: quiz._id }));

  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState<boolean>(false);
  const [ dialogQuizEmbedInstructionOpen, setDialogQuizEmbedInstructionOpen ] = useState<boolean>(false);
  const [ dialogQuizGeofenceFormOpen, setDialogQuizGeofenceFormOpen ] = useState<boolean>(false);
  const [ dialogQRCodeOpen, setDialogQRCodeOpen ] = useState<boolean>(false);

  const toggleConfirmationDialog = () => setDialogConfirmationOpen((prevState:boolean) => !prevState);
  const toggleQuizEmbedInstructionDialog = () => setDialogQuizEmbedInstructionOpen((prevState:boolean) => !prevState);
  const toggleQuizGeofenceFormDialog = () => setDialogQuizGeofenceFormOpen((prevState:boolean) => !prevState);
  const toggleDialogQRCodeOpen = () => setDialogQRCodeOpen((prevState:boolean) => !prevState);

  const handleGoTo = (url:string) => dispatch(push((url)));

  const handleCloneQuiz = () => {
    dispatch(cloneQuiz({
      id: quiz._id, quizName: `${quiz.quizName} - clone - ${moment().format('YYYYMMDDhhmmss')}`
    }));
  }

  const handleStartQuiz = () => {
    dispatch(startQuiz(quiz._id));
  }

  const handleEndQuiz = () => {
    dispatch(endQuiz(quiz._id));
  }

  const handlePreview = () => {
    window.open(`${config.quizURL}/quiz/${quiz.quizNameSlug}?u=${new Date().getTime()}&preview=true`);
  };

  const handleLiveLeaderBoard = () => {
    const endpoint = quiz.type === QuizTypes.Poll ? 'live-results' : 'live-leaderboard';
    window.open(`${config.quizURL}/quiz/${quiz.quizNameSlug}/${endpoint}`);
  };

  const handleGenerateRichMedia = () => {
    dispatch(generateRichMedia(quiz._id));
  }

  const handleConfirm = async () => {
    try {
      await dispatch(deleteQuiz(quiz._id)).unwrap();
    } catch(err){}
  }

  const isAdmin = currentUser?.groups.includes(UserRoles.Admin);
  const role = isAdmin ? 'admin' : 'owner';

  const { Menu, MenuItem, openMenu } = useMenu();

  return (
    <Fragment>
      <tr>
        <td className="text-left title flex gap-2 items-center">
          {!quiz.questions.length && (
            <Tooltip title={t('ownerPage.quizzesPage.addQuestionsToBeAblePlayQuiz')}>
              <MdWarningAmber color="orange" />
            </Tooltip>
          )}
          {quiz.quizName}
          </td>
        <td className="text-left text capitalize">{quiz.type}</td>
        <td className="text-left text">
          <FaRegBuilding className="icon" size="16px" />
          {quiz.namespace.slug}
        </td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {(quiz as any).createdByName}
        </td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(quiz.createdAt).format('MMM Do YYYY')}
        </td>
        <td className="text-right actions">
          <Tooltip title={t('ownerPage.quizzesPage.editQuiz')}>
            <IconButton
              onClick={() => handleGoTo(`/${role}/quizzes/${quiz._id}`)}
            ><FaEdit /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={t('ownerPage.quizzesPage.editQuizQuestions')}>
            <IconButton
              onClick={() => handleGoTo(`/${role}/quizzes/${quiz._id}?tab=questions`)}
            ><FaQuestion /></IconButton>
          </Tooltip>
          <Tooltip className="ml-1" title={t('ownerPage.quizzesPage.previewQuiz')}>
            <IconButton
              onClick={handlePreview}
            ><FaEye /></IconButton>
          </Tooltip>
          {!isAdmin && (
            <Tooltip className="ml-1" title={t('ownerPage.quizzesPage.embedInstruction')}>
              <IconButton
                onClick={toggleQuizEmbedInstructionDialog}
              ><FaCode /></IconButton>
            </Tooltip>
          )}
          <div className="relative inline-block">
            <Tooltip className="ml-1" title={t('common.more')}>
              <IconButton
                onClick={openMenu}
              ><FaEllipsisV /></IconButton>
            </Tooltip>
            <Menu>
              {[QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(quiz.type) && (
                <MenuItem
                  onClick={handleLiveLeaderBoard}
                  disabled={!quiz.publicLeaderboard.enabled}
                >
                  {quiz.type === QuizTypes.Poll ? t('ownerPage.quizzesPage.liveResults') : t('ownerPage.quizzesPage.liveLeaderboard')}
                </MenuItem>
              )}
              {!isAdmin && ([
                <MenuItem
                  key="quizGeofence"
                  onClick={toggleQuizGeofenceFormDialog}
                >{t('ownerPage.quizzesPage.quizGeofence')}</MenuItem>,
                <MenuItem
                  key="generateRichMedia"
                  onClick={handleGenerateRichMedia}
                >{t('ownerPage.quizzesPage.generateRichMedia')}</MenuItem>,
                <MenuItem
                  key="generateQrCode"
                  onClick={toggleDialogQRCodeOpen}
                >{t('ownerPage.quizzesPage.generateQrCode')}</MenuItem>,
                <MenuItem
                  key="analytics"
                  onClick={() => handleGoTo(`/owner/quizzes/${quiz._id}/analytics`)}
                >{t('common.analytics')}</MenuItem>,
                <MenuItem
                  key="answersAnalytics"
                  onClick={() => handleGoTo(`/owner/quizzes/${quiz._id}/answers-analytics`)}
                >{t('common.answersAnalytics')}</MenuItem>,
                <MenuItem
                  key="report"
                  onClick={() => handleGoTo(`/owner/quizzes/${quiz._id}/report`)}
                >{t('ownerPage.quizzesPage.report')}</MenuItem>
              ])}
              <MenuItem
                onClick={handleCloneQuiz}
              >{t('ownerPage.quizzesPage.cloneQuiz')}</MenuItem>
              {isStartNowVisible && !isAdmin && (
                <MenuItem
                  onClick={handleStartQuiz}
                >{t('ownerPage.quizzesPage.startNow')}</MenuItem>
              )}
              {isEndNowVisible && !isAdmin && (
                <MenuItem
                  onClick={handleEndQuiz}
                >{t('ownerPage.quizzesPage.stopNow')}</MenuItem>
              )}
              <MenuItem
                color="error"
                onClick={toggleConfirmationDialog}
              >{t('ownerPage.quizzesPage.deleteQuiz')}</MenuItem>
            </Menu>
          </div>
        </td>
      </tr>
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('ownerPage.quizzesPage.deleteQuiz')}: ${quiz.quizName}`}
          content={t('ownerPage.quizzesPage.confirmDeleteQuiz')}
          open={dialogConfirmationOpen}
          loading={loading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleConfirm}
        />
      ) : null}
      {dialogQuizEmbedInstructionOpen ? (
        <QuizEmbedInstructionDialog
          quizNameSlug={quiz.quizNameSlug}
          quizNamespaceSlug={quiz.namespace.slug}
          open={dialogQuizEmbedInstructionOpen}
          onClose={toggleQuizEmbedInstructionDialog}
        />
      ) : null}
      {dialogQuizGeofenceFormOpen ? (
        <QuizGeofenceFormDialog
          quizId={quiz._id}
          open={dialogQuizGeofenceFormOpen}
          onClose={toggleQuizGeofenceFormDialog}
        />
      ) : null}
      {dialogQRCodeOpen ? (
        <QRCodeGeneratorDialog
          open={dialogQRCodeOpen}
          onClose={toggleDialogQRCodeOpen}
          quizName={quiz.quizName}
          quizNameSlug={quiz.quizNameSlug}
        />
      ) : null}
    </Fragment>
  )
}

export default QuizzesListItem;
