import config from '../config';
import React from 'react';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  quizNameSlug: string,
  quizNamespaceSlug: string;
  open: boolean;
  onClose: () => void;
};

const injectScriptInRef = (text:string, ref:any) => {
  const script = document.createElement('script');
  script.text = text;
  ref.current.appendChild(script);
}

const QuizEmbedInstructionDialog:React.FC<Props> = ({
  // Props
  quizNameSlug, quizNamespaceSlug, open, onClose
}) => {
  const { t } = useTranslation('common');

  const exampleOne = React.useRef(null);
  const exampleTwo = React.useRef(null);
  const exampleThree = React.useRef(null);
  const exampleFour = React.useRef(null);
  const exampleFive = React.useRef(null);
  const exampleSix = React.useRef(null);
  const exampleSeven = React.useRef(null);

  React.useEffect(() => {
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        incognito: true
      });
    `, exampleOne);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        targetSelector: '.exampleTwo',
        ${process.env.REACT_APP_STAGE}: true,
        incognito: true
      });
    `, exampleTwo);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        type: 'modal',
        targetSelector: '.exampleThree',
        incognito: true
      });
    `, exampleThree);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        type: 'modal',
        buttonColorHex: '#FF1493',
        targetSelector: '.exampleFour',
        incognito: true
      });
    `, exampleFour);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        type: 'modal',
        backgroundImageUrl: '/large-sample-banner.jpg',
        targetSelector: '.exampleFive',
        incognito: true
      });
    `, exampleFive);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        type: 'modal',
        backgroundImageUrl: '/clickable-sample-banner.jpg',
        banner: true,
        incognito: true,
        targetSelector: '.exampleSix'
      });
    `, exampleSix);
    injectScriptInRef(`
      quizwizardsEmbed({
        quizName: '${quizNameSlug}',
        ${process.env.REACT_APP_STAGE}: true,
        incognito: true,
        targetSelector: '.exampleSeven'
      });
    `, exampleSeven);
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={t('dialogs.quizEmbedInstruction.embedInstructions')} onClose={onClose} />
      <DialogContent>
        <h2 className="block text-white text-sm font-semibold mb-2">{t('dialogs.quizEmbedInstruction.basicUsage')}</h2>
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.includeLibrary')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            &lt;html&gt;<br />
            &nbsp;&lt;head&gt;<br />
            &nbsp;&nbsp;...<br />
            &nbsp;&nbsp;<b>&lt;script src="https://{config.production ? 'app.quizwizards.com' : 'app-staging.quizwizards.com'}/embed.js"&gt;&lt;/script&gt;</b><br />
            &nbsp;&nbsp;...<br />
            &nbsp;&lt;/head&gt;<br />
            &nbsp;&lt;body&gt;<br />
            &nbsp;...
          </code>
        </pre>
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.placeCodeIntoBody')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            &nbsp;&nbsp;height: '<b>740px</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b><br /></div>
            )
            }
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youWillGet')}</b></h3>
        <div ref={exampleOne} style={{ height: '600px' }} />
        <h3 className="text-white">{t('dialogs.quizEmbedInstruction.youHaveEmbedStylesAvailable')}</h3>
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youCanInjectQuizIntoYourElement')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            &nbsp;&nbsp;height: '<b>100%</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )}
            &nbsp;&nbsp;targetSelector: &#x27;.foo&#x27;,<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...<br />
            &#x3C;div style="<b>height: 100vh;</b> background-color: red; padding: 16px; "&#x3E;&#x3C;/div&#x3E;<br />
            ...
          </code>
        </pre>
        <div ref={exampleTwo} />
        <div className="exampleTwo" style={{ backgroundColor: 'red', padding: '16px' }} />
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youCanHaveButtonTriggerModal')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )
            }
            &nbsp;&nbsp;type: 'modal',<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <div ref={exampleThree} />
        <div className="exampleThree" />
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youCanControlColorButton')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )}
            &nbsp;&nbsp;type: 'modal',<br />
            &nbsp;&nbsp;buttonColorHex: &#x27;#FF1493&#x27;,<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <div ref={exampleFour} />
        <div className="exampleFour" />
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youCanPresentLargeBannerWithCallToAction')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )
            }
            &nbsp;&nbsp;type: 'modal',<br />
            &nbsp;&nbsp;backgroundImageUrl: '&lt;URL_OF_BANNER_BACKGROUND_IMAGE&gt;',<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <div ref={exampleFive} />
        <div className="exampleFive" style={{ height: '320px' }} />
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.youCanHaveSmallClickableBanner')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )
            }
            &nbsp;&nbsp;type: 'modal',<br />
            &nbsp;&nbsp;backgroundImageUrl: '&lt;URL_OF_BANNER_BACKGROUND_IMAGE&gt;',<br />
            &nbsp;&nbsp;banner: true,<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <div ref={exampleSix} />
        <div className="exampleSix" style={{ height: '240px' }} />
        <h3 className="text-white"><b>{t('dialogs.quizEmbedInstruction.haveQuizForgetPlays')}</b></h3>
        <pre className="codeBlockWrapper">
          <code className="codeBlock">
            ...<br />
            &lt;script&gt;<br />
            &nbsp;quizwizardsEmbed(&#123;<br />
            &nbsp;&nbsp;quizName: '<b>{quizNameSlug}</b>',<br />
            {config.production ? null : (
              <div>&nbsp;&nbsp;staging: <b>true</b>,<br /></div>
            )
            }
            &nbsp;&nbsp;incognito: true,<br />
            &nbsp;&#125;);<br />
            &lt;/script&gt;<br />
            ...
          </code>
        </pre>
        <div ref={exampleSeven} />
        <div className="exampleSeven" />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default QuizEmbedInstructionDialog;
