import { createAsyncThunk } from '@reduxjs/toolkit';
import { appActions } from 'store/app/appSlice';
// Utilities
import HttpClient from 'utilities/HttpClient';
//
import { resultMessages } from './resultMessages';
import { resultActions } from './resultSlice';

const _url:string = '/result';
const _http:HttpClient = new HttpClient();

export const ResultAsync = {
  fetchResultList: createAsyncThunk(
    'result/Fetch result list',
    async (params:any = {}, thunkApi) => {
      const { quizId, key, direction, ...nextParams } = params;
      
      const url = quizId ? `/analytics/${quizId}/results` : _url;

      const response:Response = await _http.get(url, {...nextParams, sort: `${key} ${direction}` });
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  fetchCountAnswersByQuestions: createAsyncThunk(
    'result/Fetch count answers by questions',
    async (quizId: string, thunkApi) => {
      const response:Response = await _http.get(`${_url}/answers/${quizId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  fetchAnalytics: createAsyncThunk(
    'result/Fetch analytics',
    async (_, thunkApi) => {
      const response:Response = await _http.get('/analytics');
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  fetchQuizAnalytics: createAsyncThunk(
    'result/Fetch quiz analytics',
    async (quizId:string, thunkApi) => {
      const response:Response = await _http.get(`/analytics/${quizId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  updateResult: createAsyncThunk(
    'result/Update result',
    async (data:any, thunkApi) => {
      const { resultId, resultData } = data;
      const response:Response = await _http.patch(`${_url}/${resultId}`, resultData);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  deleteResult: createAsyncThunk(
    'result/Delete result',
    async (resultId:string, thunkApi) => {
      const response:Response = await _http.delete(`${_url}/${resultId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: resultMessages.deleteResult }));
    }
  ),
  deleteAllResults: createAsyncThunk(
    'result/Delete all results',
    async (quizId:string, thunkApi) => {
      const response:Response = await _http.delete(`${_url}/quiz/${quizId}`);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
      thunkApi.dispatch(appActions.createNotification({ message: resultMessages.deleteAllResult }));
    }
  ),
  updateCorrectAnswersAndScores: createAsyncThunk(
    'result/Update correct answers and scores',
    async (data:{quizId:string}, thunkApi) => {
      const response:Response = await _http.post(`${_url}/actions/updateCorrectAnswersAndScores`, data);
      setTimeout(() => {
        thunkApi.dispatch(resultActions.setUpdateCorrectAnswersLoading(false));
      }, 2000);
      if ( !response.ok ) return thunkApi.rejectWithValue((await response.json()) as any);
    }
  )
}
