import React from 'react';
import moment from 'moment';
// Icons
import { FaEdit } from 'react-icons/fa';
// Components
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  item: any;
  onGoTo: (url:string) => void;
}

const ReportListItem:React.FC<Props> = ({
  // Props
  item, onGoTo
}) => {
  const { t } = useTranslation('common');
  return (
    <div className="flex items-center p-4">
      <div className="flex flex-col flex-grow text-white">
        <h3 className="text-xl font-bold mb-2">{item.quizName}</h3>
        <span>{t('common.startDate')}:&nbsp;<b>{moment(Number(item.startsDate)).format('llll')}</b></span>
        <span>{t('common.endDate')}:&nbsp;<b>{moment(Number(item.endsDate)).format('llll')}</b></span>
      </div>
      <div>
        <Tooltip title={t('dialogs.groupQuizzes.editQuiz')}>
          <IconButton
            onClick={() => onGoTo(`/owner/quizzes/${item._id}`)}
          ><FaEdit /></IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default ReportListItem;
