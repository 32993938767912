import React from 'react';

type Props = {
  className?: string;
  icon: 'add' | 'arrow_back' | 'call_to_action' | 'cup' | 'delete' |
        'error_outline' | 'help' | 'images' | 'list' | 'menu' | 'more_vert' |
        'notes' | 'settings' | 'theme' | 'video' | 'content_copy' | 'flag' | 'medal' | 'clipboard'
  ;
  size?: 'small' | 'default';
};

const Icon:React.FC<Props> = ({
  // Props
  className = '', icon, size = 'default'
}) => {
  return (
    <span className={`
      ${className}
      flex justify-center items-center
      ${size === 'default' ? 'w-6 h-6 text-2xl leading-6' : 'w-4 h-4 text-base leading-4'}
    `}>
      <i className={`icon icon-${icon}`}></i>
    </span>
  )
}

export default Icon;
