// Assets
import FontFamily from 'assets/font-family.json';

import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Input, Checkbox, Select } from 'components/Controls';

const QuestionsSettings:FC = () => {
  const { t } = useTranslation('common');
  
  const quiz = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const watchedQuestionsFont = watch('questionsFont');

  const questionTimeoutOptions = new Array(6).fill(5).map((e, i) => {
    if (i === 0) return ({ label: t('common.none'), value: '' });
    return ({ label: e + (i * 5), value: e + (i * 5) });
  })
  
  if ( quiz?.type === QuizTypes.Personality ) return null;
  return (
    <div className="max-w-2xl h-full">
      <div className="p-6" react-scroll="settings">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizQuestionsPage.settings')}</h2>
      </div>
      <div className="p-6">
        <Controller
          control={control} name="randomizeQuestionOrder"
          render={({ field: { onChange, value } }) => (
            <Checkbox
              label={t('ownerPage.quizQuestionsPage.randomizeQuestionOrder')} id="randomizeQuestionOrder" name="randomizeQuestionOrder"
              checked={value}
              onChange={onChange}
              helperText={t('ownerPage.quizQuestionsPage.randomizeQuestionOrderHint')}
            />
          )}
        />
        {quiz?.type === QuizTypes.Scoring && (
          <Controller
            control={control} name="highlightCorrectAnswer"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('ownerPage.quizQuestionsPage.highlightCorrectAnswer')} id="highlightCorrectAnswer" name="highlightCorrectAnswer"
                checked={value}
                onChange={onChange}
                helperText={t('ownerPage.quizQuestionsPage.highlightCorrectAnswerHint')}
              />
            )}
          />
        )}
        <Controller
          control={control} name="questionLimit"
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('ownerPage.quizQuestionsPage.questionLimit')} id="questionLimit" type="number" name="questionLimit"
              value={value}
              onChange={onChange}
            />
          )}
        />
        <span className="block text-gray-400 text-sm font-light leading-5 mb-4">{t('ownerPage.quizQuestionsPage.questionLimitHint')}</span>

        {quiz && [QuizTypes.Scoring, QuizTypes.Prediction].includes(quiz.type) && (
          <Fragment>
            <Controller
              control={control} name="questionTimeout"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('ownerPage.quizQuestionsPage.questionTimeout')} id="questionTimeout" name="questionTimeout"
                  options={questionTimeoutOptions}
                />
              )}
            />
          <span className="block text-gray-400 text-sm font-light leading-5 mb-1">{t('ownerPage.quizQuestionsPage.questionTimeoutHint')}</span>
          <span className="block text-gray-400 text-sm font-light leading-5 mb-4">{t('ownerPage.quizQuestionsPage.questionTimeoutHint2')}</span>
          </Fragment>
        )}

        <Controller
          control={control} name="questionsFont"
          render={({ field }) => (
            <Select
              {...field}
              label={t('common.fonts')} id="questionsFont" name="questionsFont"
              helperText={t('ownerPage.quizQuestionsPage.questionsFontHint')}
              required={true}
              options={FontFamily.families}
            />
          )}
        />
        <p className="text-white text-xl mb-4" style={{ fontFamily: `"${watchedQuestionsFont}", sans-serif` }}>
          {t('common.howTextWillLook')}
        </p>
      </div>
    </div>
  )
}

export default QuestionsSettings;
