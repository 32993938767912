import { ReactNode, FC, useEffect, useState } from 'react';
// Icons
import { FaFilter, FaTimes } from 'react-icons/fa';
// Components
import { IconButton } from './Buttons';

type Props = {
  children: ReactNode;
}

const PageSidebar:FC<Props> = ({
  // Props
  children
}) => {
  const [ open, setOpen ] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, []);

  const handleToggleOpen = () => setOpen(!open);

  return (
    <div className="lg:hidden">
      <IconButton
        onClick={handleToggleOpen}
      ><FaFilter /></IconButton>
      <div
        className={`
          fixed top-0 right-0 h-full rounded-l
          flex flex-col
          z-50 overflow-hidden
          transform transition-transform
          ${open ? 'translate-x-0' : 'translate-x-full'}
        `}
        style={{
          minWidth: '260px',
          backgroundColor: 'rgb(56, 55, 55)',
          boxShadow: 'rgb(0 0 0 / 14%) 0px 8px 10px, rgb(0 0 0 / 12%) 0px 3px 14px, rgb(0 0 0 / 20%) 0px 5px 5px'
        }}
      >
        <div
          className="p-4 text-right"
          style={{
            borderBottom: '1px solid rgba(255,255,255,0.12)'
          }}
        >
          <IconButton
            className="-mr-2"
            onClick={handleToggleOpen}
          ><FaTimes /></IconButton>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageSidebar;
