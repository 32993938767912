import { createAsyncThunk } from '@reduxjs/toolkit';
// Utilities
import HttpClient from 'utilities/HttpClient';
// Actions
import { appActions } from 'store/app/appSlice';
import { playerMessages } from './playerMessages';

const _url:string = '/players';
const _http:HttpClient = new HttpClient();

export const getPlayers = createAsyncThunk(
  'player/Get players',
  async (params:any, { rejectWithValue }) => {
    const response:Response = await _http.get(_url, params);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    return response.json();
  }
);

// Compress image
export const deletePlayer = createAsyncThunk(
  'player/Delete player',
  async (playerId:string, { rejectWithValue, dispatch, fulfillWithValue }) => {
    const response:Response = await _http.delete(`${_url}/${playerId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(appActions.createNotification({ message: playerMessages.deletePlayer }));
    return fulfillWithValue(playerId)
  }
);
