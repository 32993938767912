import HomeHeroImage from 'assets/images/HomeHero.jpg';

import { FC, Fragment } from 'react'
// Models
import { IUser } from 'models/User';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors'
// 
import PublicContent from './PublicContent';
import PrivateContent from './PrivateContent';

const HomePage:FC = () => {
  const currentUser:IUser | null = useSelector(selectCurrentUser);

  return (
    <Fragment>
      <div
        className="flex flex-grow items-center bg-no-repeat bg-right bg-cover"
        style={{
          backgroundImage: `url(${HomeHeroImage})`
        }}
      >
        <div className="container max-w-screen-2xl">
          <div
            className="
              w-full md:w-1/2
              bg-opacity-75 md:bg-opacity-1
              bg-content md:bg-transparent
              rounded-lg md:rounded-none
              py-8 md:py-0
            "
          >
            <div className="flex flex-col text-center md:text-left">
              {currentUser ? <PrivateContent currentUser={currentUser} /> : <PublicContent />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HomePage;
