import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from 'utilities/HttpClient';

import { appActions } from 'store/app/appSlice';
import IAccount from 'models/Account';
import { AccountsMessages } from './accountsMessages';

const _url:string = '/accounts';
const _http:HttpClient = new HttpClient();

const AccountsAsync = {
  getAccount: createAsyncThunk(
    'accounts/Get account',
    async (accountId: string, { rejectWithValue }) => {
      const response:Response = await _http.get(`${_url}/${accountId}`);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      return response.json();
    }
  ),
  updateAccount: createAsyncThunk(
    'accounts/Update account',
    async (data:{ accountId: string, accountData: Pick<IAccount, 'features' | 'quizTypes'> }, { rejectWithValue, dispatch }) => {
      const { accountId, accountData } = data;
      const response:Response = await _http.put(`${_url}/${accountId}`, accountData);
      if ( !response.ok ) return rejectWithValue((await response.json()) as any);
      dispatch(appActions.createNotification({ message: AccountsMessages.update }));
      return response.json();
    }
  ),
}

export default AccountsAsync;
