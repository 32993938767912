import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AiChatAsync from './aiChatAsync';
import IQuestion from 'models/Question';

interface IState {
  content: {
    topic: string;
    questions: Pick<IQuestion, 'title' | 'answers'>[];
  } | null;
};

const initialState:IState = {
  content: null,
};

const slice = createSlice({
  name: 'aiChat',
  initialState,
  reducers: {
    removeQuestion: (state, action:PayloadAction<number>) => {
      if (state.content) {
        state.content = { ...state.content, questions: state.content.questions.filter((_,i) => i !== action.payload) }
      }
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(AiChatAsync.generateQuestions.fulfilled, (state, action:PayloadAction<any>) => {
      state.content = action.payload;
    })
    builder.addCase(AiChatAsync.generateQuestion.fulfilled, (state, action) => {
      const { arg:questionIndex } = action.meta;
      if (state.content?.questions.length === questionIndex) {
        state.content.questions.push(action.payload);
      } else if (state.content) {
        state.content = { ...state.content, questions: state.content.questions.map((q, i) => i === questionIndex ? action.payload : q) }
      }
    })
  }
});

export const aiChatActions = slice.actions;

export default slice.reducer;
