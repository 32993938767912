import React from 'react';
// Layouts
import { Dialog, DialogTitle, DialogContent } from 'layouts/Dialog';
// Components
import QuizFrame from 'components/QuizFrame';

import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  quizNameSlug: string;
  quizHash: string | null;
  preview: 'quiz' | 'liveLeaderboard';
};

const PreviewDesktopDialog:React.FC<Props> = ({
  // Props
  open, onClose, quizNameSlug, quizHash, preview
}) => {
  const { t } = useTranslation('common');

  if ( !quizHash ) return null;
  return (
    <Dialog size="full" open={open} onClose={onClose}>
      <DialogTitle title={t('dialogs.previewDesktop.desktopPreview')} onClose={onClose} />
      <DialogContent>
        <div className="absolute top-0 left-0 w-full h-full">
          <div className="relative w-full h-full">
            <QuizFrame
              style={{
                width: '100%',
                height: '100%'
              }}
              id="preview-desktop"
              quizNameSlug={quizNameSlug}
              quizHash={quizHash}
              preview={preview}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PreviewDesktopDialog;
