import { FC, Fragment, useState } from 'react';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { getFilteredNamespaceItems } from 'store/namespace/namespaceSelectors';
// Components
import NamespaceFormDialog from 'dialogs/NamespaceForm.dialog';
import { Button } from 'components/Buttons';
import { Toolbar } from 'components/Toolbar';
import NamespacesFilter from './NamespacesFilter';
import NamespacesSort from './NamespacesSort';
import NamespacesList from './NamespacesList';
// i18next
import { useTranslation } from 'react-i18next';

const initialConfig = { key: 'name', direction: Direction.Ascending }

const NamespacesPage:FC = () => {
  const { t } = useTranslation('common');
  
  const namespaces = useAppSelector(getFilteredNamespaceItems);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    namespaces, initialConfig
  );

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  const toggleDialogOpen = () => setDialogOpen(!dialogOpen);

  return (
    <Fragment>
      <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
        <Toolbar title={t('ownerPage.namespacesPage.manageCategories')}>
          <div
            className="
              flex items-center
              order-2 lg:order-none
              w-full lg:w-auto
              mt-4 lg:mt-0
            "
          >
            <div className="flex-grow mr-4">
              <NamespacesFilter />
            </div>
            <NamespacesSort
              sortConfig={sortConfig || initialConfig}
              onRequestSort={requestSort}
            />
          </div>
          <Button
            variant="contained"
            type="button"
            onClick={toggleDialogOpen}
          >{t('ownerPage.namespacesPage.createCategory')}</Button>
        </Toolbar>
        <NamespacesList
          sortedNamespaces={items}
          onRequestSort={requestSort}
          getClassNamesFor={getClassNamesFor}
        />
      </div>
      {dialogOpen ? (
        <NamespaceFormDialog
          open={dialogOpen}
          onClose={toggleDialogOpen}
        />
      ) : null}
    </Fragment>
  )
}

export default NamespacesPage;
