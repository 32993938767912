import React, { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
import { Input, Textarea } from 'components/Controls';
//
import { countries } from 'components/LanguageSelect';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  label?: string;
  open: boolean;
  onClose: () => void;

  multiline?: boolean;
  rows?: number;
  customKey?: string;
}

const MultiLanguageTextFieldsDialog:React.FC<Props> = ({
  // Props
  name, label, open, onClose, multiline = false, rows, customKey
}) => {
  const { t } = useTranslation('common');

  const { watch, setValue } = useFormContext();

  const watcher = watch(name);
  const watcherLanguage = watch('language');
  const watchedAdditionalLanguages = watch('additionalLanguages');
  const watchedTranslations = watch('translations') || {};

  const key = `${`${customKey || name}`}`;

  const onChange = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, lng:string) => {
    setValue('translations', {
      ...watchedTranslations,
      [lng]: {
        ...(watchedTranslations[lng] || {}),
        [key]: e.target.value
      }
    })
  }

  const country = countries.find(country => country.value === watcherLanguage);

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title={label || t('common.translations')} onClose={onClose} />
      <DialogContent>
        <div className="mb-4">
          <h2 className="text-white text-sm font-semibold mb-1">{country?.label}</h2>
          <p className="text-white text-md">{watcher || '-'}</p>
        </div>
        {watchedAdditionalLanguages.map((lang:string) => multiline ? (
          <Textarea
            key={`${name}-${lang}`}
            value={watchedTranslations[lang]?.[key] || ''}
            onChange={(e) => onChange(e, lang)}
            label={countries.find(country => country.value === lang)?.label}
            rows={rows}
            id={`${name}-${lang}`} name={`${name}-${lang}`}
          />
        ) : (
          <Input
            key={`${name}-${lang}`}
            value={watchedTranslations[lang]?.[key] || ''}
            onChange={(e) => onChange(e, lang)}
            label={countries.find(country => country.value === lang)?.label}
            id={`${name}-${lang}`} name={`${name}-${lang}`}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onClose}
        >{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MultiLanguageTextFieldsDialog;
