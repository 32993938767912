import { FC } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Icons
import { FaTrash } from 'react-icons/fa';
// Types
import QuizTypes from 'types/QuizTypes';
// Model
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Button, IconButton } from 'components/Buttons';
import { Toggle } from 'components/Controls';
import { Tooltip } from 'components/Tooltip';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

const SectionPrizes:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prizes'
  });

  const handleAdd = () => {
    append({ prize: '' });
  }

  const handleRemove = (index:number) => () => {
    remove(index);
  }

  const spotPrizesEnabled = watch('spotPrizesEnabled');

  if ( quiz && [QuizTypes.Personality, QuizTypes.Poll].includes(quiz.type) ) return null;
  return (
    <div react-scroll="prizes">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.prizes')}</h2>
      </div>
      <div className="p-6">
        <div className="mb-4 text-right">
          <Button
            variant="outlined"
            onClick={handleAdd}
          >{t('ownerPage.quizForm.addPrize')}</Button>
        </div>
        {fields.map((field:any, index:number) => (
          <div key={`prize-item-${field.id}`} className="flex gap-2">
            <div className="flex-grow">
              <MultiLanguageTextField
                name={`prizes.${index}.prize`}
                label={`${t('ownerPage.quizForm.prize')} ${index + 1}`}
                className="mb-4"
              />
            </div>
            {index > 0 && index === fields.length - 1 ? (
              <div className="mt-6">
                <Tooltip title={t('ownerPage.quizForm.removePrize')}>
                  <IconButton
                    onClick={handleRemove(index)}
                  ><FaTrash /></IconButton>
                </Tooltip>
              </div>
            ) : null}
          </div>
        ))}
        <span className="divider my-8"></span>
        <div className="flex items-center my-8">
          <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.randomPrize')}</span>
          <Controller
            control={control} name="spotPrizesEnabled"
            render={({ field: { onChange, value } }) => (
              <Toggle
                id="spotPrizesEnabled"
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <MultiLanguageTextField
          name="spotPrizes"
          disabled={!spotPrizesEnabled}
        />
      </div>
    </div>
  )
}

export default SectionPrizes;
