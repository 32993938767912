import { FC, Fragment, useState } from 'react';
// Models
import { IUser } from 'models/User';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectFilteredUsers } from 'store/user/userSelectors';
// Dialog
import InviteUserFormDialog from 'dialogs/InviteUserForm.dialog';
// Hooks
import { Direction, useSortableItems } from 'hooks/useSortableItems';
// Components
import { Button } from 'components/Buttons';
import { Toolbar } from 'components/Toolbar';
// 
import UsersFilter from './UsersFilter';
import UsersSort from './UsersSort';
import UsersList from './UsersList';
// i18next
import { useTranslation } from 'react-i18next';

const initialConfig = { key: 'name', direction: Direction.Ascending }

const UsersPage:FC = () => {
  const { t } = useTranslation('common');
  // State
  const users:IUser[] | null = useSelector(selectFilteredUsers);

  const { items, sortConfig, requestSort, getClassNamesFor } = useSortableItems(
    users, initialConfig
  );

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  const toggleDialog = () => setDialogOpen(!dialogOpen);

  return (
    <Fragment>
      <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
        <Toolbar title={t('ownerPage.usersPage.manageUsers')}>
          <div
            className="
              flex items-center
              order-2 lg:order-none
              w-full lg:w-auto
              mt-4 lg:mt-0
            "
          >
            <div className="flex-grow mr-4">
              <UsersFilter />
            </div>
            <UsersSort
              sortConfig={sortConfig || initialConfig}
              onRequestSort={requestSort}
            />
          </div>
          <Button
            variant="contained"
            type="button"
            onClick={toggleDialog}
          >{t('ownerPage.usersPage.inviteUser')}</Button>
        </Toolbar>
        <UsersList
          sortedUsers={items}
          onRequestSort={requestSort}
          getClassNamesFor={getClassNamesFor}
        />
      </div>
      {dialogOpen ? (
        <InviteUserFormDialog
          open={dialogOpen}
          onClose={toggleDialog}
        />
      ) : null}
    </Fragment>
  )
}

export default UsersPage;
