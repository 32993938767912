import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// import { selectIntegrations } from 'store/integrations/integrationsSelectors';
// Components
import { Input, Toggle } from 'components/Controls';
import ImageUpload from 'components/ImageUpload';
// import IntegrationItem from './IntegrationItem';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

const SectionCallToAction:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);
  // const integrations = useAppSelector(selectIntegrations);

  const { control, watch } = useFormContext();

  // const { fields, append } = useFieldArray({
  //   control,
  //   name: 'integrations',
  // })

  const proceedEnabled = watch('proceedEnabled');
  const advertImageEnabled = watch('advertImageEnabled');

  // useEffect(() => {
  //   integrations.forEach(integration => {
  //     if (!quiz?.integrations?.some(i => i._id === integration._id)) {
  //       append({
  //         integrationId: integration._id,
  //         value: false
  //       });
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [integrations]);

  if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <div react-scroll="callToAction">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.callToAction')}</h2>
      </div>
      <div className="p-6">
        <div className="flex items-center mb-8">
          <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.postQuizCallToAction')}</span>
          <Controller
            control={control} name="proceedEnabled"
            render={({ field: { onChange, value } }) => (
              <Toggle
                id="proceedEnabled"
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>

        <span className="block text-gray-400 text-sm font-light leading-5 mb-4">{t('ownerPage.quizForm.postQuizCallToActionHint')}</span>

        <div className="flex flex-wrap -mx-2">
          <div className="w-full laptop:w-1/2 px-2">
            <Controller
              control={control} name="proceedImage"
              render={({ field }) => (
                <ImageUpload
                  {...field}
                  width={420}
                  height={350}
                  label="ownerPage.quizForm.proceedImage"
                  helperText="ownerPage.quizForm.proceedImageHint"
                  disabled={!proceedEnabled}
                />
              )}
            />
          </div>
          <div className="w-full laptop:w-1/2 px-2">
            <MultiLanguageTextField
              name="proceedButtonTitle"
              label={t('ownerPage.quizForm.proceedButtonTitle')}
              helperText={t('ownerPage.quizForm.proceedButtonTitleHint')}
              disabled={!proceedEnabled}
              className="mb-4"
            />
            <Controller
              control={control} name="proceedUrl"
              render={({ field }) => (
                <Input
                  {...field}
                  label={t('ownerPage.quizForm.proceedUrl')} id="proceedUrl" name="proceedUrl"
                  helperText={t('ownerPage.quizForm.proceedUrlHint')}
                  disabled={!proceedEnabled}
                />
              )}
            />
            <MultiLanguageTextField
              name="proceedText"
              label={t('ownerPage.quizForm.proceedText')}
              helperText={t('ownerPage.quizForm.proceedTextHint')}
              disabled={!proceedEnabled}
              className="mb-4"
            />
            {quiz?.type !== QuizTypes.Poll && (
              <Controller
                control={control} name="proceedClickBonusPoints"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('ownerPage.quizForm.proceedClickBonusPoints')} id="proceedClickBonusPoints" name="proceedClickBonusPoints"
                    helperText={t('ownerPage.quizForm.proceedClickBonusPointsHint')}
                    disabled={!proceedEnabled}
                  />
                )}
              />
            )}
          </div>
        </div>
        <span className="divider my-4"></span>
        <div className="flex items-center mb-8">
          <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.homeBanner')}</span>
          <Controller
            control={control} name="advertImageEnabled"
            render={({ field: { onChange, value } }) => (
              <Toggle
                id="advertImageEnabled"
                checked={value}
                onChange={onChange}
              />
            )}
          />
        </div>
        <span className="block text-gray-400 text-sm font-light leading-5 mb-4">{t('ownerPage.quizForm.homeBannerHint', { ratio: '14:4', width: 420, height: 120 })}</span>
        <Controller
          control={control} name="advertImage"
          render={({ field }) => (
            <ImageUpload
              {...field}
              width={420}
              height={120}
              label="ownerPage.quizForm.bannerImage"
              disabled={!advertImageEnabled}
            />
          )}
        />
        <Controller
          control={control} name="advertUrl"
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('ownerPage.quizForm.bannerImageUrl')} id="advertUrl" name="advertUrl" value={value}
              helperText={t('ownerPage.quizForm.bannerImageUrlHint')}
              onChange={onChange}
              disabled={!advertImageEnabled}
            />
          )}
        />
        {/* {fields.map((field: any, index) => (
          <IntegrationItem key={field.id} integrationId={field.integrationId} index={index} />
        ))} */}
      </div>
    </div>
  )
}

export default SectionCallToAction;
