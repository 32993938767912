import { FC, Fragment } from "react";
// Redux
import { useAppSelector } from "hooks/useStore";
import { selectQuiz } from "store/quiz/quizzesSelectors";
import IQuestion from "models/Question";
// Components
import QuestionItem from "./QuestionItem";
import { Message } from "components/Utilities";
// i18next
import { useTranslation } from "react-i18next";

const PostQuizQuestionsList:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  if (!quiz) return null;
  if (!quiz.postQuizPages[0]?.questions || !quiz.postQuizPages[0]?.questions.length) return (
    <Message text={t('ownerPage.questionAnswersBreakdownPage.quizDoesntHavePostQuizQuestions')} />
  )
  return (
    <Fragment>
      {quiz.postQuizPages[0].questions.map((question:IQuestion, questionIndex:number) => (
        <QuestionItem key={question.ref} question={question} questionIndex={questionIndex} />
      ))}
    </Fragment>
  );
}
 
export default PostQuizQuestionsList;
