import { v4 as uuidv4 } from 'uuid';
// Types
import QuestionTypes from 'types/QuestionTypes';
// Models
import IAnswer from 'models/Answer';
import IBucket from 'models/Bucket';
// Utilities
import { textFromCamelToNormalCase } from 'utilities/utilities';
import QuestionFormats from 'types/QuestionFormats';

export default class QuestionService {
  public static initialQuestion() {
    return {
      ref: uuidv4(),
      title: '',
      // description: '',
      type: QuestionTypes.SingleChoice,
      // image: '',
      // video: '',
      answers: []
    };
  }

  public static initialAnswer(buckets:IBucket[] | undefined) {
    const answer:IAnswer = { ref: uuidv4(), title: '', correct: false };
    if ( buckets && buckets.length ) answer['bucketScores'] = buckets.map((bucket:IBucket) => ({
      ref: bucket.ref,
      score: 0
    }));
    return answer;
  }

  public static questionTypeOptions(skipShortText:boolean = false) {
    return (Object.keys(QuestionTypes) as Array<keyof typeof QuestionTypes>)
      .filter((key) => skipShortText ? key !== 'ShortText' : true)
      .map((key) => ({
        value: QuestionTypes[key],
        label: textFromCamelToNormalCase(key)
      }));
  }

  public static questionFormatOptions() {
    return (Object.keys(QuestionFormats) as Array<keyof typeof QuestionFormats>)
      .map((key) => ({
        value: QuestionFormats[key],
        label: textFromCamelToNormalCase(key)
      }));
  }
}
