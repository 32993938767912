import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import ConsentsAsync from 'store/consents/consentsAsync';
import { consentsActions } from 'store/consents/consentsSlice';
import { selectConsent } from 'store/consents/consentsSelectors';
import IOption from 'models/Option';
import { IConsentData } from 'models/Consent';
import ConsentsTypes from 'types/ConsentsTypes';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input, Select, Toggle } from 'components/Controls';
import { TextEditor } from 'components/TextEditor';
import { Button } from 'components/Buttons';
import { Loader } from 'components/Utilities';
// Utilities
import { isRequired } from 'utilities/validation';
import { textFromCamelToNormalCase } from 'utilities/utilities';
import { countries } from 'components/LanguageSelect';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import UserRoles from 'types/UserRoles';

type Props = {
  consentId?: string;
  open: boolean;
  onClose: () => void;
}

const ConsentsFormDialog:React.FC<Props> = ({ consentId, open, onClose }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const consent = useAppSelector(selectConsent);
  const currentUser = useAppSelector(selectCurrentUser);

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, formState: { errors }, reset, watch } = useForm<IConsentData>({
    defaultValues: {
      name: consent?.name || '',
      type: consent?.type || '',
      language: consent?.language || countries[0].value,
      shortText: consent?.shortText || '',
      url: consent?.url || '',
      content: consent?.content || '',
      required: consent?.required || false
    }
  });

  const urlWatcher = watch('url');
  const contentWatcher = watch('content');

  const onSubmit = handleSubmit((data:IConsentData) => {
    setIsLoading(true);

    if (!data.type || !currentUser?.groups.includes(UserRoles.Admin)) delete data['type'];
    if (!data.url) delete data['url'];
    if (!data.content) delete data['content'];

    if ( consentId ){
      dispatch(ConsentsAsync.updateConsent({ consentId: consentId, consentData: data })).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    } else {
      dispatch(ConsentsAsync.createConsent(data)).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    }
  });

  useEffect(() => {
    if (!consentId) return;
    dispatch(ConsentsAsync.getConsent(consentId))
    // eslint-disable-next-line
  }, [consentId]);

  useEffect(() => {
    if (!consent) return;
    reset({
      name: consent?.name || '',
      type: consent?.type || '',
      language: consent?.language || countries[0].value,
      shortText: consent?.shortText || '',
      url: consent?.url || '',
      content: consent?.content || '',
      required: consent?.required || false
    });

    return () => {
      dispatch(consentsActions.setInitialField('consent'));
    }
    // eslint-disable-next-line
  }, [consent]);

  return (
    <Dialog size="lg" open={open} onClose={onClose}>
      {consentId && !consent ? <Loader /> : (
        <form className="flex flex-grow flex-col overflow-y-auto" onSubmit={onSubmit} noValidate>
          <DialogTitle title={consent ? t('dialogs.consentsForm.updateConsent') : t('dialogs.consentsForm.createConsent')} onClose={onClose} />
          <DialogContent>
            <Controller
              control={control} name="name" rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="name"
                  label={t('common.name')}
                  required={true}
                  error={Boolean(errors.name)}
                  errorText={errors.name ? (errors.name.message as any) : ''}
                />
              )}
            />
            {currentUser?.groups.includes(UserRoles.Admin) && (
              <Controller
                control={control} name="type"
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t('common.type')} id="type" name="type"
                    options={consentsTypesOptions}
                  />
                )}
              />
            )}
            <Controller
              control={control} name="language" rules={{ required: isRequired }}
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('adminPage.language')} id="language" name="language"
                  options={countries}
                  required={true}
                  error={Boolean(errors.language)}
                  errorText={errors.language ? (errors.language.message as any) : ''}
                />
              )}
            />
            <Controller
              control={control} name="shortText" rules={{ required: isRequired }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="shortText"
                  label={t('adminPage.shortText')}
                  required={true}
                  error={Boolean(errors.shortText)}
                  errorText={errors.shortText ? (errors.shortText.message as any) : ''}
                />
              )}
            />
            <Controller
              control={control} name="url" rules={{
                required: !contentWatcher ? isRequired : false,
                validate: (value) => value && contentWatcher ? t('adminPage.urlOrContentShouldBeSpecified') : true
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  id="url"
                  label={t('adminPage.url')}
                  required={!contentWatcher}
                  error={Boolean(errors.url)}
                  errorText={errors.url ? (errors.url.message as any) : ''}
                />
              )}
            />
            <span className="block text-white text-base font-semibold mr-3">
              {`${t('adminPage.content')} ${!urlWatcher ? '*' : ''}`}
            </span>
            <div className="bg-white mt-2 mb-4">
              <Controller
                control={control} name="content" rules={{ required: !urlWatcher ? isRequired : false }}
                render={({ field }) => (
                  <TextEditor
                    {...field}
                    error={Boolean(errors.content)}
                    errorText={errors.content ? (errors.content.message as any) : ''}
                  />
                )}
              />
            </div>
            <div className="flex items-center">
              <span className="block text-white text-base font-semibold mr-3">{t('adminPage.required')}</span>
              <Controller
                control={control} name="required"
                render={({ field: { onChange, value } }) => (
                  <Toggle
                    id="required"
                    checked={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
            >{t('common.cancel')}</Button>
            <Button
              className="ml-2"
              variant="contained"
              type="submit"
              loading={isLoading}
            >{consentId ? t('common.save') : t('common.create')}</Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  )
}

export default ConsentsFormDialog;

const consentsTypesOptions = (Object.keys(ConsentsTypes) as Array<keyof typeof ConsentsTypes>)
  .map(key => ({value: ConsentsTypes[key], label: textFromCamelToNormalCase(key as string) })) as IOption[];
consentsTypesOptions.unshift({ value: '', label: 'Choose a type' })
