import { FC, useEffect, useState } from 'react';
import config from 'config';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
// Icons
import { FaTrash } from 'react-icons/fa';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { GroupsAsync } from 'store/group/groupAsync';
import { groupsActions } from 'store/group/groupSlice';
import { getGroupItem } from 'store/group/groupSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input, Toggle } from 'components/Controls';
import { Button, IconButton } from 'components/Buttons';
import { ColorPicker } from 'components/ColorPicker';
import { Tooltip } from 'components/Tooltip';
import ImageUpload from 'components/ImageUpload';
import Copy from 'components/Copy';
// Utilities
import { isRequired } from 'utilities/validation';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  groupId?: string;
  open: boolean;
  onClose: () => void;
}

const GroupFormDialog:FC<Props> = ({ groupId, open, onClose }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const groupItem = useAppSelector(getGroupItem);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset, formState: { errors }, watch } = useForm({
    defaultValues: {
      name: '',
      logoImage: '',
      primaryColor: '',
      callToActionImageUrl: '',
      callToActionLinkUrl: '',
      callToActionButtonText: '',
      prizes: [{ prize: '' }],
      publicLeaderboard: {
        enabled: false,
        wallpaperImage: ''
      }
    }
  });

  const publicLeaderboardEnabledWatcher = watch('publicLeaderboard.enabled');

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: 'prizes'
  });

  useEffect(() => {
    if ( groupId ) dispatch(GroupsAsync.fetchGroupById(groupId));
    return () => {
      if ( groupId ) dispatch(groupsActions.setInitialField('item'));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ( groupItem ){
      reset({
        ...groupItem,
        prizes: groupItem.prizes.map((prize:string) => ({ prize }))
      });
    }
    // eslint-disable-next-line
  }, [groupItem]);

  const onSubmit = handleSubmit((data:any) => {
    setIsLoading(true);

    const newData = {...data, prizes: data.prizes.map((prize:any) => prize.prize)};

    if ( groupId ){
      dispatch(GroupsAsync.updateGroup({ groupId, groupData: newData })).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    } else {
      dispatch(GroupsAsync.createGroup(newData)).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    }
  });

  const handleAppendPrize = () => append({ prize: 0 });
  const handleRemovePrize = (index:number) => remove(index);

  return (
    <Dialog size="lg" open={open} onClose={onClose}>
      <form className="flex flex-col overflow-y-auto" onSubmit={onSubmit}>
        <DialogTitle title={groupId ? t('dialogs.groupForm.updateQuizGroup') : t('dialogs.groupForm.createQuizGroup')} onClose={onClose} />
        <span className="block text-gray-400 text-sm font-light leading-5 mb-4 pt-3 pr-3 pl-3">{t('dialogs.groupForm.subtitle1')}</span>
        <span className="block text-gray-400 text-sm font-light leading-5 mb-4 pr-3 pl-3">{t('dialogs.groupForm.subtitle2')}</span>
        <DialogContent>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 px-4">
              <Controller
                control={control} name="name" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <Input
                    label={t('common.name')} id="name" name="name" value={value}
                    required={true}
                    onChange={onChange}
                    error={Boolean(errors.name)}
                    errorText={errors.name ? errors.name.message : ''}
                  />
                )}
              />
              <div className="mb-4">
                <Controller
                  control={control} name="logoImage" rules={{ required: isRequired }}
                  render={({ field }) => (
                    <ImageUpload
                      {...field}
                      width={140}
                      height={60}
                      label="dialogs.groupForm.logoImage"
                      required={true}
                      error={Boolean(errors.logoImage)}
                      errorText={errors.logoImage ? errors.logoImage.message : ''}
                      helperText="dialogs.groupForm.logoImageHint"
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <Controller
                  control={control} name="primaryColor" rules={{ required: isRequired }}
                  render={({ field: { value, onChange } }) => (
                    <ColorPicker
                      label={t('dialogs.groupForm.primaryColor')}
                      value={value}
                      required={true}
                      error={Boolean(errors.primaryColor)}
                      errorText={errors.primaryColor ? errors.primaryColor.message : ''}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <span className="block w-full bg-gray-500 my-4" style={{ height: '1px' }}></span>
              <span className="block text-white text-base font-semibold mb-1">{t('dialogs.groupForm.cumulativeGrandPrizes')}</span>
              <div className="mb-4 text-right">
                <Button
                  variant="outlined"
                  onClick={handleAppendPrize}
                >{t('dialogs.groupForm.addPrize')}</Button>
              </div>
              {fields.map((field, index) => (
                <div key={`prize-item-${field.id}`} className="flex items-end">
                  <div className="flex-grow">
                    <Controller
                      control={control} name={`prizes.${index}.prize`}
                      render={({ field: { value, onChange } }) => (
                        <Input
                          label={`${t('dialogs.groupForm.prize')} ${index + 1}`} id={`prizes.${index}.prize`} name={`prizes.${index}.prize`} value={value}
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                  {index > 0 && index === fields.length - 1 ? (
                    <div className="pl-2 pb-6">
                      <Tooltip title={t('dialogs.groupForm.removePrize')}>
                        <IconButton
                          onClick={() => handleRemovePrize(index)}
                        ><FaTrash /></IconButton>
                      </Tooltip>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="w-full md:w-1/2 px-4">
              <div className="mb-4">
                <Controller
                  control={control} name="callToActionImageUrl"
                  render={({ field }) => (
                    <ImageUpload
                      {...field}
                      width={420}
                      height={350}
                      label="dialogs.groupForm.callToActionImageUrl"
                      helperText="dialogs.groupForm.callToActionImageUrlHint"
                    />
                  )}
                />
              </div>
              <Controller
                control={control} name="callToActionLinkUrl"
                render={({ field: { value, onChange } }) => (
                  <Input
                    id="callToActionLinkUrl" name="callToActionLinkUrl" value={value || ''}
                    placeholder={t('dialogs.groupForm.callToActionLinkUrl')}
                    helperText={t('dialogs.groupForm.callToActionLinkUrlHint')}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                control={control} name="callToActionButtonText"
                render={({ field: { value, onChange } }) => (
                  <Input
                    id="callToActionButtonText" name="callToActionButtonText" value={value || ''}
                    placeholder={t('dialogs.groupForm.callToActionButtonText')}
                    onChange={onChange}
                  />
                )}
              />

              <div className="mt-8">
                <div className="flex items-center mb-4">
                  <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.publicGroupLeaderboard')}</span>
                  <Controller
                    control={control} name="publicLeaderboard.enabled"
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        id="publicLeaderboardEnabled"
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </div>
                <span className="block text-gray-400 text-sm">{t('ownerPage.quizForm.publicLeaderboardHint')}</span>
                {groupId ? (
                  <div className="pt-2 flex items-center gap-2">
                    <a
                      rel="noreferrer" target="_blank"
                      className="text-sm text-primary hover:underline"
                      href={`${config.quizURL}/quiz-group/${groupItem?.slug}/live-leaderboard`}
                    >
                      {`${config.quizURL}/quiz-group/${groupItem?.slug}/live-leaderboard`}
                    </a>
                    <Copy text={`${config.quizURL}/quiz-group/${groupItem?.slug}/live-leaderboard`} />
                  </div>
                ) : null}
              </div>
              {publicLeaderboardEnabledWatcher && (
                <div className="mt-4">
                  <Controller
                    control={control} name="publicLeaderboard.wallpaperImage"
                    render={({ field }) => (
                      <ImageUpload
                        {...field}
                        width={1920}
                        height={1080}
                        label="ownerPage.quizForm.wallpaperImage"
                        helperText={t('ownerPage.quizForm.publicLeaderboardWallpaperImageHint')}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.cancel')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            type="submit"
            loading={isLoading}
          >{t('common.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default GroupFormDialog;
