import { FC } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { getQuizAnalytics } from 'store/result/resultSelectors';
//
import ReactApexChart from "react-apexcharts";
// i18next
import { useTranslation } from 'react-i18next';
// utilities
import { getCountryName } from 'utilities/utilities';

const Charts:FC = () => {
  const { t } = useTranslation('common');

  const analytics = useAppSelector(getQuizAnalytics);

  const gender = analytics?.gender || [];
  const ageGroups = analytics?.ageGroups || [];
  const countries = analytics?.countries || [];

  const getOptions = (labels: string[]):any => ({
    chart: {
      width: 380,
      type: 'pie',
    },
    labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        },
        dataLabels: {
          enabled: false,
        }
      }
    }],
    legend: {
      labels: {
        colors: '#eee'
      }
    }
  })

  return (
    <div className="mb-8 flex flex-wrap items-center gap-4 flex-col lg:flex-row lg:justify-between">
      {gender.length ? (
        <div>
          <h3 className="mb-2 text-white font-bold text-center lg:text-left">{t('ownerPage.quizReportPage.gender')}</h3>
          <ReactApexChart options={getOptions(gender.map(g => g.value))} series={gender.map(g => g.count)} type="pie" width={380} />
        </div>
      ) : null}
      {gender.length ? (
        <div>
          <h3 className="mb-2 text-white font-bold text-center lg:text-left">Age</h3>
          <ReactApexChart options={getOptions(ageGroups.map(a => a.value))} series={ageGroups.map(a => a.count)} type="pie" width={380} />
        </div>
      ) : null}
      {gender.length ? (
        <div>
          <h3 className="mb-2 text-white font-bold text-center lg:text-left">Country</h3>
          <ReactApexChart options={getOptions(countries.map(c => getCountryName(c.value)))} series={countries.map(c => c.count)} type="pie" width={380} />
        </div>
      ) : null}
    </div>
  )
}

export default Charts;
