import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  entities: { [key:string]: any },
  hash: string | null;
};

const initialState:IState = {
  entities: {},
  hash: null
};

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateForm: (state, action:PayloadAction<{ quizId:string, quizForm:any }>) => {
      const { quizId, quizForm } = action.payload;
      state.entities[quizId] = JSON.stringify(quizForm);
    },
    deleteForm: (state, action:PayloadAction<string>) => {
      delete state.entities[action.payload];
    },
    setHash: (state, action:PayloadAction<string | null>) => {
      state.hash = action.payload;
    }
  }
});

export const { updateForm, deleteForm, setHash } = slice.actions;

export default slice.reducer;
