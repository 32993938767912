import IOption from 'models/Option';
import React, { ChangeEvent } from 'react';

type DefaultProps = {
  inputRef?: any;
  className?: string;
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  value: string | number | undefined;
  onBlur?: (event:React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (event:React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  min?: number;
}

type InputProps = DefaultProps & {
  type?: 'text' | 'email' | 'password' | 'number' | 'date' | 'time' | 'datetime-local';
  min?: number;
  max?: number;
};

export const Input = React.forwardRef<any, InputProps>(({
  className = 'mb-4', id, label, value = '', required, disabled, error, errorText, helperText, onChange, type, ...props
}, ref) => {

  const handleOnChange = (e:ChangeEvent<HTMLInputElement>) => {
    if(!onChange) return;
    if (type === 'number' && /\D/.test(e.target.value)) return;
    onChange(e);
  }

  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={id}
          className={`
            block text-white text-sm font-semibold mb-1
            ${disabled ? 'opacity-50' : ''}
            ${error ? 'text-red-500' : '' }
          `}
        >{label}{required ? ' *' : ''}</label>
      ) : null}
      <input
        {...props}
        ref={ref}
        className={`
          control
          ${error ? 'border-red-500' : ''}
        `}
        id={id}
        type={type}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
      />
      {error && errorText ? <span className="block text-red-500 text-sm py-1">{errorText}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{helperText}</span> : null}
    </div>
  )
});

type SelectProps = Omit<DefaultProps, 'onChange' | 'onBlur'> & {
  options: IOption[];
  onBlur?: (event:React.FocusEvent<HTMLSelectElement>) => void;
  onChange?: (event:React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Select = React.forwardRef<any, SelectProps>(({
  className = 'mb-4', id, label, value = '', options, required, disabled, error, errorText, helperText, ...props
}, ref) => {
  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={id}
          className={`
            block text-white text-sm font-semibold mb-1
            ${disabled ? 'opacity-50' : ''}
            ${error ? 'text-red-500' : '' }
          `}
        >{label}{required ? ' *' : ''}</label>
      ) : null}
      <select
        {...props}
        ref={ref}
        className={`
          control
          ${error ? 'border-red-500' : ''}
        `}
        id={id} value={value} disabled={disabled}
      >
        {options.map((option, index:number) => (
          <option key={`${props.name}-option-item-${index}`} value={option.value} disabled={option.disabled}>{option.label}</option>
        ))}
      </select>
      {error && errorText ? <span className="block text-red-500 text-sm py-1">{errorText}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{helperText}</span> : null}
    </div>
  )
});

type TextareaProps = Omit<DefaultProps, 'onChange' | 'onBlur'> & {
  rows?: number;
  onBlur?: (event:React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event:React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export const Textarea = React.forwardRef<any, TextareaProps>(({
  className = 'mb-4', id, label, value = '', rows = 5, required, disabled, error, errorText, helperText, ...props
}, ref) => {
  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={id}
          className={`
            block text-white text-sm font-semibold mb-1
            ${disabled ? 'opacity-50' : ''}
            ${error ? 'text-red-500' : '' }
          `}
        >{label}{required ? ' *' : ''}</label>
      ) : null}
      <textarea
        {...props}
        ref={ref}
        className={`
          control
          ${error ? 'border-red-500' : ''}
        `}
        id={id} value={value} rows={rows} disabled={disabled}
      ></textarea>
      {error && errorText ? <span className="block text-red-500 text-sm py-1">{errorText}</span> : null}
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{helperText}</span> : null}
    </div>
  )
})

type CheckboxProps = {
  inputRef?: any;
  className?: string;
  id: string;
  name: string;
  checked: boolean;
  label: string;
  onClick?: (event:React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event:React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event:React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  disabled?: boolean;
};

export const Checkbox = React.forwardRef<any, CheckboxProps>(({
  className = 'mb-4', id, label, helperText, disabled = false, ...props
}, ref) => {
  return (
    <div className={className}>
      <label className="flex items-center" htmlFor={id}>
        <input
          {...props}
          ref={ref}
          id={id}
          type="checkbox"
          disabled={disabled}
        />
        <span className="ml-2 text-sm text-white">{label}</span>
      </label>
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{helperText}</span> : null}
    </div>
  )
});

type RadioProps = {
  inputRef?: any;
  className?: string;
  id: string;
  name: string;
  checked: boolean;
  label: string;
  onClick?: (event:React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event:React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event:React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
};

export const Radio = React.forwardRef<any, RadioProps>(({
  className = 'mb-4', id, label, helperText, ...props
}, ref) => {
  return (
    <div className={className}>
      <label className="flex items-center" htmlFor={id}>
        <input
          {...props}
          ref={ref}
          id={id}
          type="radio"
        />
        <span className="ml-2 text-base text-white">{label}</span>
      </label>
      {helperText ? <span className="block text-gray-400 text-sm font-light leading-5 mt-2">{helperText}</span> : null}
    </div>
  )
});

type ToggleProps = {
  inputRef?: any;
  className?: string;
  label?: string;
  id: string;
  checked: boolean;
  onClick?: (event:React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event:React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event:React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export const Toggle:React.FC<ToggleProps> = ({
  // Props
  className = '', label, id, disabled = false, ...props
}) => {
  return (
    <label className={`toggle ${className}`} htmlFor={id}>
      <input
        {...props}
        className="toggle-control"
        id={id} type="checkbox"
        disabled={disabled}
      />
      <span className="toggle-icon"></span>
      {label ? (
        <span className="toggle-label">{label}</span>
      ) : null}
    </label>
  )
}

