import { FC, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Icons
import { FaTrash } from 'react-icons/fa';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Button, IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

const SectionBuckets:FC = () => {
  const { t } = useTranslation('common');
  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control, formState:{ errors, isSubmitted } } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'buckets'
  });

  const getBucketError = useCallback((index:number) => {
    if ( !errors.buckets ) return '';
    const error = errors.buckets[index];
    return error && error.title ? error.title.message : '';
  }, [errors])

  const handleAdd = () => {
    append({ ref: uuidv4(), title: '' });
  }

  const handleRemove = (index:number) => () => {
    remove(index);
  }

  if ( quiz && [QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(quiz.type) ) return null;
  return (
    <div react-scroll="buckets">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.buckets')}</h2>
        <span className="block text-gray-400 text-sm font-light leading-5 mt-2 mb-4">
          {t('ownerPage.quizForm.bucketsHint')}
        </span>
      </div>
      <div className="p-6">
        <div className="mb-4 text-right">
          <Button
            variant="outlined"
            onClick={handleAdd}
          >{t('ownerPage.quizForm.addBucket')}</Button>
        </div>
        {isSubmitted && !fields.length
          ? <span className="block text-red-500 text-sm py-1">{t('ownerPage.quizForm.bucketRequired')}</span>
          : null
        }
        {fields.map((field:any, index:number) => (
          <div
            key={`bucket-item-${field.id}`}
            className="flex gap-2"
          >
            <MultiLanguageTextField
              className="mb-4"
              name={`buckets[${index}].title`}
              label={t('ownerPage.quizForm.title')}
              required
              error={Boolean(getBucketError(index))}
              errorText={getBucketError(index)}
              customKey={`buckets[${field.ref}].title`}
            />
            <Tooltip title={t('ownerPage.quizForm.removeBucket')}>
              <IconButton
                onClick={handleRemove(index)}
                className="mt-6"
              ><FaTrash /></IconButton>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SectionBuckets;
