import IPhoneMockupImage from 'assets/images/iphone-x-mockup.png';

import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
// i18next
import { useTranslation } from 'react-i18next';
// Icons
import { FaDesktop, FaSync, FaTimes, FaEye } from 'react-icons/fa';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { setHash } from 'store/form/formSlice';
import { appActions } from 'store/app/appSlice';
import { selectQuizFormPreview } from 'store/app/appSelectors';
import { selectFormByQuizId, selectHash } from 'store/form/formSelectors';
import { RootState } from 'store';
// Components
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import QuizFrame from 'components/QuizFrame';
import PreviewDesktopDialog from 'dialogs/PreviewDesktop.dialog';
// Utilties
import { encodeBase64 } from 'utilities/utilities';
import config from 'config';
import QuizTypes from 'types/QuizTypes';

type Props = {
  preview: 'quiz' | 'liveLeaderboard';
}

const QuizPreview:FC<Props> = ({ preview }) => {
  const { quizId } = useParams<{ quizId:string }>();

  const { t } = useTranslation('common');

  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quizFormPreview = useAppSelector(selectQuizFormPreview);
  const quizForm:any = useAppSelector((state:RootState) => selectFormByQuizId(state, { quizId }));
  const hash:string | null = useAppSelector(selectHash);

  const timeout = useRef<any>(null);

  const [ dilaogPreviewDesktopDialog, setDialogPreviewDesktopOpen ] = useState(false)

  const togglePreviewDesktopDialog = () => setDialogPreviewDesktopOpen(!dilaogPreviewDesktopDialog);

  const handleClearTimeout = () => {
    if ( timeout.current ) clearTimeout(timeout.current);
  }

  useEffect(() => {
    return () => {
      handleClearTimeout();
    }
    // eslint-disable-next-line
  }, []);

  const handleReload = () => {
    handleClearTimeout();
    dispatch(setHash(null));
    timeout.current = setTimeout(() => {
      dispatch(setHash(encodeBase64(quizForm)))
    }, 1000);
  }

  const handleTogglePreview = () => {
    dispatch(appActions.toggleQuizFormPreview());
  }

  const handlePreview = () => {
    const endpoint = preview === 'quiz'
      ? `?u=${new Date().getTime()}&preview=true`
        : quizForm.type === QuizTypes.Poll
        ? 'live-results'
      : 'live-leaderboard';

    window.open(`${config.quizURL}/quiz/${quizForm.quizNameSlug}/${endpoint}`);
  };

  const previewLabel = preview === 'quiz'
    ? t('ownerPage.quizzesPage.previewQuiz')
      : quizForm?.type === QuizTypes.Poll
      ? t('ownerPage.quizzesPage.previewLiveResults')
    : t('ownerPage.quizzesPage.previewLiveLeaderboard');

  // if ( !quizForm ) return null;
  return (
    <div
      className={`
        laptop:flex
        bg-content z-40
        fixed laptop:relative
        top-0 laptop:top-auto
        left-0 laptop:left-auto
        w-full laptop:w-96
        h-full laptop:h-auto
        laptop:border-l flex-shrink-0
        ${quizFormPreview ? '' : 'hidden'}
        laptop:overflow-y-auto
      `}
      style={{
        borderColor: 'rgba(255,255,255,0.2)'
      }}
    >
      <div className="flex flex-col p-4 h-full w-full">
        <div className="flex items-center mb-2">
          <h3 className="text-white text-lg font-semibold text-center mr-4">{t('ownerPage.quizForm.preview')}</h3>
          <div className="flex-grow">
            <Tooltip className="ml-2" title={t('ownerPage.quizForm.reloadPreview')}>
              <IconButton
                onClick={handleReload}
              ><FaSync /></IconButton>
            </Tooltip>
            <Tooltip className="ml-2" title={t('ownerPage.quizForm.desktopPreview')}>
              <IconButton
                onClick={togglePreviewDesktopDialog}
              ><FaDesktop /></IconButton>
            </Tooltip>
            <Tooltip className="ml-2" title={previewLabel}>
              <IconButton
                onClick={handlePreview}
              ><FaEye /></IconButton>
            </Tooltip>
          </div>
          <Tooltip title={t('ownerPage.quizForm.close')}>
            <IconButton
              className="flex items-center justify-center laptop:hidden"
              onClick={handleTogglePreview}
            ><FaTimes /></IconButton>
          </Tooltip>
        </div>
        {preview === 'quiz' && (
          <span className="block text-white text-sm font-light mb-2">{t('ownerPage.quizForm.previewHint')}</span>
        )}
        <div className="flex-grow relative overflow-y-auto">
          <div className="absolute w-full h-full left-0 top-0">
            <div
              className="
                flex flex-col justify-center items-center
                relative my-4 mx-auto
              "
              style={{
                width: 350,
                height: 702
              }}
            >
              <span
                className="
                  absolute top-0 left-0 w-full h-full
                  bg-contain bg-no-repeat
                  pointer-events-none
                  z-20
                "
                style={{
                  backgroundImage: `url(${IPhoneMockupImage})`
                }}
              ></span>
              <div style={{ width: '87%', height: '36px', background: '#000' }} />
              {hash ? (
                <QuizFrame
                  style={{
                    width: '87%',
                    height: '89%'
                  }}
                  id="preview-mobile"
                  quizNameSlug={quizForm?.quizNameSlug || 'newQuiz'}
                  quizHash={hash}
                  preview={preview}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {dilaogPreviewDesktopDialog ? (
        <PreviewDesktopDialog
          open={dilaogPreviewDesktopDialog}
          onClose={togglePreviewDesktopDialog}
          quizNameSlug={quizForm?.quizNameSlug || 'newQuiz'}
          quizHash={hash}
          preview={preview}
        />
      ) : null}
    </div>
  )
}

export default QuizPreview;
