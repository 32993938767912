import React from 'react';

type ButtonProps = {
  className?: string;
  component?: React.ElementType;
  to?: string;
  variant?: 'link' | 'outlined' | 'contained';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  type?: 'button' | 'submit';
  loading?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: (event:React.MouseEvent<HTMLButtonElement>) => void;
};

export const Button:React.FC<ButtonProps> = ({
  className = '', component:Component = 'button', to = '',
  variant = 'link', size = 'md', type = 'button', loading = false, disabled = false,
  children, onClick = () => null
}) => {
  const props:any = {
    className: `${className} relative btn btn-${variant} btn-${size}`,
    type, disabled: loading || disabled, to, onClick
  };
  return (
    <Component {...props}>
      {loading ? (
        <svg className="inline-block animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      ) : null}
      {children}
    </Component>
  );
};

type IconButtonProps = {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  active?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick: (event?:React.MouseEvent<HTMLButtonElement>) => void;
}

export const IconButton:React.FC<IconButtonProps> = ({
  className, size = 'md', active = false, disabled = false, children,
  ...props
}) => {
  const classes = `
    rounded-full hover:bg-opacity-30 hover:bg-black
    ${className}
    ${disabled ? 'bg-gray-600 opacity-50 cursor-wait pointer-events-none' : ''}
    ${active ? 'text-primary' : 'text-white'}
    ${size === 'sm' ? 'w-7 h-7 text-base' : ''}
    ${size === 'md' ? 'w-9 h-9 text-base' : ''}
    ${size === 'lg' ? 'w-12 h-12 text-xl' : ''}
  `;
  return (
    <button
      {...props}
      className={`${classes}`}
      type="button"
      disabled={disabled}
    ><span className="flex justify-center">{children}</span></button>
  )
}
