import { createAsyncThunk } from '@reduxjs/toolkit';
// Utilities
import HttpClient from 'utilities/HttpClient';
// Async
import { setIsAuthenticated } from 'store/auth/authSlice';
import { getCurrentUser } from 'store/currentUser/currentUserAsync';
// Actions
import { setCurrentUser } from 'store/currentUser/currentUserSlice';

const _url:string = '/auth';
const _http:HttpClient = new HttpClient();

// Sign in
export const signIn = createAsyncThunk(
  'auth/Sign in',
  async (data:any, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/sign-in`, data);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(getCurrentUser(true));
  }
);

// Sign out
export const signOut = createAsyncThunk(
  'auth/Sign out',
  async (_, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.get(`${_url}/sign-out`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(setCurrentUser(null));
    dispatch(setIsAuthenticated(true));
  }
);

// Sign in as
export const singInAs = createAsyncThunk(
  'auth/Sign in as',
  async (accountId:string, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/impersonate/${accountId}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(getCurrentUser(true));
  }
);

// Sign out as
export const singOutAs = createAsyncThunk(
  'auth/Sign out as',
  async (_, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.post(`${_url}/quitImpersonation`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    dispatch(getCurrentUser(true));
  }
);
