import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Router
import { push } from 'connected-react-router';
// Models
import IQuiz from 'models/Quiz';
// Types
import QuizTypes from 'types/QuizTypes';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import TabItem from './TabItem';

type Props = {
  activeTab: string;
}

const Tabs:FC<Props> = ({
  // Props
  activeTab
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const handleClick = (nextTab:string) => () => {
    dispatch(push({ search: `tab=${nextTab}` }));
  }

  if (!quiz) return null;

  // eslint-disable-next-line
  const tabs = useMemo(() => {
    return [
      { value: 'quiz', label: t('ownerPage.quizForm.quiz') },
      { value: 'liveLeaderboard', label: quiz.type === QuizTypes.Poll ? t('ownerPage.quizForm.liveResults') : t('ownerPage.quizForm.liveLeaderboard'), hidden: [QuizTypes.Personality].includes(quiz.type) },
      { value: 'questions', label: t('ownerPage.quizForm.questions') },
      { value: 'postQuizPages', label: t('ownerPage.quizForm.postQuizPages'), hidden: [QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(quiz.type) },
      { value: 'correctAnswers', label: t('ownerPage.quizForm.correctAnswers'), hidden: [QuizTypes.Scoring, QuizTypes.Personality, QuizTypes.Poll].includes(quiz.type) },
    ];
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col gap-1 p-4">
      {tabs.map((tab:any) => {
        if (tab.hidden) return null;
        return (
          <TabItem
            key={`tab-item-${tab.value}`}
            tab={tab}
            onClick={handleClick(tab.value)}
            active={activeTab === tab.value}
          />
        )
      })}
    </div>
  )
}

export default Tabs;
