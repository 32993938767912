import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
// i18next
import { useTranslation } from 'react-i18next';
// Components
import { Input } from 'components/Controls';

type Props = {
  questionIndex: number;
}

const QuestionTextAnswer:FC<Props> = ({ questionIndex }) => {
  const { t } = useTranslation('common');

  const name = `questions[${questionIndex}].answers`;

  const { watch, setValue } = useFormContext();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: title } = e.target;
    const nextValue = title.trim() ? [{
      ref: uuid(),
      title,
      correct: true,
    }] : [];
    setValue(name, nextValue);
  }

  // eslint-disable-next-line
  const watched = watch(`${name}`); // don't delete
  const watchedTitle = watch(`${name}.0.title`);

  return (
    <Input
      id={`${name}.0.title`}
      name={`${name}.0.title`}
      className="mb-0 flex-grow"
      label={t('common.answer')}
      onChange={onChange}
      value={watchedTitle}
    />
  )
}

export default QuestionTextAnswer;
