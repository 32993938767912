import { FC, Fragment } from 'react';
import moment from 'moment';
// Icons
import { FaRegUser, FaClipboardList, FaRegClock } from 'react-icons/fa';

type Props = {
  report: any;
}

const ReportListItem:FC<Props> = ({
  // Props
  report
}) => {
  return (
    <Fragment>
      <tr>
      <td className="text-left title">
          {report.user.name}
          <span className="lg:hidden">&nbsp;{report.user.surname}</span>
          {report.user.username ? (
            <span className="lg:hidden">&nbsp;({report.user.username})</span>
          ) : null}
        </td>
        <td className="text-left hidden lg:table-cell">{report.user.surname}</td>
        <td className="text-left hidden lg:table-cell">{report.user.username || '-'}</td>
        <td className="text-left text">
          <FaRegUser className="icon" size="16px" />
          {report.user.email || '-'}
        </td>
        <td className="text-left text">
          <FaClipboardList className="icon" size="16px" />
          {report.score}
        </td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(report.createdAt).format('MMM Do YYYY')}
        </td>
      </tr>
    </Fragment>
  )
}

export default ReportListItem;
