import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
// Utilities
import { sortByName } from 'utilities/sort';

export const getNamespaceItems = (state:RootState) => state.namespace.items;
export const getNamespaceItem = (state:RootState) => state.namespace.item;
export const getFilter = (state:RootState) => state.namespace.filter;

export const getFilteredNamespaceItems = createSelector(
  getNamespaceItems, getFilter,
  (namespaceItems, filter) => {
    if ( !namespaceItems ) return null;
    const { search } = filter;
    const searchToLower = search.toLowerCase();
    const namespaces = namespaceItems.map((namespace) => ({
      ...namespace,
      createdByName: namespace.createdBy ? `${namespace.createdBy.name} ${namespace.createdBy.surname}` : ''
    }));
    if ( !searchToLower ) return namespaces;
    return namespaces.filter((namespaceItem) => namespaceItem.name.toLowerCase().includes(searchToLower));
  }
);

export const getNamespaceItemsForSelect = createSelector(
  getNamespaceItems, (items) => {
    if ( !items || items.length === 0 ) return [];
    return items.slice().sort(sortByName).map((item) => ({ value: item._id, label: item.name }));
  }
);
