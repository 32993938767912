import { FC } from 'react';
import { Switch, Redirect } from 'react-router-dom';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Redux
import { useSelector } from 'react-redux';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// Pages
import HomePage from 'pages/HomePage';
// import CreatePasswordPage from 'pages/CreatePassword.page';
import ChangePasswordPage from 'pages/ChangePassword.page';
import AdminPage from 'pages/AdminPage';
import OwnerPage from 'pages/OwnerPage';
// Components
import PrivateRoute from 'components/PrivateRoute';
import PublicRoute from 'components/PublicRoute';

const AppRouting:FC = () => {
  // State
  const currentUser:IUser | null = useSelector(selectCurrentUser);

  const redirectTo = currentUser
    ? currentUser.groups.includes(UserRoles.Admin)
      ? `/admin`
      : '/owner'
    : '/'
  ;

  return (
    <Switch>
      <PublicRoute exact path="/" component={HomePage} />
      <PublicRoute exact path="/create-password" component={ChangePasswordPage} />
      <PublicRoute exact path="/change-password" component={ChangePasswordPage} />
      <PrivateRoute roles={[UserRoles.Admin]} path="/admin" component={AdminPage} />
      <PrivateRoute roles={[UserRoles.Owner, UserRoles.Staff]} path="/owner" component={OwnerPage} />
      <Redirect from="/" to={redirectTo} />
    </Switch>
  )
}

export default AppRouting;
