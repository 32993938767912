import { FC, Fragment, useState } from 'react';
import moment from 'moment';
// Hooks
import useToggle from 'hooks/useToggle';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import QuizTypes from 'types/QuizTypes';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
import ReportDetailsDialog from './ReportDetails.dialog';
// Icons
import { FaTrash, FaRegUser, FaClipboardList, FaRegClock, FaEye, FaFlag } from 'react-icons/fa';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  report: any;
}

const ReportListItem:FC<Props> = ({ report }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const [isLoading, setIsLoading] = useState(false);
  const quiz = useAppSelector(selectQuiz);

  const { open, toggle } = useToggle();
  const { open:openConfirmation, toggle:toggleConfirmation } = useToggle();

  const handleDeleteResult = () => {
    setIsLoading(true);
    dispatch(ResultAsync.deleteResult(report.id)).unwrap()
      .finally(() => setIsLoading(false));
  }

  const toggleFlag = () => {
    setIsLoading(true);
    dispatch(ResultAsync.updateResult({ resultId: report.id, resultData: { flagged: !report.flagged } })).unwrap()
      .finally(() => setIsLoading(false));
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left break-all title lg:max-w-xs">
          {report.user.name}
          <span className="lg:hidden">&nbsp;{report.user.surname}</span>
          {report.user.username ? (
            <span className="lg:hidden">&nbsp;({report.user.username})</span>
          ) : null}
        </td>
        <td className="text-left max-w-xs break-all hidden lg:table-cell">{report.user.surname}</td>
        <td className="text-left max-w-xs break-all hidden lg:table-cell">{report.user.username || '-'}</td>

        <td className="text-left max-w-xs break-all text">
          <FaRegUser className="icon" size="16px" />
          {report.user.email || '-'}
        </td>
        {quiz?.type !== QuizTypes.Poll && (
          <td className="text-left text">
            <FaClipboardList className="icon" size="16px" />
            {report.bucket ? (
              report.bucket.title
            ) : (
              report.score
            )}
          </td>
        )}
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(report.createdAt).format('MMM Do YYYY')}
        </td>
        {report.id ? (
          <td className="text-right">
            <Tooltip title="Result details">
              <IconButton
                onClick={toggle}
              ><FaEye /></IconButton>
            </Tooltip>
            <Tooltip title={report.flagged ? 'Unflag' : 'Flag'}>
              <IconButton
                onClick={toggleFlag}
              ><FaFlag className={report.flagged ? 'text-red-400' : ''} /></IconButton>
            </Tooltip>
            <Tooltip className="ml-1" title={t('ownerPage.quizReportPage.deleteResult')}>
              <IconButton
                onClick={toggleConfirmation}
              ><FaTrash /></IconButton>
            </Tooltip>
          </td>
        ) : null}
      </tr>
      {open ? (
        <ReportDetailsDialog
          open={open}
          onClose={toggle}
          report={report}
        />
      ) : null}
      {openConfirmation ? (
        <ConfirmationDialog
          title={t('ownerPage.quizReportPage.deleteResult')}
          content={t('ownerPage.quizReportPage.confirmDeleteResult')}
          open={openConfirmation}
          loading={isLoading}
          onClose={toggleConfirmation}
          onConfirm={handleDeleteResult}
        />
      ) : null}
    </Fragment>
  )
}

export default ReportListItem;
