import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ConsentsAsync from './consentsAsync';
import IConsent from 'models/Consent';
import ConsentsStatuses from 'types/ConsentsStatuses';

interface IState {
  consents: IConsent[] | null;
  consent: IConsent | null;
  filter: {
    search: string;
  };
};

const initialState:IState = {
  consents: null,
  consent: null,
  filter: {
    search: ''
  }
};

const slice = createSlice({
  name: 'consents',
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<Record<string, string>>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(ConsentsAsync.getConsents.pending, (state) => {
      state.consents = null;
    })
    .addCase(ConsentsAsync.getConsents.fulfilled, (state, action:PayloadAction<IConsent[]>) => {
      state.consents = action.payload;
    })
    .addCase(ConsentsAsync.getConsent.pending, (state) => {
      state.consent = null;
    })
    .addCase(ConsentsAsync.getConsent.fulfilled, (state, action:PayloadAction<IConsent>) => {
      state.consent = action.payload;
    })
    .addCase(ConsentsAsync.createConsent.fulfilled, (state, action:PayloadAction<IConsent>) => {
      if (state.consents) {
        state.consents.push(action.payload);
      }
    })
    .addCase(ConsentsAsync.updateConsent.fulfilled, (state, action:PayloadAction<IConsent>) => {
      if (state.consents) {
        state.consents = state.consents.map(consent => consent._id === action.payload._id ? action.payload : consent);
      }
    })
    .addCase(ConsentsAsync.deleteConsent.fulfilled, (state, action) => {
      const { arg:consentId } = action.meta;
      if (state.consents) {
        state.consents = state.consents.filter(consent => consent._id !== consentId);
      }
    })
    .addCase(ConsentsAsync.publishConsent.fulfilled, (state, action) => {
      const { arg:consentId } = action.meta;
      if (state.consents) {
        state.consents = state.consents.map(consent => consent._id === consentId ? { ...consent, status: ConsentsStatuses.published } : consent);
      }
    })
    .addCase(ConsentsAsync.archiveConsent.fulfilled, (state, action) => {
      const { arg:consentId } = action.meta;
      if (state.consents) {
        state.consents = state.consents.map(consent => consent._id === consentId ? { ...consent, status: ConsentsStatuses.archived } : consent);
      }
    })
  }
});

export const consentsActions = slice.actions;

export default slice.reducer;
