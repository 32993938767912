import { FC, Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Checkbox, Input } from 'components/Controls';
import ImageUpload from 'components/ImageUpload';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

const LandingPage:FC = () => {
  const { t } = useTranslation('common');
  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control, watch } = useFormContext();

  const quizNameVisible = watch('quizNameVisible');

  const isQuizPersonality = useMemo(() => {
    if ( !quiz ) return false;
    return quiz && quiz.type === QuizTypes.Personality;
  }, [quiz]);

  return (
    <Fragment>
      <span className="divider" />
      <div className="my-4">
        <span className="block text-white text-base font-semibold">{t('ownerPage.quizForm.homeScreen')}</span>
        {isQuizPersonality ? (
          <span className="block text-gray-400 text-sm font-light leading-5">
            {t('ownerPage.quizForm.homeScreenHint')}
          </span>
        ) : null}
      </div>
      <div className="flex flex-wrap -mx-2">
        {isQuizPersonality ? (
          <div className="w-full laptop:w-1/2 px-2">
            <Controller
              control={control} name="landingPage.callToAction.image"
              render={({ field }) => (
                <ImageUpload
                  {...field}
                  width={420}
                  height={350}
                  label={t('ownerPage.quizForm.image')}
                  helperText="ownerPage.quizForm.proceedImageHint"
                />
              )}
            />
          </div>
        ) : null}
        <div className={`w-full ${isQuizPersonality ? 'laptop:w-1/2' : ''} px-2`}>
          {isQuizPersonality ? (
            <Fragment>
              <Controller
                control={control} name="landingPage.callToAction.url"
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('ownerPage.quizForm.url')} id="landingPage.callToAction.url"
                  />
                )}
              />
              <MultiLanguageTextField
                multiline
                name="landingPage.callToAction.text"
                label={t('ownerPage.quizForm.text')}
                rows={4}
              />
            </Fragment>
          ) : null}
          <MultiLanguageTextField
            name="landingPage.callToAction.buttonTitle"
            label={t('ownerPage.quizForm.buttonTitle')}
            className="mb-4"
          />
          <Controller
            control={control} name="quizNameVisible"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('ownerPage.quizForm.quizNameVisible')} id="quizNameVisible" name="quizNameVisible" checked={value}
                onChange={onChange}
              />
            )}
          />
          <MultiLanguageTextField
            disabled={!quizNameVisible}
            name="displayName"
            label={t('ownerPage.quizForm.displayName')}
            helperText={t('ownerPage.quizForm.displayNameHint')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default LandingPage;
