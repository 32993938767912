import { FC } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// 
import SocialShare from './SocialShare';
import Waiver from './Waiver';
import PostQuizQuestion from './PostQuizQuestion';
import PreQuizQuestion from './PreQuizQuestion';

const SectionOther:FC = () => {
  const { t } = useTranslation('common');

  return (
    <div react-scroll="other">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.other')}</h2>
      </div>
      <div className="p-6">
        <SocialShare />
        <Waiver />
        <PreQuizQuestion />
        <PostQuizQuestion />
      </div>
    </div>
  )
}

export default SectionOther;
