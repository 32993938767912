import { FC, Fragment } from 'react';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
// Components
import { Button } from 'components/Buttons';
import { Select } from 'components/Controls';
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import Icon from 'components/Icon';
// Services
import QuestionService from 'services/question.service';
// 
import Answer from './Answer';
import QuestionTypes from 'types/QuestionTypes';
import { useTranslation } from 'react-i18next';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

type Props = {
  name: string;
  onRemove: () => void;
  idx: number;
};

const Question:FC<Props> = ({
  // Props
  name, onRemove, idx
}) => {
  const { t } = useTranslation('common');

  const { control, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.answers`
  });

  const handleAdd = () => {
    append(QuestionService.initialAnswer([]));
  }

  const handleRemove = (index:number) => () => {
    remove(index);
  }

  const watchedQuestionRef = watch(`${name}.ref`);
  const questionKey = `${name.split('.').slice(0, -1).join('.')}.${watchedQuestionRef}.title`

  const watchedQuestionType = watch(`${name}.type`);

  return (
    <Fragment>
      <div className="flex items-center gap-4 mb-4">
        <h2 className="flex-grow text-white text-xl leading-4 font-bold">{t('common.question')} {idx + 1}</h2>
        <Tooltip title="Remove question">
          <IconButton
            onClick={onRemove}
          ><Icon icon="delete" /></IconButton>
        </Tooltip>
      </div>

      <MultiLanguageTextField
        name={`${name}.title`}
        customKey={questionKey}
        label={t('common.question')}
        className="mb-4"
      />

      <Controller
        control={control} name={`${name}.type`}
        render={({ field }) => (
          <Select
            {...field}
            label={t('common.type')} id={`${name}.type`}
            options={QuestionService.questionTypeOptions()}
          />
        )}
      />

      {watchedQuestionType !== QuestionTypes.ShortText ? (
        <Fragment>
          <span className="divider"></span>
          <h2 className="text-white text-xl font-bold leading-6 my-4">{t('common.answers')}</h2>

          {fields.map((field:any, index:number) => (
            <Answer
              key={`${name}-answer-item-${field.id}`}
              name={`${name}.answers.${index}`}
              onRemove={handleRemove(index)}
              idx={index}
              questionRef={watchedQuestionRef}
            />
          ))}

          <div className="text-right mb-4">
            <Button
              onClick={handleAdd}
              variant="contained"
            >{t('common.addAnswer')}</Button>
          </div>
        </Fragment>
      ) : null}

      <span className="divider my-4" />
    </Fragment>
  )
}

export default Question;
