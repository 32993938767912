import { FC, Fragment } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import QuizTypes from 'types/QuizTypes';
// Components
import QuizPreview from '../TabContentQuiz/QuizPreview';
import PublicLeaderboard from './PublicLeaderboard';
import PublicLeaderboardQrCode from './PublicLeaderboardQrCode';
// i18next
import { useTranslation } from 'react-i18next';

const TabContentLiveLeaderboard:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  if (!quiz) return null;
  return (
    <Fragment>
      <div className="w-full overflow-y-hidden tablet:overflow-y-auto overflow-x-hidden" react-action="scroll">
        <div className="pt-6 px-6">
          <h2 className="text-white text-2xl leading-6 font-bold">
            {quiz.type === QuizTypes.Poll ? t('ownerPage.quizForm.liveResults') : t('ownerPage.quizForm.liveLeaderboard')}
          </h2>
        </div>
        <div className="px-6 pb-6">
          <PublicLeaderboard />
          <PublicLeaderboardQrCode />
        </div>
      </div>
      <QuizPreview preview="liveLeaderboard" />
    </Fragment>
  )
}

export default TabContentLiveLeaderboard;
