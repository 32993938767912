import { FC } from 'react';
// Store
import { useAppSelector } from 'hooks/useStore';
import { getFilteredGroupItems } from 'store/group/groupSelectors';
import { IGroup } from 'models/Group';
// Components
import { Loader, Message } from 'components/Utilities';
// 
import GroupsListItem from './GroupsListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedGroups: IGroup[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const GroupsList:FC<Props> = ({ sortedGroups, onRequestSort, getClassNamesFor }) => {
  const { t } = useTranslation('common');
  
  const groups = useAppSelector(getFilteredGroupItems);

  if ( !groups ) return <Loader />;
  if ( groups.length === 0 ) return <Message text={t('ownerPage.groupsPage.noGroups')} />;
  return (
    <table>
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('name')}`}
            onClick={() => onRequestSort('name')}
          >{t('common.name')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdByName')}`}
            onClick={() => onRequestSort('createdByName')}
          >{t('common.createdBy')}</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedGroups.map((group) => (
          <GroupsListItem
            key={`group-item-${group._id}`}
            group={group}
          />
        ))}
      </tbody>
    </table>
  )
}

export default GroupsList;
