import { FC, Fragment } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IQuiz from 'models/Quiz';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selectors
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Component
import QuizFormQuestions from 'components/QuizFormQuestions';

const PreQuizQuestion:FC = () => {
  const { t } = useTranslation('common');

  // State
  const quiz:IQuiz | null = useAppSelector(selectQuiz);

  if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <Fragment>
      <div className="flex items-center mt-2 mb-4">
        <span className="block text-white text-base font-semibold mr-3">{t('ownerPage.quizForm.preQuizQuestions')}</span>
      </div>
      <span className="block text-gray-400 text-sm font-light leading-5 mb-4">
        {t('ownerPage.quizForm.preQuizQuestionsHint')}
      </span>

      <QuizFormQuestions name="preQuizPages[0].questions" />
    </Fragment>
  )
}

export default PreQuizQuestion;
