import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// // Types
// import QuizTypes from 'types/QuizTypes';
// // Models
// import IQuiz from 'models/Quiz';
// // Redux
// import { useAppSelector } from 'hooks/useStore';
// // Selectors
// import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { Checkbox } from 'components/Controls';

const ShowBrandLogoOnWaiver:FC = () => {
  const { t } = useTranslation('common');

  // State
  // const quiz:IQuiz | null = useAppSelector(selectQuiz);

  const { control } = useFormContext();

  // if ( quiz && quiz.type === QuizTypes.Personality ) return null;
  return (
    <Controller
      control={control} name="showBrandLogoOnWaiver"
      render={({ field: { onChange, value } }) => (
        <Checkbox
          label={t('ownerPage.quizForm.showBrandLogoOnWaiver')} id="showBrandLogoOnWaiver" name="showBrandLogoOnWaiver" checked={value}
          onChange={onChange}
        />
      )}
    />
  )
}

export default ShowBrandLogoOnWaiver;
