import { FC, Fragment } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
// Components
import { Button } from 'components/Buttons';
// Services
import QuestionService from 'services/question.service';
// 
import Question from './Question';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
}

const QuizFormQuestions:FC<Props> = ({
  // Props
  name
}) => {
  const { t } = useTranslation('common');

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  const handleAdd = () => {
    append(QuestionService.initialQuestion());
  }

  const handleRemove = (index:number) => () => {
    remove(index);
  }

  return (
    <Fragment>
      <div className="text-right mb-4">
        <Button
          onClick={handleAdd}
          variant="contained"
        >{t('common.addQuestion')}</Button>
      </div>
      {fields.map((field:any, index:number) => (
        <Question
          key={`question-item-${field.id}`}
          name={`${name}.${index}`}
          onRemove={handleRemove(index)}
          idx={index}
        />
      ))}
    </Fragment>
  )
}

export default QuizFormQuestions;
