import 'react-datepicker/dist/react-datepicker.css';

import { Fragment, forwardRef, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
// Icons
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// Components
import { Select } from 'components/Controls';
import { IconButton } from 'components/Buttons';
// Service
import DateTimeService from 'services/datetime.service';

type Props = {
  label: string;
  name: string;
  id: string;
  value: string;
  onChange: any;
  onBlur?: any;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
};

const DatePicker = forwardRef<any, Props>(({
  // Props
  label, name, id, value, onChange, onBlur, required = false, error, errorText, helperText
}, ref) => {
  label = required ? `${label} *` : label;

  const monthsOptions = useMemo(() => {
    return DateTimeService.generateMonthsOptions();
  }, []);

  const yearsOptions = useMemo(() => {
    return DateTimeService.generateYearsOptions();
  }, []);

  const datePickerProps:any = {
    className: `control ${error ? 'border-red-500' : ''}`,
    id,
    showPopperArrow: false,
    selected: value,
    name,
    onChange,
    required,
    timeInputLabel: 'Time:',
    dateFormat: 'MM/dd/yyyy h:mm aa',
    showTimeInput: true,
    adjustDateOnChange: true
  };
  if ( onBlur ) datePickerProps['onBlur'] = onBlur;

  return (
    <Fragment>
      <label
        htmlFor={id}
        className={`
          block text-white text-sm font-semibold mb-2
          ${error ? 'text-red-500' : '' }
        `}
      >{label}</label>
      <ReactDatePicker
        ref={ref}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="flex items-center gap-1">
            <IconButton
              disabled={prevMonthButtonDisabled}
              onClick={decreaseMonth}
              size="sm"
            ><FaChevronLeft /></IconButton>

            <Select
              className="mb-0"
              value={DateTimeService.getYear(date)} name={`${name}YearPicker`} id={`${name}YearPicker`}
              onChange={({ target: { value } }) => {
                changeYear(Number(value))

                console.log(value);
              }}
              options={yearsOptions}
            />

            <Select
              className="mb-0"
              value={monthsOptions[DateTimeService.getMonth(date)].value} name={`${name}MonthPicker`} id={`${name}MonthPicker`}
              onChange={({ target: { value } }) => changeMonth(Number(value))}
              options={monthsOptions}
            />

            <IconButton
              disabled={nextMonthButtonDisabled}
              onClick={increaseMonth}
              size="sm"
            ><FaChevronRight /></IconButton>
          </div>
        )}
        {...datePickerProps}
      />
      {error ? (
        <span className="block text-red-500 text-sm py-1 px-4">{errorText}</span>
      ) : null}
    </Fragment>
  )
})

export default DatePicker;
