import React from 'react';
import { Route, Redirect, RouteProps } from "react-router-dom";
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';

type Props = {
  exact?: boolean;
  path: string;
  component: any;
}

const PublicRoute:React.FC<Props> = ({
  // Props
  component:Component, ...rest
}) => {
  // State
  const currentUser:IUser | null = useSelector(selectCurrentUser);
  const redirectTo = currentUser
    ? currentUser.groups.includes(UserRoles.Admin)
      ? `/admin/users`
      : '/owner/quizzes'
    : '/'
  ;

  return (
    <Route {...rest} render={(props:RouteProps) => (
      !currentUser
      ? <Component {...props} />
      : <Redirect to={redirectTo} />
    )}/>
  )
};

export default PublicRoute;
