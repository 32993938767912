import React, { FC, Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Async
import { getQuiz, assignSpotPrizeWinner } from 'store/quiz/quizzesAsync';
// Selectors
import { selectQuiz, selectLoading } from 'store/quiz/quizzesSelectors';
import { getReportItems } from 'store/result/resultSelectors';
// Types
import QuizTypes from 'types/QuizTypes';
// Icons
import { FaCrown } from 'react-icons/fa';
// Components
import { Button } from 'components/Buttons';
import { Loader } from 'components/Utilities';
import { Tooltip } from 'components/Tooltip';
// i18next
import { useTranslation } from 'react-i18next';

const ReportSpotPrizeAssign:FC = () => {
  const { t } = useTranslation('common');
  const { quizId } = useParams<{ quizId:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quiz:any = useAppSelector(selectQuiz);
  const loading:boolean = useAppSelector(selectLoading);
  const reportItems = useAppSelector(getReportItems);

  useEffect(() => {
    dispatch(getQuiz(quizId));
    // return () => {
    //   dispatch(QuizActions.setInitialField('item'));
    // }
    // eslint-disable-next-line
  }, []);

  const handleAssignSpotPrizeWinner = () => dispatch(assignSpotPrizeWinner(quizId));

  const buttonIsDisabled = (quiz && (!quiz.spotPrizes || quiz.spotPrizes.length === 0)) || (reportItems && reportItems.length === 0);

  if ( !quiz ) return <Loader />;
  if ( quiz.type === QuizTypes.Poll ) return null;
  return (
    <Fragment>
      <div className="flex items-center py-2">
        <div className="pr-4">
          <Button
            variant="contained"
            loading={loading}
            disabled={buttonIsDisabled}
            onClick={handleAssignSpotPrizeWinner}
          >{t('ownerPage.quizReportPage.assignRandomPrizeWinner')}</Button>
        </div>
        <div className="text-white">
          {quiz.spotPrizeWinners && quiz.spotPrizeWinners.length > 0 ? (
            quiz.spotPrizeWinners.map((winner:any) => (
              <div key={`winner-item-${winner._id}`} className="flex items-center border-l border-gray-50">
                <span
                  className="text-xl px-4 text-primary"
                ><Tooltip title={t('ownerPage.quizReportPage.winner')}><FaCrown /></Tooltip></span>
                <div className="flex flex-col text-white text-opacity-80 pr-4">
                  <span className="text-sm font-light">{winner.email}</span>
                  <span className="font-bold">{winner.name}</span>
                </div>
              </div>
            ))
          ) : null}
        </div>
      </div>
      <span className="block text-gray-400 text-sm font-light leading-5 mb-4">{t('ownerPage.quizReportPage.whyDisabledRandomPrizes')}</span>
    </Fragment>
  )
}

export default ReportSpotPrizeAssign;
