import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NamespaceAsync } from './namespaceAsync';
import INamespace from 'models/Namespace';

interface IState {
  items: INamespace[] | null;
  item: INamespace | null;
  filter: {
    search: string;
  },
};

const initialState:IState = {
  items: null,
  item: null,
  filter: {
    search: ''
  },
};

const slice = createSlice({
  name: 'namespace',
  initialState,
  reducers: {
    setFilter: (state:IState, action:PayloadAction<Record<string, string>>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(NamespaceAsync.fetchNamespacesList.pending, (state:IState) => {
        state.items = null;
      })
      .addCase(NamespaceAsync.fetchNamespacesList.fulfilled, (state:IState, action:PayloadAction<INamespace[]>) => {
        state.items = action.payload;
      })
      .addCase(NamespaceAsync.fetchNamespaceById.pending, (state:IState) => {
        state.item = null;
      })
      .addCase(NamespaceAsync.fetchNamespaceById.fulfilled, (state:IState, action:PayloadAction<INamespace>) => {
        state.item = action.payload;
      })
      .addCase(NamespaceAsync.createNamespace.fulfilled, (state:IState, action:PayloadAction<INamespace>) => {
        if ( state.items ){
          state.items.push(action.payload);
        }
      })
      .addCase(NamespaceAsync.updateNamespace.fulfilled, (state:IState, action:PayloadAction<INamespace>) => {
        if ( state.items ){
          state.items = state.items.map(item => item._id === action.payload._id ? action.payload : item)
        }
      })
      .addCase(NamespaceAsync.deleteNamespace.fulfilled, (state:IState, action) => {
        const { arg:namespaceId } = action.meta;
        if ( state.items ){
          state.items = state.items.filter((item) => item._id !== namespaceId)
        }
      })
  }
});

export const namespaceActions = slice.actions;

export default slice.reducer;
