import React from 'react';
// Redux
import Statuses from 'types/Statuses';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { getNotifications } from 'store/app/appSelectors';
// Icons
import { FaTimes } from 'react-icons/fa';

type Props = {}

const dismissTime = 5000;

export const Notifications:React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  
  const notifications = useAppSelector(getNotifications);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if ( notifications.length > 0 ) dispatch(appActions.removeNotification(notifications[0].key));
    }, dismissTime);
    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [notifications]);

  const handleRemoveNotification = (key:string) => dispatch(appActions.removeNotification(key));

  if ( notifications.length === 0 ) return null;
  return (
    <div className="fixed bottom-0 right-0 m-4" style={{ zIndex: 99 }}>
      {notifications.map((notification:any) => (
        <div
          key={`notification-item-${notification.key}`}
          className={`
            ${ notification.status === Statuses.Success ? 'bg-green-500' : '' }
            ${ notification.status === Statuses.Error ? 'bg-red-500' : '' }
            relative rounded py-4 pl-4 pr-12 mb-4
          `}
        >
          <span className="text-white font-semibold">{notification.message}</span>
          <button
            className={`
              absolute top-0 right-0
              text-white p-2 rounded-full mt-1 mr-1
              ${ notification.status === Statuses.Success ? 'hover:bg-green-600' : '' }
              ${ notification.status === Statuses.Error ? 'hover:bg-red-600' : '' }
            `}
            type="button"
            onClick={() => handleRemoveNotification(notification.key)}
          ><FaTimes /></button>
        </div>
      ))}
    </div>
  )
}
