import { createAsyncThunk } from '@reduxjs/toolkit';
// Utilities
import HttpClient from 'utilities/HttpClient';

const _http:HttpClient = new HttpClient();

// Upload image
export const uploadImage = createAsyncThunk(
  'media/Upload image',
  async (blob:Blob, { rejectWithValue, fulfillWithValue }) => {
    const uploadResponse:Response = await _http.get(`/image/upload?contentType=${blob.type}`);
    if ( !uploadResponse.ok ) return rejectWithValue((await uploadResponse.json()) as any);
    const { signedRequest, url, contentType } = await uploadResponse.json();

    // Signed response
    const signedResponse:Response = await fetch(signedRequest, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType
      },
      body: blob
    });
    if ( !signedResponse.ok ) return rejectWithValue((await signedResponse.json()) as any);

    if ( blob.type.includes('gif') ){
      return fulfillWithValue(url);
    } else {
      // Compress request
      const compressResponse:Response = await fetch(`https://app.quizwizards.com/api/image/compress/${url}`, {
        method: 'POST'
      });
      if ( !compressResponse.ok ) return rejectWithValue((await compressResponse.json()) as any);
      const nextBlob = await compressResponse.blob();

      // Re-upload
      const reUploadResponse:Response = await _http.get(`/image/upload?contentType=${blob.type}`);
      if ( !reUploadResponse.ok ) return rejectWithValue((await reUploadResponse.json()) as any);
      const data = await reUploadResponse.json();

      // Re-signed
      const reSignedResponse:Response = await fetch(data.signedRequest, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType
        },
        body: nextBlob
      });
      if ( !reSignedResponse.ok ) return rejectWithValue((await reSignedResponse.json()) as any);
      return fulfillWithValue(data.url);
    }
  }
);

export const uploadVideo = createAsyncThunk(
  'media/Upload video',
  async (blob:Blob, { rejectWithValue, fulfillWithValue }) => {
    const response:Response = await _http.get(`/video/upload?contentType=${blob.type}`);
    if ( !response.ok ) return rejectWithValue((await response.json()) as any);
    const { signedRequest, url, contentType } = await response.json();

    // Signed response
    const signedResponse:Response = await fetch(signedRequest, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType
      },
      body: blob
    });
    if ( !signedResponse.ok ) return rejectWithValue((await signedResponse.json()) as any);

    return fulfillWithValue(url);
  }
)

// export const uploadImage = createAsyncThunk(
//   'media/Upload image',
//   async (_, { rejectWithValue }) => {
//     const response:Response = await _http.get('/image/upload');
//     if ( !response.ok ) return rejectWithValue((await response.json()) as any);
//     return response.json();
//   }
// );

// // Upload video
// export const uploadVideo = createAsyncThunk(
//   'media/Upload video',
//   async (_, { rejectWithValue }) => {
//     const response:Response = await _http.get('/video/upload');
//     if ( !response.ok ) return rejectWithValue((await response.json()) as any);
//     return response.json();
//   }
// );

// // Compress image
// export const compressImage = createAsyncThunk(
//   'media/Compress image',
//   async (url:string, { rejectWithValue }) => {
//     const response:Response = await fetch(`https://app.quizwizards.com/api/image/compress/${url}`, {
//       method: 'POST'
//     });
//     if ( !response.ok ) return rejectWithValue((await response.json()) as any);
//     return response.json();
//   }
// );

// // Signed request
// export const signedRequest = createAsyncThunk(
//   'media/Signed request',
//   async ({ url, body, headers = { 'Content-Type': 'image/png' } }:{ url:string, body:any, headers?:any }, { rejectWithValue }) => {
//     const response:Response = await fetch(url, {
//       method: 'PUT',
//       headers,
//       body
//     });
//     if ( !response.ok ) return rejectWithValue((await response.json()) as any);
//     return response.json();
//   }
// );

