import { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import { selectQuizFormSidebar } from 'store/app/appSelectors';
// Components
import Icon from 'components/Icon';
import { IconButton } from 'components/Buttons';
import { Loader } from 'components/Utilities';
import Tabs from './Tabs';
import QuizMenu from './QuizMenu';
import QuestionsMenu from './QuestionsMenu';
import PostQuizPagesMenu from './PostQuizPagesMenu';
import CorrectAnswersMenu from './CorrectAnswersMenu';
// Icons
import { FaEye, FaTimes } from 'react-icons/fa';

const QuizFormSidebar:FC = () => {
  const { search } = useLocation<{ search:string }>();
  const dispatch = useAppDispatch();

  const params = new URLSearchParams(search);
  const activeTab = params.get('tab') || 'quiz';

  const quizFormSidebar = useAppSelector(selectQuizFormSidebar);
  const quiz = useAppSelector(selectQuiz);

  const isQuizMenuActive = !activeTab || activeTab === 'quiz';
  const isQuestionsMenuActive = activeTab === 'questions';
  const isPostQuizPagesMenuActive = activeTab === 'postQuizPages';
  const isCorrectAnswersMenuActive = activeTab === 'correctAnswers';

  const handelToggleSidebar = () => {
    dispatch(appActions.toggleQuizFormSidebar());
  }

  const handleTogglePreview = () => {
    dispatch(appActions.toggleQuizFormPreview())
  }

  if ( !quiz ) return <Loader />;
  return (
    <Fragment>
      {quizFormSidebar ? (
        <div
          className="
            fixed top-0 left-0 w-full h-full z-40
            tablet:hidden
          "
          style={{ backgroundColor: 'rgba(0,0,0,0.68)'}}
          onClick={handelToggleSidebar}
        />
      ) : null}
      <div className="
        flex tablet:hidden
        items-center p-4
      ">
        <IconButton
          onClick={handelToggleSidebar}
          className="mr-2"
        ><Icon icon="menu" /></IconButton>
        <span className="flex-grow"></span>
        <IconButton
          className="flex items-center justify-center"
          onClick={handleTogglePreview}
        ><FaEye /></IconButton>
      </div>
      <span className="divider my-0 tablet:hidden"></span>
      <div className={`
        max-h-full
        w-full tablet:w-1/4 laptop:w-1/5
        bg-content
        fixed tablet:relative
        left-0 tablet:left-auto
        bottom-0 tablet:bottom-auto
        transform tablet:translate-y-0
        tablet:flex-shrink-0 
        tablet:border-divider
        tablet:border-r
        overflow-y-auto
        z-50 tablet:z-10
        ${quizFormSidebar ? 'translate-y-0' : 'translate-y-full'}
        flex flex-col
      `}>
        <div className="tablet:hidden">
          <div className="text-right p-4">
            <IconButton
              onClick={handelToggleSidebar}
            ><FaTimes /></IconButton>
          </div>
          <div className="divider my-0" />
        </div>
        <Tabs activeTab={activeTab} />
        <div className="divider my-0" />
        {isQuizMenuActive ? <QuizMenu /> : null}
        {isQuestionsMenuActive ? <QuestionsMenu /> : null}
        {isPostQuizPagesMenuActive ? <PostQuizPagesMenu /> : null}
        {isCorrectAnswersMenuActive ? <CorrectAnswersMenu /> : null}
      </div>
    </Fragment>
  )
}

export default QuizFormSidebar;
