import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Components
import { Button } from 'components/Buttons';
// i18next
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  currentUser: IUser;
}

const PrivateContent:FC<Props> = ({
  currentUser
}) => {
  const { t } = useTranslation('common');
  const roles:Array<string> = Object.values(UserRoles).map((role:UserRoles) => role);
  return (
    <Fragment>
      <h1
        className="text-white text-4xl font-semibold mb-8"
        style={{
          textShadow: '6px 0 8px rgba(0,0,0,0.38)'
        }}
      >{t('welcome', { name: currentUser.name })}</h1>
      <h3
        className="text-white text-2xl mb-8"
        style={{
          textShadow: '6px 0 8px rgba(0,0,0,0.38)'
        }}
      >
        <Trans
          components={{ span: <span className="font-semibold" /> }}
          tOptions={{ role: currentUser.groups.join(', ') || 'client', email: currentUser.email }}
          i18nKey={t('homePage.signInAs')}
        />
      </h3>
      {currentUser.groups.some((group:string) => roles.includes(group)) ? (
        <Fragment>
          <div className="mb-4">
            <Button
              className="w-9/12"
              component={Link} to="/owner/quizzes"
              variant="contained"
              size="xl"
            >{t('homePage.manageQuizzes')}</Button>
          </div>
          <div>
            <Button
              className="w-9/12"
              component={Link} to="/owner/groups"
              variant="outlined"
              size="xl"
            >{t('homePage.manageQuizGroups')}</Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  )
}

export default PrivateContent;
