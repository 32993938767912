interface IConfig {
  [key:string]: {
    production: boolean;
    apiURL: string;
    quizURL: string;
    ipInfoUrl: string;
  }
}

const config:IConfig = {
  'development': {
    production: false,
    apiURL: 'http://api.quizwizards.test/api',
    quizURL: 'http://app.quizwizards.test',
    ipInfoUrl: 'https://ipinfo.io/json'
  },
  'staging': {
    production: false,
    apiURL: 'https://api-staging.quizwizards.com/api',
    quizURL: 'https://app-staging.quizwizards.com',
    ipInfoUrl: 'https://ipinfo.io/json'
  },
  'production': {
    production: true,
    apiURL: 'https://api.quizwizards.com/api',
    quizURL: 'https://app.quizwizards.com',
    ipInfoUrl: 'https://ipinfo.io/json'
  }
}

const stage:string = process.env.REACT_APP_STAGE || 'development';

export default config[stage];