import { Buffer } from "buffer";
import { countries } from "./countries";

export const decodeBase64 = (hash:string) => {
  const hasToString = Buffer.from(hash, 'base64').toString();
  return JSON.parse(hasToString);
}

export const encodeBase64 = (data:any) => {
  const dataToString = JSON.stringify(data);
  return Buffer.from(dataToString).toString('base64');
}

export const textFromCamelToNormalCase = (text:string) => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (l:string) => l.toUpperCase());
}

export const getCountryName = (code: string) => {
  if (!code) return 'Unknown';
  const c = code.toLowerCase();
  const countryName = countries[c];
  if (!countryName) return code.toUpperCase();
  return countryName;
}
