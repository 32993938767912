import { FC, useState, useMemo, useCallback, MouseEvent } from 'react';
// Mui
import {
  Menu as MuiMenu, MenuProps,
  MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps, makeStyles
} from '@material-ui/core';

type MenuItemProps = Omit<MuiMenuItemProps, 'button'> & {
  component?: any;
  to?: string;
  color?: 'default' | 'error';
}

const useMenu = () => {
  const classes = useStyles();

  const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((event:MouseEvent<HTMLButtonElement> | undefined) => {
    if (!event) return;
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    // eslint-disable-nextline
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    // eslint-disable-nextline
  }, []);

  const Menu = useMemo(() => {
    const MenuComponent:FC<Omit<MenuProps, 'open' | 'onClose'>>  = ({ children, ...props }) => {
      const open = Boolean(anchorEl);
      if ( !open ) return null;
      return (
        <MuiMenu
          {...props}
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          getContentAnchorEl={null}
          PopoverClasses={{
            paper: classes.paper,
          }}
        >{children}</MuiMenu>
      )
    }
    return MenuComponent;
    // eslint-disable-next-line
  }, [anchorEl]);

  const MenuItem = useMemo(() => {
    const MenuItemComponent:FC<MenuItemProps>  = ({ children, color, ...props }) => {
      return (
        <MuiMenuItem
          {...props}
          onClick={(event:MouseEvent<HTMLLIElement>) => {
            event.stopPropagation();
            if ( props.onClick ) props.onClick(event);
            closeMenu();
          }}
          className={classes.item}
          style={{ color: color === 'error' ? 'rgb(239 68 68)' : '#fff' }}
        >{children}</MuiMenuItem>
      )
    }
    return MenuItemComponent;
    // eslint-disable-next-line
  }, []);

  return {
    Menu,
    MenuItem,
    openMenu,
    closeMenu
  }
}


export default useMenu;

const useStyles = makeStyles({
  paper: {
    backgroundColor: '#383737',
    color: '#fff',
  },
  item: {
    padding: '8px 16px',

    fontFamily: '"Montserrat", sans-serif',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,

    '&:hover': {
      backgroundColor: 'rgb(107 114 128)'
    }
  }
});
