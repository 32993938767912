import { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
// Redux
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import ConsentsAsync from 'store/consents/consentsAsync';
import { consentsActions } from 'store/consents/consentsSlice';
import { selectConsents, selectConsentsGroups } from 'store/consents/consentsSelectors';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import ConsentsStatuses from 'types/ConsentsStatuses';
// Components
import ConsentItem from './ConsentItem';
// i18next
import { useTranslation } from 'react-i18next';

const SectionConsents:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const { watch, setValue } = useFormContext();

  const languageWatcher = watch('language');
  const registrationPageWatcher = watch('registrationPage');

  const quiz = useAppSelector(selectQuiz);
  const consents = useAppSelector(selectConsents);
  const consentsGroups = useAppSelector((state:RootState) => selectConsentsGroups(state, { lng: languageWatcher }));
  const [selectedConsents, setSelectedConsents] = useState<string[]>([]);

  const handleSelect = (consentId: string) => {
    if (selectedConsents.includes(consentId)) {
      setSelectedConsents(prev => prev.filter(id => id !== consentId));
    } else {
      setSelectedConsents(prev => ([...prev, consentId]));
    }
  }

  useEffect(() => {
    setValue('consents', selectedConsents);
    // eslint-disable-next-line
  }, [selectedConsents]);

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(ConsentsAsync.getConsents({ status: ConsentsStatuses.published, ['include.platform']: true }));
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (!consents) return;

    const quizConsents = quiz?.consents?.filter(consentId => consents.find(consent => consent._id === consentId)) || [];
    const requiredConsentsIds = consents.filter(consent => (consent.language === languageWatcher && consent.required)).map(c => c._id);
    const set = new Set([...quizConsents, ...requiredConsentsIds]);

    setSelectedConsents([...set]);
    // eslint-disable-next-line
  }, [languageWatcher, consents]);

  useEffect(() => {
    return () => {
      dispatch(consentsActions.setInitialField('consents'));
    }
    // eslint-disable-next-line
  }, []);

  const consentsLabels:Record<string, { title: string; hint: string }> = {
    platform: {
      title: t('ownerPage.quizForm.platformConsentsTitle'),
      hint:  t('ownerPage.quizForm.platformConsentsHint')
    },
    brand: {
      title: t('ownerPage.quizForm.accountConsentsTitle'),
      hint:  t('ownerPage.quizForm.accountConsentsHint')
    }
  }

  if (!registrationPageWatcher || registrationPageWatcher.anonymous) return null;
  if (!consentsGroups) return null;
  return (
    <div react-scroll="consents">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('common.consents')}</h2>
      </div>
      <div className="p-6">
        {Object.keys(consentsGroups).map((key) => (
          <div key={key} className="mb-8">
            <span className="block text-white text-base font-semibold mr-3 mb-1">{consentsLabels[key].title}</span>
            <span className="block text-white text-xs mr-3 mb-3">{consentsLabels[key].hint}</span>
            {consentsGroups[key].map(consent => (
              <ConsentItem
                key={consent._id}
                consent={consent}
                checked={selectedConsents.includes(consent._id)}
                handleSelect={handleSelect}
              />
            ))}
          </div>
        ))}
        {!Object.keys(consentsGroups).length && (
          <span className="block text-white text-sm font-semibold mr-3 mb-3">{t('ownerPage.quizForm.noConsents')}</span>
        )}
      </div>
    </div>
  )
}

export default SectionConsents;
