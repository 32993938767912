import QWLogo from 'assets/images/poweredBy_logo.png'
import config from 'config';

import { FC } from "react";
import { QRCodeCanvas } from "qrcode.react";
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import AccountFeatures from "types/AccountFeatures";
// Commponents
import { Button } from './Buttons';
// i18next
import { useTranslation } from 'react-i18next';

interface QRCodeProps {
  link: string;
  quizName: string;
  quizNameSlug: string;
}
 
const QRCode:FC<QRCodeProps> = ({ link, quizName, quizNameSlug }) => {
  const { t } = useTranslation('common');

  const currentUser = useAppSelector(selectCurrentUser);

  const qrCodeLink = link || `${config.quizURL}/quiz/${quizNameSlug}`;

  const downloadQRCode = () => {
    const canvas = document.querySelector("#qrcode-canvas") as HTMLCanvasElement;
    if (!canvas) return;

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${quizName.replace(/\s/g, '-')}-Leaderboard-QR-Code.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <div className="pt-6">
      <QRCodeCanvas
        id="qrcode-canvas"
        level="H"
        size={175}
        value={qrCodeLink}
        imageSettings={currentUser?.account?.features?.includes(AccountFeatures.WhiteLabeling) ? undefined : {
          src: QWLogo,
          height: 30,
          width: 80,
          excavate: true
        }}
      />
      <Button onClick={downloadQRCode} className="mt-2">{t('ownerPage.quizForm.downloadQrCode')}</Button>
    </div>
  );
}
 
export default QRCode;
