import { FC, Fragment, useState } from 'react';
import moment from 'moment';
// Redux
import { useAppDispatch } from 'hooks/useStore';
import { NamespaceAsync } from 'store/namespace/namespaceAsync';
// Dialogs
import NamespaceFormDialog from 'dialogs/NamespaceForm.dialog';
import ConfirmationDialog from 'dialogs/Confirmation.dialog';
// Component
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// Icons
import { FaTrash, FaRegClock, FaRegUser } from 'react-icons/fa';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  namespace: any;
}

const NamespacesListItem:FC<Props> = ({ namespace }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const [isLoading, setIsLoading] = useState(false);

  const [ dialogNamespaceFormOpen, setDialogNamespaceFormOpen ] = useState<boolean>(false);
  const [ dialogConfirmationOpen, setDialogConfirmationOpen ] = useState<boolean>(false);

  const toggleNamespaceFormDialog = () => setDialogNamespaceFormOpen(!dialogNamespaceFormOpen);
  const toggleConfirmationDialog = () => setDialogConfirmationOpen(!dialogConfirmationOpen);

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(NamespaceAsync.deleteNamespace(namespace._id)).unwrap()
      .finally(() => setIsLoading(false));
  }

  return (
    <Fragment>
      <tr>
        <td className="text-left title">{namespace.name}</td>
        <td className="text-left title">
          <FaRegUser className="icon" size="16px" />
          {(namespace as any).createdByName}
        </td>
        <td className="text-left text">
          <FaRegClock className="icon" size="16px" />
          {moment(namespace.createdAt).format('MMM Do YYYY')}
        </td>
        <td className="text-right actions">
          <Tooltip className="ml-1" title={t('ownerPage.namespacesPage.deleteCategory')}>
            <IconButton
              onClick={toggleConfirmationDialog}
            ><FaTrash /></IconButton>
          </Tooltip>
        </td>
      </tr>
      {dialogNamespaceFormOpen ? (
        <NamespaceFormDialog
          namespaceId={namespace._id}
          open={dialogNamespaceFormOpen}
          onClose={toggleNamespaceFormDialog}
        />
      ) : null}
      {dialogConfirmationOpen ? (
        <ConfirmationDialog
          title={`${t('ownerPage.namespacesPage.deleteCategory')}: ${namespace.name}`}
          content={t('ownerPage.namespacesPage.confirmDeleteCategory')}
          open={dialogConfirmationOpen}
          loading={isLoading}
          onClose={toggleConfirmationDialog}
          onConfirm={handleDelete}
        />
      ) : null}
    </Fragment>
  )
}

export default NamespacesListItem;
