import React, { Fragment, useEffect, useRef } from 'react';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import ConsentsAsync from 'store/consents/consentsAsync';
import { consentsActions } from 'store/consents/consentsSlice';
import { selectConsent } from 'store/consents/consentsSelectors';
// Components
import { Dialog, DialogContent, DialogTitle } from 'layouts/Dialog';
import { Loader } from 'components/Utilities';

type Props = {
  consentId: string;
  open: boolean;
  onClose: () => void;
}

const ConsentsDetailsDialog:React.FC<Props> = ({ consentId, open, onClose }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const contentRef = useRef<any>();

  const consent = useAppSelector(selectConsent);

  useEffect(() => {
    if (!consent) return;
  
    if (consent.content) {
      contentRef.current?.insertAdjacentHTML('beforeend', consent.content);
    }
  }, [consent]);

  useEffect(() => {
    dispatch(ConsentsAsync.getConsent(consentId));

    return () => {
      dispatch(consentsActions.setInitialField('consent'));

      contentRef.current = null;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog size="lg" open={open} onClose={onClose}>
      {!consent ? <Loader /> : (
        <Fragment>
          <DialogTitle title={consent.name} onClose={onClose} />
          <DialogContent>
            <span className="block text-white text-xs mr-3 mb-1">{t('adminPage.shortText')}</span>
            <span className="block text-white text-base font-semibold mr-3 mb-3">{consent.shortText}</span>

            <span className="block text-white text-xs mr-3 mb-1">{consent.content ? t('adminPage.content') : t('adminPage.url')}</span>
            {consent.content ? (
              <div ref={contentRef} className="bg-white p-4" />
            ) : (
              <a
                href={consent.url} target="_blank" rel="noreferrer"
                className="block text-white text-base font-semibold mr-3"
              >{consent.url}</a>
            )}
          </DialogContent>
        </Fragment>
      )}
    </Dialog>
  )
}

export default ConsentsDetailsDialog;
