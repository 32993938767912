import { FC, Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { selectQuizFormMenuItem } from 'store/app/appSelectors';
import IBucket from 'models/Bucket';
// Components
import MenuItem from './MenuItem';

const PostQuizPagesMenu:FC = () => {
  const dispatch = useAppDispatch();
  
  const quizFormMenuItem = useAppSelector(selectQuizFormMenuItem);

  const { getValues } = useFormContext();

  useEffect(() => {
    dispatch(appActions.setQuizFormMenuItem('post-quiz-page-0'));
    // eslint-disable-next-line
  }, []);

  const buckets = getValues('buckets');

  if ( !buckets ) return null;
  return (
    <Fragment>
      <div className="p-4">
        <span className="block text-white text-base font-semibold">Winning bucket pages</span>
      </div>
      <div className="flex flex-col flex-grow my-2">
        {buckets.map((bucket:IBucket, index:number) => (
          <MenuItem
            key={`post-quiz-page-${index}`}
            menuItem={{
              label: bucket.title,
              value: `post-quiz-page-${index}`
            }}
            active={quizFormMenuItem === `post-quiz-page-${index}`}
          />
        ))}
      </div>
    </Fragment>
  )
}

export default PostQuizPagesMenu;
