import { FC } from 'react';
// Models
import { IUser } from 'models/User';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectFilteredUsers } from 'store/user/userSelectors';
// Componenr
import { Loader, Message } from 'components/Utilities';
// 
import UsersListItem from './UsersListItem';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  sortedUsers: IUser[];
  onRequestSort: (key:string) => void;
  getClassNamesFor: (key:string) => void;
}

const UsersList:FC<Props> = ({
  // Props
  sortedUsers, onRequestSort, getClassNamesFor
}) => {
  const { t } = useTranslation('common');
  // State
  const users:IUser[] | null = useSelector(selectFilteredUsers);

  if ( !users ) return <Loader />;
  if ( users.length === 0 ) return <Message text="No user(s)" />;
  return (
    <table>
      <thead>
        <tr>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('name')}`}
            onClick={() => onRequestSort('name')}
          >{t('common.firstName')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('surname')}`}
            onClick={() => onRequestSort('surname')}
          >{t('common.lastName')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('email')}`}
            onClick={() => onRequestSort('email')}
          >{t('common.email')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('groups')}`}
            onClick={() => onRequestSort('groups')}
          >{t('common.group')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('organization')}`}
            onClick={() => onRequestSort('organization')}
          >{t('common.organization')}</th>
          <th
            className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdAt')}`}
            onClick={() => onRequestSort('createdAt')}
          >{t('common.dateCreated')}</th>
          <th
            className="text-right"
          >{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user:IUser) => (
          <UsersListItem
            key={`user-item-${user._id}`}
            user={user}
          />
        ))}
      </tbody>
    </table>
  )
}

export default UsersList;
