import { ChangeEvent, FC } from 'react';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { getParams } from 'store/result/resultSelectors';
// Components
import Sidebar from 'components/Sidebar';
import { Select } from 'components/Controls';
// i18next
import { useTranslation } from 'react-i18next';

const NamespacesSort:FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  
  const params = useAppSelector(getParams);

  const handleChange = (event:ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const key = name === 'key' ? value : params.key;
    const direction = name === 'key' ? params.key : value;
    dispatch(ResultAsync.fetchResultList({
      ...params,
      offset: 0,
      key,
      direction
    }));
  }

  return (
    <Sidebar>
      <Select
        label={t('common.sortBy')} id="sortBy" name="key" value={params.key}
        onChange={handleChange}
        options={[
          { value: 'user.name', label: t('common.firstName') },
          { value: 'user.surname', label: t('common.lastName') },
          { value: 'user.username', label: t('common.username') },
          { value: 'user.email', label: t('common.email') },
          { value: 'score', label: t('common.score') },
          { value: 'createdAt', label: t('common.dateCreated') }
        ]}
      />
      <Select
        label={t('common.direction')} id="direction" name="direction" value={params.direction}
        onChange={handleChange}
        options={[
          { value: 'asc', label: 'Ascending' },
          { value: 'desc', label: 'Descending' },
        ]}
      />
    </Sidebar>
  )
}

export default NamespacesSort;
