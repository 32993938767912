import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// i18next
import { useTranslation } from 'react-i18next';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { getGroupItemsForSelect } from 'store/group/groupSelectors';
import { getNamespaceItemsForSelect } from 'store/namespace/namespaceSelectors';
import QuizTypes from 'types/QuizTypes';
// Components
import { Checkbox, Input, Select } from 'components/Controls';
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
import DatePicker from 'components/DatePicker';
import NamespaceFormDialog from 'dialogs/NamespaceForm.dialog';
import { countries } from 'components/LanguageSelect';
import PublicDevicePlayable from './PublicDevicePlayable';
import LandingPage from './LandingPage';
import RegistrationCaption from './RegistrationCaption';
// Icons
import { FaEye } from 'react-icons/fa';
// Utilities
import { isRequired } from 'utilities/validation';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

const SectionMain = () => {
  const { t } = useTranslation('common');

  const { control, formState: { errors }, watch, setValue } = useFormContext();

  const dispatch = useAppDispatch();

  const namespaceOptions = useAppSelector(getNamespaceItemsForSelect);
  const groupOptions = useAppSelector(getGroupItemsForSelect);

  const [ dialogNamespaceFormOpen, setDialogNamespaceFormOpen ] = useState(false);

  const toggleNamespaceFormDialog = () => setDialogNamespaceFormOpen(!dialogNamespaceFormOpen);

  const handleTogglePreview = () => {
    dispatch(appActions.toggleQuizFormPreview())
  }

  const watchedAdditionalLanguages = watch('additionalLanguages');

  const [additionalLanguages, setAdditionalLanguages] = useState<string[]>(watchedAdditionalLanguages || []);

  const handleAdditionalLanguages = (language: string) => {
    if (additionalLanguages.includes(language)) {
      setAdditionalLanguages(prev => prev.filter(lng => lng !== language));
    } else {
      setAdditionalLanguages(prev => ([...prev, language]));
    }
  }

  useEffect(() => {
    setValue('additionalLanguages', additionalLanguages);
    // eslint-disable-next-line
  }, [additionalLanguages]);

  const watchedType = watch('type');
  const watchedRegistrationPage = watch('registrationPage');
  const watchedLanguage = watch('language');

  useEffect(() => {
    setAdditionalLanguages(prev => prev.filter(lng => lng !== watchedLanguage));
    // eslint-disable-next-line
  }, [watchedLanguage]);

  const registrationLabels:Record<string, string> = {
    'anonymous': t('ownerPage.quizForm.anonymous'),
    'required': t('ownerPage.quizForm.required'),
    'none': t('common.none'),
  }

  const handleChangeRegistration = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    if (value === 'none') {
      setValue('registrationPage', null);
      return;
    }

    const nextData = watchedRegistrationPage ? { ...watchedRegistrationPage } : {
      anonymous: false,
      caption: '',
      showPriorQuiz: false,
    }

    if (value === 'anonymous') {
      setValue('registrationPage', { ...nextData, anonymous: true });
    } else {
      setValue('registrationPage', { ...nextData, anonymous: false });
    }
  }

  return (
    <div react-scroll="main">
      <div className="flex items-center p-6">
        <h2 className="flex-grow text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.main')}</h2>
        <Tooltip title="Open preview">
          <IconButton
            className="hidden tablet:flex items-center justify-center laptop:hidden"
            onClick={handleTogglePreview}
          ><FaEye /></IconButton>
        </Tooltip>
      </div>
      <div className="p-6">
        <div className="-mt-3 mb-4">
          <button
            className="uppercase text-primary font-bold hover:underline"
            type="button"
            onClick={toggleNamespaceFormDialog}
          >{t('ownerPage.quizForm.createCategory')}</button>
        </div>
        <Controller
          control={control} name="namespaceId" rules={{ required: isRequired }}
          render={({ field }) => (
            <Select
              {...field}
              label={t('ownerPage.quizForm.category')} id="namespaceId" name="namespaceId"
              required={true}
              options={[
                { value: '', label: t('ownerPage.quizForm.chooseCategory') },
                ...namespaceOptions
              ]}
              error={Boolean(errors.namespaceId)}
              errorText={errors.namespaceId ? errors.namespaceId.message : ''}
              helperText={t('ownerPage.quizForm.categoryHint')}
            />
          )}
        />
        <Controller
          control={control} name="language"
          render={({ field }) => (
            <Select
              {...field}
              label={t('ownerPage.quizForm.quizLanguage')} id="language" name="language"
              options={countries}
            />
          )}
        />
        <div>
          <p className="text-white text-sm font-semibold mb-2">{t('ownerPage.quizForm.additionalLanguages')}</p>
          <span className="block text-gray-400 text-sm font-light leading-5 mb-2">{t('ownerPage.quizForm.additionalLanguagesHint')}</span>
          {countries.filter(country => country.value !== watchedLanguage).map(c => (
            <Checkbox
              key={`language-${c.value}`}
              id={`language-${c.value}`}
              name={`language-${c.value}`}
              label={c.label}
              checked={additionalLanguages.includes(c.value)}
              onChange={() => handleAdditionalLanguages(c.value)}
            />
          ))}
        </div>
        <Controller
          control={control} name="quizName" rules={{ required: isRequired }}
          render={({ field }) => (
            <Input
              {...field}
              label={t('ownerPage.quizForm.quizName')} id="quizName" name="quizName"
              required={true}
              error={Boolean(errors.quizName)}
              errorText={errors.quizName ? errors.quizName.message : ''}
              helperText={t('ownerPage.quizForm.quizNameHint')}
            />
          )}
        />
        {watchedType === QuizTypes.Scoring && (
          <Controller
            control={control} name="quizGroup"
            render={({ field }) => (
              <Select
                {...field}
                label={t('ownerPage.quizForm.quizGroup')} id="quizGroup" name="quizGroup"
                options={[
                  { value: '', label: t('ownerPage.quizForm.chooseQuizGroup') },
                  ...groupOptions
                ]}
                helperText={t('ownerPage.quizForm.quizGroupHint')}
              />
            )}
          />
        )}
        <div className="flex flex-wrap -mx-2">
          <div className="w-full sm:w-1/2 px-2">
            <div className="mb-4">
              <Controller
                control={control} name="startsDate"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={t('ownerPage.quizForm.startDate')}
                    id="startsDate"
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <div className="mb-4">
              <Controller
                control={control} name="endsDate"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label={t('ownerPage.quizForm.endDate')}
                    id="endsDate"
                  />
                )}
              />
            </div>
          </div>
        </div>
        {watchedType === QuizTypes.Prediction && (
          <span className="block text-gray-400 text-sm font-light leading-5 -mt-2 mb-4">
            {t('ownerPage.quizForm.datesPredictionsHint')}
          </span>
        )}
        <MultiLanguageTextField
          name="brand"
          label={t('ownerPage.quizForm.brandName')}
          helperText={t('ownerPage.quizForm.brandNameHint')}
          className="mb-4"
        />
        <PublicDevicePlayable />
          <Select
            id="registration" name="registration"
            label={t('ownerPage.quizForm.registration')}
            options={['anonymous', 'required', 'none'].map(field => (
              { value: field, label: registrationLabels[field] }
            ))}
            value={!watchedRegistrationPage ? 'none' : watchedRegistrationPage.anonymous ? 'anonymous' : 'required'}
            onChange={handleChangeRegistration}
          />
        {!!watchedRegistrationPage ? (
          <Fragment>
            <Controller
              control={control} name="registrationPage.showPriorQuiz"
              render={({ field }) => (
                <Select
                  {...field}
                  label={t('ownerPage.quizForm.showRegisterScreen')} id="registrationPriorQuiz" name="registrationPriorQuiz"
                  options={[
                    { value: 'true', label: t('ownerPage.quizForm.priorQuiz') },
                    { value: 'false', label: t('ownerPage.quizForm.afterQuiz') },
                  ]}
                />
              )}
            />
            <RegistrationCaption />
          </Fragment>
        ) : null}
        {!!watchedRegistrationPage && !watchedRegistrationPage.anonymous ? (
          <Controller
            control={control} name="registrationPage.requirePhoneNumber"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                id="requirePhoneNumber" name="requirePhoneNumber"
                label={t('ownerPage.quizForm.collectPhoneNumber')}
                checked={value}
                onChange={onChange}
                helperText={t('ownerPage.quizForm.collectPhoneNumberHint')}
              />
            )}
          />
        ) : null}
        
        <span className="divider"></span>
        <span className="block text-gray-400 text-sm py-1">{t('ownerPage.quizForm.requiredFields')}</span>

        <LandingPage />

        {dialogNamespaceFormOpen ? (
          <NamespaceFormDialog
            open={dialogNamespaceFormOpen}
            onClose={toggleNamespaceFormDialog}
          />
        ) : null}
      </div>
    </div>
  )
}

export default SectionMain;
