import React from 'react';
import { ChromePicker } from 'react-color';

type Props = {
  label?: string;
  value: string;
  required?: boolean;
  error?: boolean;
  errorText?: string;
  helperText?: string;
  onBlur?: () => void;
  onChange: (hex:string) => void;
};

export const ColorPicker:React.FC<Props> = ({
  // Props
  label = '', value, required = false, error = false, errorText = '', helperText = '', onBlur, onChange
}) => {
  return (
    <React.Fragment>
      {label ? (
        <span className="block text-white text-base font-semibold mb-1">
          {label}{required ? <React.Fragment>&nbsp;*</React.Fragment> : null}
        </span>
      ) : null}
      <ChromePicker
        color={value}
        onChange={(value:{ hex:string }) => {
          onChange(value.hex);
          if ( onBlur ) onBlur();
        }}
      />
      {error && errorText ? <span className="block text-red-500 text-sm py-1 px-4">{errorText}</span> : null}
      {helperText ? <span className="text-white text-xs font-light">{helperText}</span> : null}
    </React.Fragment>
  )
}
