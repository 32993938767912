// Assets
import FontFamily from 'assets/font-family.json';

import { FC, Fragment } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import QuizTypes from 'types/QuizTypes';
// Components
import {Checkbox, Select} from 'components/Controls';
import { ColorPicker } from 'components/ColorPicker';
// Utilities
import { isRequired } from 'utilities/validation';
// i18next
import { useTranslation } from 'react-i18next';

const SectionTheme:FC = () => {
  const { t } = useTranslation('common');

  const quiz = useAppSelector(selectQuiz);

  const { control, formState: { errors }, watch } = useFormContext();

  const watchedPrimaryFont = watch('primaryFont');

  return (
    <div react-scroll="theme">
      <span className="divider"></span>
      <div className="p-6">
        <h2 className="text-white text-2xl leading-6 font-bold">{t('ownerPage.quizForm.theme')}</h2>
      </div>
      <div className="p-6">
        <Controller
          control={control} name="primaryFont"
          render={({ field }) => (
            <Select
              {...field}
              label={t('common.fonts')} id="primaryFont" name="primaryFont"
              required={true}
              options={FontFamily.families}
            />
          )}
        />
        <p className="text-white text-xl mb-4" style={{ fontFamily: `"${watchedPrimaryFont}", sans-serif` }}>
          {t('common.howTextWillLook')}
        </p>
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2">
            <div className="mr-4 mb-4">
              <Controller
                control={control} name="primaryColor" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <ColorPicker
                    label={t('ownerPage.quizForm.primaryColor')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.primaryColor)}
                    errorText={errors.primaryColor ? errors.primaryColor.message : ''}
                    helperText=""
                  />
                )}
              />
              <span className="text-white text-xs font-light">{t('ownerPage.quizForm.primaryColorHint')}</span>
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="mr-4 mb-4">
              <Controller
                control={control} name="secondaryColor" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <ColorPicker
                    label={t('ownerPage.quizForm.secondaryColor')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.secondaryColor)}
                    errorText={errors.secondaryColor ? errors.secondaryColor.message : ''}
                    helperText={t('ownerPage.quizForm.secondaryColorHint')}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="mr-4 mb-4">
              <Controller
                control={control} name="callToActionColor" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <ColorPicker
                    label={t('ownerPage.quizForm.callToActionColor')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.callToActionColor)}
                    errorText={errors.callToActionColor ? errors.callToActionColor.message : ''}
                    helperText={t('ownerPage.quizForm.callToActionColorHint')}
                  />
                )}
              />
            </div>
          </div>
          <div className="w-full sm:w-1/2">
            <div className="mr-4 mb-4">
              <Controller
                control={control} name="tertiaryColor" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <ColorPicker
                    label={t('ownerPage.quizForm.tertiaryColor')}
                    value={value}
                    onChange={onChange}
                    error={Boolean(errors.tertiaryColor)}
                    errorText={errors.tertiaryColor ? errors.tertiaryColor.message : ''}
                    helperText={t('ownerPage.quizForm.tertiaryColorHint')}
                  />
                )}
              />
            </div>
          </div>
        </div>
        {quiz && [QuizTypes.Scoring, QuizTypes.Prediction, QuizTypes.Poll].includes(quiz.type) ? (
          <Fragment>
            <Controller
              control={control} name="applyPrimaryColorToProgressBar"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('ownerPage.quizForm.applyPrimaryColorToProgressBar')} id="applyPrimaryColorToProgressBar" name="applyPrimaryColorToProgressBar" checked={value}
                  onChange={onChange}
                />
              )}
            />
            <div className="flex flex-col sm:flex-row -mx-4">
              <div className="px-4">
                <img src="/progress-bar-1.png" alt="progress-bar-1" />
              </div>
              {quiz.type !== QuizTypes.Poll && (
                <div className="px-4">
                  <img src="/progress-bar-2.png" alt="progress-bar-2" />
                </div>
              )}
            </div>
          </Fragment>
        ) : null}
      </div>
    </div>
  )
}

export default SectionTheme;
