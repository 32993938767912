import { MouseEvent, FC } from 'react';
// Redux
import { useAppDispatch } from 'hooks/useStore';
// Components
import Icon from 'components/Icon';
import { IconButton } from 'components/Buttons';
import { Tooltip } from 'components/Tooltip';
// Icons
import { MdDragIndicator } from 'react-icons/md';
// i18next
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/app/appSlice';

type Props = {
  menuItem: any;
  onDelete?: () => void;
  onCopy?: () => void;
  active: boolean;
  error?: boolean;
  dragIcon?: boolean;
}

const MenuItem:FC<Props> = ({
  // Props
  menuItem, onDelete, onCopy, active, error, dragIcon = false
}) => {
  const { t } = useTranslation('common');
  
  const dispatch = useAppDispatch();

  const handeClick = () => {
    dispatch(appActions.setQuizFormMenuItem(menuItem.value));
    const element = document.querySelector(`[react-scroll="${menuItem.value}"]`);
    if ( element ) element.scrollIntoView();
    dispatch(appActions.toggleQuizFormSidebar());
  }

  const handleDelete = (event:MouseEvent<HTMLButtonElement> | undefined) => {
    if ( event ) event.stopPropagation();
    if ( typeof onDelete !== 'undefined' ) onDelete();
  }

  const handleCopy = (event:MouseEvent<HTMLButtonElement> | undefined) => {
    if ( event ) event.stopPropagation();
    if ( typeof onCopy !== 'undefined' ) onCopy();
  }

  const classes = active
    ? 'bg-primary-light text-primary'
    : 'text-white'
  ;

  return (
    <div
      className={`
        ${classes}
        flex items-center gap-2 py-4 px-2 cursor-pointer
        hover:bg-primary-light
      `}
      onClick={handeClick}
    >
      {dragIcon && (
        <IconButton onClick={() => {}}>
          <MdDragIndicator />
        </IconButton>
      )}
      {menuItem.icon ? <Icon icon={menuItem.icon} /> : null}
      <span className="flex-grow text-base text-left">{menuItem.label}</span>
      {error ? (
        <Icon
          className="text-red-500"
          icon="error_outline"
        />
      ) : null}
      <div className="flex items-center">
        {typeof onCopy !== 'undefined' && (
          <Tooltip title={t('ownerPage.quizQuestionsPage.copyQuestion')}>
            <IconButton onClick={handleCopy}>
              <Icon icon="content_copy" size="small" />
            </IconButton>
          </Tooltip>
        )}
        {typeof onDelete !== 'undefined' && (
          <Tooltip title={t('ownerPage.quizQuestionsPage.deleteQuestion')}>
            <IconButton onClick={handleDelete}>
              <Icon icon="delete" />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default MenuItem;
