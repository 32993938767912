import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import INotification from 'models/Notification';
import Statuses from 'types/Statuses';
import { v4 as uuidv4 } from 'uuid';

interface IState {
  isInitialized: boolean;
  publicDeviceSnackbarOpen: boolean;
  quizNameSlug: string | null;
  namespaceSlug: string | null;
  notifications: INotification[];
  quizFormSidebar: boolean;
  quizFormPreview: boolean;
  quizFormMenuItem: string;
};


const initialState:IState = {
  isInitialized: false,
  publicDeviceSnackbarOpen: false,
  quizNameSlug: null,
  namespaceSlug: null,
  notifications: [],
  quizFormSidebar: false,
  quizFormPreview: false,
  quizFormMenuItem: 'main'
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    createNotification: (state:IState, action:PayloadAction<Omit<INotification, 'key'>>) => {
      const notification = { ...action.payload, status: action.payload.status || Statuses.Success, key: uuidv4() };
      state.notifications = [...state.notifications, notification];
    },
    removeNotification: (state: IState, action:PayloadAction<string>) => {
      state.notifications = state.notifications.filter(notification => notification.key !== action.payload);
    },
    openPublicDeviceSnackbar: (state: IState, action:PayloadAction<{quizNameSlug:string, namespaceSlug:string}>) => {
      state.publicDeviceSnackbarOpen = true;
      state.quizNameSlug = action.payload.quizNameSlug;
      state.namespaceSlug = action.payload.namespaceSlug;
    },
    closePublicDeviceSnackbar: (state: IState) => {
      state.publicDeviceSnackbarOpen = false;
      state.quizNameSlug = null;
      state.namespaceSlug = null;
    },
    toggleQuizFormSidebar: (state: IState) => {
      state.quizFormSidebar = !state.quizFormSidebar;
    },
    toggleQuizFormPreview: (state: IState) => {
      state.quizFormPreview = !state.quizFormPreview;
    },
    setQuizFormMenuItem: (state: IState, action:PayloadAction<string>) => {
      state.quizFormMenuItem = action.payload;
    }
  }
});

export const appActions = slice.actions;

export default slice.reducer;
