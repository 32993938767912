import React, { ChangeEvent, useState } from 'react';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Button } from 'components/Buttons';
import { Input } from 'components/Controls';
import QRCode from 'components/QRCode';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
  quizName: string;
  quizNameSlug: string;
}

const QRCodeGeneratorDialog:React.FC<Props> = ({
  // Props
  open, onClose, quizName, quizNameSlug
}) => {
  const { t } = useTranslation('common');

  const [link, setLink] = useState<string>('');
  const onChange = (e:ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  }

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <DialogTitle title="QR Code Generator" onClose={onClose} />
      <DialogContent>
        <QRCode link={link} quizName={quizName} quizNameSlug={quizNameSlug} />
        <Input
          name="qr-code-link"
          className="mt-4"
          onChange={onChange}
          value={link}
          label={t('ownerPage.quizForm.quizQRCodeLink')} id="publicLeaderboard.quizQRCodeLink"
          helperText={t('ownerPage.quizForm.quizQRCodeLinkHint')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
        >{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default QRCodeGeneratorDialog;
