import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Models
import IQuiz from 'models/Quiz';
// Async
import {
  getQuizzes,
  getQuiz, createQuiz, updateQuiz, deleteQuiz, cloneQuiz,
  startQuiz, endQuiz,
  assignSpotPrizeWinner
} from './quizzesAsync';

interface IState {
  quizzes: IQuiz[] | null;
  quiz: IQuiz | null;
  filter: {
    search: string;
  };
  loading: boolean;
};

const initialState:IState = {
  quizzes: null,
  quiz: null,
  filter: {
    search: ''
  },
  loading: false
};

const slice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    setFilter: (state, action:PayloadAction<{ field:keyof IState['filter'], value:string }>) => {
      state.filter[action.payload.field] = action.payload.value;
    },
    setInitialField: <IStateKey extends keyof IState>(state:IState, action:PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    // Get quizzes
    builder.addCase(getQuizzes.pending, (state) => {
      state.quizzes = null;
    });
    builder.addCase(getQuizzes.fulfilled, (state, action:PayloadAction<IQuiz[]>) => {
      state.quizzes = action.payload;
    });
    // Get quiz
    builder.addCase(getQuiz.pending, (state) => {
      state.quiz = null;
    });
    builder.addCase(getQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      state.quiz = action.payload;
    });
    // Create quiz
    builder.addCase(createQuiz.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      if ( state.quizzes ){
        state.quizzes = [...state.quizzes, action.payload]
      }
    });
    // Update quiz
    builder.addCase(updateQuiz.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      if ( state.quizzes ){
        state.quizzes = state.quizzes.map((quiz:any) => {
          if ( quiz._id === action.payload._id ) return action.payload;
          return quiz;
        })
      }
      state.quiz = action.payload;
    });
    // Delete quiz
    builder.addCase(deleteQuiz.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteQuiz.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.quizzes ){
        state.quizzes = state.quizzes.filter((quiz:any) => quiz._id !== action.payload);
      }
    });
    // Clone quiz
    builder.addCase(cloneQuiz.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(cloneQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      if ( state.quizzes ){
        state.quizzes = [...state.quizzes, action.payload]
      }
    });
    // Start/end quiz
    builder.addCase(startQuiz.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(startQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      if ( state.quizzes ){
        state.quizzes = state.quizzes.map((quiz:any) => {
          if ( quiz._id === action.payload._id ) return action.payload;
          return quiz;
        })
      }
    });
    // Start/end quiz
    builder.addCase(endQuiz.pending, (state) => {
      state.loading = true;
    })
    builder.addCase(endQuiz.fulfilled, (state, action:PayloadAction<IQuiz>) => {
      if ( state.quizzes ){
        state.quizzes = state.quizzes.map((quiz:any) => {
          if ( quiz._id === action.payload._id ) return action.payload;
          return quiz;
        })
      }
    });
    // Assign spot prize winner
    builder.addCase(assignSpotPrizeWinner.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignSpotPrizeWinner.fulfilled, (state, action:PayloadAction<any>) => {
      if ( state.quiz ){
        const { spotPrizeWinners } = action.payload;
        state.quiz = {...state.quiz, spotPrizeWinners};
      }
    });

    builder.addMatcher(
      (action) => action.type.includes('/fulfilled') || action.type.includes('/rejected'),
      (state) => {
        state.loading = false;
      }
    )
  }
});

export const { setFilter, setInitialField } = slice.actions;

export default slice.reducer;
