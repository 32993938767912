import { FC, useEffect, useState } from "react";
// i18next
import { useTranslation } from "react-i18next";

type Props = {
  value: number;
  width: number;
  percent: number;
  label: string;
  isCorrect: boolean;
}

let interval:any = null;

const AnswerItem:FC<Props> = ({ width, percent, value, label, isCorrect }) => {
  const { t } = useTranslation('common');

  const [w, setW] = useState<number>(0);

  useEffect(() => {
    interval = setInterval(() => {
      setW(prev => {
        if (prev + width / 100 > width) {
          clearInterval(interval);
          return width;
        } else return prev + width / 100;
      })
    }, 10)

    return () => {
      clearInterval(interval);
      setW(0);
    };
    // eslint-disable-next-line
  }, [])

  return (
    <div className="px-4 mb-2">
      <h3 className={`text-xs font-bold ${isCorrect ? 'text-green-400' : 'text-white'}`}>{`${label} ${isCorrect ? `(${t('ownerPage.questionAnswersBreakdownPage.correct')})` : ''}`}</h3>
      <div className="flex items-center gap-4">
        <div className="w-96 max-w-full relative mt-1 rounded bg-gray-500">
          <div
            className="absolute rounded h-full top-0 left-0 bg-gradient-to-r from-blue-600 to-blue-400"
            style={{ width: `${w}%` }}
          />
          <p className="relative text-white text-xs text-center z-1">{`${percent}%`}</p>
        </div>
        <p className="relative text-white text-xs">{value}</p>
      </div>
    </div>
  );
}
 
export default AnswerItem;