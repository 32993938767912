import config from '../config';

import { CSSProperties, useRef } from 'react';
// Redux
import { useAppSelector } from 'hooks/useStore';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import QuizTypes from 'types/QuizTypes';

type Props = {
  style?: CSSProperties;
  id: string;
  quizNameSlug: string;
  quizHash: string | null;
  preview: 'quiz' | 'liveLeaderboard';
};

const QuizFrame:React.FC<Props> = ({
  // Props
  style = {}, id, quizNameSlug, quizHash, preview
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const quiz = useAppSelector(selectQuiz);

  const onLoad = () => {
    if ( !iframeRef.current ) return;
    iframeRef.current.contentWindow?.postMessage({
      action: 'preview',
      quizHash
    }, '*');
  }

  const quizSrc = `${config.quizURL}/quiz/${quizNameSlug}?preview=true&hasHash=true`;
  const liveLeaderboardSrc = `${config.quizURL}/quiz/${quizNameSlug}/live-${quiz?.type === QuizTypes.Poll ? 'results' : 'leaderboard'}`;

  const src = preview === 'quiz' ? quizSrc : liveLeaderboardSrc;

  return (
    <iframe
      ref={iframeRef}
      style={style}
      id={id}
      src={src}
      title={`${preview}Preview`}
      onLoad={onLoad}
    />
  )
}

export default QuizFrame;
