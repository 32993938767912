import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { NamespaceAsync } from 'store/namespace/namespaceAsync';
import { namespaceActions } from 'store/namespace/namespaceSlice';
import { getNamespaceItem } from 'store/namespace/namespaceSelectors';
import { selectUsersOptions } from 'store/user/userSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Input, Select } from 'components/Controls';
import { Button } from 'components/Buttons';
// Utilities
import { isRequired } from 'utilities/validation';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  namespaceId?: any;
  open: boolean;
  onClose: () => void;
}

const NamespaceFormDialog:FC<Props> = ({ namespaceId, open, onClose }) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  
  const userOptions = useAppSelector(selectUsersOptions);
  const namespaceItem = useAppSelector(getNamespaceItem);

  useEffect(() => {
    if ( namespaceId ) dispatch(NamespaceAsync.fetchNamespaceById(namespaceId));
    return () => {
      if ( namespaceId ) dispatch(namespaceActions.setInitialField('item'));
    }
    // eslint-disable-next-line
  }, []);

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    defaultValues: {
      name: '',
      ownerId: ''
    }
  });

  useEffect(() => {
    if ( namespaceItem ) reset(namespaceItem);
    // eslint-disable-next-line
  }, [namespaceItem]);

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    if ( namespaceId ){
      dispatch(NamespaceAsync.updateNamespace({ namespaceId, namespaceData: { owner: data.ownerId } })).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    } else {
      dispatch(NamespaceAsync.createNamespace({ name: data.name })).unwrap()
        .then(() => onClose())
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <form onSubmit={onSubmit} noValidate>
        <DialogTitle title={namespaceId ? t('dialogs.namespaceForm.updateCategory') : t('dialogs.namespaceForm.createCategory')} onClose={onClose} />
        <DialogContent>
          {namespaceId ? (
            <React.Fragment>
              {namespaceItem ? <span className="block text-white text-sm mb-4">{namespaceItem.name}</span> : null}
              <Controller
                control={control} name="ownerId" rules={{ required: isRequired }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    label={t('dialogs.namespaceForm.owner')} id="ownerId" name="ownerId" value={value}
                    required={true}
                    options={[
                      { value: '', label: t('dialogs.namespaceForm.chooseOwner') },
                      ...userOptions
                    ]}
                    onChange={onChange}
                    error={Boolean(errors.ownerId)}
                    errorText={errors.ownerId ? errors.ownerId.message : ''}
                  />
                )}
              />
            </React.Fragment>
          ) : (
            <Controller
              control={control} name="name" rules={{ required: isRequired }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t('common.name')} id="name" name="name" value={value}
                  required={true}
                  onChange={onChange}
                  error={Boolean(errors.name)}
                  errorText={errors.name ? errors.name.message : ''}
                  autoFocus={true}
                />
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.cancel')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            type="submit"
            loading={isLoading}
          >{t('common.save')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default NamespaceFormDialog;
