import { ChangeEvent, FC, useState, useEffect } from 'react';
// Models
import IQuiz from 'models/Quiz';
// Store
import { useAppSelector, useAppDispatch } from 'hooks/useStore';
// Actions
import { setFilter, setInitialField } from 'store/quiz/quizzesSlice';
// Selectors
import { selectQuizzes, selectFilter } from 'store/quiz/quizzesSelectors';
// Components
import { Input } from 'components/Controls';
// i18next
import { useTranslation } from 'react-i18next';

let timeout:any = null;

const QuizzesFilter:FC = () => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const quizzes:IQuiz[] | null = useAppSelector(selectQuizzes);
  const filter:any = useAppSelector(selectFilter);

  const [ stateFilter, setStateFilter ] = useState<any>(filter);

  useEffect(() => {
    return () => {
      if ( timeout ) clearTimeout(timeout);
      dispatch(setInitialField('filter'));
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setStateFilter((prevState:any) => ({ ...prevState, [name]: value }));
    if ( timeout ) clearTimeout(timeout);
    timeout = setTimeout(() => {
      dispatch(setFilter({ field: name as any, value }));
    }, 1000);
  }

  if ( !quizzes || !quizzes.length ) return null;
  return (
    <Input
      className="mb-0"
      id="search" name="search" value={stateFilter.search} placeholder={t('common.search')}
      onChange={handleChange}
    />
  );
}

export default QuizzesFilter;
