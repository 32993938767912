import { createAsyncThunk } from '@reduxjs/toolkit';
import { replace } from 'connected-react-router';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Utilities
import HttpClient from 'utilities/HttpClient';
// Actions
import { appActions } from 'store/app/appSlice';
import { setIsAuthenticated } from 'store/auth/authSlice';
import { setCurrentUser } from './currentUserSlice';
// ToDO: Should be moved to some JSON file with all errors
import { errorMessage } from 'utilities/error-message';
import { currentUserMessages } from './currentUserMessages';

const _url:string = '/user';
const _http:HttpClient = new HttpClient();

// Fetch me
export const getCurrentUser = createAsyncThunk(
  'currentUser/Get current user',
  async (isAfterLogin:boolean, { rejectWithValue, dispatch }) => {
    const response:Response = await _http.get(`${_url}/self`);
    if ( !response.ok ){
      dispatch(setIsAuthenticated(false));
      return rejectWithValue({ showNotification: false });
    }
    const currentUser:IUser = await response.json();
    dispatch(setCurrentUser(currentUser));
    dispatch(setIsAuthenticated(true));
    if ( isAfterLogin ) dispatch(replace(currentUser.groups.includes(UserRoles.Admin) ? '/admin/users' : '/owner/quizzes'));
  }
);
// Update me
export const updateCurrentUser = createAsyncThunk(
  'currentUser/Update current user',
  async (data:any, { rejectWithValue, fulfillWithValue, dispatch }) => {
    const response:Response = await _http.put(`${_url}/self`, data);
    if ( !response.ok ){
      const body = await response.json();
      const message = body.message || `${errorMessage('deleting')} API error: ${body.status}`
      return rejectWithValue(message);
    }
    dispatch(appActions.createNotification({ message: currentUserMessages.profileUpdate }))
    return fulfillWithValue(data);
  }
);
