import { FC, Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { ResultAsync } from 'store/result/resultAsync';
import { resultActions } from 'store/result/resultSlice';
import { getParams, selectReports } from 'store/result/resultSelectors';
import { selectQuiz } from 'store/quiz/quizzesSelectors';
import IQuiz from 'models/Quiz';
import QuizTypes from 'types/QuizTypes';
// Components
import { Loader, Message } from 'components/Utilities';
import ReportListItem from './ReportListItem';
// i18next
import { useTranslation } from 'react-i18next';

const ReportList:FC = () => {
  const { t } = useTranslation('common');
  const { quizId } = useParams<{ quizId:string }>();

  const dispatch = useAppDispatch();
  
  const quiz:IQuiz | null = useAppSelector(selectQuiz);
  const reports = useAppSelector(selectReports);
  const params = useAppSelector(getParams);

  useEffect(() => {
    dispatch(ResultAsync.fetchResultList({
      ...params,
      quizId,
      key: 'score',
      direction: 'desc'
    }));
    return () => {
      dispatch(resultActions.setInitialField('items'));
    }
    // eslint-disable-next-line
  }, []);

  const handleSortBy = (key:string) => {
    const direction = params.direction === 'asc' ? 'desc' : 'asc';
    dispatch(ResultAsync.fetchResultList({
      ...params,
      quizId,
      offset: 0,
      key,
      direction
    }));
  };

  const getClassNamesFor = (key:string) => {
    return params.key === key ? `sort-${params.direction}` : '';
  };

  const label = quiz 
    ? quiz.type === QuizTypes.Personality
      ? 'Winning bucket'
      : 'Score'
    : null
  ;

  if ( !reports ) return <Loader />;
  if ( reports.length === 0 ) return <Message text={t('ownerPage.quizReportPage.noReports')} />;
  return (
    <Fragment>
      <table>
        <thead>
          <tr>
            <th
              className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('user.name')}`}
              onClick={() => handleSortBy('user.name')}
            >{t('common.firstName')}</th>
            <th
              className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('user.surname')}`}
              onClick={() => handleSortBy('user.surname')}
            >{t('common.lastName')}</th>
            <th
              className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('user.username')}`}
              onClick={() => handleSortBy('user.username')}
            >{t('common.username')}</th>
            <th
              className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('user.email')}`}
              onClick={() => handleSortBy('user.email')}
            >{t('common.email')}</th>
            {quiz?.type !== QuizTypes.Poll && (
              <th
                className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('score')}`}
                onClick={() => handleSortBy('score')}
              >{label}</th>
            )}
            <th
              className={`text-left hover:bg-gray-500 cursor-pointer ${getClassNamesFor('createdAt')}`}
              onClick={() => handleSortBy('createdAt')}
            >{t('common.dateCreated')}</th>
            <th className="text-right">{t('common.actions')}</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report:any, index:number) => (
            <ReportListItem key={`report-item-${index}`} report={report} />
          ))}
        </tbody>
      </table>
    </Fragment>
  )
}

export default ReportList;
