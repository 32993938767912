import { FC } from 'react';
// Components
import Analytics from './Analytics';
import { Toolbar } from 'components/Toolbar';
// i18next
import { useTranslation } from 'react-i18next';

const AnalyticsPage:FC = () => {
  const { t } = useTranslation('common');

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <Toolbar title={t('common.analytics')} />
      <Analytics />
    </div>
  );
}

export default AnalyticsPage;
