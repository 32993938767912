import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Icons
import { FaTrash } from 'react-icons/fa';
// Types
import QuizTypes from 'types/QuizTypes';
// Models
import IAnswer from 'models/Answer';
import IBucket from 'models/Bucket';
// Redux
import { useAppSelector } from 'hooks/useStore';
// Selector
import { selectQuiz } from 'store/quiz/quizzesSelectors';
// Components
import { IconButton } from 'components/Buttons';
import { Checkbox } from 'components/Controls';
import { Tooltip } from 'components/Tooltip';
// Utilities
import { isRequired } from 'utilities/validation';
// 
import AnswerBucketScore from './AnswerBucketScore';
import MultiLanguageTextField from 'components/MultiLanguageTextField';

type Props = {
  index: number;
  questionIndex: number;
  onDelete?: (index:number) => void;

  questionRef: string;
}

const QuestionAnswer:FC<Props> = ({
  // Props
  index, questionIndex, onDelete, questionRef
}) => {
  const { t } = useTranslation('common');

  const name = `questions[${questionIndex}].answers`;

  const quiz = useAppSelector(selectQuiz);

  const { control, watch, setValue, getValues, formState:{ errors } } = useFormContext();

  const { fields, replace } = useFieldArray({
    control,
    name: 'bucketScores'
  });

  const getBuckets = getValues('buckets');

  const getAnswerError = useCallback(() => {
    if ( !errors || !errors.questions ) return '';

    const question = (errors as any).questions[questionIndex];

    if ( !question || !question.answers ) return '';

    const answer = question.answers[index];

    return answer && answer.title ? answer.title.message : ''
    // eslint-disable-next-line
  }, [errors])();

  useEffect(() => {
    if ( getBuckets ){
      replace(getBuckets.map((bucket:IBucket) => ({
        ...bucket,
        score: 0
      })));
    }
    // eslint-disable-next-line
  }, [getBuckets]);

  const handleClick = () => {
    if ( typeof onDelete === 'undefined' ) return;
    onDelete(index);
  }

  const handleChange = (onChange:any) => (event:ChangeEvent<HTMLInputElement>) => {
    const answers = watch(name);
    if ( watchedQuestionType === 'singleChoice' ){
      setValue(name, answers.map((answer:IAnswer, idx:number) => {
        const correct = index !== idx ? false : !answer.correct;
        return { ...answer, correct };
      }));
    }
    onChange(event.target.checked);
  }

  const watchedQuestionType = watch(`questions.${questionIndex}.type`);
  const watchedCorrect = watch(`${name}.${index}.correct`);
  const watchedAnswer = watch(`${name}.${index}`);

  return (
    <div className="flex-grow">
      <div className="flex gap-4 mb-1">
        <MultiLanguageTextField
          rules={{ required: isRequired }}
          className="mb-0 flex-grow"
          name={`${name}.${index}.title`}
          label={`${t('common.answer')} ${index + 1}`}
          required={true}
          error={Boolean(getAnswerError)}
          errorText={getAnswerError}
          customKey={`questions[${questionRef}].answers.${watchedAnswer.ref}.title`}
        />
        {typeof onDelete !== 'undefined' ? (
          <div>
            <span className="block mb-1 h-5"></span>
            <Tooltip title={t('common.removeAnswer')}>
              <IconButton
                onClick={handleClick}
                ><FaTrash /></IconButton>
            </Tooltip>
          </div>
        ) : null}
      </div>
      {quiz?.type === QuizTypes.Scoring && (
        <Controller
          control={control} name={`${name}.${index}.correct`}
          render={({ field }) => (
            <Checkbox
              {...field}
              className="mb-0"
              label={t('ownerPage.quizForm.correctAnswer')}
              id={`${name}.${index}.correct`}
              checked={watchedCorrect}
              onChange={handleChange(field.onChange)}
            />
          )}
        />
      )}
      {quiz?.type === QuizTypes.Personality && (
        <div className="border border-divider rounded p-4">
          <span className="block text-gray-400 text-sm font-light leading-5 mb-2">{t('ownerPage.quizForm.bucketScores')}</span>
          <div className="
            grid
            grid-cols-1 landscape:grid-cols-2 tablet:grid-cols-3
            gap-4
          ">
            {fields.map((field:any, idx:number) => (
              <AnswerBucketScore
                key={`${index}-answer-bucket-item-${idx}`}
                questionIndex={questionIndex}
                answerIndex={index}
                index={idx}
                bucketRef={field.ref}
                bucketTitle={field.title}
              />
            ))}
          </div>
        </div>
      )}
      <span className="divider"></span>
    </div>
  )
}

export default QuestionAnswer;
