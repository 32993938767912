import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const getGeofenceItems = (state:RootState) => state.geofence.items;

export const getGeofenceItemsForSelect = createSelector(
  getGeofenceItems, (items) => {
    if ( !items || items.length === 0 ) return [];
    return items.map((item) => ({ value: item.value, label: item.name, allowed: item.allowed }));
  }
);
