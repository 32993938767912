import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
// Components
import { Input } from 'components/Controls';

type Props = {
  questionIndex: number;
}

const QuestionGameScoreAnswer:FC<Props> = ({ questionIndex }) => {
  const name = `questions[${questionIndex}].answers`;

  const { watch, setValue } = useFormContext();

  // eslint-disable-next-line
  const watched = watch(`${name}`); // don't delete
  const watchedTitle = watch(`${name}.0.title`) || '';
  const scores = watchedTitle.split('-');

  const [firstScore, setFirstScore] = useState<string>(scores[0] || '');
  const [secondScore, setSecondScore] = useState<string>(scores[1] || '');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    if (name.includes('answers.0.title')) {
      setFirstScore(value);
    } else {
      setSecondScore(value);
    }
  }

  useEffect(() => {
    const nextValue = (firstScore !== '' || secondScore !== '') ? [{
      ref: uuid(),
      title: `${firstScore}-${secondScore}`,
      correct: true,
    }] : [];
    setValue(name, nextValue);
    // eslint-disable-next-line
  }, [firstScore, secondScore])

  return (
    <div className="flex gap-4">
      <Input
        id={`${name}.0.title`}
        name={`${name}.0.title`}
        className="mb-0 flex-grow"
        onChange={onChange}
        value={firstScore}
        type="number"
      />

      <Input
        id={`${name}.1.title`}
        name={`${name}.1.title`}
        className="mb-0 flex-grow"
        onChange={onChange}
        value={secondScore}
        type="number"
      />
    </div>
  )
}

export default QuestionGameScoreAnswer;
