import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const getAnalytics = (state:RootState) => state.result.analytics;
export const getQuizAnalytics = (state:RootState) => state.result.quizAnalytics;
export const getReportItems = (state:RootState) => state.result.items;
export const getTotalItems = (state:RootState) => state.result.totalItems;
export const getParams = (state:RootState) => state.result.params;
export const getUpdateCorrectAnswersLoading = (state:RootState) => state.result.updateCorrectAnswersLoading;
export const getQuestionsResults = (state:RootState) => state.result.questionsResults;
export const getSelectedTab = (state:RootState) => state.result.selectedTab;

export const getQuestionResults = createSelector(
  [
    getQuestionsResults,
    getSelectedTab,
    (_, questionRef:string) => questionRef
  ],
  (questionsResults, tab, questionRef) => {
    if (!questionsResults) return null;
    return questionsResults[tab]?.find((q:any) => q.questionRef === questionRef) || null;
  }
)

export const selectReports = createSelector(
  [ getReportItems ],
  (reports) => {
    if ( !reports ) return null;

    return reports.map((report) => {
      const { buckets = [], ...otherData } = report;
      const bucket = buckets && buckets.length
        ? report.buckets.reduce((acc, cur) => {
            return acc.score < cur.score ? cur : acc;
          }, { title: '', score: -1 })
        : null
      ;
      return {
        ...otherData,
        score: bucket ? bucket.score : report.score,
        bucket
      }
    })
  }
)
