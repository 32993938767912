import React, { Fragment, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
// Types
import UserRoles from 'types/UserRoles';
// Store
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
// Actions
import { inviteOwner, inviteStaff, inviteAdministrator } from 'store/user/userAsync';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
import { selectLoading } from 'store/user/userSelectors';
// Layouts
import { Dialog, DialogTitle, DialogContent, DialogActions } from 'layouts/Dialog';
// Components
import { Checkbox, Input, Select } from 'components/Controls';
import { Button } from 'components/Buttons';
// Utilities
import { isRequired, isEmail } from 'utilities/validation';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  name: string;
  surname: string;
  email: string;
  group: UserRoles;
  organization?: string;
  cloneQuizzes?: boolean;
}

type Props = {
  open: boolean;
  onClose: () => void;
}

const InviteUserForm:React.FC<Props> = ({
  // Props
  open, onClose
}) => {
  const { t } = useTranslation('common');
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const currentUser = useAppSelector(selectCurrentUser);
  const loaidng:boolean = useAppSelector(selectLoading);

  const isAdmin = currentUser?.groups.includes(UserRoles.Admin);

  const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm<IFormData>({
    defaultValues: {
      group: isAdmin ? UserRoles.Admin : UserRoles.Staff,
      name: '',
      surname: '',
      email: '',
      organization: '',
      cloneQuizzes: false
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    const { cloneQuizzes, organization, ...otherData } = data;
    const nextData:IFormData = { ...otherData };
    if ( [UserRoles.Admin, UserRoles.Owner].includes(data.group) ) nextData['organization'] = organization || 'Internal';
    if ( data.group === UserRoles.Owner && cloneQuizzes ) nextData['cloneQuizzes'] = cloneQuizzes;
    try {
      if ( data.group === UserRoles.Admin ) await dispatch(inviteAdministrator(nextData)).unwrap();
      if ( data.group === UserRoles.Owner ) await dispatch(inviteOwner(nextData)).unwrap();
      if ( data.group === UserRoles.Staff ) await dispatch(inviteStaff(nextData)).unwrap();
      onClose();
    } catch(error){}
  });

  const groupWatch = watch('group');

  useEffect(() => {
    if (groupWatch !== UserRoles.Owner) {
      setValue('organization', '');
      setValue('cloneQuizzes', false);
    }
    // eslint-disable-next-line
  }, [groupWatch]);

  return (
    <Dialog size="sm" open={open} onClose={onClose}>
      <form className="flex flex-col overflow-y-auto" onSubmit={onSubmit} noValidate>
        <DialogTitle title={t('dialogs.inviteUserForm.inviteUser')} onClose={onClose} />
        <DialogContent>
          {isAdmin ? (
            <Controller
              control={control} name="group" rules={{ required: isRequired }}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={t('dialogs.inviteUserForm.group')} id="group" name="group" value={value}
                  required={true}
                  onChange={onChange}
                  error={Boolean(errors.group)}
                  errorText={errors.group ? errors.group.message : ''}
                  options={[
                    { value: UserRoles.Admin, label: t('dialogs.inviteUserForm.admin') },
                    { value: UserRoles.Owner, label: t('dialogs.inviteUserForm.owner') },
                  ]}
                />
              )}
            />
          ) : null}
          <Controller
            control={control} name="name" rules={{ required: isRequired }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('common.name')} id="name" name="name" value={value}
                required={true}
                onChange={onChange}
                error={Boolean(errors.name)}
                errorText={errors.name ? errors.name.message : ''}
              />
            )}
          />
          <Controller
            control={control} name="surname" rules={{ required: isRequired }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('common.surname')} id="surname" name="surname" value={value}
                required={true}
                onChange={onChange}
                error={Boolean(errors.surname)}
                errorText={errors.surname ? errors.surname.message : ''}
              />
            )}
          />
          <Controller
            control={control} name="email" rules={{ required: isRequired, pattern: isEmail }}
            render={({ field: { value, onChange } }) => (
              <Input
                label={t('common.email')} id="email" type="email" name="email" value={value}
                required={true}
                onChange={onChange}
                error={Boolean(errors.email)}
                errorText={errors.email ? errors.email.message : ''}
              />
            )}
          />
          {groupWatch === UserRoles.Owner ? (
            <Fragment>
              <Controller
                control={control} name="organization" rules={{ required: isRequired }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label={t('common.organization')} id="organization" name="organization"
                    required={true}
                    error={Boolean(errors.organization)}
                    errorText={errors.organization ? errors.organization.message : ''}
                  />
                )}
              />
              <Controller
                control={control} name="cloneQuizzes"
                render={({ field: { value, ...otherFields } }) => (
                  <Checkbox
                    {...otherFields}
                    checked={value || false}
                    label="Clone quizzes to owner account"
                    id="cloneQuizzes"
                  />
                )}
              />
            </Fragment>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
          >{t('common.cancel')}</Button>
          <Button
            className="ml-2"
            variant="contained"
            type="submit"
            loading={loaidng}
          >{t('common.invite')}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InviteUserForm;
