import { FC, useState } from 'react';
// Components
import { Tooltip } from './Tooltip';
// icons
import { AiOutlineCopy, AiOutlineCheck } from 'react-icons/ai';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {
  text: string;
}

const Copy:FC<Props> = ({ text }) => {
  const { t } = useTranslation('common');

  const [copied, setCopied] = useState<boolean>(false);

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  }

  const copyToClipBoard = () => {
    try {
      var temp = document.createElement('input');
      document.body.append(temp);
      temp.value = text;
      temp.select();
      document.execCommand('copy');
      temp.remove();
      handleCopied();
    } catch { }
  }

  const handleCopy = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text).then(() => handleCopied());
      } catch { }
    } else {
      copyToClipBoard();
    }
  }

  if (copied) return (
    <div className="flex items-center gap-1">
      <AiOutlineCheck className="text-white" />
      <p className="text-white text-xs">{`${t('common.copied')}..`}</p>
    </div>
  );

  return (
    <Tooltip title={t('common.copyToClipboard')}>
      <AiOutlineCopy
        className="cursor-pointer text-gray-400 transition duration-300 hover:text-white"
        onClick={handleCopy}
      />
    </Tooltip>
  );
}

export default Copy;
