import QuizTypes from "types/QuizTypes";

export const initialQuizForm = {
  advertImageEnabled: true,
  advertImage: '',
  advertUrl: '',

  type: QuizTypes.Scoring,

  advertVideoEndQuiz: false,
  advertVideoMidQuiz: false,
  advertVideoStartQuiz: false,
  advertVideoUrl: '',
  landingPage: {
    callToAction: {
      text: '',
      image: '',
      url: '',
      buttonTitle: 'Play now'
    }
  },
  registrationPage: {
    anonymous: false,
    caption: '',
    showPriorQuiz: 'false',
    requirePhoneNumber: false,
  },
  displayName: '',
  brand: '',
  brandVideo: '',
  callToActionColor: '#FA8E01',
  countDownImage: '',
  countDownImageOpacity: '',
  countDownTextColor: '',
  endsDate: '',
  hashtags: '',
  interstitialImage: '',
  interstitialImage2: '',
  logoImage: '',
  sponsorImage: '',
  countDownLogoImage: '',
  mastheadImage: '',
  namespace: '',
  owner: '',
  postQuizQuestion: '',
  postQuizQuestionAnswer1: '',
  postQuizQuestionAnswer2: '',
  postQuizQuestionAnswer3: '',
  postQuizQuestionAnswer4: '',
  postQuizQuestionEnabled: false,
  primaryColor: '#383838',
  primaryFont: 'Montserrat',
  questionsFont: 'Montserrat',
  prizes: [],
  backets: [],
  proceedButtonTitle: 'See how you placed',
  proceedEnabled: true,
  proceedImage: '',
  proceedText: '',
  proceedUrl: '',
  proceedClickBonusPoints: '500',
  socialShareBonusValue: 500,
  promoVideo: '',
  publicDevicePlayableOnly: false,
  quizGroup: '',
  quizName: '',
  quizNameVisible: false,
  secondaryColor: '#FA8E01',
  showBrandLogoOnWaiver: false,
  showSponsorImageOnRegisterPage: false,
  showWaiverBeforeQuiz: false,
  spotPrizesEnabled: false,
  spotPrizes: '',
  socialShareEnabled: true,
  socialShareImage: '',
  socialShareURL: '',
  startsDate: '',
  tertiaryColor: '#31373A',
  videoEndQuiz: '',
  videoMidQuiz: '',
  videoStartQuiz: '',
  videoUrl: '',
  waiverText: '',
  wallpaperImage: '',
  applyPrimaryColorToProgressBar: false,
  randomizeQuestionOrder: false,
  questionLimit: '',
  language: 'en',
  additionalLanguages: [],
  translations: {},
  highlightCorrectAnswer: false,
  publicLeaderboard: {
    enabled: false,
    headerText: '',
    wallpaperImage: '',
    quizQRCodeEnabled: false,
    quizQRCodeTitle: 'Join the quiz now!',
    quizQRCodeLink: '',
    textColor: '#FFFFFF',
  },
  consents: []
};