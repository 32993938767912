import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const getGroupItems = (state:RootState) => state.group.items;
export const getGroupItem = (state:RootState) => state.group.item;
export const getFilter = (state:RootState) => state.group.filter;

export const getFilteredGroupItems = createSelector(
  getGroupItems, getFilter,
  (groupItems, filter) => {
    if ( !groupItems ) return null;
    const { search } = filter;
    const searchToLower = search.toLowerCase();
    const groups = groupItems.map((group) => ({
      ...group,
      createdByName: group.createdBy ? `${group.createdBy.name} ${group.createdBy.surname}` : ''
    }));
    if ( !searchToLower ) return groups;
    return groups.filter((groupItem) => groupItem.name.toLowerCase().includes(searchToLower));
  }
);

export const getGroupItemsForSelect = createSelector(
  getGroupItems, (items) => {
    if ( !items || items.length === 0 ) return [];
    return items.map((item) => ({ value: item._id, label: item.name }));
  }
)
