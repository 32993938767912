import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AccountsAsync from './accountsAsync';
import IAccount from 'models/Account';

interface IState {
  account: IAccount | null;
};

const initialState:IState = {
  account: null,
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setInitialField: <IStateKey extends keyof IState>(state: IState, action: PayloadAction<IStateKey>) => {
      state[action.payload] = initialState[action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(AccountsAsync.getAccount.fulfilled, (state, action:PayloadAction<IAccount>) => {
      state.account = action.payload;
    })
  }
});

export const accountsActions = slice.actions;

export default slice.reducer;
