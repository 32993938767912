import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// Redux
import { useAppDispatch } from 'hooks/useStore';
import { getQuiz } from 'store/quiz/quizzesAsync';
// Components
import { QuizToolbar } from 'components/QuizToolbar';
import Analytics from './Analytics';
import Charts from './Charts';
import SocialsBars from './SocialsBars';

const QuizAnalyticsPage:FC = () => {
  const { quizId } = useParams<{ quizId:string }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getQuiz(quizId));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container max-w-screen-desktop flex-grow py-4 overflow-y-auto">
      <QuizToolbar />
      <Analytics />
      <Charts />
      <SocialsBars />
    </div>
  );
}

export default QuizAnalyticsPage;
