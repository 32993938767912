import React from 'react';
import config from '../config';
// Redux
import { useAppDispatch, useAppSelector } from 'hooks/useStore';
import { appActions } from 'store/app/appSlice';
import { getNamespaceSlug, getPublicDeviceSnackbarOpen, getQuizNameSlug } from 'store/app/appSelectors';
// MUI
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
// Service
import { clearPlayerCookies } from 'store/app/app.service';
// i18next
import { useTranslation } from 'react-i18next';

type Props = {}

const PublicDeviceSnackbar:React.FC<Props> = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const publicDeviceSnackbarOpen = useAppSelector(getPublicDeviceSnackbarOpen);
  const quizNameSlug = useAppSelector(getQuizNameSlug);
  const namespaceSlug = useAppSelector(getNamespaceSlug);

  const handleClose = () => {
    if ( quizNameSlug && namespaceSlug ){
      fetch(`${config.apiURL}/player/auth/sign-out`)
        .then(() => {
          clearPlayerCookies(quizNameSlug);
          window.location.href = `/quiz/${quizNameSlug}`;
          dispatch(appActions.closePublicDeviceSnackbar());
        });
    }
  }

  if ( !publicDeviceSnackbarOpen ) return null;
  return (
    <div className="p-2" style={{ maxWidth: '420px', width: '100%', marginRight: 'auto', marginLeft: 'auto' }}>
      <Alert
        action={
          <Button
            size="small"
            onClick={handleClose}
            variant="contained"
            color="primary"
          >{t('components.publicDeviceSnackbar.ok')}</Button>
        }
      >{t('components.publicDeviceSnackbar.restartQuizForNextPlayer')}</Alert>
    </div>
  )
}

export default PublicDeviceSnackbar;
