import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { replace } from 'connected-react-router';
// Store
import { useAppSelector, useAppDispatch } from 'hooks/useStore';
// Async
import { changePassword } from 'store/user/userAsync';
// Selectors
import { selectLoading } from 'store/user/userSelectors';
// Components
import { Toolbar } from 'components/Toolbar';
import { Input } from 'components/Controls';
import { Button } from 'components/Buttons';
// Utilities
import { isRequired, isPassword, isMatch } from 'utilities/validation';
// i18next
import { useTranslation } from 'react-i18next';

interface IFormData {
  password: string;
  rePassword?: string;
};

const ChangePasswordPage:FC = () => {
  const { t } = useTranslation('common');
  const { pathname } = useLocation<{ pathname:string }>();
  // Dispatch
  const dispatch = useAppDispatch();
  // State
  const loading:boolean = useAppSelector(selectLoading);

  const query = useQuery();

  const title = pathname.includes('/change-password') ? t('common.change') : t('common.create');

  const { control, handleSubmit, formState: { errors }, watch } = useForm<IFormData>({
    defaultValues: {
      password: '',
      rePassword: ''
    }
  });

  const onSubmit = handleSubmit(async (data:IFormData) => {
    try {
      const token = query.get('token');
      if ( token ){
        const newData:any = { token, password: data.password };
        await dispatch(changePassword(newData)).unwrap();
        dispatch(replace('/'))
      }
    } catch(error){}
  });

  const passwordWatched = watch('password');

  return (
    <div className="flex-grow overflow-y-auto py-4">
      <div className="container max-w-screen-xl">
        <Toolbar className="max-w-lg mx-auto" title={`${title} ${t('common.password')}`} />
        <div className="max-w-lg mx-auto bg-content rounded p-8">
          <form onSubmit={onSubmit} noValidate>
            <Controller
              control={control} name="password" rules={{
                required: isRequired,
                validate: {
                  isPassword
                }
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t('changePasswordPage.password')} id="password" type="password" name="password" value={value}
                  required={true}
                  onChange={onChange}
                  error={Boolean(errors.password)}
                  errorText={errors.password ? errors.password.message : ''}
                />
              )}
            />
            <Controller
              control={control} name="rePassword" rules={{
                required: isRequired,
                validate: {
                  isPasswordMatch: (value:string | undefined) => isMatch(value, passwordWatched, t('changePasswordPage.passwordDoesNotMatch'))
                }
              }}
              render={({ field: { value, onChange } }) => (
                <Input
                  label={t('changePasswordPage.rePassword')} id="rePassword" type="password" name="rePassword" value={value}
                  required={true}
                  onChange={onChange}
                  error={Boolean(errors.rePassword)}
                  errorText={errors.rePassword ? errors.rePassword.message : ''}
                />
              )}
            />
            <div className="mb-4">
              <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
                {`* ${t('changePasswordPage.passwordHint1')}`}
              </span>
              <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
                {`* ${t('changePasswordPage.passwordHint2')}`}
              </span>
              <span className="block text-gray-400 text-sm font-light leading-5 mt-2">
                {`* ${t('changePasswordPage.passwordHint3')}`}
              </span>
            </div>
            <div className="text-right">
              <Button
                className="ml-2"
                variant="contained"
                type="submit"
                loading={loading}
              >{title}</Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordPage;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}
