import LogoImage from 'assets/images/Logo.png';

import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
// Types
import UserRoles from 'types/UserRoles';
// Models
import { IUser } from 'models/User';
// Store
import { useSelector } from 'react-redux';
// Selectors
import { selectCurrentUser } from 'store/currentUser/currentUserSelectors';
// 
import UserText from './UserText';
import UserMenu from './UserMenu';
import LanguageSelect from 'components/LanguageSelect';

const Header:FC = () => {
  // State
  const currentUser:IUser | null = useSelector(selectCurrentUser);

  const homeURL = currentUser
    ? currentUser.groups.includes(UserRoles.Admin)
      ? '/admin/users'
      : '/owner/quizzes'
    : '/'
  ;

  return (
    <header
      className="flex items-center flex-shrink-0 z-40"
      style={{
        background: 'linear-gradient(90deg, #0c141a 0%, #1d262d 100%)',
        height: '78px',
      }}
    >
      <div className="container max-w-screen-desktop flex items-center">
        <Link to={homeURL}>
          <img className="h-12" src={LogoImage} alt="Quizwizard - Admin panel" />
        </Link>
        <span className="flex-1"></span>
        <div className="flex items-center font-semibold">
          <LanguageSelect />
          {currentUser ? (
            <Fragment>
              <UserText />
              <UserMenu />
            </Fragment>
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default Header;
